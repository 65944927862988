import React from "react";

const LoadingIcon = ({ loading }: { loading: boolean }) => {
  return (
    <>
      {loading ? (
        <span className="file-icon">
          <i className="fas fa-circle-notch fa-spin" />
        </span>
      ) : null}
    </>
  );
};

export default LoadingIcon;
