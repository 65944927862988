import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextareaAutosize } from "@mui/material";
import React, { useEffect, useState } from "react";

const TrackerCommentModal = (props: any) => {
  const [comment, setComment] = useState<string>("");

  useEffect(() => {
    setComment(props.selectedRowTrackerComment?.original?.remarks);
    // eslint-disable-next-line
  }, []);

  const saveComment = () => {
    const updatedRowsDataTrackerLevel = props.rowsDataTrackerLevel?.map((v) =>
      v.businessPartnerId === props.selectedRowTrackerComment.original.businessPartnerId &&
      v.activityMonth === props.selectedRowTrackerComment.original.activityMonth
        ? { ...v, remarks: comment }
        : v
    );
    props.setRowsDataTrackerLevel(updatedRowsDataTrackerLevel);
    props.setStoreRowsDataTrackerLevel(updatedRowsDataTrackerLevel);
    handleClose();
    props.saveUpdateRemarksTracker(
      props.selectedRowTrackerComment.original.businessPartnerId,
      props.selectedRowTrackerComment.original.activityMonth,
      comment
    );
  };

  const handleTextChange = (event) => {
    setComment(event.target.value);
  };

  const handleClose = () => {
    props.setOpenTrackerCommentModal(false);
  };
  return (
    <div>
      <Dialog open={props.openTrackerCommentModal} aria-labelledby="TrackerCommentModal">
        <DialogTitle className="dialog_header">
          <p className="fs_24">Comments/ Remarks</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div className="mt_30">
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              style={{ width: "500px", maxHeight: "50vh", padding: "15px", overflow: "auto" }}
              value={comment}
              onChange={handleTextChange}
            />
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer flex_end">
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                saveComment();
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TrackerCommentModal;
