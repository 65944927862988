import { Autocomplete, Box, Button, Card, CardContent, createFilterOptions, Grid, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";

const ledgerReportDaysLimit = 90;
const Ledger = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const [selectedVendor, setSelectedVendor] = useState({ vendorCode: "All", id: "all", name: "Select All" });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errorFutureDate, setErrorFutureDate] = useState<boolean>(false);
  const [errorEndFutureDate, setErrorEndFutureDate] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const [entitiesList, setEntitiesList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState<{ companyId: string; companyName: string }>({
    companyId: "all",
    companyName: "Select All",
  });
  const [branchesList, setBranchesList] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState({ branchCode: "all", branchName: "Select All" });
  const [ownBusinessPartners, setOwnBusinessPartners] = useState([]);

  const [errorStartMsg, setErrorStartMsg] = useState("");
  const [errorEndMsg, setErrorEndMsg] = useState("");

  const listCompanies = async () => {
    await useFetch("/api/ListCompanies", "GET", {
      thenCallBack: (response) => {
        setEntitiesList(response?.data?.companies);
      },
    });
  };
  const listBranches = async (companyId) => {
    await useFetch("/api/ListBranches", "GET", {
      config: {
        params: {
          companyId,
        },
      },
      thenCallBack: (response) => {
        setBranchesList(response?.data?.branches);
      },
    });
  };
  const listAllBusinessPartners = async (companyId, branchCode) => {
    await useFetch("/api/ListAllBusinessPartners", "GET", {
      config: {
        params: {
          ownId: actor.id,
          companyId: companyId !== "all" ? companyId : null,
          branchCode: branchCode !== "all" ? branchCode : null,
        },
      },
      thenCallBack: (response) => {
        setOwnBusinessPartners(response.data.partnerList);
      },
    });
  };
  const handleStartDateChange = (date) => {
    setStartDate(date);
    // Check if end date is more than 90 days ahead
    if (date) {
      const maxEndDate = new Date(date);
      maxEndDate.setDate(maxEndDate.getDate() + ledgerReportDaysLimit);
      if (endDate && endDate > maxEndDate) {
        setEndDate(null);
      }
      if (endDate && date && date > endDate) setEndDate(null);
    }
  };
  const handleEndDateChange = (date) => {
    // Check if end date is not before start date and not more than 90 days ahead
    if (date && startDate) {
      const maxEndDate = new Date(startDate);
      maxEndDate.setDate(maxEndDate.getDate() + ledgerReportDaysLimit);
      if (date < startDate || date > maxEndDate) {
        setEndDate(null);
        return;
      }
    }
    setEndDate(date);
  };

  const downloadLedgerData = async () => {
    setShowLoadingIcon(true);

    useFetch("/api/ReturnLedgerDataAsExcel", "POST", {
      showSuccessToast: true,
      data: {
        ownId: actor.id,
        startDate: startDate,
        endDate: endDate,
        businessPartnerId: selectedVendor.id !== "all" ? selectedVendor.id : null,
        vendorCode: selectedVendor.vendorCode !== "All" ? selectedVendor.vendorCode : null,
        branchCode: selectedBranch.branchCode !== "all" ? selectedBranch.branchCode : null,
        companyCode: selectedEntity.companyId !== "all" ? selectedEntity.companyId : null,
      },
      thenCallBack: (response) => {
        setShowLoadingIcon(false);
        const { ledgerDataExcelBase64: excelBuffer, fileName } = response.data;
        const excelData = Buffer.from(excelBuffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, fileName);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  useEffect(() => {
    listCompanies();
  }, []);

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons("Ledger Analytics", actor.name, location?.state?.openCollapseObj, actor)}
    >
      <Grid>
        <Grid>
          <Card className="mt_20">
            <CardContent>
              <Grid container={true} spacing={2}>
                {/* First Part */}
                <Grid item={true} xs={4}>
                  <Grid container={true} spacing={2} className="pl_20">
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item={true} xs={4}>
                        <label className="fw_600">Entity Name:</label>
                      </Grid>
                      <Grid item={true} xs={8}>
                        <Autocomplete
                          size="small"
                          disableClearable={true}
                          disablePortal={true}
                          id="combo-box-demo"
                          options={[{ companyId: "all", companyName: "Select All" }, ...entitiesList]}
                          getOptionLabel={(option) => {
                            return option.companyName;
                          }}
                          value={selectedEntity}
                          //   sx={{ width: 300 }}
                          onChange={(_e, value) => {
                            setSelectedEntity(value);
                            setSelectedBranch({ branchCode: "all", branchName: "Select All" });
                            setSelectedVendor({ vendorCode: "All", id: "all", name: "Select All" });
                            if (value.companyId !== "all") {
                              if (actor.branchLevelReconcilation) {
                                listBranches(value.companyId);
                              } else {
                                listAllBusinessPartners(value.companyId, "all");
                              }
                            }
                          }}
                          renderInput={(params) => <TextField {...params} placeholder="Entity Name" />}
                        />
                      </Grid>
                    </Grid>
                    {actor.branchLevelReconcilation && (
                      <Grid
                        item={true}
                        xs={12}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Grid item={true} xs={4}>
                          <label className="fw_600">Branch Name:</label>
                        </Grid>
                        <Grid item={true} xs={8}>
                          <Autocomplete
                            disabled={selectedEntity.companyId === "all"}
                            disableClearable={true}
                            size="small"
                            disablePortal={true}
                            id="combo-box-demo"
                            options={[{ branchCode: "all", branchName: "Select All" }, ...branchesList]}
                            getOptionLabel={(option) => {
                              return option.branchName;
                            }}
                            value={selectedBranch}
                            //   sx={{ width: 300 }}
                            onChange={(_e, value) => {
                              setSelectedBranch(value);
                              setSelectedVendor({ vendorCode: "All", id: "all", name: "Select All" });
                              if (value.branchCode !== "all")
                                listAllBusinessPartners(selectedEntity.companyId, value.branchCode);
                            }}
                            renderInput={(params) => <TextField {...params} placeholder="Branch Name" />}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                {/* Second Part */}
                <Grid item={true} xs={4}>
                  <Grid container={true} spacing={2} className="pl_20">
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item={true} xs={4}>
                        <label className="fw_600">Vendor Code:</label>
                      </Grid>
                      <Grid item={true} xs={8}>
                        <Autocomplete
                          disabled={
                            (actor.branchLevelReconcilation && selectedBranch.branchCode === "all") ||
                            selectedEntity.companyId === "all"
                          }
                          size="small"
                          disablePortal={true}
                          disableClearable={true}
                          id="combo-box-demo"
                          options={[
                            { vendorCode: "All", id: "all", name: "Select All" },
                            ...ownBusinessPartners.map((op) => {
                              return {
                                vendorCode: op.businessPartnerVendorCode,
                                id: op.businessPartnerId,
                                name: op.businessPartnerName,
                              };
                            }),
                          ]}
                          getOptionLabel={(option: any) =>
                            option.vendorCode ? `${option.name} (Vendor Code: ${option.vendorCode})` : option.name
                          }
                          filterOptions={createFilterOptions({
                            stringify: (option: any) => `${option.vendorCode} ${option.name}`,
                          })}
                          renderOption={(props, option) => (
                            <Box
                              key={`${option.id}-${option.vendorCode}`}
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                              style={{ display: "block" }}
                            >
                              <div>{option.name}</div>
                              <div>
                                <span style={{ color: "#666666", fontSize: "12px" }}>
                                  Vendor Code: {option.vendorCode}
                                </span>
                              </div>
                            </Box>
                          )}
                          value={selectedVendor}
                          //   sx={{ width: 300 }}
                          onChange={(_e, value) => setSelectedVendor(value)}
                          renderInput={(params) => <TextField {...params} placeholder="Type Code" />}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {/* <Grid item={true} xs={4}>
                        <label className="fw_600">Vendor Name:</label>
                      </Grid>
                      <Grid item={true} xs={8}>
                        <Autocomplete
                          disabled={vendorPan ? true : false}
                          size="small"
                          disablePortal={true}
                          id="combo-box-demo"
                          options={vendorNameArr}
                          value={vendorName}
                          //   sx={{ width: 300 }}
                          onChange={(_e, value) => setVendorName(value)}
                          renderInput={(params) => <TextField {...params} placeholder="Type Name" />}
                        />
                      </Grid> */}
                    </Grid>
                  </Grid>
                </Grid>

                {/* Third Part */}
                {/* <Grid item={true} xs={4}>
                  <Grid container={true} spacing={2} className="pl_20">
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item={true} xs={4}>
                        <label className="fw_600">Vendor PAN:</label>
                      </Grid>
                      <Grid item={true} xs={8}>
                        <Autocomplete
                          disabled={vendorName ? true : false}
                          size="small"
                          disablePortal={true}
                          id="combo-box-demo"
                          options={vendorPanArr}
                          value={vendorPan}
                          //   sx={{ width: 300 }}
                          onChange={(_e, value) => setVendorPan(value)}
                          renderInput={(params) => <TextField {...params} placeholder="Type PAN" />}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item={true}
                      xs={12}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Grid item={true} xs={4}>
                        <label className="fw_600">Vendor GST:</label>
                      </Grid>
                      <Grid item={true} xs={8}>
                        <Autocomplete
                          disabled={vendorCode ? true : false}
                          size="small"
                          disablePortal={true}
                          id="combo-box-demo"
                          options={vendorGstNumberArr}
                          value={vendorGstNumber}
                          //   sx={{ width: 300 }}
                          onChange={(_e, value) => setVendorGstNumber(value)}
                          renderInput={(params) => <TextField {...params} placeholder="Type GST No." />}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>

              <Grid sx={{ display: "flex", justifyContent: "start", paddingLeft: "20px", marginTop: "30px" }}>
                <Grid sx={{ mt: "16px" }}>
                  <label className="fw_600">Apply date filter on ledger entries</label>
                </Grid>
                <Grid xs={12} md={12} className="center_align ml_20">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    // style={{ minWidth: "300px" }}
                    // className="closingblc_input"
                  >
                    <DesktopDatePicker
                      format="dd/MM/yyyy"
                      label="Start Date"
                      onError={(error) => {
                        if (error) setErrorStartMsg(error);
                        else setErrorStartMsg("");
                      }}
                      value={startDate}
                      onChange={(date: Date) => {
                        if (date !== null) {
                          if (date.getTime() > new Date().getTime()) {
                            setErrorFutureDate(true);
                          } else {
                            setErrorFutureDate(false);
                          }
                        }
                        handleStartDateChange(date);
                      }}
                      slotProps={{
                        textField: {
                          helperText: errorStartMsg
                            ? errorFutureDate
                              ? "Date cannot be in the future"
                              : "Invalid date format"
                            : "",
                        },
                      }}
                      maxDate={new Date()}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid xs={12} md={12} className="center_align ml_20">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    // style={{ minWidth: "300px" }}
                    // className="closingblc_input"
                  >
                    <DesktopDatePicker
                      format="dd/MM/yyyy"
                      label="End Date"
                      onError={(error) => {
                        if (error) setErrorEndMsg(error);
                        else setErrorEndMsg("");
                      }}
                      value={endDate}
                      onChange={(date: Date) => {
                        if (date !== null) {
                          if (
                            moment(date).format("YYYY-MM-DD").valueOf() <
                            moment(new Date()).format("YYYY-MM-DD").valueOf()
                          ) {
                            setErrorEndFutureDate(false);
                          } else if (
                            moment(date).format("YYYY-MM-DD").valueOf() >
                            moment(new Date()).format("YYYY-MM-DD").valueOf()
                          ) {
                            // setErrorEndFutureDate(true);
                            // allow future end date
                            setErrorEndFutureDate(false);
                          } else {
                            setErrorEndFutureDate(false);
                          }
                        }
                        handleEndDateChange(date);
                      }}
                      slotProps={{
                        textField: {
                          helperText: errorEndMsg
                            ? errorEndFutureDate
                              ? "Date cannot be in the future"
                              : "Invalid date format"
                            : "",
                        },
                      }}
                      // maxDate={new Date()}
                      minDate={startDate}
                      maxDate={
                        startDate && new Date(new Date(startDate).setDate(startDate.getDate() + ledgerReportDaysLimit))
                      }
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid className="mt_20 center_align_ver_horiz">
          <Button
            className="theme_btn"
            onClick={() => {
              downloadLedgerData();
            }}
            disabled={
              startDate === null ||
              endDate === null ||
              errorEndMsg !== "" ||
              errorEndFutureDate ||
              errorStartMsg !== "" ||
              errorFutureDate ||
              showLoadingIcon
            }
            style={{ width: "200px" }}
          >
            <LoadingIcon loading={showLoadingIcon} />
            Download
          </Button>
        </Grid>
      </Grid>
    </LoggedInSkeleton>
  );
};

export default Ledger;
