import React, { useContext, useState } from "react";
import { reconciliationStatus } from "src/Utils/Recon/Recon360/Constants";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";

export default function RaiseDisputeModal() {
  const {
    openMsgDialog,
    setOpenMsgDialog,
    detailsHeadingName,
    setColumnDefinition,
    setPaymentGroupBy,
    summaryDetailsData,
    setExpanded,
    setOpendetailsJSON,
    invoiceAmountMismatchDispute,
    invoiceAmountMismatchDisputeValue,
    invoiceAmountMismatchColDef,
    invoiceNotPresentInPartnerLedgerColDef,
    invoiceNotPresentInOwnLedgerColDef,
    paymentAmountMismatchColDef,
    paymentNotPresentInPartnerLedgerColDef,
    dnCnNotPresentInOwnLedgerColDef,
    tdsAmountMismatchColDef,
    dnCnNotPresentInPartnerLedgerColDef,
    tdsNotPresentInPartnerLedgerColDef,
    tdsNotPresentInOwnLedgerColDef,
    dnCnAmountMismatchColDef,
    paymentNotPresentInOwnLedgerColDef,
  } = useContext(Recon360Context);

  const [showInputMsg, setShowInputMsg] = useState<boolean>(false);
  const [displayInputMsg, setDisplayInputMsg] = useState<any[]>([]);
  const [inputMsg, setInputMsg] = useState<any>();
  const [allinvoiceAmountMismatchDispute, setAllinvoiceAmountMismatchDispute] = useState<any[]>([]);
  const [allinvoiceAmountMismatchDisputeValue1, setAllinvoiceAmountMismatchDisputeValue] = useState<number[]>([]);

  interface ObjectEle {
    [code: string]: boolean;
  }
  const msgRaiseDisputeHandler = (doc: string, value: number) => {
    const updateallinvoiceAmountMismatchDispute = [...allinvoiceAmountMismatchDispute, doc];
    setAllinvoiceAmountMismatchDispute(updateallinvoiceAmountMismatchDispute);
    const updateallinvoiceAmountMismatchDisputeValue = [...allinvoiceAmountMismatchDisputeValue1, value];

    let sumOfValues = 0;
    updateallinvoiceAmountMismatchDisputeValue.map((ele) => {
      return (sumOfValues += ele);
    });
    setAllinvoiceAmountMismatchDisputeValue(updateallinvoiceAmountMismatchDisputeValue);

    setOpenMsgDialog(false);
    setInputMsg("");
    setShowInputMsg(false);

    //
    if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(invoiceAmountMismatchColDef);
      setPaymentGroupBy(["own_invoice_match"]);

      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(tempDetailsJSON);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(invoiceNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_businessPartner) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(invoiceNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_own) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(paymentAmountMismatchColDef);
      setPaymentGroupBy(["own_payment_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(paymentNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(paymentNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(dnCnAmountMismatchColDef);
      setPaymentGroupBy(["own_dncn_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(dnCnNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(tdsNotPresentInPartnerLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(tdsNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(tdsAmountMismatchColDef);
      setPaymentGroupBy(["own_invoice_match"]);
      const setExpandedValue: ObjectEle = {};
      summaryDetailsData.current?.forEach((item: any) => {
        if (item.own_invoice_match.length > 0) {
          setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
        }
        if (item.own_payment_match.length > 0) {
          setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
        }
        if (item.own_dncn_match.length > 0) {
          setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
        }
      });
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA) {
      const tempDetailsJSON = summaryDetailsData.current;
      for (const [i, jValue] of tempDetailsJSON.entries()) {
        if (doc === jValue.doc_date_qtr_sup) {
          tempDetailsJSON[i].entry_adjusted = "Dispute Raised";
        }
      }
      setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
      const setExpandedValue: ObjectEle = {};
      setExpanded(setExpandedValue);
      setOpendetailsJSON(summaryDetailsData.current);
    }
    //
  };

  const getTimeAMPMFormat = (date: Date) => {
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const hourStr = hours.toString().padStart(2, "0");
    // appending zero in the start if hours less than 10
    const minuteStr = minutes.toString().padStart(2, "0");
    return hourStr + ":" + minuteStr + " " + ampm;
  };

  const displayInputMsgHandler = (e: any) => {
    const updatedInputMsg = e.target.value;
    setInputMsg(updatedInputMsg);
  };

  const handleSend = (e: any) => {
    e.preventDefault();
    displayInputMsg.push(inputMsg);
    setDisplayInputMsg(displayInputMsg);
    setShowInputMsg(true);
    setInputMsg("");
  };
  return (
    <Dialog open={openMsgDialog} onClose={() => setOpenMsgDialog(false)}>
      <header className="modal-card-head" style={{ display: "block" }}>
        <p className="modal-card-title">Dispute Resolution</p>
      </header>
      <section className="modal-card-body">
        <div className="container">
          <div className="column" style={{ paddingLeft: "0" }}>
            <div className="field">
              <div
                style={{
                  background: "#eae6df",
                  padding: "20px",
                  borderRadius: "18px",
                  fontSize: "24px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 6px",
                  marginBottom: "30px",
                }}
              >
                {showInputMsg && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      marginLeft: "55%",
                    }}
                  >
                    <span>
                      {displayInputMsg.map((msg, i) => (
                        <p
                          style={{
                            background: "rgb(217, 253, 211)",
                            color: "rgb(17, 27, 33)",
                            padding: "10px",
                            borderRadius: "10px",
                            marginTop: "10px",
                          }}
                          key={i + msg}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              fontWeight: "bold",
                              color: "#06cf9c",
                              fontSize: "14px",
                            }}
                          >
                            Ramesh Kumar
                          </p>
                          {msg}
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              fontSize: "12px",
                              color: "#667781",
                            }}
                          >
                            {getTimeAMPMFormat(new Date())}
                          </p>
                        </p>
                      ))}
                    </span>
                  </div>
                )}
              </div>
              <div className="control" style={{ display: "flex" }}>
                <input
                  className="input"
                  type="text"
                  name="inputMsg"
                  value={inputMsg}
                  onChange={(e) => {
                    displayInputMsgHandler(e);
                  }}
                />
                <span style={{ fontSize: "20px", padding: "5px 3px 5px 10px" }}>
                  <i className="fa fa-paperclip" aria-hidden="true" />
                </span>
                <button
                  className="button"
                  type="button"
                  onClick={(e) => {
                    handleSend(e);
                  }}
                  style={{
                    marginLeft: "10px",
                    borderRadius: "10px",
                    color: "#fff",
                    background: "#21b47d",
                  }}
                >
                  <i className="fa fa-paper-plane" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <button
            className="button"
            style={{ width: "130px", color: "#fff", background: "#21b47d" }}
            onClick={() => {
              //
              if (detailsHeadingName.current === reconciliationStatus.invoiceAmountMismatch) {
                setColumnDefinition(invoiceAmountMismatchColDef);
                setPaymentGroupBy(["own_invoice_match"]);
                const setExpandedValue: ObjectEle = {};
                summaryDetailsData.current?.forEach((item: any) => {
                  if (item.own_invoice_match.length > 0) {
                    setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
                  }
                });
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus?.invoiceNotPresentInPartnerLedger) {
                setColumnDefinition(invoiceNotPresentInPartnerLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.invoiceNotPresentInOwnLedger) {
                setColumnDefinition(invoiceNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.paymentAmountMismatch) {
                setColumnDefinition(paymentAmountMismatchColDef);
                setPaymentGroupBy(["own_payment_match"]);
                const setExpandedValue: ObjectEle = {};
                summaryDetailsData.current?.forEach((item: any) => {
                  if (item.own_payment_match.length > 0) {
                    setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
                  }
                });
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInPartnerLedger) {
                setColumnDefinition(paymentNotPresentInPartnerLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.paymentNotPresentInOwnLedger) {
                setColumnDefinition(paymentNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.debitNoteCreditNoteAmountMismatch) {
                setColumnDefinition(dnCnAmountMismatchColDef);
                setPaymentGroupBy(["own_dncn_match"]);
                const setExpandedValue: ObjectEle = {};
                summaryDetailsData.current?.forEach((item: any) => {
                  if (item.own_dncn_match.length > 0) {
                    setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
                  }
                });
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInPartnerLedger) {
                setColumnDefinition(dnCnNotPresentInPartnerLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.dnCnNotPresentInOwnLedger) {
                setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInPartnerLedger) {
                setColumnDefinition(tdsNotPresentInPartnerLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.tdsTcsNotPresentInOwnLedger) {
                setColumnDefinition(tdsNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.tdsAmountMismatch) {
                setColumnDefinition(tdsAmountMismatchColDef);
                setPaymentGroupBy(["own_invoice_match"]);
                const setExpandedValue: ObjectEle = {};
                summaryDetailsData.current?.forEach((item: any) => {
                  if (item.own_invoice_match.length > 0) {
                    setExpandedValue[`own_invoice_match:${item.own_invoice_match}`] = true;
                  }
                  if (item.own_payment_match.length > 0) {
                    setExpandedValue[`own_payment_match:${item.own_payment_match}`] = true;
                  }
                  if (item.own_dncn_match.length > 0) {
                    setExpandedValue[`own_dncn_match:${item.own_dncn_match}`] = true;
                  }
                });
                setExpanded(setExpandedValue);
              } else if (detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInOwnSOA) {
                setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              } else if (
                detailsHeadingName.current === reconciliationStatus.miscellaneousEntriesInBusinessPartnersSOA
              ) {
                setColumnDefinition(dnCnNotPresentInOwnLedgerColDef);
                const setExpandedValue: ObjectEle = {};
                setExpanded(setExpandedValue);
              }
              //

              setOpenMsgDialog(false);
            }}
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            className="button"
            style={{ width: "130px", color: "#fff", background: "#d6235c" }}
            onClick={() => {
              msgRaiseDisputeHandler(invoiceAmountMismatchDispute, invoiceAmountMismatchDisputeValue);
            }}
          >
            Raise Dispute
          </button>
        </div>
      </footer>
    </Dialog>
  );
}
