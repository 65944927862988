import { Close } from "@mui/icons-material";
import { Box, Button, Dialog as MuiDialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import React, { useContext } from "react";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import { Recon360Context } from "../Recon360";
type ColDef<T = any> = ColumnDef<T> & {
  sticky?: "left" | "right";
};

const ErrorInUploadModal = () => {
  const { openErrorInUploadModal, setOpenErrorInUploadModal, uploadErrorData, setUploadErrorData } =
    useContext(Recon360Context);

  const columnDefinitionUploadError: ColDef[] = [
    {
      header: "File Name - Sheet Name",
      accessorKey: "filename",
      size: 250,
      enableSorting: false,
    },
    {
      header: "Error Description",
      accessorKey: "error",
      size: 400,
      enableSorting: false,
    },
  ];

  const closeErrorInUploadModal = () => {
    setOpenErrorInUploadModal(false);
    setUploadErrorData([]);
  };

  return (
    <div>
      <MuiDialog
        open={openErrorInUploadModal}
        maxWidth="md"
        fullWidth={true}
        sx={{ width: "50%", maxWidth: "50%", margin: "auto" }}
      >
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Error in Upload</DialogTitle>
          <IconButton
            onClick={() => {
              closeErrorInUploadModal();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5, height: "35vh" }}>
          <div>
            <BpDashboardTable columns={columnDefinitionUploadError} rows={uploadErrorData} />
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer flex_end">
          <Button
            className="theme_btn"
            onClick={() => {
              closeErrorInUploadModal();
            }}
          >
            Continue
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ErrorInUploadModal;
