import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel } from "@mui/material";
import { Alert, Box, Button, RadioGroup, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import {
  IFilledDetails,
  MsmeSegment,
  MsmeSegmentEnum,
  NdButton,
  NdRadio,
  NdTextField,
  validateEmail,
  validatePAN,
  validatePhoneNumber,
} from "./CommonComponents";
import { BackdropLoader, FilledDetailsGrid } from "./PartnerPortal.common";
import $ from "./PartnerPortal.module.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface DetailsPage1Props {
  openDialog: boolean;
  setOpenDialog: StateDispatch<boolean>;
  setNext: () => void;
  data: IFilledDetails;
  setData: StateDispatch<IFilledDetails>;
}

export const DetailsPage1 = ({ openDialog, setOpenDialog, setNext, data, setData }: DetailsPage1Props) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const [optionalData, setOptionalData] = useState<Pick<IFilledDetails, "phoneNumber">>({
    phoneNumber: data?.phoneNumber || "",
  });

  const submitDetails = () => {
    setOpenBackDrop(true);
    useFetch(API_ENDPOINTS.UPDATE_FIRM_AND_AUTHORISED_PERSON_DETAILS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_FIRM_AND_AUTHORISED_PERSON_DETAILS.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        firmName: data.companyName,
        pan: data.panNumber,
        udyamRegistrationNumber: data.msmeUdyamNumber || null,
        enterpriseSegment: (data.segment as MsmeSegment) || null,
        authorisedPersonName: data.personName,
        designation: data.designation,
        email: data.email,
        phoneNumber: optionalData.phoneNumber,
        isMSME: true,
      },
      thenCallBack: (_res) => {
        setOpenDialog(false);
        setOpenBackDrop(false);
        setNext();
      },
      catchCallBack: () => {
        setOpenBackDrop(false);
      },
      errorCallback: () => {
        setOpenBackDrop(false);
      },
    });
  };

  return (
    <>
      <Stack className="fade_in details_box" py={5} gap={4} maxWidth={900} mx={"auto"}>
        <Typography variant="h6">
          Fill out the details to confirm that your Company/Firm/Enterprise is registered as MSME
        </Typography>
        <FormControl component={"form"}>
          <Box className="d_flex" gap={2} py={"12px"}>
            <NdTextField
              label="Name of the firm"
              required
              value={data.companyName}
              onChange={(_e) => setData((_d) => ({ ..._d, companyName: _e.target.value }))}
            />
            <NdTextField
              label="PAN Number"
              required
              value={data.panNumber}
              onChange={(_e) => setData((_d) => ({ ..._d, panNumber: _e.target.value.toUpperCase() }))}
              error={data.panNumber && !validatePAN(data.panNumber)}
            />
            <NdTextField
              label="MSME/Udyam Registration Number"
              required
              sx={{ width: 350 }}
              value={data.msmeUdyamNumber}
              onChange={(_e) => setData((_d) => ({ ..._d, msmeUdyamNumber: _e.target.value }))}
            />
          </Box>
          <Stack gap={2} py={"12px"}>
            <Typography variant="subtitle2" id="enterprise-segment">
              Select your Enterprise Segment *
            </Typography>
            <FormControl>
              <RadioGroup
                aria-labelledby="enterprise-segment"
                name="enterprise-segment"
                value={data.segment}
                onChange={(_e, val) => setData((_d) => ({ ..._d, segment: val as MsmeSegment }))}
              >
                <FormControlLabel value={MsmeSegmentEnum.Micro} control={<NdRadio />} label="Micro Enterprise" />
                <FormControlLabel value={MsmeSegmentEnum.Small} control={<NdRadio />} label="Small Enterprise" />
                <FormControlLabel value={MsmeSegmentEnum.Medium} control={<NdRadio />} label="Medium Enterprise" />
              </RadioGroup>
            </FormControl>
          </Stack>
          <Stack gap={2} py={"12px"}>
            <Typography variant="subtitle2">
              Please fill the details of the authorized person who will stamp the MSME confirmation PDF
            </Typography>
            <Box className="d_flex" gap={2}>
              <NdTextField
                label="Name"
                required
                sx={{ width: 300 }}
                value={data.personName}
                onChange={(_e) => setData((_d) => ({ ..._d, personName: _e.target.value }))}
              />
              <NdTextField
                label="Designation"
                required
                sx={{ width: 300 }}
                value={data.designation}
                onChange={(_e) => setData((_d) => ({ ..._d, designation: _e.target.value }))}
              />
            </Box>
            <Box className="d_flex" gap={2}>
              <NdTextField
                label="Email"
                type="email"
                required
                sx={{ width: 300 }}
                value={data.email}
                onChange={(_e) => setData((_d) => ({ ..._d, email: _e.target.value }))}
                error={data.email && !validateEmail(data.email)}
              />
              <NdTextField
                label="Phone No."
                type="number"
                sx={{ width: 300 }}
                value={optionalData.phoneNumber}
                onChange={(_e) => {
                  setOptionalData((_d) => ({ ..._d, phoneNumber: _e.target.value }));
                  setData((_d) => ({ ..._d, phoneNumber: _e.target.value }));
                }}
                error={optionalData.phoneNumber && !validatePhoneNumber(optionalData.phoneNumber)}
              />
            </Box>
          </Stack>
        </FormControl>
      </Stack>
      <Dialog
        data-name="dialog"
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Please confirm the filled details are correct</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3, gap: 2 }}>
          <Alert className={$.BR_fix} severity="info" sx={{}}>
            <Typography fontSize={14}>
              Details filled by you here will be used in MSME confirmation document in the next step.{" "}
              <b style={{ fontFamily: "inherit", fontSize: 14 }}>Make sure the details is correct.</b>
            </Typography>
          </Alert>
          <Stack className={$.BR_fix} bgcolor={"#f3f3f3"}>
            <Typography variant="subtitle1" py={"12px"} px={2} fontWeight={700}>
              Company/Firm/Enterprise details
            </Typography>
            <Box py={"12px"} px={2}>
              <FilledDetailsGrid data={data} />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button className={$.BR_fix} onClick={() => setOpenDialog(false)} color="inherit">
            Change Details
          </Button>
          <NdButton onClick={() => submitDetails()} autoFocus variant="contained">
            Agree
          </NdButton>
        </DialogActions>
      </Dialog>
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Saving Details..."} />
    </>
  );
};

export default DetailsPage1;
