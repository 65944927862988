import { Button, Grid } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useMemo } from "react";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";

const PartnerUserBulkUploadErrorReport = (props: any) => {
  const columnDefinitionPartnerUserBulkUploadErrorReport = useMemo(
    (): ColumnDef<any>[] => [
      {
        header: "Partner user name",
        accessorKey: "name",
        maxSize: 200,
        minSize: 200,
        size: 200,
      },
      {
        header: "Partner Name",
        accessorKey: "businessPartnerName",
        maxSize: 200,
        minSize: 200,
        size: 200,
      },
      {
        header: "Error reason",
        accessorKey: "reason",
        maxSize: 300,
        minSize: 300,
        size: 300,
      },
      {
        header: "Mail ID",
        accessorKey: "emailId",
        maxSize: 240,
        minSize: 240,
        size: 240,
      },
      {
        header: "Designation",
        accessorKey: "designation",
        maxSize: 180,
        minSize: 180,
        size: 180,
      },

      {
        header: "Balance Confirmation",
        accessorKey: "balanceConfirmation",
        maxSize: 140,
        minSize: 140,
        size: 140,
      },
      {
        header: "Ledger request",
        accessorKey: "ledgerRequest",
        maxSize: 140,
        minSize: 140,
        size: 140,
      },
      {
        header: "Payment advice",
        accessorKey: "paymentAdvice",
        maxSize: 140,
        minSize: 140,
        size: 140,
      },
    ],

    []
  );

  const downloadPartnerUsersErrorReport = () => {
    const excelData = Buffer.from(props.errorListsPartnerUsersBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Partner User Record error report.xlsx");
  };

  return (
    <>
      <header className="modal-card-head">
        <p className="modal-card-title">Record error report</p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => props.setOpenPartnerUserBulkUploadErrorReport(false)}
        />
      </header>
      <section className="modal-card-body">
        <Grid>
          <p className="p_20 fw_600">
            {props.numberOfUsersSaved} records successfully added, {props.numberOfUsersFailed} records ignored due to
            errors mentioned below. Kindly check and re-upload
          </p>
        </Grid>
        <Grid>
          <BpDashboardTable
            columns={columnDefinitionPartnerUserBulkUploadErrorReport}
            rows={props.rowsDataPartnerUserBulkUploadErrorReport}
            sortEnable={true}
          />
        </Grid>
      </section>
      <footer className="modal-card-foot space_between">
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              downloadPartnerUsersErrorReport();
            }}
          >
            Download excel
          </Button>
        </div>
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              props.setOpenPartnerUserBulkUploadErrorReport(false);
            }}
          >
            Close
          </Button>
        </div>
      </footer>
    </>
  );
};

export default PartnerUserBulkUploadErrorReport;
