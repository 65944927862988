import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Alert, Box, Button, Divider, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import LendingTopBanner from "../Common/LendingTopBanner";
import PortalAccessDialog from "../Common/PortalAccessDialog";
import {
  IFilledDetails,
  MsmeDetailsStatus,
  NdButton,
  validateEmail,
  validatePAN,
  validatePhoneNumber,
} from "./CommonComponents";
import DetailsPage1 from "./DetailsPage1";
import DetailsPage2, { FilledDetailsAndFilesRes } from "./DetailsPage2";
import ErrorPage from "./ErrorPage";
import FinalPage from "./FinalPage";
import { BackdropLoader, Header, LoadingPage, NavBar } from "./PartnerPortal.common";
import { NotMSMEDialog } from "./PartnerPortal.dialogs";
import $ from "./PartnerPortal.module.scss";
import wrapPromise from "./wrapPromise";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

const cache = new Map<string, Promise<any>>();

const PartnerPortal = () => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const [showInitialPage, setShowInitialPage] = useState(true);
  const [showNextPage, setShowNextPage] = useState(false);

  const encryptedData = params.get("data");

  const [openPortalAccessDialog, setOpenPortalAccessDialog] = useState(true);

  const data = useRef<IFilledDetails>({
    companyName: "",
    panNumber: "",
    msmeUdyamNumber: "",
    personName: "",
    designation: "",
    segment: null,
    email: "",
  });

  function fetchData<T = any>(uri: string) {
    if (!cache.has(uri)) {
      const promise = new Promise<T>((resolve, reject) => {
        useFetch<T>(uri, "GET", {
          config: {
            params: {
              data: encryptedData,
            },
          },
          thenCallBack: (res) => {
            resolve(res.data);
          },
          catchCallBack: (err) => {
            toast.error(<CustomToast message={err?.message || err?.name} />);
            reject();
          },
          errorCallback: reject,
        });
      });
      cache.set(uri, promise);
    }
    return wrapPromise<T>(cache.get(uri).then((res) => res));
  }

  const getMsmeDetailsStatus = () =>
    fetchData<{ msmeDetailsStatus: MsmeDetailsStatus }>("/api/partnerPortal/GetMsmeDetailsStatus");
  const promisedDetailsStatus = getMsmeDetailsStatus();

  const getPartnerNameAndLogo = () =>
    fetchData<{ name: string; logo: "s3Link" }>("/api/partnerPortal/GetPartnerNameAndLogo");
  const promisedPartnerNameAndLogo = getPartnerNameAndLogo();

  const getFilledDetailsAndFiles = () =>
    fetchData<FilledDetailsAndFilesRes>("/api/partnerPortal/GetDetailsAndUploadedFiles");
  const promisedFilledDetailsAndFiles = getFilledDetailsAndFiles();

  const PartnerPortalPage = () => {
    const status = promisedDetailsStatus.read();
    const nameAndLogo = promisedPartnerNameAndLogo.read();
    const extractedData = promisedFilledDetailsAndFiles.read();

    const [showFilledDetails, setShowFilledDetails] = useState(false);
    const [showFinalPage, setShowFinalPage] = useState(false);
    const [openFinalPage, setOpenFinalPage] = useState(false);

    useEffect(() => {
      if (status?.msmeDetailsStatus === MsmeDetailsStatus.NotFilled) {
        setShowInitialPage(true);
        setShowFilledDetails(false);
        setShowFinalPage(false);
      } else if (status?.msmeDetailsStatus === MsmeDetailsStatus.DetailsFilled) {
        setShowInitialPage(false);
        setShowFilledDetails(true);
        setShowFinalPage(false);
        data.current = {
          companyName: extractedData?.firmDetails?.name,
          panNumber: extractedData?.firmDetails?.pan,
          msmeUdyamNumber: extractedData?.firmDetails?.udyamRegistrationNumber,
          personName: extractedData?.authorisedPersonDetails?.name,
          designation: extractedData?.authorisedPersonDetails?.designation,
          segment: extractedData?.firmDetails?.segment,
          email: extractedData?.authorisedPersonDetails?.email,
          phoneNumber: extractedData?.authorisedPersonDetails?.phoneNumber,
        };
      } else if (status?.msmeDetailsStatus === MsmeDetailsStatus.FinalPage) {
        setShowInitialPage(false);
        setShowFilledDetails(false);
        setShowFinalPage(true);
      }
    }, []);

    return (
      <>
        {openFinalPage && <LendingTopBanner />}
        <NavBar companyName={nameAndLogo.name} companyLogo={nameAndLogo.logo} />
        <Divider className={$.borderColor} />
        <Header headerText={`MSME Confirmation for FY ${nameAndLogo.name === "SKH SMC" ? "2024-25" : "2023-24"}`} />
        <Divider className={$.borderColor} />
        <Box className="main_container d_flex">
          {!openFinalPage && (
            <>
              {showInitialPage && !showNextPage ? (
                <Box className="first_content" maxWidth={900} py={3} mx={"auto"}>
                  <FirstPage
                    openPortalAccessDialog={openPortalAccessDialog}
                    setOpenPortalAccessDialog={setOpenPortalAccessDialog}
                    setNext={setShowNextPage}
                    setOpenFinalPage={setOpenFinalPage}
                  />
                </Box>
              ) : (
                <Box className="details_content" width={"100%"} mx={"auto"}>
                  <FillDetails
                    openPortalAccessDialog={openPortalAccessDialog}
                    setOpenPortalAccessDialog={setOpenPortalAccessDialog}
                    data={data.current}
                    handleBack={() => {
                      setShowInitialPage(true);
                      setShowNextPage(false);
                    }}
                    showFilledDetails={showFilledDetails}
                    showFinalPage={showFinalPage}
                    setShowFinalPage={setShowFinalPage}
                  />
                </Box>
              )}
            </>
          )}
          {openFinalPage && <FinalPage />}
        </Box>
      </>
    );
  };

  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          <PartnerPortalPage />
        </Suspense>
      </ErrorPage>
    </>
  );
};

const FirstPage = ({
  setNext,
  setOpenFinalPage,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: {
  setNext: StateDispatch<boolean>;
  setOpenFinalPage: StateDispatch<boolean>;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}) => {
  const [openNotMsme, setOpenNotMsme] = useState(false);

  return (
    <>
      <Stack className={$.borderColor} borderRadius={1} border={1} p={3} gap={5}>
        <Alert className={$.BR_fix} severity="info" sx={{}}>
          <Typography>
            You are requested to inform whether your organization is registered as MSME under Micro, Small and Medium
            Enterprise Development Act, 2006 or not.
          </Typography>
        </Alert>
        <Stack gap={3}>
          <Typography variant="h6">Please confirm if your Company/Firm/Enterprise registered as MSME?</Typography>
          <Box className="d_flex" gap={2}>
            <NdButton variant="contained" size="small" onClick={() => setNext(true)}>
              YES
            </NdButton>
            <NdButton variant="contained" size="small" onClick={() => setOpenNotMsme(true)}>
              NO
            </NdButton>
          </Box>
        </Stack>
      </Stack>
      <NotMSMEDialog openDialog={openNotMsme} setOpenDialog={setOpenNotMsme} setOpenFinalPage={setOpenFinalPage} />
      <PortalAccessDialog
        openPortalAccessDialog={openPortalAccessDialog}
        setOpenPortalAccessDialog={setOpenPortalAccessDialog}
      />
    </>
  );
};

interface FillDetailsProps {
  data: IFilledDetails;
  showFilledDetails: boolean;
  showFinalPage: boolean;
  setShowFinalPage: StateDispatch<boolean>;
  handleBack: () => void;
  openPortalAccessDialog: boolean;
  setOpenPortalAccessDialog: StateDispatch<boolean>;
}

const FillDetails = ({
  data: netData,
  handleBack: handleInitialPageBack,
  showFilledDetails,
  showFinalPage,
  setShowFinalPage,
  openPortalAccessDialog,
  setOpenPortalAccessDialog,
}: FillDetailsProps) => {
  const steps = [
    { title: "Fill Details", sub: "Company/Firm/Enterprise details" },
    { title: "Upload Documents", sub: "Signed MSME Confirmation document along with Udyam Certificate" },
  ];
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formStateInvalid, setFormStateInvalid] = useState(false);

  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);

  const encryptedData = params.get("data");

  const [data, setData] = useState(netData);
  const [finalStepCompleted, setFinalStepCompleted] = useState<0 | 1>(0);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  useEffect(() => {
    if (showFilledDetails) {
      setActiveStep(1);
    } else if (showFinalPage) {
      setActiveStep(2);
    }
  }, [showFilledDetails, showFinalPage]);

  const handleBack = () => {
    if (activeStep < 1) handleInitialPageBack();
    else setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep === 1) return;
    else setActiveStep(activeStep + 1);
  };

  const checkFormInvalid = () => {
    if (!validatePAN(data.panNumber)) return true;
    if (data.phoneNumber && !validatePhoneNumber(data.phoneNumber)) return true;
    if (!validateEmail(data.email)) return true;

    for (const key in data) {
      if (key === "phoneNumber") {
        // ignore phone number validation
      } else if (!data[key]) return true;
    }
    return false;
  };

  const FinalSubmit = () => {
    setOpenBackDrop(true);
    useFetch(API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION_PARTNER_PORTAL.url, "POST", {
      failureMessage: API_ENDPOINTS.CONFIRM_FINAL_SUBMISSION_PARTNER_PORTAL.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
      },
      thenCallBack: () => {
        setOpenBackDrop(false);
        setShowFinalPage(true);
        setActiveStep(2);
        // why not working correctly setActiveStep(2) in local environment?
        // setTimeout(() => {
        //   setActiveStep(2);
        // }, 500);
      },
    });
  };

  return (
    <>
      {((showFinalPage && data.segment) || !showFinalPage) && (
        // Top Stepper Bar
        <>
          <Box className="stepper_box" width={"100%"} py={4} pr={2} bgcolor={"#f3f3f3"}>
            <Stepper
              activeStep={activeStep}
              sx={{ width: 900, mx: "auto", ".MuiSvgIcon-root.Mui-completed": { color: "#2E7D32" } }}
            >
              {steps.map((step, _index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                  optional?: React.ReactNode;
                } = {};
                labelProps.optional = <Typography variant="caption">{step.sub}</Typography>;
                return (
                  <Step key={step.title} {...stepProps}>
                    <StepLabel {...labelProps} sx={{ ".MuiStepLabel-label": { fontWeight: 600 } }}>
                      {step.title}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Divider className={$.borderColor} />
        </>
      )}
      {activeStep === 0 && (
        <DetailsPage1
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          setNext={handleNext}
          data={data}
          setData={setData}
        />
      )}
      {activeStep === 1 && (
        <DetailsPage2 finalStepCompleted={finalStepCompleted} setFinalStepCompleted={setFinalStepCompleted} />
      )}
      {activeStep !== 2 && (
        // bottom back and next buttons
        <>
          <Box className="fade_in d_flex" maxWidth={900} mx={"auto"} hidden={!formStateInvalid} mb={5}>
            <Alert className={$.BR_fix} severity="warning" sx={{ width: "100%" }}>
              <Typography variant="body2">Please Fill in all the mandatory details.</Typography>
            </Alert>
          </Box>
          <Box className="fade_in d_flex" gap={2} pb={5} maxWidth={900} mx={"auto"}>
            <Button
              className={$.BR_fix}
              startIcon={<ChevronLeft />}
              variant="text"
              color="inherit"
              onClick={handleBack}
              disabled={false}
            >
              Back
            </Button>
            {activeStep === 0 && (
              <NdButton
                endIcon={<ChevronRight />}
                variant="contained"
                onClick={() => {
                  const formInvalid = checkFormInvalid();
                  setFormStateInvalid(formInvalid);

                  if (!formInvalid) setOpenDialog(true);
                }}
              >
                Next
              </NdButton>
            )}
            {activeStep === 1 && (
              <NdButton disabled={!finalStepCompleted} variant="contained" onClick={FinalSubmit}>
                Submit
              </NdButton>
            )}
          </Box>
        </>
      )}
      {(showFinalPage || activeStep === 2) && <FinalPage />}
      {showFinalPage === false && (
        <PortalAccessDialog
          openPortalAccessDialog={openPortalAccessDialog}
          setOpenPortalAccessDialog={setOpenPortalAccessDialog}
        />
      )}
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Submitting Response..."} />
    </>
  );
};

export default PartnerPortal;
