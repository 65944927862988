import { Autocomplete, Button, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TagsInputUniqueKeys } from "../../ReconMIS/ClientReporting/AutoCompletes";
import { DisputeResolutionEmailStatuses } from "../DRInterfaces";
// import "../Styles/LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface ClosureTrackerFilterProps {
  allCategories: string[];
  storeRowsDataClosureTracker: any[];
  setRowDataClosureTracker: StateDispatch<any[]>;
  setAppliedFilters: setAppliedFilters;
  showFilter: boolean;
  setShowFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  setIsFilterApplied: StateDispatch<boolean>;
  allStatuses: string[];
}

const ClosureTrackerFilter = ({
  allCategories,
  storeRowsDataClosureTracker,
  setRowDataClosureTracker,
  setAppliedFilters,
  setIsFilterApplied,
  showFilter,
  setShowFilter,
  reFilter,
  setReFilter,
  allStatuses,
}: ClosureTrackerFilterProps) => {
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [selectedTaskID, setSelectedTaskID] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedEmailStatus, setSelectedEmailStatus] = useState<string[]>([]);

  const DisputeResolutionEmailAllStatuses = Array.from(new Set(Object.values(DisputeResolutionEmailStatuses))); // only unique values

  type keys = "partnerNames" | "category" | "status" | "emailStatus" | "taskIds";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    setShowFilter(false);
  };

  const filterBalanceConfirmation = () => {
    let tempRowData = storeRowsDataClosureTracker;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          setIsFilterApplied(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }
    if (selectedTaskID.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedTaskID?.some((val) => item.ticketId?.toString()?.toLowerCase() === val?.toLowerCase());
      });
    }
    if (selectedStatus.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.status?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (selectedCategory.length > 0) {
      setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.every((val) => item.category?.includes(val));
      });
    }
    if (selectedEmailStatus.length > 0) {
      setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedEmailStatus?.some((val) => item.emailStatus?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    setFilterObj({
      partnerNames: partnerNames,
      category: selectedCategory,
      status: selectedStatus,
      emailStatus: selectedEmailStatus,
      taskIds: selectedTaskID,
    });

    setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Task Ids": selectedTaskID.join(", "),
      Category: selectedCategory.join(","),
      Status: selectedStatus.join(", "),
      "Email Status": selectedEmailStatus.join(", "),
    });

    setRowDataClosureTracker(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (reFilter) {
      filterBalanceConfirmation();
      setReFilter(false);
    }
    // eslint-disable-next-line
  }, [reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setSelectedTaskID([]);
    setSelectedEmailStatus([]);
    setPartnerNames([]);
    setAppliedFilters({});
    handleClose();
    setTimeout(() => {
      setShowFilter(true);
      setRowDataClosureTracker(storeRowsDataClosureTracker);
      setIsFilterApplied(false);
    }, 700);
  };

  return (
    <div>
      <Dialog open={showFilter} aria-labelledby="BalanceConfirmationFilter" className="ledgerRequestFilter">
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              setSelectedTaskID(filterObj?.taskIds || []);
              setSelectedEmailStatus(filterObj?.emailStatus || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
              <div className="w_400 d_flex category_textFeild_width_390 ">
                <TagsInputUniqueKeys
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={storeRowsDataClosureTracker}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Task ID :</div>
              <div className="w_400 category_textField_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedTaskID}
                  fullWidth={true}
                  onChange={(_e, value) => {
                    setSelectedTaskID(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={storeRowsDataClosureTracker?.map((option) => option?.ticketId?.toString())}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions={true}
                  renderInput={(params) => <TextField {...params} label="Select Task ID" />}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Task Status :</div>
              <div className="w_400 category_textField_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedStatus}
                  fullWidth={true}
                  onChange={(_e, value) => {
                    setSelectedStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={allStatuses || []}
                  getOptionLabel={(option) => option}
                  filterSelectedOptions={true}
                  renderInput={(params) => <TextField {...params} label="Select status" />}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={allCategories || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Email Status :</div>
              <div className="w_400 category_textFeild_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedEmailStatus}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setSelectedEmailStatus(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={DisputeResolutionEmailAllStatuses || []}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Email Status"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterBalanceConfirmation_button"
              onClick={() => {
                filterBalanceConfirmation();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClosureTrackerFilter;
