import React from "react";
import Loader from "../../../Common/Loader";
import DetailsTable from "../../../ReactTable/DetailsTable";

export default function ReconSummaryDetails({
  loader,
  detailsHeading,
  openDetailsJSON,
  columnDefinition,
  paymentGroupBy,
  expanded,
}) {
  const goBack = () => {
    const summaryEle = document.getElementById("summary_page");
    summaryEle.style.display = "block";
    const detailsEle = document.getElementById("open_details_page");
    detailsEle.style.display = "none";
  };

  return (
    <div id="open_details_page" style={{ display: "none" }}>
      {loader ? (
        <Loader />
      ) : (
        <>
          <div className="details_page">
            <div style={{ paddingLeft: "20px", fontSize: "24px", fontWeight: 600 }}>{detailsHeading}</div>

            <button
              className="button is-bold is-success theme_btn"
              type="button"
              style={{ marginRight: "20px" }}
              onClick={() => {
                goBack();
              }}
            >
              Go Back
            </button>
          </div>
          <div id="table-1" className="center_align" style={{ paddingRight: "2vw", paddingLeft: "2vw" }}>
            {openDetailsJSON === null ? (
              ""
            ) : (
              <DetailsTable
                getCellProps={(cellInfo: any) => ({
                  style: {
                    backgroundColor:
                      cellInfo.value === "Reconciled"
                        ? "#82FB7F"
                        : cellInfo.value === "Mismatched Entry"
                        ? "#FDE485"
                        : cellInfo.value === "Missed Entry" ||
                          cellInfo.value === "Extra Entry with Suggested Match" ||
                          cellInfo.value === "Extra Entry" ||
                          cellInfo.value === "Missed Entry with Suggested Match"
                        ? "#FD8C85"
                        : "",
                  },
                })}
                columns={columnDefinition}
                rows={openDetailsJSON?.map((value) => {
                  return value;
                })}
                sortEnable={false}
                paymentGroupBy={paymentGroupBy}
                setExpandedValue={expanded}
              />
            )}
          </div>
          <div className="details_page">
            <button
              className="button is-bold is-success theme_btn"
              type="button"
              style={{ fontSize: "12px", width: "100px" }}
              onClick={() => goBack()}
            >
              Go Back
            </button>
          </div>
        </>
      )}
      {/* {getMsgDialog(invoiceAmountMismatchDispute, invoiceAmountMismatchDisputeValue)} */}
    </div>
  );
}
