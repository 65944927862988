import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context } from "../Recon360";

export default function SaveTicketModal(props) {
  const { resetChecklist } = useContext(Recon360Context);
  return (
    <Dialog open={props.showSaveTicketModal} sx={{ borderRadius: "4px" }}>
      <DialogTitle>Are you sure you want to save the current recon</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This action will close this reconciliation and save the result for future reconciliations
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            props.setShowSaveTicketModal(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={props.isStatusUpdating}
          variant="contained"
          onClick={() => {
            props.changeStatus("Saved Ticket", props.ticketId);
            resetChecklist();
          }}
        >
          {props.isStatusUpdating && <LoadingIcon loading={props.isStatusUpdating} />}
          Save
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
