import { ListAllActorData } from "src/Components/Admin/AdminInterfaces";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";

export const getAllActorsList = async () => {
  return new Promise<ListAllActorData[]>((resolve, reject) => {
    useFetch<{ data: ListAllActorData[] }>(API_ENDPOINTS.LIST_ALL_ACTOR_DETAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_ACTOR_DETAILS.failureMessage,
      thenCallBack: (response) => {
        return resolve(response.data.data);
      },
      catchCallBack: reject,
    });
  });
};
