import { ValueFormatterParams } from "ag-grid-community";
import Dinero from "dinero.js";
import { Currency, DineroObject } from "dinero.js";

export type DineroFormats = {
  // input amount  { amount: 500050 }
  "$0,0.00": `$5,000.50`;
  "$0,0": `$5,001`;
  $0: `$5001`;
  "$0.0": `$5000.5`;
  "USD0,0.0": `USD5,000.5`;
} & { [k: OtherFormat]: string };

type OtherFormat = string & NonNullable<unknown>;

/**
 *
 * @param {DineroObject} money accepts a dinero object as money
 * @param {Intl.LocalesArgument} [locale="en-In"]
 * if dinero object contains a currency other than `INR`,
 * then the found locale will be used instead of default locale (`INR`) else defaults to `en-Us` locale
 * @return {*}  {Returns `formatted string`}
 */
export function formatMoney(
  money: DineroObject,
  _locale: Intl.LocalesArgument = "en-In",
  _format: keyof DineroFormats = "USD0,0"
): string {
  if (money === null || money === undefined) {
    return "NA";
    // code like x === -0 will pass for both +0 and -0. replacing money.amount === -0 with Object.is()
  } else if (Object.is(money.amount, -0)) {
    money.amount = 0;
  }

  if (!_locale) _locale = "en-In";

  return money.currency === "INR"
    ? Dinero(money).setLocale("en-IN").toFormat(_format)
    : _locale !== "en-In"
    ? Dinero(money).setLocale(_locale.toString()).toFormat(_format)
    : Dinero(money).toFormat(_format);
}

export function currencyValueFormatter(params: ValueFormatterParams) {
  return formatMoney(params.value);
}

export function subtractMoney(money1: DineroObject, money2: DineroObject) {
  if (!money2) {
    return money1;
  }
  return Dinero(money1).subtract(Dinero(money2)).toObject();
}

export function addMoney(money1: DineroObject, money2: DineroObject) {
  if (!money2) {
    return money1;
  }
  return Dinero(money1).add(Dinero(money2)).toObject();
}

export function multiply(obj1: DineroObject, multiplier: number): DineroObject {
  return Dinero(obj1).multiply(multiplier).toObject();
}

export function createEnumObject<T extends string>(o: Partial<{ [P in T]: P }>) {
  return o;
}

export const DefaultCurrency = {
  INR: "INR",
} as const;

export const ToDineroObj = (_amountInDecimal: number, _currency: Currency = "INR", _precision = 2): DineroObject => {
  if (isNaN(_amountInDecimal)) {
    _amountInDecimal = 0;
  }
  return {
    amount: Math.abs(Math.round(_amountInDecimal)) === 0 ? 0 : Math.floor(_amountInDecimal * 100),
    precision: _precision,
    currency: _currency,
  } as DineroObject;
};

export const toDineroObject = (amountInDecimal: number): DineroObject => {
  if (isNaN(amountInDecimal)) {
    amountInDecimal = 0;
  }
  return {
    amount: Math.abs(Math.round(amountInDecimal)) === 0 ? 0 : Math.floor(amountInDecimal * 100),
    precision: 2,
    currency: "INR",
  } as DineroObject;
};
