import { Alert, Box, Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import React from "react";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { StateDispatch } from "../Recon360";

interface BulkTasksUploadIssueModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  errorWorkbook: string;
  tasksReport: any;
  fetchTickets: any;
}

export default function BulkTasksUploadIssueModal({
  open,
  setOpen,
  errorWorkbook,
  tasksReport,
  fetchTickets,
}: BulkTasksUploadIssueModalProps) {
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Bulk Upload Issue</DialogTitle>

      <Box sx={{ padding: "16px 24px", gap: "16px", display: "flex", flexDirection: "column" }}>
        <Typography variant="subtitle1" sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "16px" }}>
          {tasksReport.createdTasks} out of {tasksReport.totalTasks} tasks has been added
        </Typography>
        <Alert severity="info">
          <Typography fontSize={"14px"}>
            Please check and re-upload the sheet for remaining {tasksReport.failedTasks} tasks
          </Typography>
        </Alert>
      </Box>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          CLOSE
        </Button>
        <NdButton
          variant="contained"
          onClick={() => {
            if (errorWorkbook) {
              const excelData = Buffer.from(errorWorkbook, "base64");
              const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const blob = new Blob([excelData], { type: fileType });
              saveAs(blob, "add-bulk-task-template-error.xlsx");
            }
            fetchTickets();
            setOpen(false);
          }}
        >
          DOWNLOAD
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
