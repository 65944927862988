/* eslint-disable no-useless-escape */
import { ClearAll, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedTextFieldProps,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ActorTypes, ClientType, UserRole, UserRoleUserTypeMap, UserTypes } from "../../entity/models/FrontendActor";
import { AccountTypeForRecon } from "../../entity/models/UtilityInterfaces";
import { LoggedInSkeleton } from "../Common/LoggedInSkeleton";
import { getAdminTopBarButtons } from "../Common/TopNavBar";
import useFetch from "../Common/useFetch";
import TabPanel from "../Recon360/NewBusinessPartner/TabPanel";
import { RegisterResponse } from "./AdminInterfaces";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const emailValidationRegEx = /^[\w._%+-]+@[\w.-]+\.[\w]{2,5}$/i;
export const gstNumberValidationRegEx = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/i;
export const phoneNumberValidationRegEx = /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[6789]\d{9}$/i;
export const personNameValidationRegEx = /^([\w\. \(\),\[\]&#\:\-\/']){3,100}$/;
export const userNameValidationRegEx = /^([a-zA-Z. ]){3,100}$/;
export const pincodeValidationRegEx = /^[1-9][0-9]{5}$/i;

const Register = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <LoggedInSkeleton topBarButtons={getAdminTopBarButtons("Register")}>
      <div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Account Registration" {...a11yProps(0)} sx={{ textTransform: "none" }} />
              <Tab label="User Registration" {...a11yProps(1)} sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <AccountRegistration />
          </TabPanel>
          <TabPanel value={value} index={1} key={2}>
            <UserRegistration />
          </TabPanel>
        </Box>
      </div>
    </LoggedInSkeleton>
  );
};

interface CustomTextFieldProps extends OutlinedTextFieldProps {
  regex: RegExp;
  errorMsg: string;
}
const CustomTextField = (props: CustomTextFieldProps) => {
  const [validationMsg, setValidationMsg] = useState("");

  const InputVerifyRegEx = (element: HTMLInputElement, message: string) => {
    if (element instanceof HTMLInputElement) {
      if (!element.validity.valid) setValidationMsg(message);
      else setValidationMsg("");
    }
  };

  return (
    <TextField
      inputRef={(r) => {
        if (r) (r as HTMLInputElement).pattern = `${props.regex.source}`;
      }}
      error={!!validationMsg.length}
      {...props}
      helperText={validationMsg}
      onBlur={(e) => InputVerifyRegEx(e.target as HTMLInputElement, props.errorMsg)}
    />
  );
};

const clearData = () => {
  const allInputs = document.forms[0].querySelectorAll("input");
  allInputs.forEach((input) => (input.value = null));
};

const AccountRegistration = () => {
  const [validationMsgs, setValidationMsgs] = useState({
    password: "",
    confirmPassword: "",
  });
  const passInput = useRef<HTMLInputElement>(null);
  const cPassInput = useRef<HTMLInputElement>(null);
  const [integration, setIntegration] = useState(false);
  const [registeredEmails, setRegisteredEmails] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [accountTypeForRecon, setAccountTypeForRecon] = useState<string>(AccountTypeForRecon.ReconUser);
  const [clientType, setClientType] = useState<string>(ClientType.Internal);

  useEffect(() => {
    if (accountTypeForRecon === AccountTypeForRecon.Partner) {
      setClientType(ClientType.None);
    } else {
      setClientType(ClientType.Internal);
    }
  }, [accountTypeForRecon]);

  const setValidationMsg = (key: keyof typeof validationMsgs, message: string) => {
    setValidationMsgs({ ...validationMsgs, [key]: message });
  };

  const PasswordVerify = (type: "length" | "confirm") => {
    if (type === "length") {
      if (passInput.current.value.length < 8) {
        setValidationMsg("password", "Password length must be at least 8 characters");
      } else setValidationMsg("password", "");
    }
    if (type === "confirm") {
      if (passInput.current.value !== cPassInput.current.value) {
        setValidationMsg("confirmPassword", "Confirm Password didn't match Password");
      } else setValidationMsg("confirmPassword", "");
    }
  };

  const InputObjectsRequired = [
    {
      name: "accountName",
      label: "Account Name",
      pattern: /.{3,200}/,
      jsPattern: true,
      errorMsg: "Account Name should be in between 3 and 200 Characters",
      sx: { gridColumn: "1/-1", width: "100%" },
    },
    {
      name: "adminName",
      label: "Admin Username",
      pattern: userNameValidationRegEx,
      errorMsg: "Invalid Admin UserName",
    },
    {
      name: "adminEmail",
      label: "Admin Email",
      type: "email",
      pattern: emailValidationRegEx,
      errorMsg: "Invalid Email",
    },
  ];

  const InputObjectsNR = [
    {
      name: "gstNumber",
      label: "GSTIN",
      pattern: gstNumberValidationRegEx,
      errorMsg: "Invalid GSTIN",
    },
    {
      name: "userPhoneNumber",
      label: "Phone Number",
      pattern: phoneNumberValidationRegEx,
      errorMsg: "Invalid Phone Number",
    },
    {
      name: "pincode",
      label: "Pincode",
      pattern: pincodeValidationRegEx,
      errorMsg: "Invalid Pincode",
    },
  ];

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    if (form.checkValidity()) SubmitAccountRegistration(formData);
  };

  const SubmitAccountRegistration = (formData: FormData) => {
    useFetch<RegisterResponse>(API_ENDPOINTS.REGISTER.url, "POST", {
      failureMessage: API_ENDPOINTS.REGISTER.failureMessage,
      showSuccessToast: true,
      data: {
        adminEmail: formData.get("adminEmail"),
        adminName: formData.get("adminName"),
        accountName: formData.get("accountName"),
        accountNickname: formData.get("accountNickname"),
        password: formData.get("password"),
        confirmPassword: formData.get("confirmPassword"),
        actorType: formData.get("actorType"),
        clientType: formData.get("clientType"),
        actorInfo: {
          gstNumber: formData.get("gstNumber"),
          address: formData.get("address"),
          userPhoneNumber: formData.get("userPhoneNumber"),
          accountingSoftware: formData.get("accountingSoftware"),
        },
        pincode: formData.get("pincode"),
        integration: Boolean(formData.get("integration")),
        location: formData.get("location"),
        accountCode: formData.get("accountCode"),
        accountTypeForRecon: formData.get("accountTypeForRecon"),
        clientId: formData.get("clientId"),
      },
      thenCallBack: (response) => {
        setRegisteredEmails((response.data.registeredEmails as string[]).join("\n"));
      },
    });
  };

  return (
    <Box sx={{ animation: "fade-in 0.5s ease-out", position: "relative" }}>
      <Card sx={{ m: "auto", width: "min-content" }}>
        <Box component="form" sx={{ m: 1 }} autoComplete="off" id="AccountRegistration" onSubmit={handleSubmit}>
          <FormControl
            sx={{
              gap: 1.5,
              display: "grid",
              gridTemplateColumns: "30ch 30ch",
              "& > :not(style)": { minWidth: "30ch" },
              "& fieldset span": {
                fontSize: "11px",
              },
            }}
          >
            {InputObjectsRequired.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                required={true}
                sx={inputObj.sx ? inputObj.sx : {}}
                type={inputObj.type || "text"}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField
              inputRef={(r) => {
                if (r) passInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.password.length}
              name="password"
              label="Password"
              required={true}
              type={showPassword ? "text" : "password"}
              helperText={validationMsgs.password}
              onBlur={() => PasswordVerify("length")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        marginRight: "-6px",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              inputRef={(r) => {
                if (r) cPassInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.confirmPassword.length}
              name="confirmPassword"
              label="Confirm Password"
              required={true}
              type="password"
              helperText={validationMsgs.confirmPassword}
              onBlur={() => PasswordVerify("confirm")}
            />
            <CustomTextField
              regex={/^.{0,16}$/}
              errorMsg="Maximum 16 chars are allowed"
              variant="outlined"
              sx={{ gridColumn: "1/-1", width: "100%" }}
              name="accountNickname"
              label="Account Nickname"
            />
            <TextField name="actorType" select={true} label="Actor Type" defaultValue={ActorTypes.User}>
              {Object.keys(ActorTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {ActorTypes[type]}
                </MenuItem>
              ))}
            </TextField>
            <CustomTextField
              key="NAKAD Client ID"
              name="clientId"
              label="NAKAD Client ID"
              variant="outlined"
              regex={/^(?!.*e)([1-9]\d*|1\d+)$/}
              errorMsg="NAKAD Client ID should be a valid number"
            />
            <div />
            <div />
            {InputObjectsNR.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField name="address" label="Address" />
            <TextField name="accountCode" label="Account Code" />
            <TextField name="location" label="Location" />
            <TextField
              name="accountTypeForRecon"
              select={true}
              label="Account Type"
              defaultValue={AccountTypeForRecon.ReconUser}
              value={accountTypeForRecon}
              onChange={(e) => setAccountTypeForRecon(e.target.value)}
            >
              {Object.keys(AccountTypeForRecon).map((type) => (
                <MenuItem key={type} value={type}>
                  {AccountTypeForRecon[type]}
                </MenuItem>
              ))}
            </TextField>
            <FormControlLabel
              name="integration"
              control={<Checkbox value={integration} onChange={(_e, c) => setIntegration(c)} />}
              label="Integration"
              value={integration}
              sx={{ justifyContent: "center" }}
            />
            <TextField
              name="accountingSoftware"
              disabled={!integration}
              select={true}
              label="Accounting Software"
              required={true}
            >
              <MenuItem value={"Tally"}>Tally</MenuItem>
              <MenuItem value={"SAP"}>SAP</MenuItem>
              <MenuItem value={"PULL_INTEGRATION"}>Pull Integration</MenuItem>
            </TextField>
            <TextField
              name="clientType"
              select={true}
              label="Client Type"
              value={clientType}
              onChange={(e) => setClientType(e.target.value)}
              disabled={accountTypeForRecon === AccountTypeForRecon.Partner}
            >
              {Object.keys(ClientType).map((type) => (
                <MenuItem key={type} value={type}>
                  {ClientType[type]}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Box>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Button type="submit" form="AccountRegistration" className="theme_btn" variant="outlined" size="large">
          Register
        </Button>
      </div>
      <Button
        variant="outlined"
        onClick={clearData}
        startIcon={<ClearAll />}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        Clear
      </Button>
      <TextField
        label="Registered Emails"
        disabled={true}
        value={registeredEmails}
        multiline={true}
        maxRows={2}
        sx={{
          position: "absolute",
          top: 120,
          right: 2,
          width: "210px",
          background: "#fff",
          "& fieldset span": {
            fontSize: "11px",
          },
          "& label:not([data-shrink='true'])": {
            top: 8,
          },
        }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => setRegisteredEmails("")}>
              <ClearAll />
            </IconButton>
          ),
        }}
      />
    </Box>
  );
};

const UserRegistration = () => {
  const [validationMsgs, setValidationMsgs] = useState({
    email: "",
    password: "",
    username: "",
  });
  const passInput = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);

  const [selectedUserType, setSelectedUserType] = useState("");
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [showUserRole, setShowUserRole] = useState(false);

  const setValidationMsg = (key: keyof typeof validationMsgs, message: string) => {
    setValidationMsgs({ ...validationMsgs, [key]: message });
  };

  const PasswordVerify = (type: "length" | "confirm") => {
    if (type === "length") {
      if (passInput.current.value.length < 8) {
        setValidationMsg("password", "Password length must be at least 8 characters");
      } else setValidationMsg("password", "");
    }
  };

  const InputObjectsRequired = [
    {
      name: "name",
      label: "User Name",
      pattern: userNameValidationRegEx,
      errorMsg: "Invalid Username",
      sx: { gridColumn: "1/-1", width: "100%" },
    },
    {
      name: "email",
      label: "User Email",
      type: "email",
      pattern: emailValidationRegEx,
      errorMsg: "Invalid Email",
    },
  ];

  const handleUserTypeChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const userType = event.target.value as UserTypes;
    setSelectedUserType(userType);
    setShowUserRole(true);
    const roles = Object.entries(UserRoleUserTypeMap).filter(([_, type]) => type === userType);
    if (roles.length === 1) {
      setSelectedUserRole(roles[0][0]);
    } else {
      setSelectedUserRole("");
    }
  };

  const handleUserRoleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    setSelectedUserRole(event.target.value as UserRole);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);
    if (form.checkValidity()) SubmitUserRegistration(formData);
  };

  const SubmitUserRegistration = (formData: FormData) => {
    useFetch(API_ENDPOINTS.ADD_ACTOR_USER_MAPPING.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_ACTOR_USER_MAPPING.failureMessage,
      showSuccessToast: true,
      data: {
        name: formData.get("name"),
        actorId: formData.get("actorId"),
        email: formData.get("email"),
        password: formData.get("password"),
        userType: selectedUserType,
        userRole: selectedUserRole,
      },
    });
  };

  return (
    <Box sx={{ animation: "fade-in 0.5s ease-out", position: "relative" }}>
      <Card sx={{ m: "auto", width: "min-content" }}>
        <Box component="form" sx={{ m: 1 }} autoComplete="off" id="UserRegistration" onSubmit={handleSubmit}>
          <FormControl
            sx={{
              gap: 1.5,
              display: "grid",
              gridTemplateColumns: "30ch 30ch",
              "& > :not(style)": { minWidth: "30ch" },
              "& fieldset span": {
                fontSize: "11px",
              },
            }}
          >
            {InputObjectsRequired.map((inputObj) => (
              <CustomTextField
                key={inputObj.name}
                name={inputObj.name}
                label={inputObj.label}
                required={true}
                sx={inputObj.sx ? inputObj.sx : {}}
                type={inputObj.type || "text"}
                variant="outlined"
                regex={inputObj.pattern}
                errorMsg={inputObj.errorMsg}
              />
            ))}
            <TextField
              inputRef={(r) => {
                if (r) passInput.current = r as HTMLInputElement;
              }}
              error={!!validationMsgs.password.length}
              name="password"
              label="Password"
              required={true}
              type={showPassword ? "text" : "password"}
              helperText={validationMsgs.password}
              onBlur={() => PasswordVerify("length")}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{
                        marginRight: "-6px",
                      }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              select
              label="User Type"
              value={selectedUserType}
              onChange={handleUserTypeChange}
              defaultValue={UserTypes.User}
            >
              {Object.keys(UserTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {UserTypes[type]}
                </MenuItem>
              ))}
            </TextField>
            {showUserRole ? (
              <TextField select label="User Role" value={selectedUserRole} onChange={handleUserRoleChange}>
                {Object.keys(UserRole)
                  .filter((role) => UserRoleUserTypeMap[role] === selectedUserType)
                  .map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
              </TextField>
            ) : (
              <br />
            )}

            <TextField
              name="actorId"
              label="Actor IDs"
              type="text"
              onChange={(e) => {
                e.target.value = e.target.value.replace(/[^0-9,]/g, "");
              }}
              onBlur={(e) => {
                // replace anything that is not a number
                e.target.value = e.target.value.replace(/[^0-9]/g, "");

                // remove empty . in number input
                if (e.target.value === ".") {
                  e.target.value = e.target.value.replace(".", "");
                }
              }}
            />
          </FormControl>
        </Box>
      </Card>
      <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
        <Button type="submit" form="UserRegistration" className="theme_btn" variant="outlined" size="large">
          Register
        </Button>
      </div>
      <Button
        variant="outlined"
        onClick={clearData}
        startIcon={<ClearAll />}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        Clear
      </Button>
    </Box>
  );
};

export default Register;
