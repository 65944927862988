import { AttachFile, Delete, DownloadRounded, ErrorOutline, Visibility } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import useFetch from "src/Components/Common/useFetch";
// import FormatDate from "src/Utils/DateUtils";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerBcPortal } from "src/Utils/Recon/UiLogger/Constants";
import { uiLoggerPortal } from "src/Utils/UiLogger";
import { CommunicationTypeForBC } from "../Common/PartnerCommsInterfaces";
import { getShortName } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton, NdButton, NdButtonOutlined, NdCheckbox } from "../MsmePartnerPortal/CommonComponents";
import { BackdropLoader, LoadingPage } from "../MsmePartnerPortal/PartnerPortal.common";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
import { BcPortalContext } from "./BalanceConfirmationPortalContext";

export type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export type UploadFileType = "signedPdf" | "ledgerFile" | "openItem";

export enum UploadFileTypes {
  signedPdf = "signedPdf",
  ledgerFile = "ledgerFile",
  openItem = "openItem",
}

export type BasicFile = {
  name: string;
  size?: number;
  type?: string;
};

export interface MailBoxPropsBc {
  subject: string;
  body: string;
  from: string;
  time: string;
}

export interface BcPortalDetails {
  closingBalanceOwn: number;
  closingBalanceBp: number;
  currency: string;
  ledgers: string[];
  confirmationDocument: string;
  openItems: string[];
  isBalanceConfirmed: boolean;
  isPdfDownloaded: boolean;
  remarks: string;
  // customizations
  customisationIfConfirmed: CommunicationTypeForBC;
  customisationIfNotConfirmed: CommunicationTypeForBC;
  customisationIfLedgerRequest?: CommunicationTypeForBC;
}

export const FileType: { [k in UploadFileType]: string } = {
  signedPdf: "confirmationDocument",
  ledgerFile: "ledgers",
  openItem: "openItems",
} as const;

export interface BcCustomisationDetails {
  isPdfRequired: boolean;
  isPdfOptional: boolean;
  isLedgerRequired: boolean;
  isOpenItemRequired: boolean;
  isPdfHidden: boolean;
  isLedgerHidden: boolean;
  isOpenItemHidden?: boolean;
}

// }

export const CommonUploaderBox = ({
  fileType,
  viewMode,
  disabled,
  setCompleted,
  viewModePdfName,
  viewModeLedgerNames,
  customization,
}: {
  setFileNames?: StateDispatch<string[]>;
  fileType: UploadFileType;
  viewMode?: boolean;
  disabled?: boolean;
  uploadFn?: () => Promise<void>;
  setCompleted?: StateDispatch<{ [k in UploadFileType]: boolean }>;
  viewModePdfName?: string;
  viewModeLedgerNames?: string[];
  customization?: BcCustomisationDetails;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const fileUploader = useRef<HTMLInputElement>(null);
  const currentFiles = useRef<File[]>([]);
  const [files, setFiles] = useState<BasicFile[]>(
    fileType === UploadFileTypes.ledgerFile
      ? viewModeLedgerNames?.map((name) => ({ name }))
      : viewModePdfName
      ? [{ name: viewModePdfName }]
      : []
  );
  const [issueRaisedInLedger, setIssueRaisedInLedger] = useState(false);
  const [issueMessage, setIssueMessage] = useState("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openBackDrop, setOpenBackDrop] = useState<boolean>(false);

  const { confirmStatus } = useContext(BcPortalContext);

  const titleBody: { [k in UploadFileType]: { title: string; body: string } } = {
    signedPdf: {
      title: "Signed and Stamped Balance Confirmation Document" + (customization?.isPdfRequired === false ? "" : ""),
      body: "Upload the scanned copy of the signed and sealed Balance Confirmation Document",
    },
    ledgerFile: {
      title:
        "Full Ledger Statement in your ERP/Accounting Software Format" +
        (customization?.isLedgerRequired === false ? "" : ""),
      body: "Upload the Full Ledger in excel(.xlsx) format",
    },
    openItem: {
      title: `Open Items in your ERP/Accounting Software Format${customization?.isLedgerRequired === false ? "" : ""}`,
      body: "Upload the Open Item in excel(.xlsx) format",
    },
  };

  useEffect(() => {
    GetUploadedLedgers();
  }, []);

  const GetUploadedLedgers = () =>
    useFetch<{ details: BcPortalDetails }>(API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DETAILS_FOR_UPLOADING_PAGE.failureMessage,
      config: {
        params: {
          data: encryptedData,
        },
      },
      thenCallBack: (res) => {
        const UploadedLedgers = res.data.details;
        const { ledgers: uploadedLedgers, confirmationDocument, openItems } = UploadedLedgers;

        if (uploadedLedgers?.length > 0 && fileType === "ledgerFile") {
          setFiles(uploadedLedgers?.map((fileName) => ({ name: fileName })));
          setCompleted && setCompleted((completed) => ({ ...completed, [fileType]: true }));
        } else if (confirmationDocument && fileType === "signedPdf") {
          setFiles([{ name: confirmationDocument }]);
          setCompleted && setCompleted((completed) => ({ ...completed, [fileType]: true }));
        } else if (openItems?.length > 0 && fileType === "openItem") {
          setFiles(openItems?.map((fileName) => ({ name: fileName })));
          setCompleted && setCompleted((completed) => ({ ...completed, [fileType]: true }));
        }
      },
    });

  const UploadFiles = () => {
    const bodyFormData = new FormData();

    bodyFormData.append("data", encryptedData);
    bodyFormData.append("type", FileType[fileType]);

    for (const item of currentFiles.current) {
      bodyFormData.append("file", item);
    }

    setOpenBackDrop(true);

    useFetch<{ updatedFilesList: string[] }>(API_ENDPOINTS.UPLOAD_FILES_BC_PORTAL.url, "POST", {
      failureMessage: API_ENDPOINTS.UPLOAD_FILES_BC_PORTAL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        setFiles(
          _res.data?.updatedFilesList?.length > -1
            ? _res.data.updatedFilesList?.map((name) => ({ name }))
            : currentFiles.current
        );
        // clear issue in ledger, if re-uploaded ledger
        if (issueRaisedInLedger) {
          setIssueRaisedInLedger(false);
          setIssueMessage("");
        }
        setOpenDialog(false);
        setCompleted((completed) => ({ ...completed, [fileType]: true }));
        setOpenBackDrop(false);
      },
      catchCallBack: () => setOpenBackDrop(false),
      errorCallback: () => setOpenBackDrop(false),
    });
  };

  const ViewUploadedFiles = (fileName: string) => {
    const foundFile = files.find((f) => f.name === fileName);

    useFetch<{ signedUrl?: string }>(API_ENDPOINTS.VIEW_UPLOADED_FILES_BC_PORTAL.url, "GET", {
      failureMessage: API_ENDPOINTS.VIEW_UPLOADED_FILES_BC_PORTAL.failureMessage,
      showSuccessToast: true,
      config: {
        params: {
          data: encryptedData,
          fileName: fileName,
          contentType: foundFile?.type,
          type: FileType[fileType],
          isToBeDownloaded: getShortName(fileName)[1].toLowerCase() !== "pdf",
        },
      },
      thenCallBack: (_res) => {
        const link = document.createElement("a");
        link.href = _res.data.signedUrl;
        link.download = fileName;
        link.target = "_blank";
        link.referrerPolicy = "no-referrer";
        link.style.display = "none";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
    });
  };

  const DeleteUploadedFiles = (fileName: string) => {
    useFetch(API_ENDPOINTS.DELETE_UPLOADED_FILES_BC_PORTAL.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_UPLOADED_FILES_BC_PORTAL.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        fileName: fileName,
        type: FileType[fileType],
      },
      thenCallBack: (_res) => {
        setFiles(files.filter((f) => f.name !== fileName));
        if (files.length < 2 && issueRaisedInLedger) {
          setIssueRaisedInLedger(false);
          setIssueMessage("");
        }
        if (files.length < 2) {
          // no files uploaded, and state not yet updated
          setCompleted((completed) => ({ ...completed, [fileType]: false }));
        }
      },
    });
  };

  return (
    <>
      <Suspense fallback={<LoadingPage noHeader={true} />}>
        {/* Uploader Box */}
        <Box className={$.uploader_box + " " + (issueRaisedInLedger ? $.error : "")}>
          <Box className="vertical_center_align" p={2} gap={1}>
            <Box className="left" mr={"auto"}>
              <Typography variant="subtitle1" fontWeight={700} color={"#000"}>
                {titleBody[fileType].title}
              </Typography>
              <Typography variant="body2">{titleBody[fileType].body}</Typography>
              {issueRaisedInLedger && (
                <Box className="d_flex" gap={"12px"} mt={2}>
                  <ErrorOutline color="error" />
                  <div className="issue_box">
                    <Typography color="error" fontWeight={500}>
                      Issue Reported
                    </Typography>
                    <Typography className="" fontSize={14}>
                      {issueMessage}
                    </Typography>
                  </div>
                </Box>
              )}
            </Box>
            {!viewMode && (
              <Button
                startIcon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                onClick={() => {
                  fileUploader.current && fileUploader.current.click();
                  if (fileType === "signedPdf") {
                    if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfSignedUploadClick, encryptedData);
                    else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfSignedUploadClick, encryptedData);
                  } else if (fileType === "ledgerFile") {
                    if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfLedgerUploadClick, encryptedData);
                    else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfLedgerUploadClick, encryptedData);
                  }
                }}
                sx={{ minWidth: 95 }}
                disabled={disabled}
              >
                Upload
              </Button>
            )}
          </Box>
          {files?.length > 0 &&
            files?.map((file, index) => (
              <CommonFileViewDeleteBox
                key={file.name + index}
                fileName={file.name}
                fileType={fileType}
                viewFn={ViewUploadedFiles}
                delFn={DeleteUploadedFiles}
                viewMode={viewMode}
              />
            ))}
        </Box>
        <input
          ref={fileUploader}
          name="ConfirmDocUploader"
          type="file"
          multiple={true}
          onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
          onChange={(_e) => {
            const _files = Array.from(_e.target.files);
            currentFiles.current = _files;
            setOpenDialog(true);
          }}
          hidden
          style={{ visibility: "hidden" }}
          accept={
            fileType === "ledgerFile" || fileType === "openItem"
              ? ".xlsx,.xls,.pdf,.xlsm,.xlsb," + (fileType === "openItem" ? ".jpeg,.jpg,.png" : "")
              : ".pdf,.jpeg,.jpg,.png"
          }
        />
      </Suspense>
      <UploadFileDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        modalType={fileType}
        uploadFn={UploadFiles}
      />
      <BackdropLoader openBackDrop={openBackDrop} loadingText={"Uploading File..."} />
    </>
  );
};

export const CommonFileViewDeleteBox = ({
  fileName,
  fileType,
  viewFn,
  delFn: deleteFn,
  viewMode,
}: {
  fileName: string;
  fileType: UploadFileType;
  viewFn: (fileName: string) => void;
  delFn?: (fileName: string) => void;
  viewMode?: boolean;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [openDelDialog] = React.useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_disabled, setDisabled] = React.useState(false);

  const { confirmStatus } = useContext(BcPortalContext);

  useEffect(() => {
    if (openDelDialog) setDisabled(false);
  }, [openDelDialog]);

  const fileTypePdf = getShortName(fileName)[1].toLowerCase() === "pdf";

  return (
    <>
      <Box
        className="d_flex"
        sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3", ":last-child": { borderRadius: "0 0 4px 4px" } }}
        p={2}
        gap={1}
      >
        <Chip
          sx={{ mr: "auto", borderRadius: "8px", maxWidth: "70%" }}
          title={fileName}
          variant="outlined"
          label={
            <div className="vertical_center_align textOverflow_hidden">
              <AttachFile fontSize="small" />
              <Typography className="textOverflow_hidden">{fileName}</Typography>
            </div>
          }
        />
        <Button
          className={$.BR_fix}
          variant="outlined"
          startIcon={fileTypePdf ? <Visibility /> : <DownloadRounded />}
          onClick={() => {
            viewFn(fileName);
            if (fileType === "signedPdf") {
              if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfSignedViewClick, encryptedData);
              else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfSignedViewClick, encryptedData);
            } else if (fileType === "ledgerFile") {
              if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfLedgerViewClick, encryptedData);
              else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfLedgerViewClick, encryptedData);
            }
          }}
        >
          {fileTypePdf ? `View` : `Download`}
        </Button>
        {!viewMode && (
          <Button
            className={$.BR_fix}
            variant="outlined"
            startIcon={<Delete />}
            color="error"
            onClick={() => {
              deleteFn(fileName);
              if (fileType === "signedPdf") {
                if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfSignedDeleteClick, encryptedData);
                else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfSignedDeleteClick, encryptedData);
              } else if (fileType === "ledgerFile") {
                if (confirmStatus) uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfLedgerDeleteClick, encryptedData);
                else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfLedgerDeleteClick, encryptedData);
              }
            }}
          >
            Delete
          </Button>
        )}
      </Box>
    </>
  );
};

export const UploadFileDialog = ({
  openDialog,
  setOpenDialog,
  uploadFn,
  modalType,
}: {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  uploadFn: () => void;
  modalType: UploadFileType;
}) => {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [checkboxTicked, setCheckboxTicked] = useState<boolean>(false);
  const { confirmStatus } = useContext(BcPortalContext);

  useEffect(() => {
    if (openDialog) {
      setCheckboxTicked(false);
    }
  }, [openDialog]);

  const ModalType: { [k in UploadFileType]: { title: string; labelBlock: string; labelEnd?: string } } = {
    signedPdf: {
      title: "Balance Confirmation Document",
      labelBlock: "duly signed and stamped",
      labelEnd: " by authorized person",
    },
    ledgerFile: { title: "Full Ledger", labelBlock: "Full Ledger" },
    openItem: { title: "Open Item", labelBlock: "Open Item" },
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Upload the file as {ModalType[modalType]["title"]}?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <FormControlLabel
            control={<NdCheckbox checked={checkboxTicked} onChange={() => setCheckboxTicked(!checkboxTicked)} />}
            label={
              <Typography>
                I confirm that the uploading file is{" "}
                <b style={{ fontFamily: "inherit" }}>{ModalType[modalType]["labelBlock"]}</b>
                {ModalType[modalType]["labelEnd"] || ""}
              </Typography>
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButton
            disabled={!checkboxTicked}
            onClick={() => {
              uploadFn();
              if (modalType === "signedPdf") {
                if (confirmStatus)
                  uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfSignedConfirmationUploadClick, encryptedData);
                else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfSignedConfirmationUploadClick, encryptedData);
              } else if (modalType === "ledgerFile") {
                if (confirmStatus)
                  uiLoggerPortal(uiLoggerBcPortal.ui_PpBcCfLedgerConfirmationUploadClick, encryptedData);
                else uiLoggerPortal(uiLoggerBcPortal.ui_PpBcNcfLedgerConfirmationUploadClick, encryptedData);
              }
            }}
            autoFocus
            variant="contained"
          >
            Upload
          </NdButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ConfirmationRequiredDialog = ({
  openDialog,
  setOpenDialog,
  nextStepAction,
  viewQueryAction,
}: {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  nextStepAction: () => void;
  viewQueryAction: () => Promise<void>;
}) => {
  // const { confirmStatus } = useContext(BcPortalContext);

  useEffect(() => {}, [openDialog]);

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirmation Required</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Typography variant="body1">
            There are some Query/ Issues raised by you or some other user of your company which is still not resolved,
            do you still want to proceed?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <NdButtonOutlined
            onClick={() => {
              nextStepAction && nextStepAction();
              setOpenDialog(false);
            }}
            autoFocus
            variant="outlined"
          >
            Proceed to next step
          </NdButtonOutlined>
          <NdAsyncButton
            onClick={async () => {
              viewQueryAction && (await viewQueryAction());
              setOpenDialog(false);
            }}
            autoFocus
            variant="contained"
          >
            View Query/ Issues
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const ConfirmEditingDialog = ({ open, setOpen, approveAction }) => {
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle> Are you sure you want to edit the response? </DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info">
            This will undo your submitted response, edit only when you submitted incorrect/ incomplete details.
          </Alert>
        </DialogContent>
        <DialogActions>
          <NdAsyncButton
            className={$.BR_fix}
            onClick={async () => {
              setOpen(false);
              await approveAction();
            }}
            color="primary"
            buttonComponent={Button}
          >
            Yes
          </NdAsyncButton>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpen(false);
            }}
            color="primary"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
