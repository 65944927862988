import { Alert, AlertTitle, Chip, Collapse, FormControl, MenuItem, Select } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { userContext } from "src/Components/Contexts/userContext";
import { columnHeaderDescription, groupLabels } from "src/Utils/Recon/ColumnHeaderConfig/Constants";
import {
  BUSINESS_PARTNER_LEDGER,
  columnHeaderNameLabel,
  Customized,
  OWN_LEDGER,
  transFormColumnHeader,
} from "src/Utils/Recon/Recon360/Constants";
import { uiLoggerName } from "../../../Utils/Recon/UiLogger/Constants";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";

export default function ColumnHeaderModal() {
  const { actor } = useContext(userContext);
  const {
    openColumnHeaderDialog,
    setOpenColumnHeaderDialog,
    fetchDataFromERP,
    sheetNameForUnidentified,
    ignoreDoctypesList,
    configUserType,
    columnHeaderArr,
    setColumnHeaderArr,
    isOpenSelectTemplateUploadConfirmRefOwn,
    isOpenSelectTemplateUploadConfirmRefBp,
    highlightedHeader,
    setHighlightedHeader,
    listColumnHeaderEntries,
    headerNameBp,
    headerName,
    saveRuleApi,
    sanitizeColBusiness,
    businessPartnerIntegrationWithERP,
    compareLedgersCheckEndDate,
    uiLogger,
    documentTypesArr,
    setOpenDocumentTypeDialog,
    reUploadLedger,
    saveRuleApiOwn,
    sanitizeColOwn,
    fileNameForUnidentified,
    cancelUploadRequest,
    loadingConfigOwn,
    loadingConfigBP,
  } = useContext(Recon360Context);
  const [openColumnHeaderFoundMsg, setOpenColumnHeaderFoundMsg] = useState(true);
  const [openColumnHeaderDialogLeftToMap, setOpenColumnHeaderDialogLeftToMap] = useState(false);

  const highlightOnClick = (item) => {
    setHighlightedHeader({
      columnHeaderName: item?.columnHeaderName,
      columnHeaderConfigValue: item?.columnHeaderConfigValue,
    });
  };

  const changeColumnHeaderConfig = (item: any, value: any) => {
    setHighlightedHeader({ columnHeaderName: item, columnHeaderConfigValue: value });

    // Create a new array with the updated values
    const updatedColumnHeaderArr = columnHeaderArr.map((object) => {
      if (object.columnHeaderName === item) {
        // Create a new object with the updated values
        return { ...object, errorFlag: false, columnHeaderConfigValue: value };
      }
      return object;
    });

    // Set the state with the updated array
    setColumnHeaderArr(updatedColumnHeaderArr);

    if (configUserType === BUSINESS_PARTNER_LEDGER) {
      Object.keys(headerNameBp).forEach((k: any) => {
        if (k === item) {
          // const tempColumnHeaderConfigValue =
          columnHeaderArr.map((object: any, index: any) => {
            // return { ...object, columnHeaderConfigValue: value };
            if (object.columnHeaderName === item) {
              columnHeaderArr[index].columnHeaderConfigValue = value;
            }
            return columnHeaderArr;
          });
          // setColumnHeaderArr(tempColumnHeaderConfigValue);
          setColumnHeaderArr(columnHeaderArr);
        }
      });
    } else if (configUserType === OWN_LEDGER) {
      Object.keys(headerName).forEach((k: any) => {
        if (k === item) {
          // const tempColumnHeaderConfigValue =
          columnHeaderArr.map((object: any, index: any) => {
            if (object.columnHeaderName === item) {
              columnHeaderArr[index].columnHeaderConfigValue = value;
            }
            // return { ...object, columnHeaderConfigValue: value };
            return columnHeaderArr;
          });
          // setColumnHeaderArr(tempColumnHeaderConfigValue);
          setColumnHeaderArr(columnHeaderArr);
        }
      });
    }
  };
  const saveColumnHeaderConfig = async (checkHasEmptyConfigValue) => {
    // Filter out items with "columnHeaderConfigValue" equal to "Never Ask Again"
    const updatedColumnHeaderArr = columnHeaderArr.filter((item) => item.columnHeaderConfigValue !== "Never ask again");
    if (checkHasEmptyConfigValue) {
      // Check if there are any items with an empty columnHeaderConfigValue
      const hasEmptyConfigValue = updatedColumnHeaderArr.some((item) => item.columnHeaderConfigValue === "");

      if (hasEmptyConfigValue) {
        setOpenColumnHeaderDialogLeftToMap(true);
        return;
      }
    }

    if (configUserType === BUSINESS_PARTNER_LEDGER) {
      Object.keys(headerNameBp).map((k: any) => {
        updatedColumnHeaderArr.map((item: any) => {
          if (k === item.columnHeaderName) {
            const temp = headerNameBp[k].length;
            let tempFlag = true;
            if (item?.columnHeaderConfigValue !== undefined && item?.columnHeaderConfigValue !== "") {
              if (headerNameBp[k][0] === "") {
                headerNameBp[k][0] = item?.columnHeaderConfigValue;
                tempFlag = false;
              } else {
                if (!item.alreadyMappedColumnHeaders) {
                  headerNameBp[k].push(item?.columnHeaderConfigValue);
                }
              }
            }
            if (headerNameBp[k][0] === "") {
              item.errorFlag = true;
            } else if (temp === headerNameBp[k].length && tempFlag) {
              item.errorFlag = true;
            }
          }
          return updatedColumnHeaderArr;
        });
        return updatedColumnHeaderArr;
      });

      // do not ask to reupload after unassigned col headers saved
      await saveRuleApi(headerNameBp, Customized, sanitizeColBusiness, true);
      if (businessPartnerIntegrationWithERP) {
        compareLedgersCheckEndDate();
      }
      setOpenColumnHeaderDialog(false);
      if (documentTypesArr.length > 0) {
        setOpenDocumentTypeDialog(true);
        uiLogger(uiLoggerName.ui_BpAutoConfigOpen);
      } else {
        if (!fetchDataFromERP) {
          // excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
          reUploadLedger("false");
        }
      }
      // }
      uiLogger(uiLoggerName.ui_BpAutoConfigClose);
    } else if (configUserType === OWN_LEDGER) {
      Object.keys(headerName).map((k) => {
        updatedColumnHeaderArr.map((item: any) => {
          if (k === item.columnHeaderName) {
            const temp = headerName[k].length;
            let tempFlag = true;
            if (item?.columnHeaderConfigValue !== undefined && item?.columnHeaderConfigValue !== "") {
              if (headerName[k][0] === "") {
                headerName[k][0] = item?.columnHeaderConfigValue;
                tempFlag = false;
              } else {
                if (!item.alreadyMappedColumnHeaders) {
                  headerName[k].push(item?.columnHeaderConfigValue);
                }
              }
            }

            if (headerName[k][0] === "") {
              item.errorFlag = true;
            } else if (temp === headerName[k].length && tempFlag) {
              item.errorFlag = true;
            }
          }
          return updatedColumnHeaderArr;
        });
        return updatedColumnHeaderArr;
      });

      // do not ask to reupload after unassigned col headers saved
      await saveRuleApiOwn(headerName, Customized, sanitizeColOwn, true);
      if (actor.integration) {
        compareLedgersCheckEndDate();
      }
      setOpenColumnHeaderDialog(false);
      if (documentTypesArr.length > 0) {
        setOpenDocumentTypeDialog(true);
        uiLogger(uiLoggerName.ui_OwnAutoConfigOpen);
      } else {
        if (!fetchDataFromERP) {
          // excelFileUploadOnChange(excelFileUploadOnChangeEvent, excelFileUploadOnChangeFileName);
          reUploadLedger("false");
        }
      }
      uiLogger(uiLoggerName.ui_OwnAutoConfigClose);
    }
  };

  return (
    <>
      <Dialog open={openColumnHeaderDialog} onClose={() => setOpenColumnHeaderDialog(true)} width70Per={true}>
        <header className="modal-card-head">
          <div className="space_between w_100_per vertical_center_align">
            <div style={{ maxWidth: "60%" }}>
              <div className="create_config">Create Configuration</div>
              <p className="d_flex mt_6">
                {!fetchDataFromERP && (
                  <span className="file_name_border">
                    <span className="fw_700">File: </span>
                    {fileNameForUnidentified}
                  </span>
                )}

                {sheetNameForUnidentified && (
                  <span className="file_name_border ml_5">
                    <span className="fw_700">Sheet: </span>
                    {sheetNameForUnidentified}
                  </span>
                )}
              </p>
            </div>
            <div className="step_text">Step 1 of 2 - Map Column Headers</div>
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  // clear ignore doc type list on close click
                  ignoreDoctypesList.current = [];

                  if (fetchDataFromERP) {
                    setOpenColumnHeaderDialog(false);
                  } else {
                    setOpenColumnHeaderDialog(false);
                  }
                  isOpenSelectTemplateUploadConfirmRefOwn.current = false;
                  isOpenSelectTemplateUploadConfirmRefBp.current = false;
                  if (!fetchDataFromERP) {
                    cancelUploadRequest();
                  }
                }}
              />
            </div>
          </div>
        </header>
        <section className="modal-card-body" style={{ padding: "16px 4px 16px 16px" }}>
          <Grid container={true} spacing={2}>
            <Grid xs={9.5} className=" p_16 column_header_scrollbar">
              <Grid>
                <Collapse in={openColumnHeaderFoundMsg} className={openColumnHeaderFoundMsg ? "pb_16" : ""}>
                  <Alert
                    severity="info"
                    onClose={() => {
                      setOpenColumnHeaderFoundMsg(false);
                    }}
                    className="alert_text_unassigned"
                  >
                    <AlertTitle>Unassigned Column Headers Found</AlertTitle>
                    We have already Pre-filled the Unassigned Column headers for you. Edit/modify/verify them as per
                    your requirement
                  </Alert>
                </Collapse>
              </Grid>
              {columnHeaderArr.map((item: any, index: any) => (
                <React.Fragment key={index}>
                  {(item.columnHeaderName === columnHeaderNameLabel.documentDate ||
                    item.columnHeaderName === columnHeaderNameLabel.debitAmount ||
                    item.columnHeaderName === columnHeaderNameLabel.referenceNumber ||
                    item.columnHeaderName === columnHeaderNameLabel.miscellaneous) && (
                    <Grid className="theme_bg_col_header">
                      <h3 className="fw_900">{groupLabels[index]}</h3>
                    </Grid>
                  )}
                  <Grid
                    className={`${
                      item.unassignedColumnHeadersFound ? "config_box_card_column_found" : "config_box_card"
                    } cursor_pointer`}
                    style={{
                      border:
                        highlightedHeader?.columnHeaderName === item.columnHeaderName
                          ? "2px solid #531c4c"
                          : "1px solid rgba(0, 0, 0, 0.08)",
                    }}
                    onClick={() => {
                      highlightOnClick(item);
                    }}
                  >
                    <Grid className="space_between">
                      <h3 className="fw_900 center_align_ver_horiz">
                        {transFormColumnHeader(item.columnHeaderName)}
                        {item.unassignedColumnHeadersFound && (
                          <span className="ml_10">
                            <Chip
                              label="Check if mapped column is correct"
                              color="primary"
                              size="small"
                              style={{ fontSize: "13px !important" }}
                            />
                          </span>
                        )}
                      </h3>
                    </Grid>
                    <Grid className="desc_name">{columnHeaderDescription[item.columnHeaderName]}</Grid>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControl
                          className="update-custom-border-radius"
                          fullWidth={true}
                          disabled={item.alreadyMappedColumnHeaders}
                          // error={item?.errorFlag}
                        >
                          <Select
                            size="small"
                            name="columnHeader"
                            variant="outlined"
                            fullWidth={true}
                            // multiple={item.columnHeaderName === columnHeaderNameLabel.miscellaneous ? true : false}
                            defaultValue={item?.columnHeaderConfigValue !== "" ? [item?.columnHeaderConfigValue] : []}
                            value={item?.columnHeaderConfigValue !== "" ? [item?.columnHeaderConfigValue] : []}
                            // value={item?.columnHeaderConfigValue?.includes(',') ? item?.columnHeaderConfigValue.split(',') : [item?.columnHeaderConfigValue]}
                            displayEmpty={true}
                            onChange={(e) => {
                              changeColumnHeaderConfig(item.columnHeaderName, e.target.value);
                            }}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <span>Select Column</span>; // Placeholder text when nothing is selected
                              }
                              return (
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                  {selected.map((option) => (
                                    <Chip key={option} label={option} />
                                  ))}
                                </div>
                              );
                            }}
                          >
                            <MenuItem disabled={true} value="">
                              Select Column
                            </MenuItem>
                            {item.columnHeaders.map((val: any, ind: any) => (
                              <MenuItem key={ind} value={val}>
                                {val}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
            <Grid xs={2.5} className="column_header_entries_scrollbar ">
              <div className="m_8 text_center col_header_entries">
                <div className="col_header_entries_heading">
                  <p className="mb_15">
                    {highlightedHeader?.columnHeaderConfigValue ? (
                      <>
                        <span className="fw_700">{highlightedHeader.columnHeaderConfigValue}</span> Column has following
                        entries in the ledger
                      </>
                    ) : (
                      <>
                        <span className="fw_700">No Preview:</span> Please select a column to view entries
                      </>
                    )}
                  </p>
                </div>
                <div>
                  {listColumnHeaderEntries?.map(
                    (entry) =>
                      entry.header === highlightedHeader?.columnHeaderConfigValue &&
                      entry.data?.map((entryItem, entryIndex) => (
                        <p key={entryIndex} className="col_header_entries_list">
                          {(highlightedHeader?.columnHeaderName === "debitAmount" ||
                            highlightedHeader?.columnHeaderName === "creditAmount") &&
                          typeof entryItem === "number"
                            ? entryItem.toLocaleString("en-IN")
                            : entryItem}
                        </p>
                      ))
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
        </section>
        <footer className="modal-card-foot">
          <div className="w_100_per right_align">
            <Button
              className="green_btn"
              onClick={() => {
                saveColumnHeaderConfig(true);
              }}
            >
              Save and Proceed
            </Button>
          </div>
        </footer>
      </Dialog>
      <Dialog open={openColumnHeaderDialogLeftToMap} onClose={() => setOpenColumnHeaderDialogLeftToMap(true)}>
        <header className="modal-card-head">
          <p className="create_config">There are still Column header left to be mapped</p>
        </header>
        <section className="modal-card-body">
          <Grid className="mb_20">
            <Alert severity="info" style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: 600 }}>To get the best Reconciliation output,</span> it is advised that you
              fill all the applicable Column headers
            </Alert>
          </Grid>
          <Grid>
            {columnHeaderArr
              .filter((item) => item.columnHeaderConfigValue === "")
              .map((item, index) => (
                <Grid
                  key={index}
                  className="config_box_card cursor_pointer"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.08)",
                  }}
                >
                  <Grid className="space_between">
                    <h3 className="fw_900 center_align_ver_horiz">{transFormColumnHeader(item.columnHeaderName)}</h3>
                  </Grid>
                  <Grid className="desc_name">{columnHeaderDescription[item.columnHeaderName]}</Grid>
                </Grid>
              ))}
          </Grid>
        </section>
        <footer className="modal-card-foot">
          <div className="w_100_per right_align">
            <Button
              startIcon={<LoadingIcon loading={loadingConfigBP || loadingConfigOwn} />}
              className="grey_btn"
              onClick={() => {
                saveColumnHeaderConfig(false);
              }}
              disabled={loadingConfigBP || loadingConfigOwn}
            >
              Save and Proceed
            </Button>
            <Button
              className="green_btn ml_10"
              disabled={loadingConfigBP || loadingConfigOwn}
              onClick={() => {
                setOpenColumnHeaderDialogLeftToMap(false);
              }}
            >
              Map Columns
            </Button>
          </div>
        </footer>
      </Dialog>
    </>
  );
}
