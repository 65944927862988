import { AxiosError } from "axios";
import React, { ErrorInfo } from "react";
import { toast } from "react-toastify";
import CustomToast from "src/Components/CustomToast";
import { LoadingPage } from "./PartnerPortal.common";

type MyProps = {
  [k: string]: any;
  children: React.ReactNode[] | React.ReactNode;
};

type MyState = {
  hasError: boolean;
  error: Error;
  errorInfo: ErrorInfo;
};

export default class ErrorPage extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  protected static getDerivedStateFromError(_error: string) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // log the error and store in state

    this.setState((_state) => ({
      error,
      errorInfo,
    }));

    if ((error as AxiosError)?.isAxiosError) {
      const axiosError = error as AxiosError;
      if (axiosError?.isAxiosError) {
        toast.error(<CustomToast message={axiosError.response?.data?.message || ""} />);
      }
    }
  }

  public render() {
    if (this.state.hasError) {
      // render any custom fallback UI

      return (
        <>
          <LoadingPage />
        </>
      );
    }

    return this.props.children;
  }
}
