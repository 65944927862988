import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from "@mui/material";
import React, { useContext } from "react";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { BcBetaContext } from "../BalanceConfirmationBeta";

interface MultipleCommunicationTypesProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenBcBetaBulkMailing: (open: boolean) => void;
  setActiveStep: (activeStep: number) => void;
}

const MultipleCommunicationTypes = ({
  open,
  setOpen,
  setOpenBcBetaBulkMailing,
  setActiveStep,
}: MultipleCommunicationTypesProps) => {
  const { selectedRow, setSelectedRow, companyId, branchCode } = useContext(BcBetaContext);
  const { setCommunicationTypeId } = useContext(PartnerCommsContext);

  const categorizedRows = selectedRow.reduce((acc, row) => {
    const { communicationTypeName } = row;
    if (!communicationTypeName) {
      if (!acc["No Communication Type"]) {
        acc["No Communication Type"] = [];
      }
      acc["No Communication Type"].push(row);
      return acc;
    }
    if (!acc[communicationTypeName]) {
      acc[communicationTypeName] = [];
    }
    acc[communicationTypeName].push(row);
    return acc;
  }, {} as Record<string, BusinessPartnerListForBcBeta[]>);

  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>You have selected partners having multiple communication types</DialogTitle>
        <DialogContent sx={{ py: 2, px: 3 }}>
          <List>
            {Object.keys(categorizedRows).map((text, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={text}
                  secondary={`${categorizedRows[text]?.length} out of ${selectedRow?.length} selected Partners`}
                />
                <ListItemSecondaryAction>
                  <Button
                    variant="text"
                    color="primary"
                    size="small"
                    onClick={() => {
                      uiLogger(
                        uiLoggerNamesPartnerCommunication.UI_PC_BCB_MULTIPLE_COMMUNICATIONS_PROCEED_CLICK.functionName,
                        companyId,
                        branchCode,
                        {
                          message:
                            uiLoggerNamesPartnerCommunication.UI_PC_BCB_MULTIPLE_COMMUNICATIONS_PROCEED_CLICK.message,
                        }
                      );
                      setSelectedRow(categorizedRows[text]);
                      setOpen(false);
                      setOpenBcBetaBulkMailing(true);
                      setCommunicationTypeId(categorizedRows[text][0].communicationTypeId);

                      // next page in the Bulk Mailing stepper if the communication type is not "No Communication Type"
                      setActiveStep(text.trim().toLowerCase() === "no communication type".toLowerCase() ? 0 : 1);
                    }}
                  >
                    PROCEED
                  </Button>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MultipleCommunicationTypes;
