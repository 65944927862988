import { Box, Fab, MenuItem, Pagination, Select, Skeleton, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useEffect, useMemo } from "react";
import {
  CellProps,
  Column,
  ColumnInterface,
  HeaderGroup,
  TableInstance,
  useBlockLayout,
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import "./Table.scss";

const defaultPropGetter = () => ({});

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export interface ColumnDefs extends ColumnInterface {
  Header: string | ((props: TableInstance) => JSX.Element);
  width?: number;
  id?: string;
  Cell?: (props: CellProps<any>) => JSX.Element;
  Sort?: boolean;
  accessor?: string | ((props: any) => string);
  Filter?: JSX.Element | ((props: any) => JSX.Element);
  minWidth?: number;
  maxWidth?: number;
  sticky?: "left" | "right";
}

enum stickyClassName {
  left = "stickyLeft",
  right = "stickyRight",
}
interface TableProps<T extends object> {
  columns: ColumnDefs[] | ColumnInterface<T>[];
  rows: T[];
  sortEnable?: boolean;
  setSelectedRow?: StateDispatch<T[] | Record<string, unknown>[]>;
  actorType?: string;
  showPagination?: boolean;
  loading?: boolean;
  pageSize?: number;
  id?: string;
  getCellProps?: (props: any) => Record<string, unknown>;
  renderRowSubComponent?: (...props: any) => undefined;
  fromTab?: string;
}

interface ExtendedHeaderGroup extends HeaderGroup {
  sticky: ColumnDefs["sticky"];
}

function Table<T extends object>({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  sortEnable,
  renderRowSubComponent,
  setSelectedRow,
  actorType,
  showPagination,
  loading,
  pageSize: requestedPageSize,
  fromTab,
}: TableProps<T>) {
  const columnsData = useMemo(() => columns, [columns]);

  const rowsData = useMemo(() => rows, [rows]);

  const defaultColumn = useMemo(() => {
    return { Filter: "" };
  }, []);

  const [filterShown, setFilterShown] = React.useState(false);

  if (!navigator?.platform?.includes("Mac")) {
    document.documentElement.classList.add("NonMac");
    document.body.classList.add("NonMacBody");
  }

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    filteredRows,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    ...rest
    // state: { selectedRowIds },
  } = useTable(
    {
      columns: columnsData as Column[],
      data: rowsData,
      defaultColumn,
      initialState: { pageSize: requestedPageSize || 25 },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (actorType === "admin" || actorType === "BPDashboard") {
      const filterselectedFlatRows = selectedFlatRows.map((item) => item.original);
      setSelectedRow(filterselectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedRow, actorType]);

  const isOverFlowing = (element: Element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  };

  const setCSSVarsOnStickyEl = (elArr: NodeListOf<Element>) => {
    elArr.forEach((sticky, i) => {
      (sticky as HTMLDivElement).style.setProperty(
        "--data-val",
        i !== 0 ? elArr[i - 1].clientWidth.toString() + "px" : "0"
      );
    });
  };

  useEffect(() => {
    const canFilter = headerGroups.some((group) => group.headers.some((header) => header.canFilter && header.Filter));
    setFilterShown(canFilter);
  }, [headerGroups, rest]);

  return (
    <Card style={{ overflowX: "scroll", padding: "20px" }}>
      {/* <CssBaseline /> */}
      <table {...getTableProps()} id={id} className="main-table" style={{ borderRadius: "12px" }}>
        <thead className="table-head">
          {headerGroups.map((headerGroup, iHead) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={"thead_tr" + iHead}>
              {headerGroup.headers.map((column, iRow) => (
                <th
                  {...column.getHeaderProps()}
                  key={"th" + iRow}
                  className={stickyClassName[(column as ExtendedHeaderGroup).sticky]}
                  ref={(th) => {
                    if (th) {
                      const leftStick = th.parentElement.querySelectorAll("th.stickyLeft");
                      const rightStick = th.parentElement.querySelectorAll("th.stickyRight");
                      setCSSVarsOnStickyEl(leftStick);
                      setCSSVarsOnStickyEl(rightStick);
                    }
                  }}
                >
                  {sortEnable && column.canSort ? (
                    <div className="column" {...column.getSortByToggleProps()}>
                      {column.isSorted && column.isSortedDesc && (
                        <div>
                          {column.render("Header")} <i className="fa fa-long-arrow-alt-down" aria-hidden="true" />
                        </div>
                      )}
                      {column.isSorted && !column.isSortedDesc && (
                        <div>
                          {column.render("Header")} <i className="fa fa-long-arrow-alt-up" aria-hidden="true" />
                        </div>
                      )}
                      {!column.isSorted && (
                        <div className="header">
                          {column.render("Header")} <i className="sort-icon fa fa-sort" aria-hidden="true" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="column">{column.render("Header")} </div>
                  )}

                  <div className="filterBox" style={{ paddingBlock: column.Filter ? "2px" : "0" }}>
                    {column.canFilter ? column.render("Filter") : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ height: !page.length ? "100%" : "unset" }}>
          {!page.length && <NoDataToShow />}
          {page.map((row, iRow) => {
            prepareRow(row);
            const rowProps = row.getRowProps();

            return (
              <React.Fragment key={rowProps.key}>
                <tr {...row.getRowProps()} key={"tbody_tr" + iRow}>
                  {row.cells.map((cell, iCell) => {
                    return (
                      <td
                        aria-label={fromTab === "CustomGapDescription" ? "Custom" : ""}
                        ref={(td) => {
                          if (td && isOverFlowing(td)) td.title = td.innerText;
                          if (td) {
                            const leftStick = td.parentElement.querySelectorAll("td.stickyLeft");
                            const rightStick = td.parentElement.querySelectorAll("td.stickyRight");
                            setCSSVarsOnStickyEl(leftStick);
                            setCSSVarsOnStickyEl(rightStick);
                          }
                        }}
                        key={"td" + iCell}
                        {...cell.getCellProps([
                          {
                            // className: cell.column,
                            // style: cell.column,
                          },
                          getCellProps(cell),
                        ])}
                        className={stickyClassName[(cell.column as ExtendedHeaderGroup).sticky]}
                      >
                        {loading ? (
                          <Skeleton variant="text" sx={{ fontSize: "10px", borderRadius: 4 }} animation="wave" />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
              </React.Fragment>
            );
          })}
        </tbody>
        {filterShown && (
          <Fab
            color="default"
            aria-label="add"
            sx={{
              position: "sticky",
              bottom: "35px",
              width: "36px",
              height: "36px",
              left: "calc(100% - 75px)",
              transform: "scale(1.3)",
              backgroundColor: "#fff",
            }}
            onClick={() => {
              document.querySelectorAll("input.filterInput").forEach((input) => input.classList.toggle("shown"));
            }}
          >
            <i className="fa fa-filter" aria-hidden="true" color="#aaa" />
          </Fab>
        )}
      </table>

      {/* Pagination And Table Footer Component */}
      {showPagination && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            paddingLeft: "10px",
            marginTop: "15px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${pageSize * pageIndex + 1}–${
              pageSize * (pageIndex + 1) < filteredRows.length ? pageSize * (pageIndex + 1) : filteredRows.length
            } of ${filteredRows.length}`}
          </Typography>
          <Select
            value={pageSize}
            size="small"
            sx={{
              "&>div": { py: "2px" },
              "& > fieldset": { borderWidth: "0.5px" },
            }}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 200].map((PageSize) => (
              <MenuItem key={PageSize} value={PageSize}>
                {PageSize} per Page
              </MenuItem>
            ))}
          </Select>
          <div style={{ flex: 1 }} />
          <Pagination
            count={pageCount}
            page={pageIndex + 1}
            defaultPage={1}
            siblingCount={2}
            boundaryCount={2}
            onChange={(_e, pageNum) => gotoPage(pageNum - 1)}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "rgba(84, 28, 76, 0.1) !important",
              },
            }}
          />
        </div>
      )}
    </Card>
  );
}

const NoDataToShow = () => {
  return (
    <Box
      sx={{
        height: "100%",
        color: "#8f9eb4",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <i className="fas fa-folder-open fa-lg blankFolder" />
      <Typography>No Data To Display</Typography>
    </Box>
  );
};

export default Table;
