import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { Recon360Context } from "../Recon360";

export default function DiscardTicketModal(props) {
  const { resetChecklist } = useContext(Recon360Context);
  return (
    <Dialog open={props.showDiscardTicketModal}>
      <DialogTitle>Are you sure you want to discard the current recon</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Discarded reconciliation:
          <p> 1. Will not be visible in Recon Task Dashboard.</p>
          <p> 2. Will not be used for future Reconciliation Gap Justifications</p>
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            props.setShowDiscardTicketModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={props.isStatusUpdating}
          variant="contained"
          color="warning"
          onClick={() => {
            props.changeStatus("Discarded Ticket", props.ticketId);
            resetChecklist();
          }}
          sx={{ borderRadius: "4px !important" }}
        >
          {props.isStatusUpdating && <LoadingIcon loading={props.isStatusUpdating} />}
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}
