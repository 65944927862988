import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";
import React, { useContext } from "react";
import { Recon360Context } from "../../Recon360";
import "./LinkOldReco.scss";

const UnlinkOldReco = () => {
  const {
    openUnlinkOldReco,
    setOpenUnlinkOldReco,
    selectedTaskId,
    setSelectedTaskId,
    unlinkOldRecoTask,
    deleteCurrentLink,
  } = useContext(Recon360Context);
  const handleClose = () => {
    setOpenUnlinkOldReco(false);
  };
  const handleRadioChange = (taskId: number) => {
    setSelectedTaskId(taskId);
  };
  return (
    <div>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openUnlinkOldReco}
        maxWidth="md"
        fullWidth
        sx={{ width: "664px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Unlink Old Reconciliation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 2 }}>
          <div className="task-list">
            {unlinkOldRecoTask.map((task) => (
              <ListItem key={task.ticketId} className="task-list-item" onClick={() => handleRadioChange(task.ticketId)}>
                <ListItemIcon>
                  <Radio
                    edge="start"
                    checked={selectedTaskId === task.ticketId}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ "aria-labelledby": `task-list-item-${task.ticketId}` }}
                  />
                </ListItemIcon>
                <div className="task-details">
                  <ListItemText
                    primary={`Task ID: ${task.ticketId}`}
                    secondary={`Reconciliation Period: ${task.startDate} to ${task.endDate}`}
                  />
                </div>
              </ListItem>
            ))}
          </div>
        </DialogContent>
        <DialogActions sx={{ p: 2, background: "#F1F1F1" }}>
          <Button
            className={selectedTaskId ? "purple_btn" : "grey_btn"} // Conditional class name
            style={{ width: "143px" }}
            onClick={() => {
              deleteCurrentLink();
              handleClose();
            }}
            disabled={!selectedTaskId} // Disable the button when selectedTaskId is null
          >
            UNLINK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UnlinkOldReco;
