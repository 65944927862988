import { Autocomplete, Button, Checkbox, FormControlLabel, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BusinessPartnersListForMsme, MsmeSegment } from "src/entity/recon-entity/ReconInterfaces";
import { MsmeConfirmationStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TagsInputUniqueKeys } from "../../ReconMIS/ClientReporting/AutoCompletes";
import { CheckboxGroup } from "../../ReconMIS/utils";
import "../Styles/LedgerRequestFilter.scss";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type setAppliedFilters = StateDispatch<{
  [key: string]: string;
}>;

interface MsmeConfirmationFilterProps {
  allCategories: string[];
  storeRowsDataMsme: BusinessPartnersListForMsme[];
  rowsDataMsme: BusinessPartnersListForMsme[];
  setRowsDataMsme: StateDispatch<BusinessPartnersListForMsme[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterAppliedMsme: StateDispatch<boolean>;
  openMsmeFilter: boolean;
  setOpenMsmeFilter: StateDispatch<boolean>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  companyId: string;
  branchCode: string;
}

const MsmeConfirmationFilter = (props: MsmeConfirmationFilterProps) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [msmeDocAvailable, setMsmeDocAvailable] = useState({
    yes: false,
    no: false,
  });
  const [msmeCertAvailable, setMsmeCertAvailable] = useState({
    yes: false,
    no: false,
  });
  const [selectedSegments, setSelectedSegments] = useState<MsmeSegment[]>([]);
  const allMsmeStatus = Array.from(new Set(Object.values(MsmeConfirmationStatuses))); // only unique values
  const allSegments: MsmeSegment[] = ["Micro", "Small", "Medium"];

  type keys = "partnerNames" | "category" | "status";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenMsmeFilter(false);
  };

  const filterMsme = () => {
    let tempRowData = props.storeRowsDataMsme;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedMsme(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedMsme(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }

    if (selectedStatus.length > 0) {
      props.setIsFilterAppliedMsme(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.msmeStatus?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (selectedSegments.length > 0) {
      props.setIsFilterAppliedMsme(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedSegments?.some((val) => item.segment?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (msmeDocAvailable.yes || msmeDocAvailable.no) {
      props.setIsFilterAppliedMsme(true);
      tempRowData = tempRowData?.filter((item) => {
        if (msmeDocAvailable.yes && !msmeDocAvailable.no) return item.msmeConfirmationDocument;
        if (msmeDocAvailable.no && !msmeDocAvailable.yes) return !item.msmeConfirmationDocument;
        else if (msmeDocAvailable.no && msmeDocAvailable.yes) return true;
        else return false;
      });
    }

    if (msmeCertAvailable.yes || msmeCertAvailable.no) {
      props.setIsFilterAppliedMsme(true);
      tempRowData = tempRowData?.filter((item) => {
        if (msmeCertAvailable.yes && !msmeCertAvailable.no) return item.udyamCertificate;
        if (msmeCertAvailable.no && !msmeCertAvailable.yes) return !item.udyamCertificate;
        else if (msmeCertAvailable.no && msmeCertAvailable.yes) return true;
        else return false;
      });
    }

    setFilterObj({ partnerNames: partnerNames, category: selectedCategory, status: selectedStatus });

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Selected Status": selectedStatus.join(", "),
      "Selected Segment": selectedSegments.join(", "),
      "Msme Document Available":
        msmeDocAvailable.yes && msmeDocAvailable.no
          ? "Yes, No"
          : msmeDocAvailable.yes
          ? "Yes"
          : msmeDocAvailable.no
          ? "No"
          : "",
      "Msme Certificate Available":
        msmeCertAvailable.yes && msmeCertAvailable.no
          ? "Yes, No"
          : msmeCertAvailable.yes
          ? "Yes"
          : msmeCertAvailable.no
          ? "No"
          : "",
    });

    props.setRowsDataMsme(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterMsme();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);
    setPartnerNames([]);
    setSelectedSegments([]);
    setMsmeDocAvailable({ yes: false, no: false });
    setMsmeCertAvailable({ yes: false, no: false });

    props.setAppliedFilters({});
    setFilterObj(null);

    handleClose();
    setTimeout(() => {
      props.setOpenMsmeFilter(true);
      props.setRowsDataMsme(props.storeRowsDataMsme);
      props.setIsFilterAppliedMsme(false);
    }, 700);
  };

  return (
    <div>
      <Dialog open={props.openMsmeFilter} aria-labelledby="MsmeFilter" className="msmeFilter" maxWidth={"md"}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedCategory(filterObj?.category || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="d_flex m_15">
            <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
            <div className="w_400 d_flex category_textField_width_390 ">
              <TagsInputUniqueKeys
                tags={partnerNames}
                setTags={setPartnerNames}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Names"
                splitter="newline"
                limitTags={3}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={props.storeRowsDataMsme}
                accessor={"businessPartnerName"}
                uniqueKeyAccessor={"businessPartnerId"}
              />
            </div>
          </div>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Category :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedCategory}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedCategory(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allCategories || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select category" />}
              />
            </div>
          </div>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">MSME Status :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedStatus}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedStatus(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={allMsmeStatus || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select status" />}
              />
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">MSME Segment :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                value={selectedSegments}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedSegments(value as MsmeSegment[]);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={allSegments || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select Segment" />}
              />
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">MSME Confirmation Document :</div>
            <div className="w_400 category_textField_width_390">
              <CheckboxGroup row={true} onChange={(_e, v) => setMsmeDocAvailable({ yes: v.yes, no: v.no })}>
                <FormControlLabel control={<Checkbox checked={msmeDocAvailable.yes} name="yes" />} label="Yes" />
                <FormControlLabel control={<Checkbox checked={msmeDocAvailable.no} name="no" />} label="No" />
              </CheckboxGroup>
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">MSME / Udyam Certificate :</div>
            <div className="w_400 category_textField_width_390">
              <CheckboxGroup row={true} onChange={(_e, v) => setMsmeCertAvailable({ yes: v.yes, no: v.no })}>
                <FormControlLabel control={<Checkbox checked={msmeCertAvailable.yes} name="yes" />} label="Yes" />
                <FormControlLabel control={<Checkbox checked={msmeCertAvailable.no} name="no" />} label="No" />
              </CheckboxGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              id="filterMsme_button"
              className="theme_btn"
              onClick={() => {
                filterMsme();
                uiLogger(uiLoggerName.ui_FilterApplied, props.companyId, props.branchCode);
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MsmeConfirmationFilter;
