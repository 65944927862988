import { Edit, Person, Settings } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { updateIsSendingBulkMailsBCB } from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { BcBetaContext } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../Context/PartnerCommunicationContext";
import { StateDispatch } from "../CommonComponents";
import { UpdateEmailTemplateDialog, UpdateUserEmailSettingDialog } from "../CommonDialogs";
import EmailEditorWithTemplate from "../EmailEditorWithTemplate";
import { CommunicationTypeDetails, EmailTemplateDetailsForCommsType } from "../PartnerCommsInterfaces";
import { EmailFields } from "../ViewReplyMailThread";

interface SetupMailProps {
  handleBack: () => void;
  handlePageBack: () => void;
  setPassedFns: StateDispatch<{ sendEmail: () => Promise<void> }>;
  //   selectedRow: object[] | any[] | Record<string, any>[];
}

const SetupMail: React.FC<SetupMailProps> = ({ handleBack, ...props }) => {
  const { actor } = useContext(userContext);
  const { selectedRow, setIntervalBCBeta, branchCode, companyId, listUserEmailTemplates } = useContext(BcBetaContext);
  const { emailBusinessPartnerId, communicationTypeId, emailTemplates, emailTemplateContent, userEmailSetting } =
    useContext(PartnerCommsContext);
  const dispatch = useDispatch();

  const [files, setFiles] = useState<File[]>([]);

  const [commsTypeName, setCommsTypeName] = useState("");
  const [emailTemplateDetails, setEmailTemplateDetails] = useState<EmailTemplateDetailsForCommsType>({} as any);

  const [emailSubject, setEmailSubject] = useState(emailTemplateContent?.emailSubject || "");
  const [editorContent, setEditorContent] = useState(emailTemplateContent?.emailBody || "");
  const [emailTemplateId, setEmailTemplateId] = useState(emailTemplateContent?.templateId || null);
  const [templateError, setTemplateError] = useState(false);

  const emailTemplateName = emailTemplates.find((item) => item.templateId === emailTemplateId)?.templateName;

  // update email Template section
  const [openUpdateEmailTemplate, setOpenUpdateEmailTemplate] = useState(false);
  const [openUpdateEmailSettingDlg, setOpenUpdateEmailSettingDlg] = useState(false);

  const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
  const allRowsMatched = selectedRow.every(
    (row) => row?.communicationTypeId !== firstFoundCommType?.communicationTypeId
  );

  const GetCommunicationTypeDetail = async (communicationTypeId: number) => {
    return useFetch<{ response: string; data: CommunicationTypeDetails }>(
      API_ENDPOINTS.GET_COMMUNICATION_TYPE_DETAIL.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.GET_COMMUNICATION_TYPE_DETAIL.failureMessage,
        config: {
          params: {
            communicationTypeId,
          },
        },
        thenCallBack: (_res) => {
          if (_res.data?.data) {
            setCommsTypeName(_res.data.data.name);
            setEmailTemplateDetails(_res.data.data.emailTemplateDetails);
          }
        },
      }
    );
  };

  useEffect(() => {
    GetCommunicationTypeDetail(communicationTypeId);
  }, [communicationTypeId]);

  const handleClose = () => {
    handleBack();
  };

  const checkIfUserEmailTemplateHasToUpdate = async () => {
    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("templateName", emailTemplateName);

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.url, "POST", {
        failureMessage: API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.failureMessage,
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: async (response) => {
          // check response here
          if (response.data.isTemplateUpdated) {
            setOpenUpdateEmailTemplate(true);
            resolve();
            // console.log("so why not open");
          } else {
            await sendMailInBulk();
            resolve();
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const updateUserEmailTemplate = async () => {
    setOpenUpdateEmailTemplate(false);

    if (emailSubject.trim() === "") {
      setTemplateError(true);
      return;
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateName);
    bodyFormData.append("templateType", UseCaseType.balanceConfirmationBeta);
    bodyFormData.append("senderCompanyId", companyId);

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return new Promise<void>((resolve, reject) => {
      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: async () => {
          await sendMailInBulk();

          // reload templates after Adding/Updating User Email Template
          listUserEmailTemplates();

          resolve();

          // props.setLoader(true);
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const sendMailInBulk = async () => {
    const EndPointBC = API_ENDPOINTS.SEND_BULK_EMAIL.url;

    const selectedBPIds = selectedRow.map((item) => item?.businessPartnerId);

    dispatch(updateIsSendingBulkMailsBCB(true));

    const bodyFormData = new FormData();
    bodyFormData.append("businessPartnerIds", selectedBPIds?.toString());
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("emailBody", editorContent);
    bodyFormData.append("companyId", companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("communicationTypeId", communicationTypeId.toString());

    if (files !== null && files.length > 0) {
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    return await useFetch<{
      bulkEmailStatus: {
        isComplete: boolean;
        totalPartnerCount: number;
        timeStamp: Date;
      };
    }>(EndPointBC, "POST", {
      failureMessage: API_ENDPOINTS.SEND_BULK_EMAIL.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        // setShowLoadingIcon(false);
        setIntervalBCBeta();
        handleClose();

        // go back to main page
        props.handlePageBack();
      },
      catchCallBack: () => {
        // if (props.templateType === "BCB")
        dispatch(updateIsSendingBulkMailsBCB(false));

        handleClose();
      },
    });
  };

  useEffect(() => {
    if (props.setPassedFns) {
      props.setPassedFns({ sendEmail: () => checkIfUserEmailTemplateHasToUpdate() });
    }
  }, [emailBusinessPartnerId, emailSubject, editorContent, emailTemplateName, files]);

  return (
    <>
      <Stack gap={2} mb={"90px"}>
        {/* Top Bar */}
        <Box sx={{ bgcolor: "#fff", p: 2, borderRadius: 1 }}>
          <Stack direction="row" spacing={2} className="d_flex center_align_ver_horiz">
            <Typography fontWeight="bold" fontSize={18} color="textPrimary">
              Communication Type:
            </Typography>
            <Typography fontWeight="normal" fontSize={16} color="textSecondary">
              {commsTypeName}
            </Typography>
            <Box flexGrow={1} />
            <Button
              size="small"
              variant="text"
              disabled={firstFoundCommType && !allRowsMatched}
              onClick={() => {
                handleBack();
              }}
              sx={{ color: "#531c4c" }}
            >
              Change
            </Button>
          </Stack>
        </Box>

        {/* Main Setup Section */}
        <Box component="section" className="emailSetupSection" sx={{ bgcolor: "#fff" }}>
          {/* Setup Email Header Bar */}
          <Box
            className="header_bar space_between"
            alignItems="center"
            p={2}
            sx={{ bgcolor: "#fff", borderBottom: 1, borderColor: "#dedede" }}
          >
            <Typography variant="h6" fontWeight="600">
              Setup Email
            </Typography>
            <IconButton
              size="small"
              onClick={async () => {
                // logic
                const tab = "UserEmailSetting";
                // const authDialog = 1;

                const params = `tab=${tab}`;
                const uri = `/${actor.name}/reconSettings?${params}`;
                window.open(uri, "_blank")?.focus();
                setOpenUpdateEmailSettingDlg(true);
              }}
            >
              <Settings />
            </IconButton>
          </Box>

          <EmailRecipientDetails fromEmail={userEmailSetting.from_email} bpCount={selectedRow.length} />

          <EmailEditorWithTemplate
            inputEditorState={{ emailTemplateId: emailTemplateDetails.id, templateError: templateError }}
            returnEditorState={(emailSubject, editorContent, templateId, files) => {
              setEmailSubject(emailSubject);
              setEditorContent(editorContent);
              setEmailTemplateId(templateId);

              setFiles(files);
            }}
            sectionsCustomizations={{
              subject:
                selectedRow.length > 0 &&
                selectedRow[0].status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation,
            }}
            otherProps={{
              branchCode: branchCode,
              companyId: companyId,
              listUserEmailTemplates: listUserEmailTemplates,
              templateType: UseCaseType.balanceConfirmationBeta,
            }}
          />
        </Box>
      </Stack>
      {/* Update Email Template Dialog */}
      <UpdateEmailTemplateDialog
        open={openUpdateEmailTemplate}
        setOpen={setOpenUpdateEmailTemplate}
        yesAction={async () => {
          await updateUserEmailTemplate();
        }}
        noAction={async () => {
          await sendMailInBulk();
        }}
      />
      {/* Update User Email Settings Dialog */}
      <UpdateUserEmailSettingDialog open={openUpdateEmailSettingDlg} setOpen={setOpenUpdateEmailSettingDlg} />
    </>
  );
};

const EmailRecipientDetails = ({ fromEmail, bpCount }) => {
  const { actor } = useContext(userContext);
  const [openUpdateEmailSettingDlg, setOpenUpdateEmailSettingDlg] = useState(false);

  return (
    <Box
      sx={{
        p: 2,
        bgcolor: "#f9f9f9",
        borderBottom: 1,
        borderColor: "#dedede",
      }}
    >
      <Stack position={"relative"} gap={2}>
        <Box className="d_flex" gap={2}>
          <EmailFields label="From:" emails={[fromEmail]} isSender />
          <IconButton
            onClick={async () => {
              // logic
              const tab = "UserEmailSetting";
              const authDialog = 1;

              const params = `tab=${tab}&authDialog=${authDialog}`;
              const uri = `/${actor.name}/reconSettings?${params}`;
              window.open(uri, "_blank")?.focus();
              setOpenUpdateEmailSettingDlg(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>

        {/* To Email Field */}
        <Box className="email_field" sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" sx={{ color: "#00000061", mr: 2, width: 54 }}>
            To:
          </Typography>

          <Box
            className="d_flex vertical_center_align"
            sx={{
              bgcolor: "background.paper",
              borderRadius: 1,
              p: 0.5,
              flex: 1,
              border: 1,
              borderColor: "#dfdfdf",
              maxWidth: 500,
            }}
          >
            <Stack direction="row" spacing={2} className="vertical_center_align" sx={{ width: "100%" }}>
              <Box className="avatar-group" sx={{ display: "flex", alignItems: "center", mr: 1 }}>
                <AvatarGroup />
              </Box>
              <Typography fontWeight="normal" fontSize={14} color="textPrimary" sx={{ lineHeight: "143%" }}>
                {bpCount} Business Partners
              </Typography>
            </Stack>
          </Box>
        </Box>
      </Stack>
      {/* Update User Email Settings Dialog */}
      <UpdateUserEmailSettingDialog open={openUpdateEmailSettingDlg} setOpen={setOpenUpdateEmailSettingDlg} />
    </Box>
  );
};

const AvatarGroup: React.FC = () => {
  const count = 5;
  return (
    <Box display="flex" alignItems="center" gap={-1}>
      {Array<number>(count)
        .fill(0)
        .map((_, index) => (
          <Avatar
            key={index}
            sx={{
              bgcolor: "#bdbdbd",
              border: "2px solid #fff",
              height: 21,
              width: 21,
              ml: index === 0 ? 0 : -2,
              zIndex: count - index,
            }}
          >
            {index < count - 1 ? <Person sx={{ fontSize: 14 }} /> : " "}
          </Avatar>
        ))}
    </Box>
  );
};

export default SetupMail;
