import { Check, Close, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useCallback, useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { userContext } from "src/Components/Contexts/userContext";
import BpDashboardTable from "src/Components/ReactTable/BpDashboardTable";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import useFetch from "../../Common/useFetch";
import TabPanel from "../NewBusinessPartner/TabPanel";
import {
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  TallyOrSapCompanyId,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface InvalidEmail {
  name?: string;
  businessPartnerId: number;
  businessPartnerName: string;
  vendorCode: string;
  location: string;
  emailId: string;
  designation?: string;
  ledgerRequest?: string;
  balanceConfirmation?: string;
  paymentAdvice?: string;
}

const getBlankData = (count = 10) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      emailId: `abc${Math.round(Math.random() * 100 + 1)}@nakad.co`,
      businessPartnerId: Math.round(Math.random() * 1000 + 1),
      businessPartnerName: "ABC Inc.",
      vendorCode: `${Math.round(Math.random() * 1000 + 1)}`,
      location: "Delhi, Ind",
    } as InvalidEmail);
  }
  return dataArray;
};

interface InvalidEmailProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  afterClose: () => void;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}

const InvalidEmailDialog = (props: InvalidEmailProps) => {
  const { actor } = useContext(userContext);

  const [tabIndex, setTabIndex] = useState(0);
  const [rowsDataInvalid, setRowsDataInvalid] = useState([]);
  const [rowsDataPotnInvalid, setRowsDataPotnInvalid] = useState([]);
  const [loadingRowsInvalid, setLoadingRowsInvalid] = useState(false);
  const [loadingRowsPotnInvalid, setLoadingRowsPotnInvalid] = useState(false);

  const [currentRow, setCurrentRow] = useState<InvalidEmail>(null);
  const [openEditMail, setOpenEditMail] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState("");

  const [enteredEmailInvalid, setEnteredEmailInvalid] = useState(false);
  const [downloadingReport, setDownloadingReport] = useState(false);

  const columnDefinition = useCallback(
    (potentially = false): ColumnDef<InvalidEmail>[] => [
      {
        header: "Email",
        enableSorting: true,
        accessorFn: (row) => row?.emailId,
        cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor" style={{ paddingLeft: 8 }}>
            <a>{row.original?.emailId}</a>
          </div>
        ),
        size: potentially ? 150 : 220,
      },
      {
        header: () => <div>Partner Name</div>,
        id: "Business partner name",
        enableSorting: true,
        accessorKey: "businessPartnerName",
        size: potentially ? 170 : 200,
      },
      {
        header: "Vendor Code",
        enableSorting: true,
        accessorKey: "vendorCode",
        size: 130,
      },
      {
        header: "Location",
        enableSorting: true,
        accessorKey: "location",
        size: 100,
      },
      {
        header: "Action",
        enableSorting: false,
        accessorFn: (row) => row.businessPartnerName?.toLowerCase(),
        cell: ({ row }) => (
          <div className="center_align" style={{ gap: 4 }}>
            {potentially && (
              <Button
                className="theme_btn"
                startIcon={<Check />}
                variant="contained"
                size="small"
                onClick={() => submitEmailValid(row.original?.businessPartnerId, row.original?.emailId)}
                sx={{ color: "#fff", boxShadow: "none !important" }}
              >
                Mark as valid
              </Button>
            )}
            <Button
              className="theme_btn_no_bg"
              startIcon={<Edit />}
              variant="outlined"
              size="small"
              onClick={() => {
                setCurrentRow(row.original);
                setOpenEditMail(true);
                setEnteredEmailInvalid(true);
              }}
              sx={{ color: "#27b27c", borderColor: "#27b27c", boxShadow: "none !important" }}
            >
              Edit Email
            </Button>
          </div>
        ),
        size: potentially ? 285 : 185,
      },
    ],
    []
  );

  useEffect(() => {
    if (props.open) {
      listInvalidEmails();
      listPotentiallyInvalidEmails();
    }
  }, [props.open]);

  const listInvalidEmails = () => {
    setLoadingRowsInvalid(true);
    setRowsDataInvalid(getBlankData());
    useFetch<{ invalidEmailList: InvalidEmail[] }>(API_ENDPOINTS.LIST_INVALID_EMAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_INVALID_EMAILS.failureMessage,
      config: {
        params: {
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data.invalidEmailList) {
          setRowsDataInvalid(_res.data.invalidEmailList);
        }
        setLoadingRowsInvalid(false);
      },
    });
  };

  const listPotentiallyInvalidEmails = () => {
    setLoadingRowsPotnInvalid(true);
    setRowsDataPotnInvalid(getBlankData());
    useFetch<{ potentiallyInvalidList: InvalidEmail[] }>(API_ENDPOINTS.LIST_POTENTIALLY_INVALID_EMAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_POTENTIALLY_INVALID_EMAILS.failureMessage,
      config: {
        params: {
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data.potentiallyInvalidList) {
          setRowsDataPotnInvalid(_res.data.potentiallyInvalidList);
        }
        setLoadingRowsPotnInvalid(false);
      },
    });
  };

  const submitEmail = (_bpId: number, _updatedMailId: string, _key: "invalid" | "potentiallyInvalid") => {
    useFetch(API_ENDPOINTS.SUBMIT_EDITED_EMAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.SUBMIT_EDITED_EMAIL.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: _bpId,
        email: currentRow?.emailId,
        updatedEmail: _updatedMailId,
        key: _key,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        listInvalidEmails();
        listPotentiallyInvalidEmails();
      },
    });
  };

  const submitEmailValid = (_bpId: number, _updatedMailId: string) => {
    useFetch(API_ENDPOINTS.SUBMIT_EDITED_EMAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.SUBMIT_EDITED_EMAIL.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: _bpId,
        emailId: _updatedMailId,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        listInvalidEmails();
        listPotentiallyInvalidEmails();
      },
    });
  };

  const DownloadInvalidEmailsReport = (_key: "invalid" | "potentiallyInvalid") => {
    setDownloadingReport(true);
    useFetch<{ workbookBase64: string }>(API_ENDPOINTS.DOWNLOAD_INVALID_EMAILS_REPORT.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_INVALID_EMAILS_REPORT.failureMessage,
      config: {
        params: {
          key: _key,
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (_res) => {
        setDownloadingReport(false);
        const excelData = Buffer.from(_res.data.workbookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, `InvalidEmailsReports - ${_key} - ${actor.name}.xlsx`);
        listInvalidEmails();
        listPotentiallyInvalidEmails();
      },
      catchCallBack: () => {
        setDownloadingReport(false);
      },
    });
  };

  return (
    <>
      <Dialog open={props.open} maxWidth="md" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1} flexDirection={"column"}>
          <Box className="top flex" sx={{ width: "100%" }}>
            <DialogTitle sx={{ pb: 1 }}>Unverified Emails</DialogTitle>
            <IconButton
              onClick={() => {
                props.setOpen(false);
                props.afterClose && props.afterClose();
              }}
              sx={{ ml: "auto" }}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ width: "100%", borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabIndex}
              onChange={(_e, v: number) => setTabIndex(v)}
              aria-label="basic tabs example"
              variant="fullWidth"
              scrollButtons="auto"
            >
              <Tab label="Invalid Emails" sx={{ textTransform: "none" }} />
              <Tab label="Potentially Invalid Emails" sx={{ textTransform: "none" }} />
            </Tabs>
          </Box>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5, pt: 0, px: 0 }}>
          <FormLabel sx={{ px: 2, py: 1 }}>
            {tabIndex === 0
              ? `These are the emails that are invalid and needs to corrected.`
              : `These are the emails that we are not sure whether they are valid or not.`}
          </FormLabel>
          <TabPanel value={tabIndex} index={0} sx={{ py: 0 }}>
            <Box sx={{ height: "59vh", "& .table_fixed_div": { maxHeight: "50vh" } }}>
              <BpDashboardTable
                columns={columnDefinition()}
                rows={rowsDataInvalid}
                paddings="none"
                loading={loadingRowsInvalid}
              />
            </Box>
          </TabPanel>
          <TabPanel value={tabIndex} index={1} sx={{ py: 0 }}>
            <Box sx={{ height: "59vh", "& .table_fixed_div": { maxHeight: "50vh" } }}>
              <BpDashboardTable
                columns={columnDefinition(true)}
                rows={rowsDataPotnInvalid}
                paddings="none"
                loading={loadingRowsPotnInvalid}
              />
            </Box>
          </TabPanel>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <Button
            className="theme_btn"
            disabled={downloadingReport}
            startIcon={<LoadingIcon loading={downloadingReport} />}
            autoFocus={true}
            onClick={async () => {
              DownloadInvalidEmailsReport(tabIndex === 0 ? "invalid" : "potentiallyInvalid");
            }}
          >
            Download Report
          </Button>
        </DialogActions>
      </Dialog>

      {/* Edit Email */}
      <Dialog open={openEditMail} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Edit Email</DialogTitle>
          <IconButton
            onClick={() => {
              setOpenEditMail(false);
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          {/* <FormLabel></FormLabel> */}
          <TextField
            label="Edit Email"
            placeholder="Edit Email"
            size="small"
            required
            defaultValue={currentRow?.emailId}
            onChange={(_e) => {
              if (_e.target.value.length < 1 || !_e.target.value.includes("@")) setEnteredEmailInvalid(true);
              else if (_e.target.value === currentRow?.emailId) setEnteredEmailInvalid(true);
              else setEnteredEmailInvalid(false);
              setUpdatedEmail(_e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions className="dialog_footer">
          <Button
            className="theme_btn"
            autoFocus={true}
            onClick={() => {
              submitEmail(
                currentRow.businessPartnerId,
                updatedEmail,
                tabIndex === 0 ? "invalid" : "potentiallyInvalid"
              );
              setOpenEditMail(false);
            }}
            disabled={enteredEmailInvalid}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvalidEmailDialog;
