import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, FormLabel, List, ListItem, Tooltip } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { isShownSideBar } from "src/App";
import uiLogger from "src/Utils/UiLogger";
import { ActorTypes, FrontendActor, PathType } from "../../entity/models/FrontendActor";
import Logo from "../../Graphics/icon.png";
import { userContext } from "../Contexts/userContext";
import { NavBarStructure } from "./Constants";
import { Android12Switch } from "./Loader";
import { getAdminTopBarButtons, getReconTopBarButtons } from "./TopNavBar";

export const getTopBarButtons: (actor: FrontendActor) => NavBarStructure[] = (actor: FrontendActor) => {
  const path = ActorTypeToPathType(actor);

  if (!actor) return;

  if (path === PathType.admin) {
    return getAdminTopBarButtons("Register");
  } else if (path === PathType.bank) {
    // return getBankTopBarButtons("None");
  } else if (path === PathType.recon) {
    return getReconTopBarButtons("", "", {}, actor);
  } else return getReconTopBarButtons("", "", {}, actor);
};

const isOverFlowingY = (element: Element) => {
  return element.scrollHeight > element.clientHeight;
};

export function ActorTypeToPathType(actor: FrontendActor, truePath = false): PathType {
  if (!actor) return;
  if (actor.actorType === "Admin") {
    return PathType.admin;
  } else if (actor.actorType === ActorTypes.User) {
    if (truePath) {
      const accountName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");
      const path = PathType.recon.replace(":accountName", accountName) as PathType;
      return path;
    } else return PathType.recon;
  }
  return null;
}

export function redirect(actor: FrontendActor, history: ReturnType<typeof useHistory>, returnPath?: boolean) {
  let path = ActorTypeToPathType(actor);

  if (path === PathType.recon) {
    const accountName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");
    path = PathType.recon.replace(":accountName", accountName) as PathType;
  }

  if (!path) alert(actor.actorType + " not implemented");

  if (returnPath) return path;
  else history.push(path);
}

export const isOverFlowing = (element: Element) => {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth ||
    element?.children[0]?.scrollWidth > element?.children[0]?.clientWidth
  );
};

const HOME_ROUTE = "#home";

const SideBar = () => {
  const location = useLocation<any>();
  const history = useHistory<any>();
  const { actor } = useContext(userContext);
  const sidebar = useRef<HTMLDivElement>(null);
  const [mini, setMini] = useState(false);

  // Function to hide the sidebar when the mouse leaves
  const hideSidebar = () => {
    if (sidebar.current?.classList.contains("hoverable")) {
      sidebar.current?.classList.add("close");
    }

    const menuBox = sidebar.current?.querySelector(".menu_container");

    if (menuBox) {
      menuBox.classList.toggle("overflowing", isOverFlowingY(menuBox));
    }
  };

  // Function to show the sidebar when the mouse enter
  const showSidebar = () => {
    if (sidebar.current?.classList.contains("hoverable") && !mini) {
      sidebar.current?.classList.remove("close");
    }

    const menuBox = sidebar.current?.querySelector(".menu_container");

    if (menuBox) {
      menuBox.classList.toggle("overflowing", isOverFlowingY(menuBox));
    }
  };

  if (!navigator?.platform?.includes("Mac")) {
    document.documentElement.classList.add("NonMac");
    document.body.classList.add("NonMacBody");
  }

  if (location?.pathname === "/") {
    document.title = "NAKAD | Pioneers of tokenized supply chain finance";
  }

  return (
    <>
      {actor?.name && location?.pathname && isShownSideBar(location.pathname) && (
        <nav
          key={"fixed"}
          ref={sidebar}
          className="sidebar locked hoverable close"
          onMouseEnter={showSidebar}
          onMouseLeave={hideSidebar}
          onTouchStartCapture={showSidebar}
        >
          <div className="logo_items flex">
            <Link className="nav_image" to={HOME_ROUTE} onClick={() => redirect(actor, history)}>
              {/* <img src="https://images.unsplash.com/photo-1636114673156-052a83459fc1" /> */}
              <img src={Logo} alt="Logo" />
            </Link>
            <Link className="logo_name" to={HOME_ROUTE} onClick={() => redirect(actor, history)}>
              NAKAD
            </Link>
            <i className="bx nav_image" />
          </div>

          <div className="menu_container">
            <div className="menu_items">
              {actor?.name &&
                getTopBarButtons(actor).map((btn, _i) => {
                  return btn.hasSubTab ? (
                    <React.Fragment key={btn.name + _i}>
                      <List className="menu_group" disablePadding={true}>
                        <Accordion
                          sx={{
                            background: "transparent !important",
                            boxShadow: "unset !important",
                            m: "0 !important",
                          }}
                          defaultExpanded={
                            btn.subMenu.findIndex((sub) =>
                              import.meta.env.VITE_APP_NAKAD_LOCAL === "true"
                                ? ActorTypeToPathType(actor, true).toLowerCase().includes(sub.to.toLowerCase())
                                : location.pathname.toLowerCase().includes(sub.to.toLowerCase())
                            ) > -1
                          }
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            sx={{
                              m: "0 2px !important",
                              minHeight: "unset !important",
                              p: "0 !important",
                              "& > div": {
                                m: "0 !important",
                              },
                              justifyContent: "flex-start",
                            }}
                            onClick={btn?.eventName ? () => uiLogger(btn.eventName) : null}
                          >
                            <div className="menu_title flex">
                              <Tooltip title={mini ? btn.name : ""} arrow={true} placement="right">
                                <span>{btn.icon ? btn.icon : null}</span>
                              </Tooltip>
                              <span
                                className="text_title"
                                ref={(_r) => {
                                  if (_r && isOverFlowing(_r)) {
                                    _r?.setAttribute("overflowing", "true");
                                    _r.title = btn.name;
                                    _r.innerText = btn.name.substring(0, 20) + "...";
                                  } else _r?.removeAttribute("overflowing");
                                }}
                              >
                                {btn.name}
                              </span>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails sx={{ p: "0 !important" }}>
                            {btn.subMenu?.map((subMenu, _j) => (
                              <ListItem
                                className="list_item"
                                key={subMenu.name + _j}
                                disablePadding={true}
                                sx={{ display: "block" }}
                              >
                                <Tooltip title={mini ? subMenu.name : ""} arrow={true} placement="right">
                                  <Link
                                    className={
                                      location.pathname.toLowerCase().includes(subMenu.to.toLowerCase())
                                        ? "link flex active"
                                        : "link flex "
                                    }
                                    to={subMenu.to}
                                    onClick={subMenu?.eventName ? () => uiLogger(subMenu.eventName) : null}
                                  >
                                    {subMenu?.icon ? subMenu?.icon : <i className="fa-regular fa-square" />}
                                    <span>{subMenu.name}</span>
                                  </Link>
                                </Tooltip>
                              </ListItem>
                            ))}
                          </AccordionDetails>
                        </Accordion>
                      </List>
                      <div className="sidebar_profile flex" />
                    </React.Fragment>
                  ) : (
                    <li className="list_item" key={btn.name + _i}>
                      <Tooltip title={mini ? btn.name : ""} arrow={true} placement="right">
                        <Link
                          className={
                            location.pathname.toLowerCase().includes(btn.to.toLowerCase())
                              ? "link flex active"
                              : "link flex "
                          }
                          to={btn.to}
                          onClick={btn?.eventName ? () => uiLogger(btn.eventName) : null}
                        >
                          {btn?.icon ? btn?.icon : <i className="fa-regular fa-square" />}
                          <span>{btn.name}</span>
                        </Link>
                      </Tooltip>
                    </li>
                  );
                })}
            </div>
          </div>

          <Box
            sx={{
              mt: 2,
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              width: 60,
              alignItems: "center",
            }}
          >
            <FormLabel sx={{ fontSize: 10 }}>Mini</FormLabel>
            <Android12Switch checked={mini} onChange={() => setMini(!mini)} />
          </Box>
        </nav>
      )}
    </>
  );
};

export default SideBar;
