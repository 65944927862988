import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import CustomToast from "src/Components/CustomToast";
import { Recon360Context } from "../Recon360";

const SheetSelectionModal = () => {
  const {
    sheetsArrRef,
    selectedSheets,
    setSelectedSheets,
    openSheetSelectionModal,
    setOpenSheetSelectionModal,
    uploadSelectedSheets,
    setWhichActionRequired,
    setDisableUploadBothSide,
  } = useContext(Recon360Context);
  const [currentPage, setCurrentPage] = useState(0);
  const filteredSheetsArrRef = sheetsArrRef.current.filter((file) => file.sheets.length > 1);
  function isSelected(filename, sheet) {
    const selectedFile = selectedSheets.find((item) => item.filename === filename);
    return selectedFile && selectedFile.sheets.includes(sheet);
  }

  const handleCheckboxChange = (filename, sheet) => {
    setSelectedSheets((prevSelectedSheets) => {
      const existingFileIndex = prevSelectedSheets.findIndex((item) => item.filename === filename);

      if (existingFileIndex !== -1) {
        // File exists, toggle sheet selection
        const updatedFile = { ...prevSelectedSheets[existingFileIndex] };
        updatedFile.sheets = updatedFile.sheets.includes(sheet)
          ? updatedFile.sheets.filter((selectedSheet) => selectedSheet !== sheet)
          : [...updatedFile.sheets, sheet];

        return [
          ...prevSelectedSheets.slice(0, existingFileIndex),
          updatedFile,
          ...prevSelectedSheets.slice(existingFileIndex + 1),
        ];
      } else {
        // File does not exist, add new entry
        return [
          ...prevSelectedSheets,
          {
            filename,
            sheets: [sheet],
          },
        ];
      }
    });
  };

  const handleNextPage = () => {
    if (validateSelectedSheets()) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleBackPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const showSelectedSheets = () => {
    if (validateSelectedSheets()) {
      uploadSelectedSheets();
    }
  };

  const validateSelectedSheets = () => {
    const missingSheets = filteredSheetsArrRef.slice(0, currentPage + 1).filter((file) => {
      const selectedFile = selectedSheets.find((item) => item.filename === file.filename);
      return !selectedFile || selectedFile.sheets.length === 0;
    });

    if (missingSheets.length > 0) {
      // alert(`Please select at least one sheet for each file: ${missingSheets.map((file) => file.filename).join(", ")}`);
      toast.error(<CustomToast message="Please select at least one sheet for each file" />);
      return false;
    }
    return true;
  };
  return (
    <div>
      <MuiDialog
        open={openSheetSelectionModal}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          style: { width: 600, margin: "auto" },
        }}
      >
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle
            style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "350px" }}
            title={filteredSheetsArrRef[currentPage]?.filename}
          >
            {filteredSheetsArrRef[currentPage]?.filename}
          </DialogTitle>
          <Box className="vertical_center_align">
            <span>
              {currentPage + 1}/{filteredSheetsArrRef.length}
            </span>
            <IconButton
              onClick={() => {
                setOpenSheetSelectionModal(false);
                setWhichActionRequired(null);
                setDisableUploadBothSide(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5, height: "35vh" }}>
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend" style={{ color: "black" }}>
                Please select the sheets you want to upload for the above-mentioned file.
              </FormLabel>
              <FormGroup>
                {filteredSheetsArrRef[currentPage]?.sheets &&
                  filteredSheetsArrRef[currentPage].sheets.map((sheet) => (
                    <FormControlLabel
                      key={sheet}
                      control={
                        <Checkbox
                          checked={isSelected(filteredSheetsArrRef[currentPage]?.filename, sheet)}
                          onChange={() => handleCheckboxChange(filteredSheetsArrRef[currentPage]?.filename, sheet)}
                        />
                      }
                      label={sheet}
                    />
                  ))}
              </FormGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button className="theme_outline_btn" disabled={currentPage === 0} onClick={handleBackPage}>
            Back
          </Button>
          <Button
            className="theme_btn"
            onClick={currentPage === filteredSheetsArrRef.length - 1 ? showSelectedSheets : handleNextPage}
          >
            {currentPage === filteredSheetsArrRef.length - 1 ? "Finish" : "Next"}
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default SheetSelectionModal;
