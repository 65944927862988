import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, IconButton } from "@mui/material";
import React from "react";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface StatusRefreshProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  time: number;
  refreshFn: () => Promise<void>;
}

const StatusRefreshDialog = (props: StatusRefreshProps) => {
  const timeInHours = (props.time / 60).toFixed(0);
  const minsRemainAfterHours = props.time % 60;
  const timeInDays = parseFloat(timeInHours) > 23 ? (props.time / (60 * 24)).toFixed(0) : 0;

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Update Status!</DialogTitle>
        <IconButton
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>
          Your last status update was{" "}
          <b>{timeInDays ? `${timeInDays} day(s)` : `${timeInHours} hours ${minsRemainAfterHours} minutes`}</b> ago.
        </FormLabel>
        <FormLabel>Please refresh status before sending mails!</FormLabel>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          className="theme_btn"
          autoFocus={true}
          onClick={async () => {
            await props.refreshFn();
            props.setOpen(false);
          }}
        >
          Refresh Status
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StatusRefreshDialog;
