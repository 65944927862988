import { Box, Button, Divider, List, ListItem, ListItemText, Skeleton, Switch, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { TicketStatuses } from "../Tickets/CurrentTicket";

export interface CustomisedTicketStatus {
  id: number;
  status: string;
  isStatusEnabled: boolean;
}

type StatusCategoryData = Record<string, CustomisedTicketStatus[]>;

const WorkflowSettings = ({ isTaskStatusesChanged, setIsTaskStatusesChanged }) => {
  const [allStatuses, setAllStatuses] = useState<StatusCategoryData>({});
  const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(true);
  const initiallyEnabledStatusIds = useRef<number[]>([]);
  const [isSavingStatuses, setIsSavingStatuses] = useState<boolean>(false);

  useEffect(() => {
    listCustomisableStatuses();
    setIsTaskStatusesChanged(false);
  }, []);

  const listCustomisableStatuses = () => {
    setIsLoadingStatuses(true);
    useFetch<{ customisedStatuses: StatusCategoryData }>(API_ENDPOINTS.LIST_TASK_STATUSES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_TASK_STATUSES.failureMessage,
      thenCallBack: (res) => {
        setAllStatuses(res.data.customisedStatuses);
        setIsLoadingStatuses(false);
        initiallyEnabledStatusIds.current = Object.values(res.data.customisedStatuses).flatMap((category) =>
          category.filter((currentStatus) => currentStatus.isStatusEnabled).map((currentStatus) => currentStatus.id)
        );
      },
      catchCallBack: () => {
        setIsLoadingStatuses(false);
      },
    });
  };

  const toggleStatus = (categoryName: string, id: number) => {
    setIsTaskStatusesChanged(true);
    setAllStatuses((prevData) => ({
      ...prevData,
      [categoryName]: prevData[categoryName].map((currentStatus) =>
        currentStatus.id === id ? { ...currentStatus, isStatusEnabled: !currentStatus.isStatusEnabled } : currentStatus
      ),
    }));
  };

  const handleSave = () => {
    const enabledStatusIds = Object.values(allStatuses).flatMap((category) =>
      category.filter((currentStatus) => currentStatus.isStatusEnabled).map((currentStatus) => currentStatus.id)
    );
    if (enabledStatusIds.length < 5) {
      toast.error(<CustomToast message="Unable to save Task status.Please enable minimum 5 status" />);
      return;
    }
    setIsSavingStatuses(true);

    useFetch(API_ENDPOINTS.UPDATE_TASK_STATUSES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TASK_STATUSES.failureMessage,
      showSuccessToast: true,
      data: {
        enabledStatusIds: enabledStatusIds,
      },
      thenCallBack: () => {
        setIsTaskStatusesChanged(false);
        setIsSavingStatuses(false);
      },
      catchCallBack: () => {
        setIsTaskStatusesChanged(false);
        setIsSavingStatuses(false);
      },
    });
  };

  return (
    <>
      {isLoadingStatuses ? (
        <Box
          sx={{
            maxWidth: "450px",
            backgroundColor: "#ffffff",
            display: "flex",
            gap: 2,
            flexDirection: "column",
            padding: 2,
          }}
        >
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} width={100} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} width={100} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton sx={{ alignSelf: "end" }} variant="rectangular" height={40} width={100} />
        </Box>
      ) : (
        <Box sx={{ maxWidth: "450px", backgroundColor: "#ffffff" }}>
          <Box>
            <Box sx={{ padding: 2 }}>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 500,
                  fontSize: "20px",
                }}
              >
                Task Status Customisation
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Hide status that you don’t want to use in your workflow
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                maxHeight: "520px",
                overflow: "auto",
                paddingX: 2,
              }}
            >
              {Object.entries(allStatuses).map(([categoryName, statuses]) => (
                <Box key={categoryName} sx={{ paddingY: 1 }}>
                  <Box>
                    <Typography
                      variant="overline"
                      sx={{
                        fontWeight: 900,
                        fontSize: "12px",
                      }}
                    >
                      {categoryName}
                    </Typography>
                    <List>
                      {statuses.map((status) => {
                        return (
                          <ListItem key={status.id} disableGutters sx={{ paddingY: 1, paddingRight: 2 }}>
                            <ListItemText
                              primary={status.status}
                              primaryTypographyProps={{
                                sx: {
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  color:
                                    status.status === TicketStatuses.IssueInLedger ||
                                    status.status === TicketStatuses.ApproverRejected ||
                                    status.status === TicketStatuses.CheckerRejected ||
                                    status.status === TicketStatuses.TechnicalIssue
                                      ? "#ef6c00ff"
                                      : "#000000de",
                                },
                              }}
                            />
                            <Switch
                              edge="end"
                              color="primary"
                              checked={status.isStatusEnabled}
                              onChange={() => toggleStatus(categoryName, status.id)}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              position: "sticky",
              bottom: 0,
              background: "#fff",
              padding: 2,
              borderTop: "1px solid #DADADA",
              display: "flex",
              justifyContent: "end",
            }}
          >
            <Button
              disabled={isSavingStatuses || !isTaskStatusesChanged}
              variant="contained"
              onClick={handleSave}
              sx={{
                background: "#27B27C",
                padding: "8px 22px",
                color: "#FFF",
                borderRadius: "4px !important",
                fontWeight: 500,
                fontSize: "15px",
                "&:hover, &.Mui-focusVisible": { background: "#27B27C" },
              }}
            >
              {isSavingStatuses && <LoadingIcon loading={true} />}
              Save
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default WorkflowSettings;
