import { CircularProgress, Dialog, DialogContent } from "@mui/material";
import React from "react";

const RestrictiveLoader = (props) => {
  return (
    <>
      <Dialog
        open={props.openRestrictiveLoader}
        maxWidth={false}
        fullWidth={true}
        PaperProps={{
          style: { width: 380, margin: "auto" },
        }}
      >
        <DialogContent>
          <div className="restrictive_loader">
            <span className="center_align_ver_horiz mr_6">
              <CircularProgress size={20} sx={{ color: "#541C4C" }} />
            </span>
            {props.restrictiveLoaderMsg}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RestrictiveLoader;
