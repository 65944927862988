import { FilterList, FilterListOff } from "@mui/icons-material";
import { Card, Chip, IconButton } from "@mui/material";
import React from "react";

const FilterAppliedBar = ({ appliedFilters }: { appliedFilters: { [key: string]: string } }) => {
  return (
    <Card
      className="vertical_center_align mb_15"
      sx={{
        p: 0.5,
        borderRadius: "32px !important",
        gap: 1,
        width: Object.keys(appliedFilters).length > 0 ? "auto" : "fit-content",
      }}
    >
      <IconButton disableTouchRipple={true}>
        {Object.keys(appliedFilters).length ? <FilterList /> : <FilterListOff />}
      </IconButton>
      {Object.keys(appliedFilters).length > 0 ? (
        <div className="d_flex" style={{ flexWrap: "wrap", gap: "8px", paddingInline: "8px" }}>
          {Object.keys(appliedFilters).map((_key) =>
            appliedFilters[_key] ? (
              <div key={_key}>
                <div style={{ fontSize: "10px" }}>{_key}</div>
                <Chip label={appliedFilters[_key]} size="small" sx={{ maxWidth: 400 }} title={appliedFilters[_key]} />
              </div>
            ) : (
              <React.Fragment key={_key}></React.Fragment>
            )
          )}
        </div>
      ) : (
        <></>
      )}
    </Card>
  );
};

export default FilterAppliedBar;
