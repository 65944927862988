import JoditEditor, { Jodit } from "jodit-react";
import { Config } from "jodit/config";
import React, { useMemo, useRef } from "react";
import { EmailTemplate } from "../../../../entity/recon-entity/ReconInterfaces";
import "./Editor.scss";

export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const handleSelfClosingTags = (srcString: string): string => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(srcString, "text/html");
  const serializer = new XMLSerializer();
  const serialized = serializer.serializeToString(dom.body.getRootNode());
  const serializedDom = parser.parseFromString(serialized, "text/xml");

  const finalBodyInnerHTML = serializedDom.body?.outerHTML
    ?.replace(/\s?xmlns="[^"]+"\s?/, "")
    ?.replace(/<\/?body>/g, "");

  return serializedDom?.body?.innerText === "" ? "" : finalBodyInnerHTML;
};

interface EditorProps {
  selectedEmailTemplateContent: EmailTemplate;
  selectedTemplateName: string;
  dynamicContent?: string;
  setDynamicContent: React.Dispatch<React.SetStateAction<string>>;
}

const Editor = (props: EditorProps) => {
  const editor = useRef<Jodit>(null);
  const config = useMemo(
    () =>
      ({
        placeholder: "Enter...",
        toolbarAdaptive: false,
        toolbarButtonSize: "small",
        // height: 300,
        containerStyle: {
          editorStyle: {
            "max-height": "300px",
          },
        },
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        uploader: {
          insertImageAsBase64URI: true,
          imagesExtensions: ["jpg", "jpeg", "png", "gif", "webp"],
        },
        tabIndex: 1,
        controls: {
          font: {
            list: {
              Courier: "Courier",
            },
          },
        },
      } as DeepPartial<Config>),
    []
  );

  if (editor.current) (editor.current as any).autofocus = true;
  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={
          props.selectedEmailTemplateContent !== null && props.selectedEmailTemplateContent !== undefined
            ? props.selectedEmailTemplateContent.emailBody
            : props.dynamicContent
            ? props.dynamicContent
            : ""
        }
        config={config}
        // tabIndex={1} // tabIndex of textarea
        onBlur={(newContent) => props.setDynamicContent(handleSelfClosingTags(newContent))} // prefer to use only onBlur to update the content for performance reasons
        // onChange={(_newContent) => props.setDynamicContent(handleSelfClosingTags(_newContent))}
      />
    ),
    // lint-disable-next-line react-hooks/exhaustive-deps
    [config, props]
  );
};

export default Editor;
