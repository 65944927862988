export const SAVE = "SAVE";
export const RESET = "RESET";
export const OWN = "own";
export const BP = "businessPartner";
export const RECONCILIATION = "Reconciliation";
export const RISKY_ALGORITHMS = "Risky Algorithms";
export const CUSTOM_GAP_DES_COLUMNS = {
  DOC_TYPE_OWN: "Document Type (Own)",
  DOC_TYPE_PARTNER: "Document Type (Partner)",
  AMOUNT_DIFF: "Amount Difference",
  AMOUNT_OWN: "Amount (Own)",
  AMOUNT_PARTNER: "Amount (Partner)",
  ENTRY_TYPE_OWN: "Entry Type (Own)",
  ENTRY_TYPE_PARTNER: "Entry Type (Partner)",
  GROUP_NAME_OWN: "Group Name (Own)",
  GROUP_NAME_PARTNER: "Group Name (Partner)",
};
