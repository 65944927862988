// import { OwnLedgerConfig } from "../../database/entity/recon-database/OwnLedgerConfig";
// import { OwnBusinessPartnerMapping } from "../../database/entity/recon-database/OwnBusinessPartnerMapping";
// import { BusinessPartnerLedgerConfig } from "../../database/entity/recon-database/BusinessPartnerLedgerConfig";
// import { BusinessPartnerLedgerFromOwn } from "../../database/entity/recon-database/BusinessPartnerLedgerFromOwn";
// import { BusinessPartnerLedgerFromBusinessPartner } from "../../database/entity/recon-database/BusinessPartnerLedgerFromBusinessPartner";

import {
  BalanceConfirmationBetaStatuses,
  LedgerRequestBetaStatuses,
  MsmeConfirmationStatuses,
} from "src/Utils/PartnerCommunication";

export interface SummaryEntry {
  reconciliationLineItem: string;
  valueGap: number | string;
  breakUp: number | string;
}

export interface InvoiceValueGap {
  valueGap: number;
  amountMismatch: number;
  invoiceUnaccountedInBusinessPartner: number;
  invoiceUnaccountedInOwn: number;
}

export interface PaymentValueGap {
  valueGap: number;
  amountMismatch: number;
  paymentNotRecognisedInBusinessPartner: number;
  extraUnmatchedPaymentInBusinessPartner: number;
}

export interface DNCNValueGap {
  valueGap: number;
  amountMismatch: number;
  dncnNotRecognisedInBusinessPartner: number;
  extraUnmatchedDNCNInBusinessPartner: number;
}

export interface TDSValueGap {
  valueGap: number;
  tdsNotRecognisedInBusinessPartner: number;
  extraUnmatchedTDSInBusinessPartner: number;
  suggestedTDSGap: number;
}

export interface MiscellaneousValueGap {
  valueGap: number;
  inBusinessPartnerSOA: number;
  inOwnSOA: number;
}

export interface OpeningBalanceValueGap {
  valueGap: number;
  businessPartnerSOA: number;
  ownSOA: number;
}

export interface OutputUISummary {
  closingBalanceAsPerBusinessPartner: number;
  invoice: InvoiceValueGap;
  payment: PaymentValueGap;
  dncn: DNCNValueGap;
  tds: TDSValueGap;
  miscellaneous: MiscellaneousValueGap;
  reconciledClosingBalance: number;
  closingBalanceAsPerOwn: number;
  openingBalance: OpeningBalanceValueGap;
  residualDifference: number;
}

export interface SummaryEntry {
  reconciliationLineItem: string;
  valueGap: number | string;
  breakUp: number | string;
}

export interface SanitizedColumnCheck {
  documentNumber: boolean;
  documentType: boolean;
  documentDate: boolean;
  referenceNumber: boolean;
  postingDate: boolean;
  debitAmount: boolean;
  creditAmount: boolean;
}

export interface OwnLedgerConfig {
  id: number;
  reconTemplateName: string;
  paymentAdviceTemplateName: string;
  reconConfig: LedgerConfiguration;
  paymentAdviceConfig: LedgerConfiguration;
  reconColumnsToSanitize: ColumnsToSanitize[];
  paymentAdviceColumnsToSanitize: ColumnsToSanitize[];
  createdAt?: Date;
  updatedAt?: Date;
  isUsedAsTemplate?: boolean;
}

export interface InsertLedgerConfig {
  actorId: number;
  templateName: string;
  config: LedgerConfiguration;
  createdAt: Date;
  updatedAt: Date;
  columnsToSanitize?: ColumnsToSanitize[];
}

export interface InsertLedgerResponse {
  message?: string;
  response?: string;
  lastUploadedFiles?: {
    fileName: string;
    content: string;
  }[];
  fileDetails?: FileDetails;
}
export interface FileDetails {
  [fileName: string]: string[];
}

export interface TicketStatus {
  status: string;
  metaStatus: string;
  id: number;
  category: string;
}

export interface TicketUser {
  id: number;
  name: string;
}

export interface GetReconRelatedDetailsOfBpRes {
  response: string;
  message: string;
  partnerDetails: PartnerDetails;
  currentInProgressTicket: any;
}

export interface AllTickets {
  openTickets: TicketDetails[];
  savedTickets: TicketDetails[];
  discardedTickets: TicketDetails[];
}

export interface GetAllTicketsResponse {
  response: string;
  message: string;
  allTickets: AllTickets;
  users: TicketUser[];
  statuses: TicketStatus[];
  allCategories: string[];
}
export enum TicketFollowUpType {
  Mail = "Mail",
  Conversation = "Conversation",
}

export interface TicketDetails {
  id: number;
  ownId: number;
  businessPartnerId: number;
  makerId: number;
  checkerId: number;
  approverId: number;
  lastActiveUserId: number;
  status: string;
  metaStatus: string;
  priority: string;
  dueDate?: string; // ISO Strings received from backend
  lastStatusChangeTime: string; // ISO Strings received from backend
  createdAt: string; // ISO Strings received from backend
  maker?: string;
  checker?: string;
  approver?: string;
  lastActiveUser?: string;
  account?: string;
  businessPartner?: string;
  remarks?: string;
  followUpCounts?: { [key in TicketFollowUpType]?: number };
  followUpLastTime?: { [key in TicketFollowUpType]?: Date };
  bpVendorCode: string;
  category: string[];
}

export interface PartnerDetails {
  ownBusinessPartnerMapping: OwnBusinessPartnerMapping;
  ownName: string;
  businessPartnerName: string;
  closingDifferenceByClosingBalancePercentage: null;
  integrationWithERP: boolean;
  companyId: null;
  accountingSoftware?: string;
  intraCompanyPartner?: boolean;
  reconPeriod: DateRange;
  reconPeriodDate: Date;
  dateUsedToSuggestReconPeriod: string;
}

export interface DateRange {
  startDate: Date;
  endDate: Date;
}

export interface OwnBusinessPartnerMapping {
  ownId: number;
  businessPartnerId: number;
  ownClosingBalance: number;
  businessPartnerClosingBalance: number;
  ownStartDate: Date;
  businessPartnerStartDate: Date;
  ownEndDate?: Date;
  businessPartnerEndDate?: Date;
  closingDifference: number;
  createdAt?: Date;
  updatedAt?: Date;
  ownClosingBalanceDate: Date;
  businessPartnerClosingBalanceDate: Date;
  ownClosingBalanceDateForDashboard: Date;
  businessPartnerClosingBalanceDateForDashboard: Date;
  businessPartnerClosingBalanceForDashboard: number;
  ownClosingBalanceForDashboard: number | string;
  closingDifferenceForDashboard: number;
  category?: string[];
  contactSetting?: ContactSetting[];
  lastEmailSentDate?: Date;
  overallEnteriesForOwn: number;
  overallEnteriesForOwnPaymentAdvice: number;
  overallEnteriesForBusinessPartner: number;
  overallEnteriesForBusinessPartnerPaymentAdvice: number;
  lastestEntryOwn: Date;
  lastestEntryOwnPaymentAdvice: Date;
  lastestEntryBusinessPartner: Date;
  lastestEntryBusinessPartnerPaymentAdvice: Date;
  oldestEntryBusinessPartner: Date;
  oldestEntryBusinessPartnerPaymentAdvice: Date;
  oldestEntryOwn: Date;
  oldestEntryOwnPaymentAdvice: Date;
  lastSyncOwn: Date;
  lastSyncOwnPaymentAdvice: Date;
  lastSyncBusinessPartner: Date;
  lastSyncBusinessPartnerPaymentAdvice: Date;
  ownReconSpecificConfig: boolean;
  ownPaymentAdviceSpecificConfig: boolean;
  lastReconOn: Date;
  currency?: string;
}

export interface OwnBusinessPartnerMappingWithIntegrationFlag {
  ownId: number;
  businessPartnerId: number;
  ownClosingBalance: number;
  businessPartnerClosingBalance: number;
  ownStartDate: Date;
  ownEndDate: Date;
  businessPartnerStartDate: Date;
  businessPartnerEndDate: Date;
  closingDifference: number;
  contactSetting: ContactSetting[];
  createdAt: Date;
  updatedAt: Date;
  businessPartnerName: string;
  integrationWithERP: boolean;
  ownClosingBalanceDate: Date;
  businessPartnerClosingBalanceDate: Date;
  ownClosingBalanceDateForDashboard: Date | string;
  businessPartnerClosingBalanceDateForDashboard: Date;
  businessPartnerClosingBalanceForDashboard: number;
  ownClosingBalanceForDashboard: number | string;
  closingDifferenceForDashboard: number;
  category: string[];
  lastEmailSentDate?: Date;
  businessPartnerLastSync?: string;
  ownLastSync?: string;
  accountingSoftware?: string;
  companyId?: string;
  allCategories?: string[];
  overallEnteriesForOwn: number;
  overallEnteriesForBP: number;
  lastestEntryOwn: Date;
  lastestEntryBp: Date;
  oldestEntryBp: Date;
  oldestEntryOwn: Date;
  lastReconOn: Date;
  ownReconSpecificConfig: boolean;
  ownPaymentAdviceSpecificConfig: boolean;
  ownLedgerConfig: OwnLedgerConfig;
  businessPartnerLedgerConfig: BusinessPartnerLedgerConfig;
  currency: string;
}

export interface BusinessPartnerLedgerConfig {
  id: number;
  reconTemplateName: string;
  paymentAdviceTemplateName: string;
  reconConfig: LedgerConfiguration;
  paymentAdviceConfig: LedgerConfiguration;
  reconColumnsToSanitize: ColumnsToSanitize[];
  paymentAdviceColumnsToSanitize: ColumnsToSanitize[];
  createdAt?: Date;
  updatedAt?: Date;
  isUsedAsTemplate?: boolean;
}

export interface BusinessPartnerLedgerFromOwn {
  itemId: string;
  ownId: number;
  businessPartnerId: number;
  documentType: string;
  documentDate: Date;
  amount: number | null;
  debitAmount: number | null;
  creditAmount: number | null;
  documentNumber: string;
  referenceNumber: string | null;
  postingDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface BusinessPartnerLedgerFromBusinessPartner {
  itemId: string;
  ownId: number;
  businessPartnerId: number;
  documentType: string;
  documentDate: Date;
  amount: number | null;
  debitAmount: number | null;
  creditAmount: number | null;
  documentNumber: string;
  referenceNumber: string | null;
  postingDate: Date | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface ListBusinessPartnerLedgerConfigResponse {
  ledgerConfig: BusinessPartnerLedgerConfig;
  configMapped?: LedgerConfiguration;
}

export interface ListBusinessPartnerLedgerFromBusinessPartnerResponse {
  businessPartnerLedgerFromBusinessPartner: BusinessPartnerLedgerFromBusinessPartner[];
}

export interface ListBusinessPartnerLedgerFromOwnResponse {
  businessPartnerLedgerFromOwn: BusinessPartnerLedgerFromOwn[];
}

export interface SanitizationRuleWithCategories {
  category: string;
  ruleId: number;
  n: boolean;
  rule: string;
  string1: boolean;
  string2: boolean;
}

export interface ListSanitizationRulesResponse {
  sanitizationRules: any;
}

export interface ListOwnBusinessPartnerMappingResponse {
  ownBusinessPartnerMapping: OwnBusinessPartnerMapping[];
}

export interface ListOwnLedgerConfigResponse {
  ledgerConfig: OwnLedgerConfig;
  configMapped?: LedgerConfiguration;
}

export interface AllOwnBusinessPartnerMapping {
  ownBusinessPartnerMapping: OwnBusinessPartnerMappingWithIntegrationFlag;
  ownName: string;
  businessPartnerName: string;
  closingDifferenceByClosingBalancePercentage: number;
  ownConfig: string;
  businessPartnerConfig: string;
  accountingSoftware: string;
  integrationWithERP: boolean;
  companyId: string | null;
}

export interface ListAllOwnBusinessPartnerMappingResponse {
  ownBusinessPartnerMapping: AllOwnBusinessPartnerMapping[];
}

export interface AllBusinessPartnersForDashboard {
  // ownBusinessPartnerMapping: OwnBusinessPartnerMapping;
  ownBusinessPartnerMapping: OwnBusinessPartnerMappingWithIntegrationFlag;
  ownName: string;
  closingDifferenceByClosingBalancePercentage: number;
  businessPartnerName: string;
  ownLastUpdateDate: string;
  businessPartnerLastUpdateDate: string;
  ownLastEntryAvailable: string;
  businessPartnerLastEntryAvailable: string;
  lastReconciliationOn: string;
  ownClosingBalance: string;
  businessPartnerClosingBalance: string;
  closingDifference: string;
  businessPartnerId: number;
}

export interface ListAllBusinessPartnersForDashboardResponse {
  ownBusinessPartnerMapping: AllBusinessPartnersForDashboard[];
}

export interface OwnLedgerObjKeys {
  column: string;
  index: number;
}

export interface OriginalOwnLedgerData {
  originalDocumentType: string;
  originalDocumentNumber: string;
  originalDocumentDate: Date;
  originalReferenceNumber: string;
  originalPostingDate: string;
  originalAmount: number;
  sanitizedDocumentType: string;
  sanitizedDocumentNumber: string;
  sanitizedDocumentDate: string;
  sanitizedReferenceNumber: string;
  sanitizedPostingDate: string;
  sanitizedAmount: number;
}

export interface OriginalBusinessPartnerLedgerData {
  originalDocumentType: string;
  originalDocumentNumber: string;
  originalDocumentDate: Date;
  originalReferenceNumber: string;
  originalPostingDate: string;
  originalAmount: number;
  originalCreditAmount: number;
  sanitizedDocumentType: string;
  sanitizedDocumentNumber: string;
  sanitizedDocumentDate: string;
  sanitizedReferenceNumber: string;
  sanitizedPostingDate: string;
  sanitizedAmount: number;
  sanitizedCreditAmount: number;
}

export interface DNCNBusinessPartnerJSONObjectWithMatchingStatuses {
  refNumber: string;
  Amount_Amount_Matched: string;
  Amount_Amount: number;
  Reversal: string;
  SE_SE_Date_Amount: string;
  SE_SE_Date_Amount_Matched: string;
  SE_SE_Month_Amount: string;
  SE_SE_Month_Amount_Matched: string;
  GME_SE_Date_Amount: string;
  GME_SE_Date_Amount_Matched: string;
  SE_GME_Date_Amount: string;
  SE_GME_Date_Amount_Matched: string;
  GME_SE_Month_Amount: string;
  GME_SE_Month_Amount_Matched: string;
  SE_GME_Month_Amount: string;
  SE_GME_Month_Amount_Matched: string;
  GME_GME_Date_Amount: string;
  GME_GME_Date_Amount_Matched: string;
  GME_GME_Month_Amount: string;
  GME_GME_Month_Amount_Matched: string;
  SE_SE_Quarter_Amount: string;
  SE_SE_Quarter_Amount_Matched: string;
  SE_GME_Quarter_Amount: string;
  SE_GME_Quarter_Amount_Matched: string;
  GME_SE_Quarter_Amount: string;
  GME_SE_Quarter_Amount_Matched: string;
  GME_GME_Quarter_Amount: string;
  GME_GME_Quarter_Amount_Matched: string;

  SE_SE_RW_Date_Amount: string;
  SE_SE_RW_Date_Amount_Matched: string;
  GME_SE_RW_Date_Amount: string;
  GME_SE_RW_Date_Amount_Matched: string;
  SE_GME_RW_Date_Amount: string;
  SE_GME_RW_Date_Amount_Matched: string;
  GME_GME_RW_Date_Amount: string;
  GME_GME_RW_Date_Amount_Matched: string;

  entryType: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  creditAmount: number;
  debitAmount: number;
  Amount: number;
  reconciliationStatus: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  postingDate: string;
  documentNumber: string;
}

export interface DNCNOwnJSONObjectWithMatchingStatuses {
  refNumber: string;
  Amount_Amount_Matched: string;
  Amount_Amount: number;
  Reversal: string;
  SE_SE_Date_Amount: string;
  SE_SE_Date_Amount_Matched: string;
  SE_SE_Month_Amount: string;
  SE_SE_Month_Amount_Matched: string;
  GME_SE_Date_Amount: string;
  GME_SE_Date_Amount_Matched: string;
  SE_GME_Date_Amount: string;
  SE_GME_Date_Amount_Matched: string;
  GME_SE_Month_Amount: string;
  GME_SE_Month_Amount_Matched: string;
  SE_GME_Month_Amount: string;
  SE_GME_Month_Amount_Matched: string;
  GME_GME_Date_Amount: string;
  GME_GME_Date_Amount_Matched: string;
  GME_GME_Month_Amount: string;
  GME_GME_Month_Amount_Matched: string;
  SE_SE_Quarter_Amount: string;
  SE_SE_Quarter_Amount_Matched: string;
  SE_GME_Quarter_Amount: string;
  SE_GME_Quarter_Amount_Matched: string;
  GME_SE_Quarter_Amount: string;
  GME_SE_Quarter_Amount_Matched: string;
  GME_GME_Quarter_Amount: string;
  GME_GME_Quarter_Amount_Matched: string;

  SE_SE_RW_Date_Amount: string;
  SE_SE_RW_Date_Amount_Matched: string;
  GME_SE_RW_Date_Amount: string;
  GME_SE_RW_Date_Amount_Matched: string;
  SE_GME_RW_Date_Amount: string;
  SE_GME_RW_Date_Amount_Matched: string;
  GME_GME_RW_Date_Amount: string;
  GME_GME_RW_Date_Amount_Matched: string;

  originalReferenceNumber: string;
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  postingDate: any;
  Amount: number;
  reconciliationStatus: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  creditAmount: number;
  debitAmount: number;
}

export interface InvoiceOwnJSONObjectWithMatchingStatuses {
  refNumber: string;
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  debitAmount: number;
  creditAmount: number;
  Invoice_Number_Amount_Matched: string;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  invoiceMatch: string;

  SE_SE_Invoice_Amount: string;
  SE_GME_Invoice_Amount: string;
  GME_GME_Invoice_Amount: string;
  GME_SE_Invoice_Amount: string;

  SE_SE_Invoice_Amount_Matched: string;
  SE_GME_Invoice_Amount_Matched: string;
  GME_GME_Invoice_Amount_Matched: string;
  GME_SE_Invoice_Amount_Matched: string;
}

export interface InvoiceBusinessPartnerJSONObjectWithMatchingStatuses {
  refNumber: string;
  entryType: string;
  documentDate: any;
  documentNumber: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  Amount: number;
  debitAmount: number;
  creditAmount: number;
  Invoice_Number_Amount_Matched: string;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  postingDate: string;
  invoiceMatch: string;

  SE_SE_Invoice_Amount: string;
  SE_GME_Invoice_Amount: string;
  GME_GME_Invoice_Amount: string;
  GME_SE_Invoice_Amount: string;

  SE_SE_Invoice_Amount_Matched: string;
  SE_GME_Invoice_Amount_Matched: string;
  GME_GME_Invoice_Amount_Matched: string;
  GME_SE_Invoice_Amount_Matched: string;
}

export interface PaymentBusinessPartnerJSONObjectWithMatchingStatuses {
  refNumber: string;
  Reversal: string;
  SE_SE_Date_Amount: string;
  SE_SE_Date_Amount_Matched: string;
  SE_SE_Month_Amount: string;
  SE_SE_Month_Amount_Matched: string;
  GME_SE_Date_Amount: string;
  GME_SE_Date_Amount_Matched: string;
  SE_GME_Date_Amount: string;
  SE_GME_Date_Amount_Matched: string;
  GME_SE_Month_Amount: string;
  GME_SE_Month_Amount_Matched: string;
  SE_GME_Month_Amount: string;
  SE_GME_Month_Amount_Matched: string;
  GME_GME_Date_Amount: string;
  GME_GME_Date_Amount_Matched: string;
  GME_GME_Month_Amount: string;
  GME_GME_Month_Amount_Matched: string;
  SE_SE_RW_Date_Amount: string;
  SE_SE_RW_Date_Amount_Matched: string;
  GME_SE_RW_Date_Amount: string;
  GME_SE_RW_Date_Amount_Matched: string;
  SE_GME_RW_Date_Amount: string;
  SE_GME_RW_Date_Amount_Matched: string;
  GME_GME_RW_Date_Amount: string;
  GME_GME_RW_Date_Amount_Matched: string;

  SE_SE_Quarter_Amount: string;
  SE_SE_Quarter_Amount_Matched: string;
  SE_GME_Quarter_Amount: string;
  SE_GME_Quarter_Amount_Matched: string;
  GME_SE_Quarter_Amount: string;
  GME_SE_Quarter_Amount_Matched: string;
  GME_GME_Quarter_Amount: string;
  GME_GME_Quarter_Amount_Matched: string;

  originalReferenceNumber: string;
  creditAmount: number;
  debitAmount: number;
  entryType: string;
  Amount: number;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  postingDate: any;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
}

export interface PaymentOwnJSONObjectWithMatchingStatuses {
  refNumber: string;
  Reversal: string;
  SE_SE_Date_Amount: string;
  SE_SE_Date_Amount_Matched: string;
  SE_SE_Month_Amount: string;
  SE_SE_Month_Amount_Matched: string;
  GME_SE_Date_Amount: string;
  GME_SE_Date_Amount_Matched: string;
  SE_GME_Date_Amount: string;
  SE_GME_Date_Amount_Matched: string;
  GME_SE_Month_Amount: string;
  GME_SE_Month_Amount_Matched: string;
  SE_GME_Month_Amount: string;
  SE_GME_Month_Amount_Matched: string;
  GME_GME_Date_Amount: string;
  GME_GME_Date_Amount_Matched: string;
  GME_GME_Month_Amount: string;
  GME_GME_Month_Amount_Matched: string;
  SE_SE_RW_Date_Amount: string;
  SE_SE_RW_Date_Amount_Matched: string;
  GME_SE_RW_Date_Amount: string;
  GME_SE_RW_Date_Amount_Matched: string;
  SE_GME_RW_Date_Amount: string;
  SE_GME_RW_Date_Amount_Matched: string;
  GME_GME_RW_Date_Amount: string;
  GME_GME_RW_Date_Amount_Matched: string;

  SE_SE_Quarter_Amount: string;
  SE_SE_Quarter_Amount_Matched: string;
  SE_GME_Quarter_Amount: string;
  SE_GME_Quarter_Amount_Matched: string;
  GME_SE_Quarter_Amount: string;
  GME_SE_Quarter_Amount_Matched: string;
  GME_GME_Quarter_Amount: string;
  GME_GME_Quarter_Amount_Matched: string;

  entryType: string;
  originalReferenceNumber: string;
  Amount: number;
  creditAmount: number;
  debitAmount: number;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  postingDate: any;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
}

export interface OldLedgerConfiguration {
  documentDate: string;
  documentType: string;
  documentNumber: string;
  amount: string;
  debitAmount: string;
  creditAmount: string;
  referenceNumber: string;
  postingDate: string;
  closingBalance: number;

  invoiceCodes: string[];
  paymentCodes: string[];
  DNCNCodes: string[];
  reversalCodes: string[];
}

export interface CodesWithSign {
  code: string;
  sign: number;
  addParticulars: string[];
  excludeParticulars: string[];
  group?: { groupId: string; groupName: string };
  useReference: boolean;
}

export interface LedgerConfiguration {
  documentDate: string[];
  documentType: string[];
  otherUniqueId: string[];
  amount: string[];
  debitAmount: string[];
  creditAmount: string[];
  referenceNumber: string[];
  postingDate: string[];
  paymentId: string[];
  dncnId: string[];
  invoiceIdForDncn: string[];
  particulars: string[];
  documentNumber: string[];
  clearingDocument: string[];
  clearingDate: string[];
  miscellaneous: string[];
  taxAmount: string[];
  closingBalance: number;
  invoice: CodesWithSign[];
  payment: CodesWithSign[];
  debitNote: CodesWithSign[];
  debitAndCreditNotes: CodesWithSign[];
  creditNote: CodesWithSign[];
  reversal: CodesWithSign[];
  tds: CodesWithSign[];
  interDocument: CodesWithSign[];
  nonRelevantDocTypeFlag: boolean;
  debitAmountSignReverseFlag: boolean;
  creditAmountSignReverseFlag: boolean;
  taxAmountSignReverseFlag: boolean;
  configUsedInMappingFile: MappedConfigInterfaceFileWise[];
  garbageDoctype: any[];
  ignoreDocTypeList?: any;
}

export interface MappedConfigInterfaceFileWise {
  columnMapping: ColumnMappingInterface;
  docTypeMapping: DocTypeMappingInterface;
  fileName: string;
}
export interface ColumnMappingInterface {
  documentType: string;
  documentDate: string;
  referenceNumber: string;
  debitAmount: string;
  creditAmount: string;
  otherUniqueId: string;
  postingDate: string;
  paymentId: string;
  particulars: string;
  dncnId: string;
  invoiceIdForDncn: string;
  documentNumber: string;
  clearingDocument: string;
  clearingDate: string;
  miscellaneous: string;
  taxAmount: string;
}
export interface DocTypeMappingInterface {
  Invoice: CodesWithSign[];
  Payments: CodesWithSign[];
  DNCN: CodesWithSign[];
  TDS: CodesWithSign[];
  Reversal: CodesWithSign[];
  InternalDoc: CodesWithSign[];
}

export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
  string1: boolean;
  string2: boolean;
  n: boolean;
  rule?: string;
  category?: Category;
}

export type Category =
  | "Read text before specific identifier"
  | "Read text after specific identifier"
  | "Trim String"
  | "Replace and/or remove string";

export interface LedgerObjKeys {
  column: string;
  index: number;
}

export interface UploadOwnLedgerDataResponse {
  result: UploadOwnLedgerDataRes;
}

export interface UploadOwnLedgerDataRes {
  response: string;
  message: string;
  isDuplicatePresent: string | boolean;
  uploadSummary: UploadSummary;
  configMapped: FileWiseDocTypeAndColumnHeader;
  config: OwnLedgerConfig | BusinessPartnerLedgerConfig;
  unIdentifiedColumns: UnIdentifiedDoctype[];
  columnHeadersWithData: AutoConfigColumnHeader[];
  configUserType: string;
  nonEmptyDocTypes?: UnIdentifiedDoctype[];
  defaultConfig: any;
  sheetname?: string;
}
export interface AutoConfigColumnHeader {
  header: string;
  data: string[];
}

export interface ConfigJSON {
  originalColumn: string[];
  mappedTo: string[];
  invoiceCodes: string[];
  paymentCodes: string[];
  dncnCodes: string[];
  closingBalance: number[];
  reversalCodes: string[];
}

export interface UploadDataResponse {
  status: number;
  response: string;
  message: string;
}

export interface TempObject {
  doc_type: string;
  item: string;
  doc_byuer: string;
  value: any;
  doc_date_qtr: any;
  own_payment_match: string;
  own_dncn_match: string;
  doc_businessPartner: string;
  value_businessPartner: any;
  doc_date_qtr_sup: any;
  businessPartner_payment_match: string;
  businessPartner_dncn_match: string;
  ownRefNumber: string;
  businessPartnerRefNumber: string;
  difference: any;
  action: any;
}

export interface TempInvoicePaymentDNCNObject {
  paymentMismatch: TempObject[];
  paymentMissed: TempObject[];
  paymentExtra: TempObject[];
  DNCNMismatch: TempObject[];
  DNCNMissed: TempObject[];
  DNCNExtra: TempObject[];
  invoiceMismatch: TempObject[];
  invoiceMissed: TempObject[];
  invoiceExtra: TempObject[];
  tdsExtra: TempObject[];
  tdsMissed: TempObject[];
}

export interface TempInvoiceObject {
  reconciliationStatus: string;
  ownInvoiceNumber: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  ownInvoiceMatch: string;
  invoiceNumberAsPerBusinessPartnerLedger: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  businessPartnerInvoiceMatch: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
}

export interface TempPaymentObject {
  reconciliationStatus: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  ownPaymentMatch: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  businessPartnerPaymentMatch: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
}

export interface TempDNCNObject {
  reconciliationStatus: string;
  ownInvoiceNumber: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  ownDNCNMatch: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  businessPartnerDNCNMatch: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
}

export interface BusinessPartnerLedgerWithDateAsString {
  itemId: string;
  ownId: number;
  businessPartnerId: number;
  documentType: string;
  documentDate: string;
  amount: number | null;
  documentNumber: string;
  referenceNumber: string | null;
  postingDate: any | null;
  createdAt: Date;
  updatedAt: Date;
  debitAmount: number | null;
  creditAmount: number | null;
}

// export interface BusinessPartnerLedgerFromBusinessPartnerWithDateAsString {
//   itemId: string;
//   ownId: number;
//   businessPartnerId: number;
//   documentType: string;
//   documentDate: string;
//   amount: number | null;
//   documentNumber: string;
//   referenceNumber: string | null;
//   postingDate: any | null;
//   createdAt: Date;
//   updatedAt: Date;
//   debitAmount: number | null;
//   creditAmount: number | null;
// }

export interface BusinessPartnerLedgerWithDateAsAny {
  itemId: string;
  ownId: number;
  businessPartnerId: number;
  documentType: string;
  documentDate: any;
  amount: number | null;
  documentNumber: string;
  referenceNumber: string | null;
  postingDate: any | null;
  createdAt: Date;
  updatedAt: Date;
  debitAmount: number | null;
  creditAmount: number | null;
}

// export interface BusinessPartnerLedgerFromBusinessPartnerWithDateAsAny {
//   itemId: string;
//   ownId: number;
//   businessPartnerId: number;
//   documentType: string;
//   documentDate: any;
//   amount: number | null;
//   documentNumber: string;
//   referenceNumber: string | null;
//   postingDate: any | null;
//   createdAt: Date;
//   updatedAt: Date;
//   debitAmount: number | null;
//   creditAmount: number | null;
// }

export interface InvoiceResultObject {
  ownJSON: InvoiceJSONObject[];
  businessPartnerJSON: InvoiceJSONObject[];
}

export interface PaymentResultObject {
  ownJSON: PaymentJSONObject[];
  businessPartnerJSON: PaymentJSONObject[];
  tdsPresent: boolean;
}

export interface MiscellaneousResultObject {
  ownJSON: ReversalCheckLedgerObject[];
  businessPartnerJSON: ReversalCheckLedgerObject[];
  miscellaneousPresent: boolean;
}

export interface InternalDocResultObject {
  ownJSON: InternalDocJSONObject[];
  businessPartnerJSON: InternalDocJSONObject[];
  tdsPresent: boolean;
}

export interface OpeningBalancesSummaryObject {
  ownSOA: number;
  businessPartnerSOA: number;
}

export interface InternalDocJSONObject {
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  creditAmount: number;
  debitAmount: number;
  refNumber: string;
  reconciliationStatus: string;
}

export interface DNCNResultObject {
  ownJSON: DNCNJSONObject[];
  businessPartnerJSON: DNCNJSONObject[];
}

export interface PaymentSummaryObject {
  paymentAmtMismatch: number;
  suggestedTDSMismatch: number;
  Others: number;
  paymentNotRecognizedInBusinessPartnerSOA: number;
  extraUnmatchedPaymentInBusinessPartnerSOA: number;
}

export interface TDSSummaryObject {
  tdsAmtMismatch: number;
  suggestedTDSMismatch: number;
  Others: number;
  tdsNotRecognizedInBusinessPartnerSOA: number;
  extraUnmatchedTDSInBusinessPartnerSOA: number;
}

export interface InvoiceSummaryObject {
  invoiceAmountMismatch: number;
  suggestedTDSMismatch: number;
  Others: number;
  invoiceUnaccountedInBusinessPartnerSOA: number;
  invoiceUnaccountedInOwnSOA: number;
}

export interface TDSSummaryObject {
  tdsAmtMismatch: number;
  suggestedTDSMismatch: number;
  Others: number;
  tdsNotRecognizedInBusinessPartnerSOA: number;
  extraUnmatchedTDSInBusinessPartnerSOA: number;
}

export interface MiscellaneousSummaryObject {
  ownSOA: number;
  businessPartnerSOA: number;
}

export interface DNCNSummaryObject {
  dncnAmountMismatch: number;
  suggestedTDSMismatch: number;
  Others: number;
  debitNoteNotRecognizedInBusinessPartnerSOA: number;
  extraUnmatchedDNCNInBusinessPartnerSOA: number;
}

export interface DetailsObject {
  ownDetails: OwnBusinessPartnerDetailsObject;
  businessPartnerDetails: OwnBusinessPartnerDetailsObject;
}

export interface OwnBusinessPartnerDetailsObject {
  originalReferenceNumber: string;
  referenceNumber: string;
  Amount: number;
  documentDate: any;
  Status: string;
  refNumber: string;
  paymentMatch: string;
  dncnMatch: string;
  entryType: string;
  invoiceMatch: string;
}

// export interface BusinessPartnerDetailsObject {
//   originalReferenceNumber: string;
//   referenceNumber: string;
//   Amount: number;
//   documentDate: any;
//   Status: string;
//   refNumber: string;
//   paymentMatch: string;
//   dncnMatch: string;
//   entryType: string;
//   invoiceMatch: string;
// }

export interface ReversalCheckLedgerObject {
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  entryType: string;
  Reversal: string;
  creditAmount: number;
  debitAmount: number;
  refNumber: string;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  invoiceMatch: string;
}

// export interface BusinessPartnerReversalCheckLedgerObject {
//   documentNumber: string;
//   documentDate: any;
//   documentType: string;
//   referenceNumber: string;
//   originalReferenceNumber: string;
//   postingDate: any;
//   Amount: number;
//   entryType: string;
//   Reversal: string;
//   creditAmount: number;
//   debitAmount: number;
//   refNumber: string;
//   invoiceAmtAsPerBusinessPartnerLedger: number;
//   diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
//   invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
//   percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
//   reconciliationStatus: string;
//   percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
//   paymentMatch: string;
//   percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
//   dncnMatch: string;
//   invoiceMatch: string;
// }

export interface OwnWorksheetLedgerObject {
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  postingDate: any;
  Amount: number;
  entryType: string;
  Reversal: string;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  unSanitizedDebitAmount: number | null;
  unSanitizedCreditAmount: number | null;
  unSanitizedDocumentNumber: string | null;
  unSanitizedReferenceNumber: string | null;
  unSanitizedPostingDate: any | null;
  unSanitizedDocumentType: string | null;
  unSanitizedDocumentDate: any | null;
}

export interface BusinessPartnerWorksheetLedgerObject {
  entryType: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  creditAmount: number;
  Amount: number;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  unSanitizedDebitAmount: number | null;
  unSanitizedCreditAmount: number | null;
  unSanitizedDocumentNumber: string | null;
  unSanitizedReferenceNumber: string | null;
  unSanitizedPostingDate: any | null;
  unSanitizedDocumentType: string | null;
  unSanitizedDocumentDate: any | null;
}

export interface InvoiceJSONObject {
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  creditAmount: number;
  debitAmount: number;
  refNumber: string;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  invoiceMatch: string;
}

// export interface InvoiceBusinessPartnerJSONObject {
//   entryType: string;
//   documentNumber: string;
//   documentDate: any;
//   documentType: string;
//   referenceNumber: string;
//   originalReferenceNumber: string;
//   postingDate: any;
//   Amount: number;
//   creditAmount: number;
//   debitAmount: number;
//   refNumber: string;
//   invoiceAmtAsPerBusinessPartnerLedger: number;
//   diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
//   invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
//   percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
//   reconciliationStatus: string;
//   invoiceMatch: string;
// }

export interface PaymentJSONObject {
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  creditAmount: number;
  debitAmount: number;
  refNumber: string;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
}

// export interface PaymentBusinessPartnerJSONObject {
//   entryType: string;
//   documentNumber: string;
//   documentDate: any;
//   documentType: string;
//   referenceNumber: string;
//   originalReferenceNumber: string;
//   postingDate: any;
//   Amount: number;
//   creditAmount: number;
//   debitAmount: number;
//   refNumber: string;
//   reconciliationStatus: string;
//   percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
//   paymentMatch: string;
// }

export interface DNCNJSONObject {
  entryType: string;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  originalReferenceNumber: string;
  postingDate: any;
  Amount: number;
  creditAmount: number;
  debitAmount: number;
  refNumber: string;
  reconciliationStatus: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
}

// export interface DNCNBusinessPartnerJSONObject {
//   entryType: string;
//   documentNumber: string;
//   documentDate: any;
//   documentType: string;
//   referenceNumber: string;
//   originalReferenceNumber: string;
//   postingDate: any;
//   Amount: number;
//   creditAmount: number;
//   debitAmount: number;
//   refNumber: string;
//   reconciliationStatus: string;
//   percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
//   dncnMatch: string;
// }

export interface OwnBusinessPartnerLedgerObject {
  [key: string]: any;
  documentNumber: string;
  documentDate: any;
  documentType: string;
  referenceNumber: string;
  postingDate: any;
  Amount: number;
  entryType: string;
  refNumber: string;
  Reversal: string;
  creditAmount: number;
  debitAmount: number;
  invoiceAmtAsPerBusinessPartnerLedger: number;
  originalReferenceNumber: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
  reconciliationStatus: string;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
  paymentMatch: string;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
  dncnMatch: string;
  invoiceMatch: string;
}

// export interface BusinessPartnerLedgerObject {
//   [key: string]: any;
//   postingDate: any;
//   documentNumber: string;
//   documentDate: any;
//   documentType: string;
//   referenceNumber: string;
//   originalReferenceNumber: string;
//   creditAmount: number;
//   debitAmount: number;
//   Amount: number;
//   entryType: string;
//   Reversal: string;
//   refNumber: string;
//   invoiceAmtAsPerBusinessPartnerLedger: number;
//   diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
//   invoiceNumberOfTheSuggestedMatchAsPerBusinessPartnerLedger: string;
//   percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
//   reconciliationStatus: string;
//   percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
//   paymentMatch: string;
//   percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
//   dncnMatch: string;
//   invoiceMatch: string;
// }

export interface OwnLedgerDataObject {
  ownLedgerDataObj: OwnBusinessPartnerLedgerObject[];
  invoiceCodes: CodesWithSign[];
  paymentCodes: CodesWithSign[];
  DNCNCodes: CodesWithSign[];
  ownClosingBalance: number;
  reversalCodes: CodesWithSign[];
  tdsCodes: CodesWithSign[];
}

export interface BusinessPartnerLedgerDataObject {
  businessPartnerLedgerDataObj: OwnBusinessPartnerLedgerObject[];
  invoiceCodes: CodesWithSign[];
  paymentCodes: CodesWithSign[];
  DNCNCodes: CodesWithSign[];
  businessPartnerClosingBalance: number;
  reversalCodes: CodesWithSign[];
  tdsCodes: CodesWithSign[];
}

export interface OwnRecon360Response {
  result: {
    ownName: string;
    businessPartnerName: string;
    ownDebitEqualsCredit: any;
    businessPartnerDebitEqualsCredit: any;
    summary: any;
    outputExcelData: any;
    reconMetrics: any;
    reconMetricEntries: any;
    reconOutputJsonFilename: string;
    tdsPresent: boolean;
  };
  message?: string;
  warning?: string;
  suggestedTds?: SuggestedTdsArr[];
  manualChangesData?: ManualReconciledEntriesTrack[];
}

export interface SuggestedTdsArr {
  TDS: number;
  GST: number;
  count: number;
  selectedOption: string;
}
export interface ErrorResponse {
  response?: string;
  message?: string;
  debugMessage?: string;
}

// -----

export interface TempInvoicePaymentDNCNObject {
  paymentMismatch: TempObject[];
  paymentMissed: TempObject[];
  paymentExtra: TempObject[];
  DNCNMismatch: TempObject[];
  DNCNMissed: TempObject[];
  DNCNExtra: TempObject[];
  invoiceMismatch: TempObject[];
  invoiceMissed: TempObject[];
  invoiceExtra: TempObject[];
  tdsExtra: TempObject[];
  tdsMissed: TempObject[];
}

export interface TempObject {
  doc_type: string;
  item: string;
  doc_byuer: string;
  value: any;
  doc_date_qtr: any;
  own_payment_match: string;
  own_dncn_match: string;
  doc_businessPartner: string;
  value_businessPartner: any;
  doc_date_qtr_sup: any;
  businessPartner_payment_match: string;
  businessPartner_dncn_match: string;
  ownRefNumber: string;
  businessPartnerRefNumber: string;
  difference: any;
  action: any;
}

export interface TempInvoiceObject {
  reconciliationStatus: string;
  ownInvoiceNumber: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  invoiceNumberAsPerBusinessPartnerLedger: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInInvoiceAmtBetweenOwnAndBusinessPartner: number;
}

export interface TempPaymentObject {
  reconciliationStatus: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  ownPaymentMatch: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  businessPartnerPaymentMatch: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInPaymentAmtBetweenOwnAndBusinessPartner: number;
}

export interface TempDNCNObject {
  reconciliationStatus: string;
  ownInvoiceNumber: string;
  amountAsPerOwnLedger: number;
  documentDateAsPerOwnLedger: any;
  ownDNCNMatch: string;
  amountAsPerBusinessPartnerLedger: number;
  documentDateAsPerBusinessPartnerLedger: any;
  businessPartnerDNCNMatch: string;
  diffBetweenAmtAsPerOwnBusinessPartnerLedgerAndAmtAsPerOwnLedger: number;
  percentDiffInDNCNAmtBetweenOwnAndBusinessPartner: number;
}

export interface ConfigJSON {
  originalColumn: string[];
  mappedTo: string[];
  invoiceCodes: string[];
  paymentCodes: string[];
  dncnCodes: string[];
  closingBalance: number[];
  reversalCodes: string[];
}

export interface ColDefinition {
  Header: string;
  accessor: string;
  Sort: boolean;
  minWidth: number;
  maxWidth: number;
  width: number;
}

export interface SettledColumnDefinition {
  Header: any;
  accessor: string;
  Sort: boolean;
  minWidth: number;
  maxWidth: number;
  width: number;
  columns: ColDefinition[];
}

export interface EntryAdjusted {
  ownInvoiceId: string;
  tier2InvoiceId: string;
  ownId: number;
  businessPartnerId: number;
  tier2Id: number;
  ownAdjustment: string;
}

/**
 * This is the interface for CollectionRowThis is the interface for CollectionRow
 * @interface
 *
 */
export interface CollectionRow {
  invoice: string;
  businessPartner: string;
  invoice_date: Date;
  payable_amount: string;
  businessPartner_payable: string;
  nakad_payable: string;
  entryAdjusted?: EntryAdjusted;
}

export interface RowData {
  invoice: string;
}

// export interface CodesWithSign {
//   code: string;
//   sign: number;
// }

export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
}

export interface BussinessPartnerIDInterface {
  businessPartnerId: number;
  businessPartnerName: string;
}

export enum SendType {
  to = "to",
  cc = "cc",
  bcc = "bcc",
  empty = "",
}

export interface ContactSetting {
  name: string;
  email: string;
  designationName: string;
  ledgerRequest?: SendType;
  balanceConfirmation?: SendType;
  warning?: SendType;
  paymentStopping?: SendType;
  NOC?: SendType;
  disputeManagement?: SendType;
  confirmConfiguration?: SendType;
  other?: SendType;
}

export interface BPContactInterface extends ContactSetting {
  businessPartnerId: number;
  businessPartnerName: string;
}
export interface BPContactListInterface extends ContactSetting {
  BPContact: BPContactInterface;
}
export interface ListAllBPContactsInterface {
  response: string;
  BPContactList: BPContactListInterface[];
}

export interface Designation {
  designationName: string;
  ledgerRequest: string;
  balanceConfirmation: string;
  warning: string;
  paymentStopping: string;
  NOC: string;
  disputeManagement: string;
  confirmConfiguration: string;
  other: string;
}

export interface ActorInfo {
  /** This property shows the name of the actor user */
  name: string;
  companyCode?: string;
  companyName: string;
  code: number | null;
  address: string;
  numberOfSuppliers: number;
  userDesignation: string;
  userPhoneNumber: string;
  gstNumber: string;
  relationship?: string;
  companyCFOOrPromoterName?: string;
  companyCFOOrPromoterPhoneNumber?: string;
  companyCFOOrPromoterMailId?: string;
  companyReconcilaitionAccountantName?: string;
  companyReconcilaitionAccountantPhoneNumber?: string;
  companyReconcilaitionAccountantMailId?: string;
  accountingSoftware?: string;
  applicableTDSPercentages?: ApplicableTds[];
}

export interface ApplicableTds {
  value: number;
}
export interface DocTypeAccountingSetting {
  addToBusinessPartnerLedger: boolean;
  addToOwnLedger: boolean;
  removeFromBusinessPartnerLedger: boolean;
  removeFromOwnLedger: boolean;
}
export interface ReconSummaryAccountingSetting {
  id: number;
  isDefaultSetting: boolean;
  summaryTypeSelected: string;
  invoiceUnaccountedByBusinessPartner: DocTypeAccountingSetting;
  invoiceUnaccountedByOwn: DocTypeAccountingSetting;
  dncnUnaccountedByBusinessPartner: DocTypeAccountingSetting;
  dncnUnaccountedByOwn: DocTypeAccountingSetting;
  paymentUnaccountedByBusinessPartner: DocTypeAccountingSetting;
  paymentUnaccountedByOwn: DocTypeAccountingSetting;
  tdsUnaccountedByBusinessPartner: DocTypeAccountingSetting;
  tdsUnaccountedByOwn: DocTypeAccountingSetting;
  createdAt?: Date;
  updatedAt?: Date;
}

export enum amountEntryType {
  "debitCredit" = "Debit/Credit",
  "positiveNegative" = "Positive/Negative",
}

export interface ListReconSummaryAccountingSettingResponse {
  amountEntryType?: amountEntryType;
  dateRangeForOutOfPeriod: number;
  isOutOfPeriodSelected: boolean;
  reconSummaryAccountingSetting?: ReconSummaryAccountingSetting;
  reconSummaryAccountingSettingsList?: any[];
  settingType?: string;
  toleranceForAccounting: number;
}

export interface GetReconMisDataResponse {
  dataObj: TrackerData | ReconOverviewDataObj;
  response: string;
  allCategories?: string[];
  emails?: string[];
}

export interface TrackerData {
  data: Datum[] | PartnerLevelData[];
  allCategories: string[];
}

export interface Datum {
  businessPartnerId: number;
  businessPartnerName: string;
  category: string[] | null;
  balanceConfirmationLedgerRequestDate: string;
  reminderCount: number;
  daysPastSinceLastMailByRU: string | number;
  daysPastSinceLastPartnerReply: string;
  ledgerReceivedDate: string;
  reconRunDate: string;
  reconSentToPartnerDate: string;
  userEmailsConcatenated: string;
  remarks: null;
  statusDone: boolean;
  summaryGeneratedDate: string;
  activityMonth: string;
}

export interface OldOverviewLevelData {
  Section: string;
  [key: string]: any;
}

export type OverviewSections = {
  "Total partners count": number;
  "Reminder sent": number;
  "Recon done": number;
  "Recon sent": number;
  "Balance confirmed"?: number;
  "Ledger requested"?: number;
  "Ledger received": number;
  "Recon summary done": number;
};

export interface ReconOverviewDataObj {
  allCategories: string[];
  allUserEmails: string[];
  summaryData: ReconOverviewData[];
}

export interface ReconOverviewData {
  Section: keyof OverviewSections;
  Entries: {
    "2023-09-01~2023-10-01": number;
    [key: string]: number;
  };
}

export interface ReconOverviewFilters {
  category: string[];
  targetType: {
    newTarget: boolean;
    spillOver: boolean;
    other: boolean;
  };
  buyerSupplier: {
    buyer: boolean;
    supplier: boolean;
  };
  userEmail: string[];
  balanceConfirmed: {
    confirmed: boolean;
    unConfirmed: boolean;
  };
}
export interface DataInterfaceForPartnerLevelDataResponse {
  base64PartnerLevel?: string;
  data: PartnerLevelData[];
  allCategories?: string[];
}

export interface PartnerLevelData {
  category: string[];
  allCategories: string[];
  businessPartnerName: string;
  daysPassedSinceBalanceConfirmed: string;
  daysPassedSinceReconRun: string;
  closingBalanceAsPerLastBalanceConfirmation: string | number;
  lastBalanceConfirmedForDate: string;
  gapAsPerLastReconResult: string | number;
  closingBalanceDifferenceOwnPartner: string | number;
  overDueDaysBalanceConfirmation: string;
  overDueDaysReconRun: string;
  businessPartnerId: number | null;
  remarks: null | string;
  statusDone: boolean;
  reconRunDate: string;
  activityMonth: ActivityMonth;
  reminderCount: number;
  vendorCode: string;
  location: string;
  ledgerReceivedDate: LedgerReceivedDate;
  summaryGeneratedDate: string;
  reconSentToPartnerDate: ReconSentToPartnerDate;
  userEmailsConcatenated: DaysPastSinceLastPartnerReply;
  daysPastSinceLastMailByRU: DaysPastSinceLastMailByRU;
  daysPastSinceLastPartnerReply: DaysPastSinceLastPartnerReply;
  balanceConfirmationLedgerRequestDate: string;
  fileNames?: string[];
}

export type ActivityMonth = "Sept 2023" | "Oct 2023";
export type DaysPastSinceLastMailByRU = DaysPastSinceLastPartnerReply | number;
export type DaysPastSinceLastPartnerReply = "-" | string;
export type LedgerReceivedDate = "Not Received";
export type ReconSentToPartnerDate = "Not Sent";

export interface FetchDataFromERPResponse {
  response: string;
  message: string;
  unIdentifiedColumns: any[];
  nonEmptyDocTypes?: UnIdentifiedDoctype[];
  configUserType: string;
  columnHeadersWithData?: AutoConfigColumnHeader[];
  mappedColumnHeaderList?: any;
}

export interface UploadSummary {
  [key: string]: { [key: string]: number };
}

export interface UnIdentifiedDoctype {
  unidentifiedText: string;
  suggestedDoctype: string;
  occurenceCount: number;
}

export enum ConfigUseCaseType {
  recon = "recon",
  paymentAdvice = "paymentAdvice",
}
export enum EmailSMTPHosts {
  outlook = "Microsoft Outlook",
  gmail = "Gmail",
  zimbra = "Zimbra",
  goDaddy = "GoDaddy",
  microsoftExchange = "Microsoft Exchange",
  other = "Other",
}
export interface ResponseInterface {
  response: ResponseStatusString;
  message: string;
}
export enum ResponseStatus {
  Success = "Success",
  Failure = "Failure",
  Warning = "Warning",
}
export type ResponseStatusString = keyof typeof ResponseStatus;
export interface OwnBusinessPartnerMappingUpdateClosingBalances {
  ownId: number;
  businessPartnerId: number;
  ownClosingBalance: number;
  businessPartnerClosingBalance: number;
  ownStartDate: Date;
  ownEndDate: Date;
  businessPartnerStartDate: Date;
  businessPartnerEndDate: Date;
  createdAt?: Date;
  updatedAt?: Date;
  closingDifference: number;
  ownClosingBalanceDate: Date;
  businessPartnerClosingBalanceDate: Date;
  ownClosingBalanceDateForDashboard: Date;
  businessPartnerClosingBalanceDateForDashboard: Date;
  businessPartnerClosingBalanceForDashboard: number;
  ownClosingBalanceForDashboard: number;
  closingDifferenceForDashboard: number;
  lastReconOn: Date;
}
export interface MappedColumnHeaderAndDocType {
  Recon360: ReconColumnHeaders | ReconDocTypes;
  [fileName: string]: FileColumnName;
}

export type FileColumnName = string;

export type ReconColumnHeaders =
  | "documentType"
  | "documentDate"
  | "referenceNumber"
  | "postingDate"
  | "debitAmount"
  | "creditAmount"
  | "paymentId"
  | "particulars"
  | "dncnId"
  | "invoiceIdForDncn"
  | "dateFormat"
  | "dateConverted"
  | "documentNumber"
  | "clearingDocument"
  | "otherUniqueId"
  | "clearingDate";

export type ReconDocTypes = "Invoice" | "Payments" | "DNCN" | "TDS" | "Reversal" | "InternalDoc";

export interface FileWiseDocTypeAndColumnHeader {
  columnHeader: MappedColumnHeaderAndDocType[];
  docType: MappedColumnHeaderAndDocType[];
}
export const limitValue = 25;

export interface TallyAcceptedConfigColumnsResponse {
  response: string;
  message: string;
  columnHeaders?: string[];
}
export interface ReconResultForSendingEmail {
  originalOwnLedger?: LedgerFile[];
  originalBPLedger?: LedgerFile[];
  summaryOutput?: LedgerFile;
  reconDoneStatement?: string;
  summaryTable?: SummaryTable[];
}

export interface LedgerFile {
  fileName: string;
  content: string;
}

export interface SummaryTable {
  [k: string]: string;
}

export interface ListUserEmailSettingRes {
  response: string;
  userEmailSetting: UserEmailSetting;
}

export interface UserEmailSetting {
  display_name: string;
  from_email: string;
  reply_to: string[];
  signature: EmailSignature;
  emailCredentialsProvided: boolean;
}

export interface EmailSignature {
  text: string;
  image: string;
}

export interface ListAllBusinessPartnersForLedgerRequestResponse {
  BusinessPartnerCheck: Record<string, unknown>;
  BusinessPartnersList: BusinessPartnerForLedgerRequest[];
  response: "string";
}

export interface BusinessPartnerForLedgerRequest {
  businessPartnerName: string;
  category: string[];
  allCategories: string[];
  ledgerRequestStatus: null | string;
  dateOfLastLedgerRequestStatusChange: string;
  latestLedgerReceivedOn: null | string;
  businessPartnerId: number;
  downloadLedgerBase64: string[];
}

export interface ListUserEmailTemplateResponse {
  emailTemplates: EmailTemplate[];
  lastEmailTemplateSelected: number;
  response: string;
}

export type TemplateType = "LR" | "CB" | "MSME" | "BCB" | "LRB";

export interface EmailTemplate {
  templateId: number;
  actorId: number;
  templateName: string;
  templateType: TemplateType;
  emailSubject: string;
  emailBody: string;
  createdAt: string;
  updatedAt: string;
  default: boolean;
}

export enum EmailSendCount {
  Sole = "sole",
  Bulk = "inBulk",
}
export enum PartnerCommunicationStatusResetMethod {
  autoReset = "Auto reset",
  manualReset = "Manual reset",
}
export enum UseCaseType {
  ledgerRequest = "LR",
  balanceConfirmation = "CB",
  paymentAdvice = "PA",
  msme = "MSME",
  balanceConfirmationBeta = "BCB",
  ledgerRequestBeta = "LRB",
  disputeResolution = "DR",
}

export enum PartnerCommunicationSelected {
  ledgerRequest = "Ledger request",
  balanceConfirmation = "Balance confirmation",
  msme = "MSME Confirmation",
}

export interface PDFTemplate {
  templateId: number;
  templateName: string;
  templateBody?: string;
}

export interface ListPDFTemplatesResponse {
  response: string;
  templates: PDFTemplate[];
}

export interface BulkMailStatusResponse {
  isComplete: boolean;
  message: string;
  response: string;
  emailResponseMessage: string;
  listOfFailedUsers: {
    reason: string;
    businessPartnerNames: string[];
  }[];
  failedEmailsWorkbook: string; // base 64 string;
}

export interface MsmeBulkMailStatusResponse {
  emailResponseMessage: string;
  isCompleted: boolean;
  listOfFailedUsers: {
    businessPartnerId: number;
    businessPartnerName: string;
    reason: string;
  }[];
  failedEmailsWorkbook: string; // base 64 string;
}

export interface ManualReconciledEntriesTrack {
  ownEntries: string[];
  businessPartnerEntries: string[];
  status: string;
  isValid: boolean;
  amount: number;
}

export interface BusinessPartnersListForLedgerRequest {
  businessPartnerName: string;
  category: string[];
  allCategories: string[];
  ledgerRequestStatus: string | null;
  dateOfLastLedgerRequestStatusChange: Date;
  latestLedgerReceivedOn: Date;
  businessPartnerId: number;
  downloadLedgerKey: boolean;
  // downloadLedgerBase64: string[] | null; // should be removed
  ledgerRequestStatusWithReminderCount: string | null;
  // mailThreads?: MailThread[]; // was removed
  vendorCode: string;
}

export interface BusinessPartnersListForLedgerRequestRes {
  response: string;
  businessPartnersList: BusinessPartnersListForLedgerRequest[];
  businessPartnerCheck: { [key: number]: boolean };
}

export type MsmeSegment = "Micro" | "Small" | "Medium";

export type UploadFileType = "signedPdf" | "udyamCertificate";

export interface BusinessPartnersListForMsme {
  businessPartnerName: string;
  businessPartnerId: number;
  msmeStatus: MsmeConfirmationStatuses;
  category: string[];
  segment: MsmeSegment;
  udyamRegistrationNumber: string;
  msmeConfirmationDocument: boolean;
  udyamCertificate: boolean;
  vendorCode: string;
}

export interface BusinessPartnersListForMsmeRes {
  response: string;
  businessPartnersList: BusinessPartnersListForMsme[];
  allCategories: string[];
}

export interface BusinessPartnerListForBcBeta {
  businessPartnerName: string;
  businessPartnerId: number;
  status: BalanceConfirmationBetaStatuses;
  statusOverview: string;
  ownClosingBalance: number;
  businessPartnerClosingBalance: number;
  closingBalanceDate: Date;
  signedPdf: boolean;
  ledgers: boolean;
  openItems: boolean;
  category: string[];
  vendorCode: string;
  location: string;
  currency: string;
  closingBalanceDifference: number;
  balanceConfirmationStatusWithReminderCount: string | null;
  approvedAt: Date | null;
  approvedStatus: string;
  lastStatusChangeDate: Date;
  responseSubmittedDate: Date;
  issueRaised: string;
  communicationTypeName: string;
  communicationTypeId: number;
  remarks: string;
  portalLink: string;
  isApproved: boolean;
  ticketId: number;
  relationship: string;
  emailInitiationDate?: Date;
}

export interface BusinessPartnersListForBcBetaHistory extends BusinessPartnerListForBcBeta {
  emailInitiationDate?: Date;
  newMailInitiationCount?: number;
}

export interface BusinessPartnersListForBcBetaRes {
  response: string;
  businessPartnerList: BusinessPartnerListForBcBeta[];
  allCategories: string[];
}

export interface BusinessPartnersListForBcBetaHistoryRes {
  response: string;
  businessPartnerList: BusinessPartnersListForBcBetaHistory[];
  allCategories: string[];
}

export interface BusinessPartnerListForLrBeta {
  businessPartnerName: string;
  businessPartnerId: number;
  ledgerRequestStatus: LedgerRequestBetaStatuses;
  category: string[];
  ledgerReceivedDate: string;
  downloadLedgerKey: boolean;
  ledgerRequestStatusWithReminderCount: string | null;
  vendorCode: string;
  approvedAt: Date | null;
  approvedStatus: string;
}

export interface BusinessPartnersListForLrBetaHistory extends BusinessPartnerListForLrBeta {
  mailInitiatingDate?: Date;
  newMailInitiationCount?: number;
}

export interface BusinessPartnersListForLrBetaRes {
  response: string;
  businessPartnersList: BusinessPartnerListForLrBeta[];
  allCategories: string[];
}

export interface BusinessPartnersListForLrBetaHistoryRes {
  response: string;
  businessPartnersList: BusinessPartnersListForLrBetaHistory[];
  allCategories: string[];
}

export interface BusinessPartnersListForBalanceConfirmation {
  businessPartnerName: string;
  category: string[];
  allCategories: string[];
  balanceConfirmationStatus: string | null;
  dateOfLastBalanceConfirmationStatusChange: Date;
  closingBalance: number | string;
  dateOfClosingBalance: Date | string;
  latestBalanceConfirmedOn: Date;
  businessPartnerId: number;
  downloadLedgerKey: boolean;
  // downloadLedgerBase64?: string[] | null; // should be removed
  balanceConfirmationStatusWithReminderCount: string | null;
  // mailThreads?: MailThread[]; // was removed
  currency: string;
  vendorCode: string;
}

export interface BusinessPartnersListForBalanceConfirmationRes {
  response: string;
  businessPartnersList: BusinessPartnersListForBalanceConfirmation[];
  businessPartnerCheck: { [key: number]: boolean };
}

export interface ViewEmailThreadResponse {
  mailThreads: MailThread[];
  s3Attachments: S3Attachment[];
  emailSubject: string;
}
export interface MailThread {
  fromEmailId: string;
  timestamp: string;
  emailBody: string;
  attachments: string[];
}

export interface MsmeMailThread {
  body: string;
  from: string;
  emailTime: Date;
  to?: string[];
  cc?: string[];
  attachments?: string[];
  s3Link?: string[];
}

export interface S3Attachment {
  fileName: string;
  s3SignedUrl: string;
}

export interface MatchedEntriesForManualRecon {
  ownDate: string;
  ownReferenceNumber: string;
  ownType: string;
  ownAmount: number;
  ownDocumentType: string;
  ownOtherUniqueId: string;
  ownPostingDate: string;
  ownParticulars: string;
  ownCreditAmount: number;
  ownDebitAmount: number;
  ownDncnId: string;
  ownPaymentId: string;
  ownInvoiceIdForDncn: string;
  matchId: string;
  businessPartnerDate: string;
  businessPartnerReferenceNumber: string;
  businessPartnerType: string;
  businessPartnerAmount: number;
  businessPartnerDocumentType: string;
  businessPartnerOtherUniqueId: string;
  businessPartnerPostingDate: string;
  businessPartnerParticulars: string;
  businessPartnerCreditAmount: number;
  businessPartnerDebitAmount: number;
  businessPartnerDncnId: string;
  businessPartnerPaymentId: string;
  businessPartnerInvoiceIdForDncn: string;
}

export interface ListAllBusinessPartnersRes {
  response: string;
  message: string;
  partnerList: PartnerList[];
  totalPartners: number;
}

export interface PartnerList {
  businessPartnerId: number;
  businessPartnerName: string;
  businessPartnerCode: null | string;
  businessPartnerLocation: null | string;
}

export interface LastReconciliationStatusRes {
  response: string;
  hasReconciled: boolean;
  amountTolerance: number;
  dateOfLastSummary: Date;
  dateOfLastRecon: Date;
}

export interface ListOwnTallyCompanies {
  companyName: string;
  companyId: string;
  firstLetter?: string;
}

export interface ListOwnTallyCompaniesRes {
  tallyCompanies: ListOwnTallyCompanies[];
  response: string;
  lastSelectedTallyCompanyId?: string;
}
export enum ConfigTemplateType {
  ERP = "ERP",
  Partner = "Partner",
  RU = "RU",
}
export interface FileData {
  filename: string;
  sheets: string[];
}
export interface ListAllLedgerConfigTemplateResponse {
  ledgerConfigTemplates: LedgerConfigTemplate[];
}
export class LedgerConfigTemplate {
  templateId: number;
  templateName: string;
  config?: LedgerConfiguration; // not available in /api/listAllLedgerConfigTemplate
  createdAt?: Date | string;
  updatedAt?: Date | string;
  columnsToSanitize?: ColumnsToSanitize[];
  IsRuConfigTemplate?: boolean;
  IsPartnerConfigTemplate?: boolean;
  IsERPConfigTemplate?: boolean;
}
export interface UploadPDFFileResponse {
  result: any;
}
export interface SyncFromSAPResponse {
  latestEntry: string;
  oldestEntry: string;
  lastTimeToSync: string;
  overallEnteries: number;
}
export interface ReconResponseJSON {
  result: {
    ownName: string;
    businessPartnerName: string;
    summary: any;
    reconMetrics: any;
    reconOutputJsonFilename: string;
    closingDifference: number;
  };
}
export interface ListAllBusinessPartnerContactsInterface {
  response: string;
  businessPartnerContactList: BusinessPartnerContactsInterface[];
  reportWorkbookBase64: string;
  lastSelectedTallyCompanyId: string;
}
export interface BusinessPartnerContactsInterface extends ContactSetting {
  businessPartnerId: number;
  businessPartnerName: string;
}
export interface GetReconPeriodResponse {
  response: string;
  message: string;
  periods: DateRange[];
}
export interface DateRange {
  startDate: Date;
  endDate: Date;
}
export enum ENV_CONST {
  TEST = "test",
}
export enum ActorTypesForRecon {
  ReconUser = "ReconUser",
  Partner = "Partner",
  Both = "Both",
}

export type ActorTypeForReconStrings = keyof typeof ActorTypesForRecon;

export enum UploadStatus {
  Success = "Success",
  Failure = "Failure",
  ActionRequired = "ActionRequired",
  InProgress = "InProgress",
  Pending = "Pending",
}
export enum WhichActionRequiredStatus {
  UnIdentifiedColumnHeaders = "Un-identified column headers",
  PotentiallyMissedDocumentTypes = "Potentially missed document types",
  UploadInProgress = "upload in progress",
  Success = "Success",
  Failure = "Failure",
  ReconInProgress = "Recon in progress",
}
export const ReconRequestStatus = {
  reconInProgress: "RECON_IN_PROGRESS",
  reconDone: "RECON_DONE",
  reconFailed: "RECON_FAILED",
  summaryStarted: "SUMMARY_STARTED",
  summaryDone: "SUMMARY_DONE",
  summaryFailed: "SUMMARY_FAILED",
};

export interface ObjectEle {
  [code: string]: boolean;
}

export interface PreReconLedgerScoreData {
  issues: string[];
  amountScores: {
    [key: string]: {
      ownAmount: AmountDetail;
      businessPartnerAmount: AmountDetail;
      absolutePercentage: AmountDetail;
      normalizedPercentage: number;
    };
  };
  dateScores: {
    ownEntriesDate: DateRangeScores;
    businessPartnerEntriesDate: DateRangeScores;
    reconDate: DateRangeScores;
  };
}

export interface AmountDetail {
  value: number;
  type: "amount";
  issueIndex?: number; // Optional field, as not all entries have issueIndex
}
export interface DateRangeScores {
  startDate: string;
  endDate: string;
  issueIndex?: number; // Optional field, as not all entries have issueIndex
}

export const allTaskCategories = {
  Ledger: "Ledger",
  Maker: "Maker",
  Checker: "Checker",
  Saved: "Saved",
  Discarded: "Discarded",
  Approver: "Approver",
  Miscllaneous: "Miscellaneous",
  disputeResolution: "Dispute Resolution",
};

export interface TaskStatus {
  status: string;
  category: string;
  metaStatus: string;
}
