import { Cached, FilterAlt, FilterAltOff, RestartAlt, Restore, Settings, TaskAlt } from "@mui/icons-material";
import { Download, MailOutline, Visibility } from "@mui/icons-material";
import { Autocomplete, Avatar, Button, createFilterOptions, ListItemIcon } from "@mui/material";
import { Grid, IconButton, MenuItem, TextField, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import { saveAs } from "file-saver";
import { MRT_Row } from "material-react-table";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { Dialog } from "src/Components/Dialog/Dialog";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import {
  BulkMailStatusResponse,
  BusinessPartnerListForLrBeta,
  BusinessPartnersListForLrBetaRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  MailThread,
  MsmeMailThread,
  UploadFileType,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import {
  updateEmailResponseMsgLRB,
  updateIsSendingBulkMailsLRB,
  updateMailFailedUsersLRB,
  updateMailFailedWorkbookLRB,
  updateOpenBulkMailReportDialogLRB,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { exists } from "src/Utils/Common/Validators";
import { filterDateBetweenStartAndEndDate } from "src/Utils/DateUtils";
import { LedgerRequestBetaColorMap, LedgerRequestBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import PartnerRequests from "../BalanceConfirmationBeta/PartnerRequests";
import { PageSelectModeToggle } from "../Common/CommonComponents";
import { ApproveResponseDialog, ResetStatusDialog } from "../Common/CommonDialogs";
import BulkMailReportDialog from "../CommonLegacy/BulkMailReportDialog";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../CommonLegacy/CommonComponents";
import MailThreadDialog from "../CommonLegacy/MailThreadDialog";
import CreateEmailTemplate from "../Communication/CreateEmailTemplate";
import SelectEmailTemplate from "../Communication/SelectEmailTemplate";
import SendEmailTemplateBeta from "../Communication/SendEmailTemplateBeta";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import LedgerRequestBetaFilter from "./LedgerRequestBetaFilter";
import LedgerRequestBetaSettings from "./LedgerRequestBetaSettings";
import LedgerRequestHistory from "./LedgerRequestHistory";

import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import "../Styles/PartnerCommunication.scss";

let intervalId: ReturnType<typeof setTimeout>;

interface ILrBetaContext {
  rowsDataLrBeta: BusinessPartnerListForLrBeta[];
  storeRowsDataLrBeta: BusinessPartnerListForLrBeta[];
  setRowsDataLrBeta: StateDispatch<BusinessPartnerListForLrBeta[]>;
  setStoreRowsDataLrBeta: StateDispatch<BusinessPartnerListForLrBeta[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const LrBetaContext = createContext<ILrBetaContext>(null);
const filter = createFilterOptions<any>();

export const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: ` `,
      businessPartnerId: 0,
      category: [],
      ledgerRequestStatus:
        LedgerRequestBetaStatuses[
          Object.keys(LedgerRequestBetaStatuses)[
            Math.floor(Math.random() * Object.keys(LedgerRequestBetaStatuses).length)
          ]
        ],
      ledgerReceivedDate: undefined,
      ledgerRequestStatusWithReminderCount: "1st Reminder Sent",
      downloadLedgerKey: false,
    } as BusinessPartnerListForLrBeta);
  }
  return dataArray;
};

const LedgerRequestBeta = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnerListForLrBeta[]>([]);
  const [rowsDataLrBeta, setRowsDataLrBeta] = useState<BusinessPartnerListForLrBeta[]>(getBlankData());
  const [storeRowsDataLrBeta, setStoreRowsDataLrBeta] = useState<BusinessPartnerListForLrBeta[]>([]);
  const [openLrBetaSettings, setOpenLrBetaSettings] = useState<boolean>(false);
  const [openLrBetaFilter, setOpenLrBetaFilter] = useState<boolean>(false);
  const [openLrBetaHistory, setOpenLrBetaHistory] = useState<boolean>(false);
  const storeAllCategories = useRef<string[]>([]);
  const storeLrBetaData = useRef<BusinessPartnerListForLrBeta[]>([]);
  const [isFilterAppliedLrBeta, setIsFilterAppliedLrBeta] = useState<boolean>(false);
  const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  // ----//

  const [signatureImgBase64, setSignatureImgBase64] = useState<string>(null);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [lastSelectedTemplateID, SetLastSelectedTemplateID] = useState(0);
  const [bpName, setBpName] = useState("");
  const [openMailThread, setOpenMailThread] = useState(false);
  const [mailThreads, setMailThreads] = useState<MailThread[]>([]);
  const [mailLoading, setMailLoading] = useState(false);

  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<
    { businessPartnerName: string; vendorCode: string; issues: RaisedIssueBc[] }[]
  >([]);

  const [openResetStatusDialog, setOpenResetStatusDialog] = useState(false);

  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [approveSelectedRow, setApproveSelectedRow] = useState<BusinessPartnerListForLrBeta[]>([]);

  const [loadingPartnerRequests, setLoadingPartnerRequests] = useState(false);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState(false);
  const [pageSelectMode, setPageSelectMode] = useState(false);

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    setPdfTemplates,
  } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();
  const {
    openBulkMailReportDialogLRB,
    isSendingBulkMailsLRB,
    emailResponseMsgLRB,
    mailFailedUsersLRB,
    mailFailedWorkbookLRB,
  } = useSelector((state: any) => state.bulkMailsStatus);

  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);
  // Email States END

  const [, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(null);

  const [, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(null);

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<MRT_Row<BusinessPartnerListForLrBeta>>(null);

  // reset status
  const [downloadLrBetaReportBase64, setDownloadLrBetaReportBase64] = useState<string>(null);

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  const columnDefinitionLrBeta = useMemo(
    (): ColDef<BusinessPartnerListForLrBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
        filterVariant: "autocomplete",
        sticky: "left",
        enableSorting: true,
        accessorKey: "businessPartnerName",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        maxSize: 300,
        minSize: 280,
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row.vendorCode || "",
        filterVariant: "autocomplete",
        enableSorting: true,
        size: 200,
      },

      {
        header: "Status",
        id: "Status",
        accessorFn: (row) => row.ledgerRequestStatus || "",
        filterVariant: "multi-select",
        maxSize: 360,
        minSize: 280,
        size: 320,
        Cell: ({ row }) => (
          <>
            {row.original.ledgerRequestStatus ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: LedgerRequestBetaColorMap[row.original.ledgerRequestStatus]?.color || "#000",
                    backgroundColor: LedgerRequestBetaColorMap[row.original.ledgerRequestStatus]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.ledgerRequestStatus === LedgerRequestBetaStatuses.ReminderSent
                    ? row.original.ledgerRequestStatusWithReminderCount || row.original.ledgerRequestStatus
                    : row.original.ledgerRequestStatus}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        header: "Category",
        accessorFn: (row) => row.category?.[0]?.toLowerCase(),
        filterVariant: "multi-select",
        maxSize: 200,
        minSize: 200,
        size: 200,
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
              loading={tempLoader}
            />
          </div>
        ),
      },
      {
        header: "Ledger",
        accessorFn: (row) => row.downloadLedgerKey || false,
        filterVariant: "checkbox",
        size: 200,
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.downloadLedgerKey ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => viewUploadedPartnerLedger(row.original.businessPartnerId, "signedPdf")}
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      {
        header: "Ledger Received Date",
        accessorFn: (row) => new Date(row.ledgerReceivedDate || null),
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.ledgerReceivedDate);
          }
        },
        enableSorting: true,
        size: 360,
        Cell: ({ row }) => (
          <p>
            {" "}
            {row.original.ledgerReceivedDate !== null
              ? moment(row.original.ledgerReceivedDate).format("DD-MM-YYYY")
              : null}
          </p>
        ),
      },
      {
        header: "Response Approved",
        accessorFn: (row) => row.approvedStatus || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => <div className="textOverflow_hidden">{row.original.approvedStatus}</div>,
      },
      {
        header: "Approved Date",
        accessorFn: (row) => row.approvedAt || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.approvedAt ? moment(row.original.approvedAt)?.format("YYYY-MM-DD") : "-"}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history]
  );

  const PartnerCommunicationContextValue: ILrBetaContext = {
    rowsDataLrBeta,
    storeRowsDataLrBeta,
    setRowsDataLrBeta,
    setStoreRowsDataLrBeta,
    listAllBusinessPartnersWSR: () => ListAllBusinessPartnerForLedgerRequest(),
  };

  useEffect(() => {
    if (!actor.integration) {
      ListAllBusinessPartnerForLedgerRequest();
      // listAllBusinessPartnersForLrBeta();
      listUserEmailTemplateForLrBeta("");
      getListUserEmailSetting();
    }

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  // Main ROWs API
  const ListAllBusinessPartnerForLedgerRequest = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForLrBetaRes>(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_FOR_LEDGER_REQUEST.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_FOR_LEDGER_REQUEST.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        if (!response.data?.businessPartnersList)
          response.data = { businessPartnersList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
        storeLrBetaData.current = response.data?.businessPartnersList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataLrBeta(response.data.businessPartnersList || []);
        setStoreRowsDataLrBeta(response.data.businessPartnersList || []);
        setLoader(false);
        if (Object.keys(appliedFilters).length) setReFilter(true);
      },
    });
  };

  // User Email Templates for Mail Dialog
  const listUserEmailTemplateForLrBeta = (createTemplateName: string) => {
    useFetch<ListUserEmailTemplateResponse>(API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.ledgerRequestBeta,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { emailTemplates, lastEmailTemplateSelected } = response.data;
        setEmailTemplates(emailTemplates);
        listPDFTemplates();

        if (lastEmailTemplateSelected) SetLastSelectedTemplateID(lastEmailTemplateSelected);
        if (createTemplateName.trim() !== "") {
          if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
            const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
              return item.templateName === createTemplateName;
            })[0];
            if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
          }
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          // setSelectedTemplate("");
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  const listPDFTemplates = (createTemplateName = "") => {
    useFetch<ListPDFTemplatesResponse>(API_ENDPOINTS.LIST_PDF_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_PDF_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.ledgerRequest,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
        if (createTemplateName.trim() !== "") {
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (exists(response.data.userEmailSetting?.signature?.image)) {
          setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (exists(response.data.userEmailSetting?.signature?.text)) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (
    row: MRT_Row<BusinessPartnerListForLrBeta>,
    value: string[]
  ) => {
    useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setTempLoader(true);
        updateCategory(row, value);
      },
    });
  };

  // update Rows Category
  const updateCategory = (row: MRT_Row<BusinessPartnerListForLrBeta>, value: string[]) => {
    storeLrBetaData.current.find((v) => v.businessPartnerId === row.original.businessPartnerId).category = value;
    // setRowsDataLrBeta(storeLrBetaData.current);
    // setStoreRowsDataLrBeta(storeLrBetaData.current);

    setTempLoader(false);
  };

  // Company Select
  const companyNameSelect = (e: any, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForLedgerRequest();
        listUserEmailTemplateForLrBeta("");
        getListUserEmailSetting();
        getBulkMailStatus();
        if (!intervalId) setIntervalLRBeta();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: any, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        ListAllBusinessPartnerForLedgerRequest();
        listUserEmailTemplateForLrBeta("");
        getListUserEmailSetting();
        getBulkMailStatus();
        if (!intervalId) setIntervalLRBeta();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataLrBeta([]);
    setStoreRowsDataLrBeta([]);
  };

  // Get Ledger Request Beta Rows Report
  const getDownloadLrBetaReport = () => {
    useFetch<{ workbookBase64: string }>(API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_LEDGER_REQUEST.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_LEDGER_REQUEST.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setDownloadLrBetaReportBase64(response.data?.workbookBase64);
        downloadLrBetaReport(response.data?.workbookBase64);
      },
    });
  };

  // Download Ledger Request Beta Rows Report
  const downloadLrBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadLrBetaReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Ledger Request Report.xlsx`);
  };

  // Delete User Email Templates, View API Method Above Update Categories
  const deleteUserEmailTemplate = (templateId: number) => {
    setShowDeleteLoadingIcon(true);
    useFetch(API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.failureMessage,
      config: {
        data: {
          templateId: templateId,
          useCaseType: UseCaseType.ledgerRequestBeta,
        },
      },
      thenCallBack: (_res) => {
        listUserEmailTemplateForLrBeta("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
      errorCallback: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  // View LR Beta Email Thread
  const viewEmail = (businessPartner: number) => {
    return new Promise<{ emails: MailThread[] }>((resolve, reject) => {
      useFetch<{ emails: MsmeMailThread[] }>(API_ENDPOINTS.VIEW_EMAIL_THREAD_LEDGER_REQUEST.url, "GET", {
        failureMessage: API_ENDPOINTS.VIEW_EMAIL_THREAD_LEDGER_REQUEST.failureMessage,
        config: {
          params: {
            businessPartnerId: businessPartner,
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (response) => {
          if (response.data.emails) {
            const { emails } = response.data;
            const mailThreads: MailThread[] = emails.map((email) => ({
              emailBody: email.body,
              fromEmailId: email.from,
              timestamp: email.emailTime.toString(),
              attachments: [],
            }));

            resolve({ emails: mailThreads });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  // View Rows Uploaded Partner Ledger
  const viewUploadedPartnerLedger = (_bpId: number, _fileType: UploadFileType) => {
    useFetch<{ signedUrls: string[] }>(API_ENDPOINTS.DOWNLOAD_UPLOADED_LEDGER.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_UPLOADED_LEDGER.failureMessage,
      config: {
        params: {
          businessPartnerId: _bpId,
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          type: _fileType,
        },
      },
      thenCallBack: (_res) => {
        if (_res.data.signedUrls?.length > 0) {
          _res.data.signedUrls.forEach((url) => {
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.style.display = "none";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      },
    });
  };

  const listAllBusinessPartnerRequest = () => {
    setLoadingPartnerRequests(true);
    useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUEST.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUEST.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                };
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          setLoadingPartnerRequests(false);
        },
      }
    );
  };

  const markIssueResolved = async (issueId: number) =>
    new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch(API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED_LR.url, "POST", {
        failureMessage: API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED_LR.failureMessage,
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          issueId,
        },
        thenCallBack: (_res) => {
          listAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );

  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{
        emailResponseMessage: string;
        isCompleted: boolean;
        listOfFailedUsers: { reason: string; businessPartnerName: string }[];
        failedEmailsWorkbook: string;
      }>(API_ENDPOINTS.GET_LEDGER_REQUEST_BULK_EMAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.GET_LEDGER_REQUEST_BULK_EMAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted) {
            if (res.data?.emailResponseMessage !== "") {
              ListAllBusinessPartnerForLedgerRequest();
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsLRB(false));

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              if (openBulkMailReportDialogLRB) return;
              dispatch(updateOpenBulkMailReportDialogLRB(true));
              dispatch(updateEmailResponseMsgLRB(res.data.emailResponseMessage || ""));
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });

              dispatch(updateMailFailedUsersLRB(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookLRB(res.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForLrBeta("");
            }
          } else {
            dispatch(updateIsSendingBulkMailsLRB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsLRB(false));
          reject();
        },
      });
    });
  };

  const approveResponse = async (businessPartnerIds: number[]) =>
    useFetch<{ message: string }>(API_ENDPOINTS.APPROVE_STATUS_LEDGER_REQUEST.url, "POST", {
      failureMessage: API_ENDPOINTS.APPROVE_STATUS_LEDGER_REQUEST.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerIds,
      },
      thenCallBack: (_res) => {
        ListAllBusinessPartnerForLedgerRequest();
      },
    });

  const setIntervalLRBeta = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30000);
  };

  const resetStatus = async (businessPartnerIds: number[]) => {
    return await useFetch<{ message: string }>(
      API_ENDPOINTS.RESET_STATES_ON_PARTNER_LEVEL_FOR_LEDGER_REQUEST.url,
      "POST",
      {
        failureMessage: API_ENDPOINTS.RESET_STATES_ON_PARTNER_LEVEL_FOR_LEDGER_REQUEST.failureMessage,
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          businessPartnerId: businessPartnerIds,
        },
        thenCallBack: (_res) => {
          ListAllBusinessPartnerForLedgerRequest();
        },
      }
    );
  };

  useEffect(() => {
    if (actor.integration === false) {
      getBulkMailStatus();
      if (!intervalId) setIntervalLRBeta();
    }
  }, []);

  const GREEN = "#27B27C";

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <LrBetaContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="">
            <Grid position="relative">
              <Grid className="vertical_center_align mb_15" sx={{ gap: 2, flexWrap: "wrap" }}>
                {/* Left Side Dropdowns */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {!openLrBetaSettings && !openLrBetaHistory && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={null}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={null}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={null}
                          isDisabled={isSendingBulkMailsLRB}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openLrBetaSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenLrBetaSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {!openLrBetaSettings && !openLrBetaHistory && (
                  <div className="d_flex" style={{ gap: 16, flexWrap: "wrap", marginLeft: "auto" }}>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: "32px",
                        border: "1px solid #FFC135",
                        background: "#F8EDD4",
                      }}
                      startIcon={
                        loadingPartnerRequests ? (
                          <LoadingIcon loading={loadingPartnerRequests} />
                        ) : (
                          <svg className="icon" style={{ height: "30px", width: "30px", fill: "#333" }}>
                            <use xlinkHref="#icon-person-alert" />
                          </svg>
                        )
                      }
                      disabled={loadingPartnerRequests}
                      onClick={async () => {
                        await listAllBusinessPartnerRequest();
                        setOpenRaiseReq(true);
                      }}
                    >
                      Partner Requests
                    </Button>
                    <Button
                      variant="outlined"
                      color="inherit"
                      sx={{
                        borderRadius: "32px",
                        border: "1px solid #111",
                        background: "#FFF",
                      }}
                      startIcon={<Restore sx={{ fontSize: "24px !important" }} />}
                      onClick={async () => {
                        setOpenLrBetaHistory(true);
                      }}
                    >
                      Past Communications
                    </Button>
                    <Tooltip title="Bulk Reset Status" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => {
                            setOpenResetStatusDialog(true);
                          }}
                          color="inherit"
                          disabled={selectedRow.length < 1}
                        >
                          <RestartAlt />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Approve" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => {
                            setApproveSelectedRow(selectedRow);
                            setOpenApproveDialog(true);
                          }}
                          color="inherit"
                          disabled={isSendingBulkMailsLRB || selectedRow.length < 2}
                        >
                          <TaskAlt />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Ledger Request Settings" arrow={true}>
                      <Avatar sx={{ background: GREEN }}>
                        <IconButton
                          onClick={() => setOpenLrBetaSettings(true)}
                          color="inherit"
                          disabled={isSendingBulkMailsLRB}
                        >
                          <Settings />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title={"Filter"} arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current)}
                      >
                        <span style={{ cursor: "pointer" }}>
                          <IconButton
                            onClick={() => setOpenLrBetaFilter(true)}
                            color="inherit"
                            sx={{
                              pointerEvents: "auto !important",
                            }}
                          >
                            {isFilterAppliedLrBeta ? <FilterAlt /> : <FilterAltOff />}
                          </IconButton>
                        </span>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Bulk Email" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_clickedOnBulkEmail, companyId.current, branchCode.current)
                        }
                      >
                        <IconButton
                          onClick={async () => {
                            setSendSoleOrBulkMail(EmailSendCount.Bulk);

                            setOpenSelectEmailTemplate(true);
                          }}
                          disabled={selectedRow.length < 2 ? true : isSendingBulkMailsLRB}
                          color="inherit"
                        >
                          {isSendingBulkMailsLRB ? (
                            <span
                              className="file-icon"
                              style={{
                                position: "absolute",
                                transform: "scale(2.5)",
                                left: 8,
                                color: "white",
                              }}
                            >
                              <i className="fas fa-circle-notch fa-spin" />
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fa-icon">
                            <i className="fas fa-envelope" />
                          </span>
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                    <Tooltip title="Download LR Beta Confirmation Report" arrow={true}>
                      <Avatar
                        sx={{ background: GREEN }}
                        onClick={() => uiLogger(uiLoggerName.ui_DownloadReport, companyId.current, branchCode.current)}
                      >
                        <IconButton
                          onClick={() => getDownloadLrBetaReport()}
                          color="inherit"
                          disabled={isSendingBulkMailsLRB}
                        >
                          <Download />
                        </IconButton>
                      </Avatar>
                    </Tooltip>
                  </div>
                )}
              </Grid>

              {/* Filter Applied Bar */}
              {!openLrBetaSettings && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              <div
                className="bpDashboard"
                style={{
                  height: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openLrBetaSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                  }}
                >
                  {!openLrBetaSettings && !openLrBetaHistory && (
                    <ReactTable
                      columns={columnDefinitionLrBeta}
                      rows={rowsDataLrBeta}
                      setSelectedRow={setSelectedRow}
                      loading={loader}
                      enableGrouping={true}
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        // {/* Send Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsLRB}
                          key={"sendMail"}
                          onClick={async () => {
                            // logic
                            setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                            setSendSoleOrBulkMail(EmailSendCount.Sole);
                            getListUserEmailSetting();

                            setOpenSelectEmailTemplate(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <MailOutline sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Send Email
                        </MenuItem>,
                        // {/* View Email Button */}
                        <MenuItem
                          disabled={isSendingBulkMailsLRB}
                          key={"viewEmail"}
                          onClick={async () => {
                            // logic
                            setMailLoading(true);
                            setOpenMailThread(true);
                            setMailThreads([]);
                            setBpName(row.original.businessPartnerName);
                            const { emails } = await viewEmail(row.original.businessPartnerId);
                            setMailLoading(false);
                            setMailThreads(emails || []);
                            uiLogger(uiLoggerName.ui_ViewEmailClicked, companyId.current, branchCode.current);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Visibility sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Email
                        </MenuItem>,
                        // {/* View Recon */}
                        <MenuItem
                          disabled={isSendingBulkMailsLRB}
                          key={"viewRecon"}
                          onClick={async () => {
                            // logic
                            const { businessPartnerId, businessPartnerName } = row.original;
                            const params = `bpId=${businessPartnerId}&bpName=${btoa(businessPartnerName)}`;
                            const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                            window.open(uri, "_blank")?.focus();
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Cached sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Recon
                        </MenuItem>,
                        // {/* Approve Response */}
                        <MenuItem
                          disabled={isSendingBulkMailsLRB}
                          key={"approveResponse"}
                          onClick={async () => {
                            // logic
                            // const { businessPartnerId } = row.original;
                            setApproveSelectedRow([row.original]);
                            setOpenApproveDialog(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <TaskAlt sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Approve Response
                        </MenuItem>,
                      ]}
                      renderAdditionalBottomToolbarCustomActions={() => {
                        return (
                          <PageSelectModeToggle
                            pageSelectMode={pageSelectMode}
                            setPageSelectMode={setPageSelectMode}
                            useCaseType={UseCaseType.ledgerRequestBeta}
                          />
                        );
                      }}
                      pageSelectMode={pageSelectMode}
                    />
                  )}
                  {openLrBetaSettings && !openLrBetaHistory && (
                    <LedgerRequestBetaSettings
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      listUserEmailTemplate={listUserEmailTemplateForLrBeta}
                    />
                  )}

                  {openLrBetaHistory && !openLrBetaSettings && (
                    <LedgerRequestHistory
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      setOpen={setOpenLrBetaHistory}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <LedgerRequestBetaFilter
          openLedgerRequestFilter={openLrBetaFilter}
          setOpenLedgerRequestFilter={setOpenLrBetaFilter}
          allCategories={storeAllCategories.current}
          rowsDataLedgerRequest={rowsDataLrBeta}
          setRowsDataLedgerRequest={setRowsDataLrBeta}
          storeRowsDataLedgerRequest={storeRowsDataLrBeta}
          setIsFilterAppliedLedgerRequest={setIsFilterAppliedLrBeta}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
        />

        {/* LR Beta */}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplateBeta
          companyId={companyId.current}
          branchCode={branchCode.current} // wot
          base64Image={signatureImgBase64}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          // after sending mail to update date
          listAllBusinessPartnersWSR={ListAllBusinessPartnerForLedgerRequest}
          // setLoader={setLoader}
          templateType={"LRB"}
          listUserEmailTemplate={listUserEmailTemplateForLrBeta}
          setIntervalBulkMails={setIntervalLRBeta}
          withoutTemplateCheck={withoutTemplateCheck}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForLrBeta}
          templateType={"LRB"}
          beta={true}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          width70Per={true}
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers as any}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        </Dialog>

        <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={[]}
          businessPartnerName={bpName}
          loading={mailLoading}
          msme={true}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={null}
          emailSubject={null}
          listAllBusinessPartnersWSR={null}
        />

        <PartnerRequests
          open={openRaiseReq}
          setOpen={setOpenRaiseReq}
          allIssues={raisedIssues}
          markIssueResolved={markIssueResolved}
          setSendSoleOrBulkMail={setSendSoleOrBulkMail}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
          useCaseType={UseCaseType.ledgerRequestBeta}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialogLRB}
          setOpen={(value: boolean) => {
            dispatch(updateOpenBulkMailReportDialogLRB(value));
          }}
          emailResponseMsg={emailResponseMsgLRB}
          mailFailedUsers={mailFailedUsersLRB}
          workbook={mailFailedWorkbookLRB}
        />
        <ApproveResponseDialog
          open={openApproveDialog}
          setOpen={setOpenApproveDialog}
          partnerCount={approveSelectedRow.length}
          approveAction={async () => await approveResponse(approveSelectedRow.map((row) => row.businessPartnerId))}
        />
        <ResetStatusDialog
          open={openResetStatusDialog}
          setOpen={setOpenResetStatusDialog}
          partnerCount={selectedRow.length}
          partnerName={selectedRow.length === 1 ? selectedRow[0].businessPartnerName : ""}
          resetAction={async () => await resetStatus(selectedRow.map((row) => row.businessPartnerId))}
        />
      </LrBetaContext.Provider>
    </LoggedInSkeleton>
  );
};

export default LedgerRequestBeta;
