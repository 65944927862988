import React from "react";

export const ColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;

  return (
    <span>
      <i className="fa fa-filter" aria-hidden="true" color="#aaa" />
      <input
        ref={(inp) => (inp ? (inp.value ? inp.classList.add("focused") : inp.classList.remove("focused")) : undefined)}
        className="filterInput"
        placeholder="Filter"
        value={filterValue || ""}
        onChange={(e) => setFilter(e.target.value)}
      />
    </span>
  );
};
