import { Collapse, MenuItem, Pagination, Select, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useEffect, useMemo } from "react";
import { useBlockLayout, useExpanded, useFilters, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import "./Table.scss";

const defaultPropGetter = () => ({});

function SummaryTable({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  sortEnable,
  renderRowSubComponent,
  setSelectedRow,
  actorType,
}: any) {
  const columnsData: any = useMemo(() => columns, [columns]);

  const rowsData: any = useMemo(() => rows, [rows]);

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    // nextPage,
    // previousPage,
    // canNextPage,
    // canPreviousPage,
    // pageOptions,
    filteredRows,
    gotoPage,
    pageCount,
    // allColumns,
    setPageSize,
    state,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns: columnsData,
      data: rowsData,
      defaultColumn,
      initialState: { pageSize: 25 },
    },

    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (actorType === "admin") {
      const filterselectedFlatRows = selectedFlatRows.map((item) => item.original);
      setSelectedRow(filterselectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedRow, actorType]);

  return (
    <Card className="summary_wrapper">
      <div className="table_fixed_div" style={{ borderRadius: "16px", maxHeight: "unset" }}>
        <table {...getTableProps()} id={id} className="summary_table" style={{ borderRadius: "1%" }}>
          <thead>
            {headerGroups.map((headerGroup, iHead) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={"thead_tr" + iHead}>
                {headerGroup.headers.map((column, iRow) => (
                  <th {...column.getHeaderProps()} key={"th" + iRow}>
                    {sortEnable ? (
                      <div className="column" {...column.getSortByToggleProps()} style={{ padding: "0px .75rem" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div>
                              {column.render("Header")} <i className="fa fa-sort-desc" aria-hidden="true" />
                            </div>
                          ) : (
                            <div>
                              {column.render("Header")} <i className="fa fa-sort-asc" aria-hidden="true" />
                            </div>
                          )
                        ) : (
                          <div>
                            {column.render("Header")}{" "}
                            <i className="fa fa-sort" style={{ display: "none" }} aria-hidden="true" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="column" style={{ padding: "0px .75rem" }}>
                        {column.render("Header")}{" "}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, iRow) => {
              prepareRow(row);
              const rowProps = row.getRowProps();
              return (
                <React.Fragment key={rowProps.key}>
                  <tr {...row.getRowProps()} key={"tbody_tr" + iRow}>
                    {row.cells.map((cell, iCell) => {
                      return (
                        <td
                          key={"td" + iCell}
                          {...cell.getCellProps([
                            {
                              className: cell.column,
                              style: cell.column,
                            },
                            getCellProps(cell),
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                  <Collapse in={row.isExpanded} timeout="auto" unmountOnExit={true} sx={{ background: "#fafafa" }}>
                    {renderRowSubComponent({ row, rowProps, visibleColumns })}
                  </Collapse>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* Pagination And Table Footer Component */}
      {
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "15px",
            paddingLeft: "10px",
            marginTop: "15px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${pageSize * pageIndex + 1}–${
              pageSize * (pageIndex + 1) < filteredRows.length ? pageSize * (pageIndex + 1) : filteredRows.length
            } of ${filteredRows.length}`}
          </Typography>
          <Select
            value={pageSize}
            size="small"
            sx={{
              "&>div": { py: "2px" },
              "& > fieldset": { borderWidth: "0.5px" },
            }}
            onChange={(e) => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50, 200].map((PageSize) => (
              <MenuItem key={PageSize} value={PageSize}>
                {PageSize} per Page
              </MenuItem>
            ))}
          </Select>
          <div style={{ flex: 1 }} />
          <Pagination
            count={pageCount}
            page={pageIndex + 1}
            defaultPage={1}
            siblingCount={2}
            boundaryCount={2}
            onChange={(_e, pageNum) => gotoPage(pageNum - 1)}
            sx={{
              "& .Mui-selected": {
                backgroundColor: "rgba(84, 28, 76, 0.1) !important",
              },
            }}
          />
        </div>
      }
    </Card>
  );
}

export default SummaryTable;
