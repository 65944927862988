import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { Currency } from "dinero.js";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { gapAccountedAsName } from "../../../Utils/Recon/Recon360/Constants";
import { Recon360Context } from "../Recon360";
import { ManualReconContext } from "./ManualRecon";

const ReconcileConfirmWarningModal = () => {
  const {
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    reconcileEntries,
    absoluteReconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    amountTolerance,
    showLoadingIconReconcile,
    gapAccountedName,
    reconcileTotalSum,
    reconcileTotalPercentage,
  } = useContext(ManualReconContext);
  const { currency } = useContext(Recon360Context);

  return (
    <div>
      <Dialog open={isOpenReconcileConfirmWarningModal} aria-labelledby="reconcileConfirmWarningModal" maxWidth="md">
        <DialogTitle>Where do you want to put the Gap?</DialogTitle>
        <DialogContent>
          <div className="center_align matchId_heading ml_10 mr_10">
            Gap:{" "}
            {reconcileTotalSum !== null
              ? formatMoney(ToDineroObj(reconcileTotalSum, currency.current as Currency))
              : "-"}{" "}
            {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 ? `(${reconcileTotalPercentage} %)` : ""}
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="space_between">
            {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 && (
              <Button
                variant="contained"
                className="theme_outline_btn"
                disabled={showLoadingIconReconcile}
                onClick={() => {
                  reconcileEntries(gapAccountedAsName.tds);
                }}
                sx={{ marginRight: 1 }}
              >
                {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.tds && (
                  <LoadingIcon loading={showLoadingIconReconcile} />
                )}
                TDS
              </Button>
            )}
            {/* {absoluteReconcileTotalSum < amountTolerance && ( */}
            <Button
              variant="contained"
              disabled={showLoadingIconReconcile}
              onClick={() => {
                reconcileEntries(gapAccountedAsName.roundingError);
              }}
              className="theme_outline_btn mr_10"
            >
              {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.roundingError && (
                <LoadingIcon loading={showLoadingIconReconcile} />
              )}
              Rounding Off
            </Button>
            {/* )} */}
            {absoluteReconcileTotalSum > amountTolerance &&
              !(selectedRowOwn?.length > 0 && selectedRowBp?.length > 0) && (
                <Button
                  variant="contained"
                  disabled={showLoadingIconReconcile}
                  onClick={() => {
                    reconcileEntries(gapAccountedAsName.miscellaneous);
                  }}
                  className="theme_outline_btn mr_10"
                >
                  {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.miscellaneous && (
                    <LoadingIcon loading={showLoadingIconReconcile} />
                  )}
                  Miscellaneous
                </Button>
              )}
            {absoluteReconcileTotalSum > amountTolerance && selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 && (
              <Button
                variant="contained"
                disabled={showLoadingIconReconcile}
                onClick={() => {
                  reconcileEntries(gapAccountedAsName.mismatchOthers);
                }}
                className="theme_outline_btn mr_10"
              >
                {showLoadingIconReconcile && gapAccountedName === gapAccountedAsName.mismatchOthers && (
                  <LoadingIcon loading={showLoadingIconReconcile} />
                )}
                Amount-Mismatch
              </Button>
            )}
            <Button
              variant="outlined"
              disabled={showLoadingIconReconcile}
              onClick={() => {
                setIsOpenReconcileConfirmWarningModal(false);
              }}
              className="theme_outline_btn mr_10"
            >
              close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReconcileConfirmWarningModal;
