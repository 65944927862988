import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { uiLoggerNamesChecklistAndNotes } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import "./Checklist.scss";

const Checklist = ({
  openChecklistModal,
  setOpenChecklistModal,
  setOpenAddChecklistModal,
  setShowChecklistAndNotesIcon,
  setChecklistTitle,
  setChecklistCategory,
  setChecklistDynamicContent,
  isViewChecklistRef,
  listOfChecklist,
  selectedChecklistIDRef,
  markChecklist,
  markChecklistLoader,
  companyId,
  branchCode,
  businessPartnerSelectedRef,
}) => {
  const [showChecklistAlert, setShowChecklistAlert] = useState(true);
  const handleClose = () => {
    setOpenChecklistModal(false);
    setShowChecklistAndNotesIcon(true);
    setShowChecklistAlert(true);
  };
  return (
    <div>
      <Modal
        open={openChecklistModal}
        onClose={handleClose}
        aria-labelledby="checklist-modal-title"
        aria-describedby="checklist-modal-description"
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Slide direction="up" in={openChecklistModal} mountOnEnter unmountOnExit>
          <Box
            sx={{
              position: "fixed",
              bottom: 10,
              right: 10,
              width: "100%",
              maxWidth: "400px",
              bgcolor: "background.paper",
              boxShadow: 24,
              // p: 4,
              outline: "none",
              height: "50vh",
              borderRadius: "4px",
            }}
          >
            <Grid container className="checklist_header">
              <Grid xs={8}>
                <Typography variant="h6" className="checklist_header_title">
                  Checklists{" "}
                  <span className="vertical_center_align ml_4">
                    {" "}
                    <HelpIcon style={{ color: "#B0BBFF", width: "20px", height: "20px" }} />{" "}
                  </span>
                </Typography>
              </Grid>
              <Grid xs={4}>
                <IconButton
                  onClick={() => {
                    selectedChecklistIDRef.current = null;
                    setChecklistTitle("");
                    setChecklistCategory("");
                    setChecklistDynamicContent("");
                    setOpenChecklistModal(false);
                    setOpenAddChecklistModal(true);
                  }}
                  className="checklist_add"
                >
                  <AddIcon className="icon_size_20 mr_8" /> Add
                </IconButton>
                <IconButton onClick={handleClose}>
                  <CloseIcon className="ml_8" style={{ color: "#ffffff" }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid sx={{ p: 1, overflowY: "auto", height: "calc(50vh - 56px)" }}>
              <Grid>
                <Collapse in={showChecklistAlert}>
                  <Alert
                    variant="outlined"
                    severity="info"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setShowChecklistAlert(false);
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                    sx={{ mb: 1, display: "flex", justifyContent: "center", alignItems: "center" }}
                  >
                    Add items that you want to ensure before or after doing a reconciliation
                  </Alert>
                </Collapse>
              </Grid>
              {listOfChecklist.map((checklist, index) => (
                <Grid className="mb_10" key={index}>
                  <Card
                    sx={{
                      background: checklist.isMarked
                        ? "var(--components-alert-info-background, #E5F6FD)"
                        : "transparent", // Default background when unchecked
                    }}
                  >
                    <CardContent
                      sx={{
                        p: 0,
                        pt: "4px",
                        minHeight: "72px",
                        display: "flex",
                        alignItems: "center",
                        "&:last-child": {
                          paddingBottom: "4PX", // Override the default padding
                        },
                      }}
                    >
                      <Grid container>
                        <Grid xs={1.5} className="vertical_center_align ">
                          <Checkbox
                            checked={checklist.isMarked}
                            disabled={markChecklistLoader}
                            onChange={(e) => {
                              markChecklist(checklist.id, e.target.checked, checklist.type);
                            }}
                          />
                        </Grid>
                        <Grid
                          xs={10.5}
                          className="vertical_center_align"
                          onClick={() => {
                            isViewChecklistRef.current = true;
                            setOpenChecklistModal(false);
                            selectedChecklistIDRef.current = checklist.id;
                            setChecklistTitle(checklist.title);
                            setChecklistCategory(checklist.type);
                            setChecklistDynamicContent(checklist.content);
                            setOpenAddChecklistModal(true);
                            uiLogger(
                              uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_PREVIEW_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNamesChecklistAndNotes.UI_RE_CHECKLIST_PREVIEW_CLICK.message,
                                businessPartnerId: businessPartnerSelectedRef.current,
                              }
                            );
                          }}
                        >
                          <Grid xs={11}>
                            <Typography variant="body1" className="checklist_name cursor_pointer">
                              {checklist.title}
                            </Typography>
                          </Grid>
                          <Grid xs={1} className="vertical_center_align flex_end">
                            <IconButton>
                              <KeyboardArrowRightIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}

              {listOfChecklist?.length === 0 && (
                <Grid className="center_align_ver_horiz" style={{ height: "100%" }}>
                  <Typography variant="body2" className="checklist_name_empty">
                    Click on ‘+ ADD’ button to add a checklist
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Slide>
      </Modal>
    </div>
  );
};

export default Checklist;
