import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios"; // Import API function
import { getAllActorsList } from "../../api/admin/userListApi";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const fetchAllActors = createAsyncThunk("userList/fetchAllActors", async (_, { rejectWithValue }) => {
  try {
    return await getAllActorsList();
  } catch (error) {
    const axiosError = error as AxiosError; // Type assertion
    return rejectWithValue(axiosError.message || "An error occurred");
  }
});
export const userListSlice = createSlice({
  name: "userList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllActors.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAllActors.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchAllActors.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "An error occurred";
      });
  },
});

export default userListSlice.reducer;
