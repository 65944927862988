import { Box, Button, Typography } from "@mui/material";
import React from "react";

export default function RaiseIssueSection({
  setOpenDialog,
}: {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        fontSize: "14px",
        alignItems: "center",
        ml: "auto",
      }}
    >
      <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.37)" }}>
        If you have any queries/issues,
      </Typography>
      <Button
        variant="outlined"
        onClick={() => setOpenDialog(true)}
        sx={{
          cursor: "pointer",
          textTransform: "uppercase",
          borderRadius: "4px !important",
          padding: "6px 16px",
        }}
      >
        Raise query/ Issue
      </Button>
    </Box>
  );
}
