import { Close, ThumbDown, ThumbUp } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import useFetch from "../../../Common/useFetch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "./CommonComponents";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface FeedbackProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  templateType: UseCaseType;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}

const FeedbackDialog = ({ ...props }: FeedbackProps) => {
  const [feedbackText, setFeedbackText] = useState("");

  const submitFeedback = (liked: boolean) => {
    return new Promise((resolve, reject) => {
      useFetch(API_ENDPOINTS.FEEDBACK_PARTNER_COMMUNICATION.url, "POST", {
        failureMessage: API_ENDPOINTS.FEEDBACK_PARTNER_COMMUNICATION.failureMessage,
        showSuccessToast: true,
        data: {
          ownCompanyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
          feedbackMessage: feedbackText,
          outcomeResponse: liked,
          templateType: props.templateType,
        },
        thenCallBack: (_res) => {
          resolve(_res.data);
          setFeedbackText("");
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Do You like this Feature?</DialogTitle>
        <IconButton
          onClick={() => {
            props.setOpen(false);
            setFeedbackText("");
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <ul>
          <li style={{ listStyle: "inside" }}>
            <FormLabel>Did you face any issues, while using Partner Communication?</FormLabel>
          </li>
          <li style={{ listStyle: "inside" }}>
            <FormLabel>Do you have any suggestions, we can use to improve our product?</FormLabel>
          </li>
        </ul>
        <br />
        <TextField
          multiline
          rows={4}
          placeholder="Please Add Feedback Here"
          label="Add Feedback"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />
      </DialogContent>
      <DialogActions className="dialog_footer center_align_ver_horiz" sx={{ justifyContent: "center", gap: 2 }}>
        <Button
          variant="contained"
          className="theme_btn_no_bg"
          size="small"
          onClick={async () => {
            await submitFeedback(false);
            props.setOpen(false);
          }}
          startIcon={<ThumbDown />}
          color="warning"
          sx={{ width: 100 }}
        >
          Dislike
        </Button>
        <Button
          variant="contained"
          className="theme_btn_no_bg"
          autoFocus={true}
          size="small"
          onClick={async () => {
            await submitFeedback(true);
            props.setOpen(false);
          }}
          startIcon={<ThumbUp />}
          color="primary"
          sx={{ width: 100 }}
        >
          Like
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
