import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { ReconOverviewFilters } from "../../../entity/recon-entity/ReconInterfaces";
import { CheckboxGroup } from "./utils";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;
interface OverviewFilterProps {
  openOverviewLevelFilter: boolean;
  setOpenOverviewLevelFilter: StateDispatch<boolean>;
  filter: React.MutableRefObject<ReconOverviewFilters | "all">;
  getReconMis: () => void;
  setIsFilterAppliedOverviewLevelFilter: StateDispatch<boolean>;
  allCategories: string[];
  allEmails: string[];
  setAppliedFilters: StateDispatch<{ [k: string]: string }>;
}

const initialReconOverviewFilters: ReconOverviewFilters = {
  balanceConfirmed: {
    confirmed: false,
    unConfirmed: false,
  },
  buyerSupplier: {
    buyer: false,
    supplier: false,
  },
  category: [],
  targetType: {
    newTarget: false,
    spillOver: false,
    other: false,
  },
  userEmail: [],
};

const OverviewLevelFilter = (props: OverviewFilterProps) => {
  // const [selectedValue, setSelectedValue] = useState<string>(null);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState<string[]>([]);
  const [filterObj, setFilterObj] = useState<ReconOverviewFilters>(
    typeof props.filter.current !== "string" ? props.filter.current : initialReconOverviewFilters
  );

  const handleClose = () => {
    props.setOpenOverviewLevelFilter(false);
  };

  const clearAllAppliedFilters = () => {
    props.filter.current = "all";
    // setSelectedValue(null);
    setFilterObj(initialReconOverviewFilters);
    setSelectedCategory([]);
    setSelectedEmail([]);
    props.getReconMis();
    props.setIsFilterAppliedOverviewLevelFilter(false);
    props.setAppliedFilters({});
    handleClose();
  };

  const filterOverviewLevelFilter = () => {
    props.filter.current = filterObj;
    props.setIsFilterAppliedOverviewLevelFilter(true);
    props.setAppliedFilters({
      Categories: filterObj.category.join(", "),
      Emails: filterObj.userEmail.join(", "),
      "Balance Confirmed":
        filterObj.balanceConfirmed.confirmed && filterObj.balanceConfirmed.unConfirmed
          ? "Confirmed, Not Confirmed"
          : filterObj.balanceConfirmed.confirmed
          ? "Confirmed"
          : filterObj.balanceConfirmed.unConfirmed
          ? "Not Confirmed"
          : "",
      "Buyer/Supplier":
        filterObj.buyerSupplier.buyer && filterObj.buyerSupplier.supplier
          ? "Buyer, Supplier"
          : filterObj.buyerSupplier.buyer
          ? "Buyer"
          : filterObj.buyerSupplier.supplier
          ? "Supplier"
          : "",
      "Target Type": (() => {
        const types = [];
        if (filterObj.targetType.newTarget) types.push("New Target");
        if (filterObj.targetType.spillOver) types.push("Spill Over");
        if (filterObj.targetType.other) types.push("Other");

        if (types.length === 0) return "";
        if (types.length === 1) return types[0];
        return types.join(", ");
      })(),
    });
    props.getReconMis();
    handleClose();
  };

  return (
    <Dialog open={props.openOverviewLevelFilter} aria-labelledby="openOverviewLevelFilter">
      <DialogTitle className="dialog_header">
        <p className="fs_24">Apply Filter</p>
        <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
      </DialogTitle>
      <DialogContent>
        <div className="d_flex m_15 ">
          <div className="category_textField_width_390">
            <Box component={"form"} sx={{ "& > div": { minHeight: 45 } }}>
              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="width_150">Category :</FormLabel>
                  <GetAutoComplete
                    options={props.allCategories}
                    selected={selectedCategory}
                    setSelected={setSelectedCategory}
                    label="Select Category"
                    onChange={(_e, val) => {
                      setFilterObj({
                        ...filterObj,
                        category: val,
                      });
                    }}
                  />
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="width_150">Target Type :</FormLabel>
                  <CheckboxGroup
                    row={true}
                    onChange={(_e, v) =>
                      setFilterObj({
                        ...filterObj,
                        targetType: {
                          newTarget: v.newTarget,
                          spillOver: v.spillOver,
                          other: v.other,
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      control={<Checkbox checked={filterObj.targetType.newTarget} name="newTarget" />}
                      label="New Target"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterObj.targetType.spillOver} name="spillOver" />}
                      label="Spill Over"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterObj.targetType.other} name="other" />}
                      label="Other"
                    />
                  </CheckboxGroup>
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="width_150">Buyer / Supplier :</FormLabel>
                  <CheckboxGroup
                    row={true}
                    onChange={(_e, v) =>
                      setFilterObj({
                        ...filterObj,
                        buyerSupplier: {
                          buyer: v.buyer,
                          supplier: v.supplier,
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      value="buyer"
                      control={<Checkbox checked={filterObj.buyerSupplier.buyer} name="buyer" />}
                      label="Buyer"
                    />
                    <FormControlLabel
                      value="supplier"
                      control={<Checkbox checked={filterObj.buyerSupplier.supplier} name="supplier" />}
                      label="Supplier"
                    />
                  </CheckboxGroup>
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="width_150">User Email :</FormLabel>
                  <GetAutoComplete
                    options={props.allEmails}
                    selected={selectedEmail}
                    setSelected={setSelectedEmail}
                    label="Select Email"
                    onChange={(_e, val) => {
                      setFilterObj({
                        ...filterObj,
                        userEmail: val,
                      });
                    }}
                  />
                </Box>
              </FormControl>

              <FormControl>
                <Box display={"inline-flex"} gap={4} alignItems="center">
                  <FormLabel className="width_150">Balance Confirmed :</FormLabel>
                  <CheckboxGroup
                    row={true}
                    onChange={(_e, v) =>
                      setFilterObj({
                        ...filterObj,
                        balanceConfirmed: {
                          confirmed: v.confirmed,
                          unConfirmed: v.unconfirmed,
                        },
                      })
                    }
                  >
                    <FormControlLabel
                      control={<Checkbox checked={filterObj.balanceConfirmed.confirmed} name="confirmed" />}
                      label="Confirmed"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={filterObj.balanceConfirmed.unConfirmed} name="unconfirmed" />}
                      label="Unconfirmed"
                    />
                  </CheckboxGroup>
                </Box>
              </FormControl>
            </Box>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialog_footer space_between">
        <div>
          <Button
            className="theme_outline_btn"
            onClick={() => {
              clearAllAppliedFilters();
            }}
          >
            Clear All
          </Button>
        </div>
        <div>
          <Button
            className="theme_btn"
            onClick={() => {
              filterOverviewLevelFilter();
            }}
          >
            Apply
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

interface GACProps {
  options: string[];
  selected: string[];
  setSelected: StateDispatch<any[] | string[]>;
  label?: string;
  onChange?: (_e: React.SyntheticEvent<Element, Event>, value: string[]) => void;
}
const GetAutoComplete = ({ options, selected, setSelected, label = "Select", onChange }: GACProps) => {
  return (
    <Autocomplete
      className="w_300"
      value={selected}
      fullWidth={true}
      onChange={(_e, value) => {
        setSelected(value);
        if (onChange) onChange(_e, value);
      }}
      size="small"
      multiple={true}
      options={options}
      getOptionLabel={(option: any) => option}
      filterSelectedOptions={true}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
};

export default OverviewLevelFilter;
