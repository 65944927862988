import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CloseIcon from "@mui/icons-material/Close";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { Currency } from "dinero.js";
import React, { useContext } from "react";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { Recon360Context } from "../Recon360";
import "./PreReconciliationEvaluation.scss";

const PreReconciliationEvaluation = () => {
  const {
    currency,
    openPreReconciliationEvaluationModal,
    setOpenPreReconciliationEvaluationModal,
    preReconLedgerScoreData,
    initiateRecon,
    companyId,
    branchCode,
    businessPartnerSelectedRef,
  } = useContext(Recon360Context);

  const handleClose = () => {
    setOpenPreReconciliationEvaluationModal(false);
  };

  return (
    <div className="preReconciliationEvaluation">
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openPreReconciliationEvaluationModal}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            height: "85vh",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Pre-Reconciliation Evaluation
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose();
            uiLogger(uiLoggerNamesRecon.UI_RL_PRE_RECON_CANCEL.functionName, companyId.current, branchCode.current, {
              message: uiLoggerNamesRecon.UI_RL_PRE_RECON_CANCEL.message(preReconLedgerScoreData.issues.length),
              businessPartnerId: businessPartnerSelectedRef.current,
            });
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 2 }}>
          <Grid sx={{ mb: 2 }}>
            <Typography variant="subtitle2" className="list_of_issues_title">
              List of issues found
            </Typography>
            <List sx={{ background: "#FFF4E5", padding: "8px", borderRadius: "4px" }}>
              {preReconLedgerScoreData?.issues?.map((issue, index) => (
                <ListItem key={index} sx={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                  <Badge variant="standard" badgeContent={index + 1} color="warning" />
                  <ListItemText
                    primary={issue}
                    primaryTypographyProps={{
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#000000de",
                      letterSpacing: 0.17,
                      lineHeight: "143%",
                      marginLeft: "36px",
                    }}
                  />
                </ListItem>
              ))}
            </List>

            <Grid sx={{ mt: 3 }}>
              {/*  */}
              <Box className="recon-period-box">
                <Box className="recon-period-info">
                  <CalendarTodayIcon className="calendar-icon" />
                  <Typography variant="subtitle2" className="recon-period-label">
                    Recon Period Start Date
                  </Typography>
                </Box>
                <Typography variant="body2" className="recon-period-date">
                  {preReconLedgerScoreData.dateScores.reconDate.startDate}
                </Typography>
              </Box>
              {/*  */}

              <Grid container>
                <Grid item xs={0.7} className="ver-dotted-line">
                  {/* Empty grid for spacing or other content */}
                </Grid>
                <Grid item xs={11.3} sx={{ pt: 2, pb: 2 }}>
                  <Grid>
                    <Box className="ledger-row">
                      <Typography variant="subtitle2" className="ledger-label">
                        Your Ledger Start and End Date
                      </Typography>
                      <Box className="ledger-dates">
                        <Typography variant="body2" className="ledger-date">
                          {preReconLedgerScoreData.dateScores.ownEntriesDate.startDate}
                        </Typography>
                        <Box className="horizontal-line-container">
                          <hr className="dashed-line" />
                        </Box>
                        <Typography variant="body2" className="ledger-date">
                          {preReconLedgerScoreData.dateScores.ownEntriesDate.endDate}
                        </Typography>
                        {preReconLedgerScoreData.dateScores.ownEntriesDate.issueIndex && (
                          <Tooltip
                            title={
                              preReconLedgerScoreData.issues[
                                preReconLedgerScoreData.dateScores.ownEntriesDate.issueIndex - 1
                              ]
                            } // Get the issue based on the issueIndex
                            arrow
                            placement="left"
                          >
                            <Badge
                              variant="standard"
                              badgeContent={preReconLedgerScoreData.dateScores.ownEntriesDate.issueIndex}
                              color="warning"
                              sx={{ ml: 2 }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </Grid>

                  <Grid>
                    <Box className="ledger-row">
                      <Typography variant="subtitle2" className="ledger-label">
                        Partner Ledger Start and End Date
                      </Typography>
                      <Box className="ledger-dates">
                        <Typography variant="body2" className="ledger-date">
                          {preReconLedgerScoreData.dateScores.businessPartnerEntriesDate.startDate}
                        </Typography>
                        <Box className="horizontal-line-container">
                          <hr className="dashed-line" />
                        </Box>
                        <Typography variant="body2" className="ledger-date">
                          {preReconLedgerScoreData.dateScores.businessPartnerEntriesDate.endDate}
                        </Typography>
                        {preReconLedgerScoreData.dateScores.businessPartnerEntriesDate.issueIndex && (
                          <Tooltip
                            title={
                              preReconLedgerScoreData.issues[
                                preReconLedgerScoreData.dateScores.businessPartnerEntriesDate.issueIndex - 1
                              ]
                            } // Get the issue based on the issueIndex
                            arrow
                            placement="left"
                          >
                            <Badge
                              variant="standard"
                              badgeContent={preReconLedgerScoreData.dateScores.businessPartnerEntriesDate.issueIndex}
                              color="warning"
                              sx={{ ml: 2 }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>

              <Box className="recon-period-box">
                <Box className="recon-period-info">
                  <CalendarTodayIcon className="calendar-icon" />
                  <Typography variant="subtitle2" className="recon-period-label">
                    Recon Period End Date
                  </Typography>
                </Box>
                <Typography variant="body2" className="recon-period-date">
                  {preReconLedgerScoreData.dateScores.reconDate.endDate}
                </Typography>
              </Box>
            </Grid>

            {/*  */}
            <Grid sx={{ mt: 3 }}>
              <Box
                sx={{
                  width: "100%", // Full width
                }}
              >
                <Box className="amount-table-header">
                  <Typography className="amount-table-cell" sx={{ p: 1 }}></Typography>
                  <Typography className="amount-table-cell fw_600" sx={{ p: 1 }}>
                    Your
                  </Typography>
                  <Typography className="amount-table-cell fw_600" sx={{ p: 1 }}>
                    Partner
                  </Typography>
                  <Typography className="amount-table-cell fw_600" sx={{ p: 1 }}>
                    Percentage Diff.
                  </Typography>
                </Box>
                {Object.entries(preReconLedgerScoreData.amountScores).map(([name, values], index): any => (
                  <Box
                    className="amount-table-td"
                    key={name}
                    sx={{
                      border:
                        index === 0 || index === Object.entries(preReconLedgerScoreData.amountScores).length - 1
                          ? "1px solid #d0d0d0"
                          : "none", // Top border for the first row
                    }}
                  >
                    <Typography className="amount-table-cell" sx={{ p: 1 }}>
                      {name}
                    </Typography>
                    <Tooltip
                      title={preReconLedgerScoreData.issues[values.ownAmount.issueIndex - 1]} // Get the issue based on the issueIndex
                      arrow
                      placement="left"
                    >
                      <Typography
                        className="amount-table-cell space_between"
                        sx={{
                          p: 1,
                          backgroundColor: values.ownAmount.issueIndex ? "#FFF4E5" : "transparent", // Apply background color based on issueIndex
                        }}
                      >
                        <span>{formatMoney(ToDineroObj(values.ownAmount.value, currency.current as Currency))}</span>{" "}
                        <span className="mr_12">
                          {values.ownAmount.issueIndex ? (
                            <Badge variant="standard" badgeContent={values.ownAmount.issueIndex} color="warning" />
                          ) : (
                            ""
                          )}
                        </span>
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={preReconLedgerScoreData.issues[values.businessPartnerAmount.issueIndex - 1]} // Get the issue based on the issueIndex
                      arrow
                      placement="left"
                    >
                      <Typography
                        className="amount-table-cell space_between"
                        sx={{
                          p: 1,
                          backgroundColor: values.businessPartnerAmount.issueIndex ? "#FFF4E5" : "transparent", // Apply background color based on issueIndex
                        }}
                      >
                        <span>
                          {formatMoney(ToDineroObj(values.businessPartnerAmount.value, currency.current as Currency))}
                        </span>{" "}
                        <span className="mr_12">
                          {values.businessPartnerAmount.issueIndex ? (
                            <Badge
                              variant="standard"
                              badgeContent={values.businessPartnerAmount.issueIndex}
                              color="warning"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={preReconLedgerScoreData.issues[values.absolutePercentage.issueIndex - 1]} // Get the issue based on the issueIndex
                      arrow
                      placement="left"
                    >
                      <Typography
                        className="amount-table-cell space_between"
                        sx={{
                          p: 1,
                          backgroundColor: values.absolutePercentage.issueIndex ? "#FFF4E5" : "transparent", // Apply background color based on issueIndex
                        }}
                      >
                        <span>{values.absolutePercentage.value}%</span>{" "}
                        <span className="mr_12">
                          {values.absolutePercentage.issueIndex ? (
                            <Badge
                              variant="standard"
                              badgeContent={values.absolutePercentage.issueIndex}
                              color="warning"
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </Typography>
                    </Tooltip>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }} className="bg_footer">
          <Button
            sx={{ color: "#000000DE" }}
            onClick={() => {
              handleClose();
              uiLogger(uiLoggerNamesRecon.UI_RL_PRE_RECON_CANCEL.functionName, companyId.current, branchCode.current, {
                message: uiLoggerNamesRecon.UI_RL_PRE_RECON_CANCEL.message(preReconLedgerScoreData.issues.length),
                businessPartnerId: businessPartnerSelectedRef.current,
              });
            }}
          >
            Cancel
          </Button>
          <Button
            className="green_btn"
            onClick={() => {
              handleClose();
              initiateRecon();
              uiLogger(
                uiLoggerNamesRecon.UI_RL_PRE_RECON_CONTINUED.functionName,
                companyId.current,
                branchCode.current,
                {
                  message: uiLoggerNamesRecon.UI_RL_PRE_RECON_CONTINUED.message(preReconLedgerScoreData.issues.length),
                  businessPartnerId: businessPartnerSelectedRef.current,
                }
              );
            }}
          >
            Continue Reconciliation
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PreReconciliationEvaluation;
