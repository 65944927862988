import colorLib from "@kurkle/color";
import { FormGroup, SxProps } from "@mui/material";
import React from "react";
import { formatMoney } from "../../../Utils/MoneyUtils";

export const formatNumberINR = (num: number | string) => {
  if (typeof num === "string") return num;
  if (num === null || typeof num === "undefined") {
    return "-"; // or any other fallback value you prefer
  }

  return formatMoney({ amount: Math.round(num), precision: 0, currency: "INR" });
};

interface CheckboxGroupProps {
  onChange: (
    _e: React.FormEvent<HTMLDivElement>,
    v: {
      [checkBoxName: string]: boolean;
    }
  ) => void;
  children: JSX.Element | JSX.Element[];
  row?: boolean;
  sx?: SxProps;
}
export const CheckboxGroup = ({ onChange, children, row, sx }: CheckboxGroupProps) => {
  const ref: React.MutableRefObject<HTMLDivElement> = React.useRef(null);

  const handleChange = (_e: React.FormEvent<HTMLDivElement>) => {
    const values: { [k: string]: boolean } = {};
    ref.current.querySelectorAll("input[type=checkbox]").forEach((cb) => {
      const checkBox = cb as HTMLInputElement;
      values[checkBox.name || checkBox.value] = checkBox.checked;
    });

    onChange(_e, values);
  };
  return (
    <FormGroup row={row} className="CheckBoxGroup" onChange={handleChange} ref={ref} sx={sx}>
      {children}
    </FormGroup>
  );
};

const COLORS = ["#4dc9f6", "#f67019", "#f53794", "#537bc4", "#acc236", "#166a8f", "#00a950", "#58595b", "#8549ba"];

export function color(index) {
  return COLORS[index % COLORS.length];
}

export function transparentize(value: string, opacity: number) {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value).alpha(alpha).rgbString();
}

export const CHART_COLORS = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(75, 192, 192)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  violet: "rgb(63, 81, 181)",
  grey: "rgb(201, 203, 207)",
};

const NAMED_COLORS = [
  CHART_COLORS.red,
  CHART_COLORS.orange,
  CHART_COLORS.yellow,
  CHART_COLORS.green,
  CHART_COLORS.blue,
  CHART_COLORS.purple,
  CHART_COLORS.violet,
  CHART_COLORS.grey,
];

export function namedColor(index: number) {
  return NAMED_COLORS[index % NAMED_COLORS.length];
}
