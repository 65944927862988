import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { Recon360Context, StateDispatch } from "../Recon360";
import { TicketMetaStatuses } from "./ViewTickets";

interface DiscardBulkTasksConfirmationModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  tasksCount: number;
  updateMetaStatusForBulkTasks: any;
  isLoading: boolean;
}
export default function DiscardBulkTasksConfirmationModal({
  open,
  setOpen,
  tasksCount,
  updateMetaStatusForBulkTasks,
  isLoading,
}: DiscardBulkTasksConfirmationModalProps) {
  const { companyId, branchCode } = useContext(Recon360Context);
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to Discard {tasksCount} Tasks</DialogTitle>
      <DialogContent>
        <Alert severity="warning">
          Discarded reconciliation:
          <p> 1. Will not be visible in Recon Task Dashboard.</p>
          <p> 2. Will not be used for future Reconciliation Gap Justifications</p>
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_DISCARDTASKS_CANCEL_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_DISCARDTASKS_CANCEL_CLICK.message,
              }
            );
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          sx={{ borderRadius: "4px !important" }}
          variant="contained"
          color="warning"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_DISCARDTASKS_DISCARD_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_DISCARDTASKS_DISCARD_CLICK.message,
              }
            );
            updateMetaStatusForBulkTasks(TicketMetaStatuses.Discarded);
          }}
        >
          <LoadingIcon loading={isLoading} />
          Discard
        </Button>
      </DialogActions>
    </Dialog>
  );
}
