import { Button, Grid, Slider } from "@mui/material";
import React, { useContext, useState } from "react";
import { useLocation } from "react-router";
import { LoggedInSkeleton } from "../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../Common/TopNavBar";
import { userContext } from "../../Contexts/userContext";
import Table from "../../ReactTable/Table";
import "./SupplierChainDashboard.scss";

const SupplierChainDashboard = () => {
  const location = useLocation<any>();
  const data = [
    {
      supplierName: "IPEC",
      lastReconciledOn: "23/06/2022",
      ownClosingBalance: "100000",
      businessPartnerClosingBalance: "-99900",
      closingDifference: "100",
      entriesNumInLastXDays: 1,
      totalValueInLastXDays: 100,
    },
  ];

  // const [loader, setLoader] = useState(false);
  const [rowsData, setRowsData] = useState<any[]>(data);
  const { actor } = useContext(userContext);

  const [daysEntries, setDaysEntries] = useState<string>("30");
  const [daysTotalValue, setDaysTotalValue] = useState<string>("30");
  const [value, setValue] = useState<number>(0);
  const [valueTotalValue, setValueTotalValue] = useState<number>(0);

  const marks = [
    {
      value: 0,
      label: "30",
    },
    {
      value: 20,
      label: "60",
    },
    {
      value: 40,
      label: "180",
    },
    {
      value: 60,
      label: "365",
    },
    {
      value: 100,
      label: "All",
    },
  ];

  // function valuetext(value: number) {
  //   return `${value}°C`;
  // }

  // function valueLabelFormat(value: number) {
  //   return marks.map((mark) => mark.value === value);
  // }

  const entriesInLastXDays = (e: any) => {
    if (e.target.value === 0) {
      setDaysEntries("30");
      setValue(0);
      setRowsData(data);
    } else if (e.target.value === 20) {
      setDaysEntries("60");
      setValue(20);
    } else if (e.target.value === 40) {
      setDaysEntries("180");
      setValue(40);
    } else if (e.target.value === 60) {
      setDaysEntries("365");
      setValue(60);
    } else if (e.target.value === 100) {
      setDaysEntries("All");
      setValue(100);
    }
  };
  const totalValueInLastXDays = (e: any) => {
    if (e.target.value === 0) {
      setDaysTotalValue("30");
      setValueTotalValue(0);
      // setRowsData(data)
    } else if (e.target.value === 20) {
      setDaysTotalValue("60");
      setValueTotalValue(20);
    } else if (e.target.value === 40) {
      setDaysTotalValue("180");
      setValueTotalValue(40);
    } else if (e.target.value === 60) {
      setDaysTotalValue("365");
      setValueTotalValue(60);
    } else if (e.target.value === 100) {
      setDaysTotalValue("All");
      setValueTotalValue(100);
    }
  };

  const columnDefinition = [
    {
      Header: "Supplier name",
      accessor: "supplierName",
      Sort: true,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      Header: "Last Reconciled on",
      accessor: "lastReconciledOn",
      Sort: true,
      maxWidth: 200,
      minWidth: 200,
      width: 200,
    },
    {
      Header: "Request Ledger",
      accessor: "",
      Sort: true,
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      Cell: () => (
        <Button
          className="dashboard_btn"
          // onClick={() => {
          //   history.push({ pathname: `/${actor.name}/recon360/Summary`, state: { businessPartnerId: value } });
          // }}
        >
          Get Ledger
        </Button>
      ),
    },
    {
      Header: () => <div className="borderRight borderLeft center_align">Closing balance</div>,
      id: "closingBalance",
      columns: [
        {
          Header: "In your ledger",
          accessor: "ownClosingBalance",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "In business partner ledger",
          accessor: "businessPartnerClosingBalance",
          Sort: true,
          maxWidth: 250,
          minWidth: 250,
          width: 250,
        },
        {
          Header: "Difference",
          accessor: "closingDifference",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },
    {
      // Header: "Unreconciled",
      Header: () => <div className="center_align">Unreconciled</div>,
      id: "unreconciled",
      columns: [
        {
          Header: () => (
            <span>
              <p>Total value in last {daysTotalValue} Days</p>
              <Slider
                aria-labelledby="non-linear-slider"
                defaultValue={valueTotalValue}
                // valueLabelFormat={valueLabelFormat}
                // getAriaValueText={valuetext}
                step={null}
                // valueLabelDisplay="auto"
                marks={marks}
                onChange={totalValueInLastXDays}
              />
            </span>
          ),
          accessor: "totalValueInLastXDays",
          id: "totalValueInLastXDays",
          Sort: true,
          maxWidth: 280,
          minWidth: 280,
          width: 280,
        },
        {
          Header: () => (
            <span>
              <p># entries in last {daysEntries} Days</p>
              <Slider
                aria-labelledby="non-linear-slider"
                defaultValue={value}
                // valueLabelFormat={valueLabelFormat}
                // getAriaValueText={valuetext}
                step={null}
                // valueLabelDisplay="auto"
                marks={marks}
                onChange={entriesInLastXDays}
              />
            </span>
          ),
          id: "entriesNumInLastXDays",
          accessor: "entriesNumInLastXDays",
          Sort: true,
          maxWidth: 250,
          minWidth: 250,
          width: 250,
        },
        {
          Header: "View disputes",
          accessor: "viewDisputes",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
          Cell: () => (
            <Button
              className="dashboard_btn"
              // onClick={() => {
              //   history.push({ pathname: `/${actor.name}/recon360/Summary`, state: { businessPartnerId: value } });
              // }}
            >
              View
            </Button>
          ),
        },
      ],
    },
  ];

  // useEffect(() => {
  //   setRowsData(data);
  //   setLoader(false);
  // }, [data]);

  return (
    <LoggedInSkeleton
      topBarButtons={getReconTopBarButtons(
        "Supplier Chain Dashboard",
        actor.name,
        location?.state?.openCollapseObj,
        actor
      )}
    >
      {/* {loader ? (
        <Loader />
      ) : ( */}
      <Grid className="table_list">
        <Grid className="userlisttable">
          <Table id="bcd" columns={columnDefinition} rows={rowsData} sortEnable={true} showPagination={true} />
        </Grid>
      </Grid>
      {/* )} */}
    </LoggedInSkeleton>
  );
};

export default SupplierChainDashboard;
