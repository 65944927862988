// import { useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText } from "@mui/material";
// import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
// import Select, { SelectChangeEvent } from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Nakad from "../../Graphics/Nakad_Blue.png";
import useFetch from "../Common/useFetch";
import "../Login/Login.scss";

const Register = ({ setShowSignUpForm }: any) => {
  const [form, setForm] = useState({
    companyname: "",
    email: "",
    password: undefined,
    confirmPassword: undefined,
    address: "",
    numberOfSuppliers: "",
    designation: "",
    phoneNumber: "",
    username: "",
    // actorType: "",
    gstNumber: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [error, setError] = useState<any>({
    companyname: false,
    email: false,
    password: false,
    confirmPassword: false,
    address: false,
    numberOfSuppliers: false,
    designation: false,
    phoneNumber: false,
    username: false,
    actorType: false,
    gstNumber: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  // const [actorType, setActorType] = useState("");
  const [showForm, setShowForm] = useState(true);

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: false });
  };

  // const handleChangeActorType = (event: SelectChangeEvent) => {
  //   setActorType(event.target.value);
  //   setError({ ...error, [event.target.name]: false });
  // };

  const register = (event: React.FormEvent) => {
    event.preventDefault();
    if (form.companyname.trim() === "" || form.companyname.trim().length > 100) {
      setError({ ...error, companyname: true });
      return;
    } else if (form.address.trim() === "" || form.address.trim().length > 255) {
      setError({ ...error, address: true });
      return;
    } else if (form.numberOfSuppliers.trim() === "") {
      setError({ ...error, numberOfSuppliers: true });
      return;
    } else if (form.designation.trim() === "") {
      setError({ ...error, designation: true });
      return;
    } else if (form.email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
      setError({ ...error, email: true });
      return;
    } else if (form.phoneNumber.trim() === "" || form.phoneNumber.length !== 10) {
      setError({ ...error, phoneNumber: true });
      return;
    } else if (form.username.trim() === "") {
      setError({ ...error, username: true });
      return;
    } else if (form.gstNumber.trim() === "") {
      setError({ ...error, gstNumber: true });
      return;
    } else if (form.password === "" || form.password === undefined) {
      setError({ ...error, password: true });
      return;
    } else if (form.confirmPassword !== form.password) {
      setError({ ...error, confirmPassword: true });
      return;
    }
    // else if (actorType.trim() === "") {
    //   setError({ ...error, actorType: true });
    //   return;
    // }

    useFetch(API_ENDPOINTS.REGISTER.url, "POST", {
      failureMessage: API_ENDPOINTS.REGISTER.failureMessage,
      data: {
        email: form.email,
        password: form.password,
        username: form.username,
        confirmPassword: form.confirmPassword,
        actorInfo: {
          companyName: form.companyname,
          address: form.address,
          numberOfSuppliers: form.numberOfSuppliers,
          userDesignation: form.designation,
          userPhoneNumber: form.phoneNumber,
          gstNumber: form.gstNumber,
        },
        actorType: "Anchor",
      },
      thenCallBack: (response) => {
        // if (response.data.message) {
        //   const flashMsg = document.getElementById("flash-msg");
        //   const msg = document.getElementById("msg");
        //   if (flashMsg == null || msg == null) return;
        //   flashMsg.style.display = "block";
        //   msg.innerHTML = `${response.data.message}`;
        // } else {
        const flashMsg = document.getElementById("flash-msg2");
        const msg = document.getElementById("msg2");
        if (flashMsg === null || msg === null) return;
        flashMsg.style.display = "block";
        msg.innerHTML = `${response.data.message} <br/> Please <span style="cursor:pointer; text-decoration:underline" id="registration">Login</span> here.`;
        document.getElementById("registration").onclick = () => {
          // do something
          setShowSignUpForm(false);
        };
        setShowForm(false);
        // alert("Registration Successful");
        // setShowSignUpForm(false);
        // }
      },
      catchCallBack: (err) => {
        const flashMsg = document.getElementById("flash-msg");
        const msg = document.getElementById("msg");
        if (flashMsg === null || msg === null) return;
        flashMsg.style.display = "block";
        // msg.innerHTML = `Registration Failed! Please Try Again`;
        msg.innerHTML = `${err.response.data.message}`;
      },
    });
  };
  const closeflash = () => {
    const flashMsg = document.getElementById("flash-msg");
    const flashMsg2 = document.getElementById("flash-msg2");
    if (flashMsg === null || flashMsg2 === null) return;
    flashMsg.style.display = "none";
    flashMsg2.style.display = "none";
  };

  const handleClickShowPassword = () => {
    setForm({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setForm({
      ...form,
      showConfirmPassword: !form.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Grid item={true} sm={12} md={12} className="register">
      <Grid item={true} sm={12} md={12} sx={{ mt: 6 }}>
        <img src={Nakad} alt="" className="signuplogo" />
        <p className="enter-info-text">Please enter your user information</p>
        {/* <p >Register</p> */}
      </Grid>
      <Grid item={true} sm={12} md={12} className="tier-1-login" sx={{ ml: 12, mr: 12 }}>
        <Grid item={true} sm={12} md={12}>
          <div className="notification is-danger" id="flash-msg">
            <button className="delete" onClick={closeflash} />
            <p id="msg" />
          </div>
          <div className="notification is-primary" id="flash-msg2">
            {/* <button className="delete" onClick={closeflash} /> */}
            <p id="msg2" />
          </div>
        </Grid>
        {showForm ? (
          <Grid item={true} sm={12} md={12}>
            <Grid item={true} sm={12} md={12}>
              <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-name"
                    label="Company Name"
                    name="companyname"
                    value={form.companyname}
                    onChange={update}
                    required={true}
                    error={error.companyname}
                    helperText={error.companyname ? "Please Enter Company Name" : ""}
                  />
                </Grid>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-address"
                    label="Address"
                    name="address"
                    type="text"
                    value={form.address}
                    onChange={update}
                    required={true}
                    error={error.address}
                    helperText={error.address ? "Please Enter Address" : ""}
                  />
                </Grid>
              </Grid>
              <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-numberOfSuppliers"
                    label="Number of suppliers"
                    name="numberOfSuppliers"
                    type="number"
                    value={form.numberOfSuppliers}
                    onChange={update}
                    required={true}
                    error={error.numberOfSuppliers}
                    helperText={error.numberOfSuppliers ? "Please Enter Number of suppliers" : ""}
                  />
                </Grid>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-designation"
                    label="Designation"
                    name="designation"
                    type="text"
                    value={form.designation}
                    onChange={update}
                    required={true}
                    error={error.designation}
                    helperText={error.designation ? "Please Enter Designation" : ""}
                  />
                </Grid>
              </Grid>
              <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-email"
                    label="Email"
                    name="email"
                    type="email"
                    value={form.email}
                    onChange={update}
                    required={true}
                    error={error.email}
                    helperText={error.email ? "Please Enter Valid Email" : ""}
                  />
                </Grid>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-phoneNumber "
                    label="Phone Number"
                    name="phoneNumber"
                    type="number"
                    value={form.phoneNumber}
                    onChange={update}
                    required={true}
                    error={error.phoneNumber}
                    helperText={error.phoneNumber ? "Please Enter Valid Phone Number" : ""}
                  />
                </Grid>
              </Grid>
              <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-username  "
                    label="User Name"
                    name="username"
                    type="text"
                    value={form.username}
                    onChange={update}
                    required={true}
                    error={error.username}
                    helperText={error.username ? "Please Enter User Name" : ""}
                  />
                </Grid>
                <Grid item={true} sm={12} md={6} p={1}>
                  <TextField
                    fullWidth={true}
                    id="outlined-gstNumber"
                    label="GST Number"
                    name="gstNumber"
                    type="text"
                    value={form.gstNumber}
                    onChange={update}
                    required={true}
                    error={error.gstNumber}
                    helperText={error.gstNumber ? "Please Enter GST Number" : ""}
                  />
                </Grid>
              </Grid>
              <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={6} p={1}>
                  <FormControl fullWidth={true} variant="outlined" required={true} error={error.password}>
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={form.showPassword ? "text" : "password"}
                      value={form.password}
                      name="password"
                      onChange={update}
                      error={error.password}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {form.showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                    <FormHelperText id="outlined-password-helper-text">
                      {error.password ? "Please Enter Password" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item={true} sm={12} md={6} p={1}>
                  <FormControl fullWidth={true} variant="outlined" required={true} error={error.confirmPassword}>
                    <InputLabel htmlFor="outlined-adornment-confirmPassword">Confirm Password</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-confirmPassword"
                      type={form.showConfirmPassword ? "text" : "password"}
                      value={form.confirmPassword}
                      name="confirmPassword"
                      onChange={update}
                      error={error.confirmPassword}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirmPassword visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {form.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Confirm Password"
                    />
                    <FormHelperText id="outlined-confirmPassword-helper-text">
                      {error.confirmPassword ? "Password does not match" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {/* <Grid container={true} item={true} sm={12} md={12}>
                <Grid item={true} sm={12} md={12} p={1}>
                  <FormControl sx={{ minWidth: 150 }} required={true} error={error.actorType}>
                    <InputLabel id="demo-simple-select-helper-label">Actor Type</InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={actorType}
                      label="Actor Type"
                      name="actorType"
                      onChange={handleChangeActorType}
                    >
                      <MenuItem value={"Anchor"}>Anchor</MenuItem>
                      <MenuItem value={"Tier1"}>Tier1</MenuItem>
                      <MenuItem value={"Tier2"}>Tier2</MenuItem>
                    </Select>
                    <FormHelperText id="outlined-password-helper-text">
                      {error.actorType ? "Please Enter Valid Actor Type" : ""}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid> */}
            </Grid>
            {/* </Box> */}

            <Grid item={true} sm={12} md={12}>
              <div className="field is-grouped is-grouped-centered">
                <div className="buttons">
                  <button
                    className="is-info  button "
                    style={{ marginBottom: "15px", marginTop: "20px" }}
                    onClick={register}
                  >
                    Register
                  </button>
                </div>
              </div>

              <small>
                Already have a account
                <a className="help" href="/" style={{ marginTop: "0px" }}>
                  Login here{" "}
                </a>
              </small>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
};

export default Register;
