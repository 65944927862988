import React, { ChangeEvent, useRef } from "react";

const IndeterminateCheckbox = ({
  indeterminate,
  className = "",
  ...rest
}: {
  indeterminate?: boolean;
  className?: string;
  checked?: boolean;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
}) => {
  const ref = useRef<HTMLInputElement>(null);

  if (ref?.current && typeof indeterminate === "boolean") {
    if (indeterminate === true) ref.current.indeterminate = indeterminate;
    else ref.current.indeterminate = null;
  }

  return (
    <label className="checkbox_label">
      <input type="checkbox" ref={ref} className={className + " cursor-pointer"} {...rest} onChange={rest.onChange} />
      <svg
        viewBox="0 0 10 8"
        focusable="false"
        className={ref.current?.indeterminate ? "checkbox_icon indeterminate" : "checkbox_icon"}
        aria-hidden="true"
      >
        <path d="M9.17573 0.762852C9.58285 1.13604 9.61035 1.76861 9.23716 2.17573L4.79324 7.02365C4.19873 7.6722 3.17628 7.6722 2.58177 7.02365L0.762852 5.03937C0.389659 4.63225 0.417162 3.99968 0.824281 3.62649C1.2314 3.2533 1.86397 3.2808 2.23716 3.68792L3.68751 5.27011L7.76285 0.824281C8.13604 0.417162 8.76861 0.389659 9.17573 0.762852Z" />
      </svg>
    </label>
  );
};

export default IndeterminateCheckbox;
