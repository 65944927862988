import { Button, Card, CardContent, FormControl, Grid, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Loader from "../../Common/Loader";
import MonetaryInput from "../../Common/MonetaryInput";

const ReconRuleConfigurations = () => {
  const [loader, setLoader] = useState<boolean>(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const [toleranceForReconciliation, setToleranceForReconciliation] = useState<number>(0);

  useEffect(() => {
    getToleranceAmountForRecon();
  }, []);

  const getToleranceAmountForRecon = () => {
    useFetch(API_ENDPOINTS.GET_ACCOUNTING_TOLERANCE_FOR_RECONCILIATION.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ACCOUNTING_TOLERANCE_FOR_RECONCILIATION.failureMessage,
      thenCallBack: (response) => {
        if (response.data?.toleranceLimitForReconciliation) {
          setToleranceForReconciliation(response.data?.toleranceLimitForReconciliation);
        }
        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const updateToleranceAmountForRecon = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.UPDATE_ACCOUNTING_TOLERANCE_FOR_RECONCILIATION.url, "PUT", {
      failureMessage: API_ENDPOINTS.UPDATE_ACCOUNTING_TOLERANCE_FOR_RECONCILIATION.failureMessage,
      showSuccessToast: true,
      data: { toleranceLimitForReconciliation: toleranceForReconciliation },
      thenCallBack: () => {
        setShowLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Grid className="mb_15">
          <Card>
            <CardContent>
              <div className="d_flex">
                <div style={{ width: "90%" }}>
                  <div className="vertical_center_align">
                    <div className="fs_20 fw_600">Set the tolerance amount, used while matching the entries: </div>
                    <div className="ml_60">
                      <FormControl>
                        <MonetaryInput
                          value={toleranceForReconciliation ? toleranceForReconciliation.toLocaleString("en-IN") : ""}
                          setValue={(value) => setToleranceForReconciliation(value)}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                          }}
                          label="Tolerance Amount"
                        />
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="vertical_center_align">
                  <Button
                    disabled={showLoadingIcon}
                    variant="contained"
                    onClick={() => updateToleranceAmountForRecon()}
                    className="theme_btn"
                  >
                    <LoadingIcon loading={showLoadingIcon} />
                    Save
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      )}
    </div>
  );
};

export default ReconRuleConfigurations;
