import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

const DeleteChecklist = ({ openDeleteChecklist, setOpenDeleteChecklist, deleteChecklist }) => {
  const cancelDeleteChecklist = () => {
    setOpenDeleteChecklist(false);
  };

  return (
    <div>
      <Dialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDeleteChecklist}
        maxWidth="md"
        fullWidth
        sx={{ width: "664px", margin: "auto" }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Are you sure you want to Delete this Checklist?
        </DialogTitle>
        <DialogActions sx={{ p: 2 }}>
          <Button variant="text" style={{ color: "#555555" }} onClick={cancelDeleteChecklist}>
            cancel
          </Button>
          <Button
            variant="contained"
            style={{ background: "#EB4D47" }}
            onClick={() => {
              cancelDeleteChecklist();
              deleteChecklist();
            }}
          >
            delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteChecklist;
