// Mucho Dinero
export const Currencies = [
  { code: "AFN", name: "Afghanistan Afghani" },
  { code: "ALL", name: "Albania Lek" },
  { code: "AMD", name: "Armenian Dram" },
  { code: "ANG", name: "Netherlands Antilles Guilder" },
  { code: "AOA", name: "Angolan Kwanza" },
  { code: "ARS", name: "Argentina Peso" },
  { code: "AUD", name: "Australia Dollar" },
  { code: "AWG", name: "Aruba Guilder" },
  { code: "AZN", name: "Azerbaijan Manat" },
  { code: "BAM", name: "Bosnia and Herzegovina Convertible Mark" },
  { code: "BBD", name: "Barbados Dollar" },
  { code: "BDT", name: "Bangladeshi Taka" },
  { code: "BGN", name: "Bulgaria Lev" },
  { code: "BHD", name: "Bahraini Dinar" },
  { code: "BIF", name: "Burundian Franc" },
  { code: "BMD", name: "Bermuda Dollar" },
  { code: "BND", name: "Brunei Darussalam Dollar" },
  { code: "BOB", name: "Bolivia Bolíviano" },
  { code: "BOV", name: "Bolivian Mvdol" },
  { code: "BRL", name: "Brazil Real" },
  { code: "BSD", name: "Bahamas Dollar" },
  { code: "BTN", name: "Bhutanese Ngultrum" },
  { code: "BWP", name: "Botswana Pula" },
  { code: "BYN", name: "Belarus Ruble" },
  { code: "BZD", name: "Belize Dollar" },
  { code: "CAD", name: "Canada Dollar" },
  { code: "CDF", name: "Congolese Franc" },
  { code: "CHE", name: "WIR Euro" },
  { code: "CHF", name: "Switzerland Franc" },
  { code: "CHW", name: "WIR Franc" },
  { code: "CLF", name: "Chilean Unidad de Fomento" },
  { code: "CLP", name: "Chile Peso" },
  { code: "CNY", name: "China Yuan Renminbi" },
  { code: "COP", name: "Colombia Peso" },
  { code: "COU", name: "Colombian Unidad de Valor Real" },
  { code: "CRC", name: "Costa Rica Colon" },
  { code: "CUC", name: "Cuban Convertible Peso" },
  { code: "CUP", name: "Cuba Peso" },
  { code: "CVE", name: "Cape Verdean Escudo" },
  { code: "CZK", name: "Czech Republic Koruna" },
  { code: "DJF", name: "Djiboutian Franc" },
  { code: "DKK", name: "Denmark Krone" },
  { code: "DOP", name: "Dominican Republic Peso" },
  { code: "DZD", name: "Algerian Dinar" },
  { code: "EGP", name: "Egypt Pound" },
  { code: "ERN", name: "Eritrean Nakfa" },
  { code: "ETB", name: "Ethiopian Birr" },
  { code: "EUR", name: "Euro Member Countries" },
  { code: "FJD", name: "Fiji Dollar" },
  { code: "FKP", name: "Falkland Islands (Malvinas) Pound" },
  { code: "GBP", name: "United Kingdom Pound" },
  { code: "GEL", name: "Georgian Lari" },
  { code: "GGP", name: "Guernsey Pound" },
  { code: "GHS", name: "Ghana Cedi" },
  { code: "GIP", name: "Gibraltar Pound" },
  { code: "GMD", name: "Gambian Dalasi" },
  { code: "GNF", name: "Guinean Franc" },
  { code: "GTQ", name: "Guatemala Quetzal" },
  { code: "GYD", name: "Guyana Dollar" },
  { code: "HKD", name: "Hong Kong Dollar" },
  { code: "HNL", name: "Honduras Lempira" },
  { code: "HRK", name: "Croatia Kuna" },
  { code: "HTG", name: "Haitian Gourde" },
  { code: "HUF", name: "Hungary Forint" },
  { code: "IDR", name: "Indonesia Rupiah" },
  { code: "ILS", name: "Israel Shekel" },
  { code: "IMP", name: "Isle of Man Pound" },
  { code: "INR", name: "Indian Rupee" },
  { code: "IQD", name: "Iraqi Dinar" },
  { code: "IRR", name: "Iran Rial" },
  { code: "ISK", name: "Iceland Krona" },
  { code: "JEP", name: "Jersey Pound" },
  { code: "JMD", name: "Jamaica Dollar" },
  { code: "JOD", name: "Jordanian Dinar" },
  { code: "JPY", name: "Japan Yen" },
  { code: "KES", name: "Kenyan Shilling" },
  { code: "KGS", name: "Kyrgyzstan Som" },
  { code: "KHR", name: "Cambodia Riel" },
  { code: "KMF", name: "Comorian Franc" },
  { code: "KPW", name: "Korea (North) Won" },
  { code: "KRW", name: "Korea (South) Won" },
  { code: "KWD", name: "Kuwaiti Dinar" },
  { code: "KYD", name: "Cayman Islands Dollar" },
  { code: "KZT", name: "Kazakhstan Tenge" },
  { code: "LAK", name: "Laos Kip" },
  { code: "LBP", name: "Lebanon Pound" },
  { code: "LKR", name: "Sri Lanka Rupee" },
  { code: "LRD", name: "Liberia Dollar" },
  { code: "LSL", name: "Lesotho Loti" },
  { code: "LYD", name: "Libyan Dinar" },
  { code: "MAD", name: "Moroccan Dirham" },
  { code: "MDL", name: "Moldovan Leu" },
  { code: "MGA", name: "Malagasy Ariary" },
  { code: "MKD", name: "Macedonia Denar" },
  { code: "MMK", name: "Myanmar Kyat" },
  { code: "MNT", name: "Mongolia Tughrik" },
  { code: "MOP", name: "Macanese Pataca" },
  { code: "MRU", name: "Mauritanian Ouguiya" },
  { code: "MUR", name: "Mauritius Rupee" },
  { code: "MVR", name: "Maldivian Rufiyaa" },
  { code: "MWK", name: "Malawian Kwacha" },
  { code: "MXN", name: "Mexico Peso" },
  { code: "MXV", name: "Mexican Unidad de Inversion" },
  { code: "MYR", name: "Malaysia Ringgit" },
  { code: "MZN", name: "Mozambique Metical" },
  { code: "NAD", name: "Namibia Dollar" },
  { code: "NGN", name: "Nigeria Naira" },
  { code: "NIO", name: "Nicaragua Cordoba" },
  { code: "NOK", name: "Norway Krone" },
  { code: "NPR", name: "Nepal Rupee" },
  { code: "NZD", name: "New Zealand Dollar" },
  { code: "OMR", name: "Oman Rial" },
  { code: "PAB", name: "Panama Balboa" },
  { code: "PEN", name: "Peru Sol" },
  { code: "PGK", name: "Papua New Guinean Kina" },
  { code: "PHP", name: "Philippines Peso" },
  { code: "PKR", name: "Pakistan Rupee" },
  { code: "PLN", name: "Poland Zloty" },
  { code: "PYG", name: "Paraguay Guarani" },
  { code: "QAR", name: "Qatar Riyal" },
  { code: "RON", name: "Romania Leu" },
  { code: "RSD", name: "Serbia Dinar" },
  { code: "RUB", name: "Russia Ruble" },
  { code: "RWF", name: "Rwandan Franc" },
  { code: "SAR", name: "Saudi Arabia Riyal" },
  { code: "SBD", name: "Solomon Islands Dollar" },
  { code: "SCR", name: "Seychelles Rupee" },
  { code: "SDG", name: "Sudanese Pound" },
  { code: "SEK", name: "Sweden Krona" },
  { code: "SGD", name: "Singapore Dollar" },
  { code: "SHP", name: "Saint Helena Pound" },
  { code: "SLL", name: "Sierra Leonean Leone" },
  { code: "SOS", name: "Somalia Shilling" },
  { code: "SRD", name: "Suriname Dollar" },
  { code: "SSP", name: "South Sudanese Pound" },
  { code: "STN", name: "São Tomé and Príncipe Dobra" },
  { code: "SVC", name: "El Salvador Colon" },
  { code: "SYP", name: "Syria Pound" },
  { code: "SZL", name: "Swazi Lilangeni" },
  { code: "THB", name: "Thailand Baht" },
  { code: "TJS", name: "Tajikistani Somoni" },
  { code: "TMT", name: "Turkmenistan Manat" },
  { code: "TND", name: "Tunisian Dinar" },
  { code: "TOP", name: "Tongan Paʻanga" },
  { code: "TRY", name: "Turkey Lira" },
  { code: "TTD", name: "Trinidad and Tobago Dollar" },
  { code: "TVD", name: "Tuvalu Dollar" },
  { code: "TWD", name: "Taiwan New Dollar" },
  { code: "TZS", name: "Tanzanian Shilling" },
  { code: "UAH", name: "Ukraine Hryvnia" },
  { code: "UGX", name: "Ugandan Shilling" },
  { code: "USD", name: "United States Dollar" },
  { code: "USN", name: "United States Dollar (Next day)" },
  { code: "UYI", name: "Uruguayan Peso en Unidades Indexadas" },
  { code: "UYU", name: "Uruguay Peso" },
  { code: "UYW", name: "Unidad Previsional" },
  { code: "UZS", name: "Uzbekistan Som" },
  { code: "VEF", name: "Venezuela Bolívar" },
  { code: "VES", name: "Venezuelan Bolívar Soberano" },
  { code: "VND", name: "Viet Nam Dong" },
  { code: "VUV", name: "Vanuatu Vatu" },
  { code: "WST", name: "Samoan Tala" },
  { code: "XAF", name: "Central African CFA Franc" },
  { code: "XAG", name: "Silver (one troy ounce)" },
  { code: "XAU", name: "Gold (one troy ounce)" },
  { code: "XBA", name: "European Composite Unit" },
  { code: "XBB", name: "European Monetary Unit" },
  { code: "XBC", name: "European Unit of Account 9" },
  { code: "XBD", name: "European Unit of Account 17" },
  { code: "XCD", name: "East Caribbean Dollar" },
  { code: "XDR", name: "Special Drawing Rights" },
  { code: "XOF", name: "West African CFA Franc" },
  { code: "XPD", name: "Palladium (one troy ounce)" },
  { code: "XPF", name: "CFP Franc" },
  { code: "XPT", name: "Platinum (one troy ounce)" },
  { code: "XSU", name: "SUCRE" },
  { code: "XTS", name: "Code reserved for testing purposes" },
  { code: "XUA", name: "ADB Unit of Account" },
  { code: "XXX", name: "No currency" },
  { code: "YER", name: "Yemen Rial" },
  { code: "ZAR", name: "South Africa Rand" },
  { code: "ZMW", name: "Zambian Kwacha" },
  { code: "ZWD", name: "Zimbabwe Dollar" },
  { code: "ZWL", name: "Zimbabwean Dollar" },
] as const;
