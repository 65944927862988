import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useEffect, useMemo } from "react";
import {
  useBlockLayout,
  useExpanded,
  useFilters,
  useGroupBy,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import "./DetailsTable.scss";
import "./Table.scss";

const defaultPropGetter = () => ({});

function DetailsTable({ columns, rows, setExpandedValue, getCellProps = defaultPropGetter, id, paymentGroupBy }: any) {
  const columnsData: any = useMemo(() => columns, [columns]);
  const rowsData: any = useMemo(() => rows, [rows]);

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  useEffect(() => {
    setGroupBy(paymentGroupBy);
    // eslint-disable-next-line
  }, [paymentGroupBy]);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    setGroupBy,
  } = useTable(
    {
      columns: columnsData,
      data: rowsData,
      defaultColumn,
      initialState: { pageSize: 50, expanded: setExpandedValue },
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useBlockLayout,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  return (
    <Card style={{ overflowX: "scroll", padding: "20px" }}>
      {/* <CssBaseline /> */}

      <table {...getTableProps()} id={id} style={{ borderRadius: "1%" }}>
        <thead>
          {headerGroups.map((headerGroup, iHead) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={"thead_tr" + iHead}>
              {headerGroup.headers.map((column, iRow) => (
                <th {...column.getHeaderProps()} key={"th" + iRow}>
                  <div className="column detail_column_head">{column.render("Header")} </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, iRow) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={rowProps.key}>
                <tr
                  {...row.getRowProps()}
                  key={"tbody_tr" + iRow}
                  className={row.isGrouped ? "grouped_row" : "normal_row"}
                  style={{
                    background: row.isExpanded ? "rgb(65 179 124 / 26%)" : "white",
                  }}
                >
                  {row.cells.map((cell, iCell) => {
                    return (
                      <td
                        key={"td" + iCell}
                        {...cell.getCellProps([
                          {
                            className: "cell",
                          },
                          getCellProps(cell),
                        ])}
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <>
                            <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "➖" : "➕"}</span>{" "}
                            {cell.render("Cell")} ({row.subRows.length})
                          </>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render("Aggregated")
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
                {/* {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })} */}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div style={{ paddingLeft: "10px", marginTop: "15px", textAlign: "center" }}>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        &nbsp;
        <span> | Go to page: &nbsp;</span>
        <TextField
          variant="outlined"
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          style={{ width: "50px" }}
          className="table_select_ui"
        />
        &nbsp;
        <select className="table_select_ui" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
          {[10, 25, 50, 200].map((lineItems) => (
            <option key={lineItems} value={lineItems}>
              Show {lineItems}
            </option>
          ))}
        </select>
        &nbsp;
        <Button className="next_prevbtn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        <Button className="next_prevbtn" onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button className="next_prevbtn" onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
        <Button className="next_prevbtn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>
      </div>
    </Card>
  );
}

export default DetailsTable;
