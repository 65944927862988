import { AccountTree, Dashboard, Task } from "@mui/icons-material";
import React from "react";
import { FrontendActor, UserTypes } from "../../entity/models/FrontendActor";
import { uiLoggerName } from "../../Utils/Recon/UiLogger/Constants";
import { AdminTopBar, AdminTopBarType, NavBarStructure } from "./Constants";

export function getAdminTopBarButtons(currentPage: AdminTopBarType) {
  return [
    {
      hasSubTab: false,
      name: "Userlist",
      to: "/admin/userlist",
      className: `navbar-item ${currentPage === AdminTopBar.Userlist ? "this-page" : ""}`,
      icon: <i className="fa-solid fa-list-ul" />,
    },
    {
      hasSubTab: false,
      name: "Register",
      to: "/admin/Register",
      icon: <i className="fa-solid fa-user-plus" />,
    },
    // {
    //   hasSubTab: false,
    //   name: "Recon Mapping",
    //   to: "/admin/reconmapping",
    //   icon: <i className="fa-solid fa-wrench" />,
    // },
    {
      hasSubTab: false,
      name: "Sap Data",
      to: "/admin/sapdata",
      icon: <i className="fa-solid fa-server" />,
    },
    {
      hasSubTab: false,
      name: "Ledger Data",
      to: "/admin/ledgerdata",
      icon: <i className="fas fa-server" />,
    },
    {
      hasSubTab: false,
      name: "Config Templates",
      to: "/admin/configtemplates",
      icon: <i className="fa-solid fa-file-pen" />,
    },
    {
      hasSubTab: false,
      name: "Tally Voucher",
      to: "/admin/tallyvoucher",
      icon: <i className="fa-solid fa-file-contract" />,
    },
    {
      hasSubTab: false,
      name: "Tally Ledger",
      to: "/admin/tallyledger",
      icon: <i className="fa-solid fa-file-invoice" />,
    },
    {
      hasSubTab: false,
      name: "CB Update",
      to: "/admin/CBUpdate",
      icon: <i className="fa-solid fa-pen-to-square" />,
    },
    {
      hasSubTab: false,
      name: "Unpaid Invoice",
      to: "/admin/UnpaidInvoice",
      icon: <i className="fa-solid fa-file-invoice-dollar" />,
    },
    {
      hasSubTab: false,
      name: "Payment Details",
      to: "/admin/PaymentDetails",
      icon: <i className="fa-solid fa-money-check-dollar" />,
    },
    {
      hasSubTab: true,
      name: "Analytics",
      icon: <AccountTree />,
      subMenu: [
        {
          name: "Recon Analytics",
          to: `/admin/reconAnalytics`,
          icon: <i className="fas fa-magic" />,
        },
        {
          name: "PC Analytics",
          to: `/admin/partnerCommunicationAnalytics`,
          icon: <i className="fas fa-envelope" />,
        },
        {
          name: "Task Management ",
          to: `/admin/taskManagement`,
          icon: <i className="fa-solid fa-list-check" />,
        },
      ],
    },
  ] as NavBarStructure[];
}

export function getReconTopBarButtons(
  _pageName: string,
  _actorName: string,
  _openCollapseObj: Record<string, unknown>,
  actor: FrontendActor
) {
  // const { actor } = useContext(userContext);
  const accountName = actor?.name
    .toString()
    .toLowerCase()
    .replace(/[^a-z\d]+/gi, "");

  let ledgerAnalyticsModule: NavBarStructure[] = [];

  if (actor.integration) {
    ledgerAnalyticsModule = [
      {
        hasSubTab: true,
        name: "Ledger Analytics",
        icon: <i className="fas fa-chart-line" />,
        subMenu: [
          {
            name: "Ledger",
            to: `/${accountName}/LedgerAnalytics/Ledger`,
            icon: <i className="fas fa-file-invoice" />,
          },
        ],
      },
    ];
  }
  const allNavBarButtons: NavBarStructure[] = [
    {
      hasSubTab: true,
      name: "Partner Communication",
      icon: <i className="fas fa-envelope" />,
      eventName: uiLoggerName.ui_PartnerCommunicationSelected,
      subMenu: [
        {
          name: "Balance Confirmation",
          to: `/${accountName}/partnerCommunication/balanceConfirmation`,
          icon: (
            <i>
              <Task sx={{ height: "28px !important" }} />
            </i>
          ),
          eventName: uiLoggerName.ui_BCSelected,
        },
        {
          name: "Ledger Request",
          to: `/${accountName}/partnerCommunication/ledgerRequest`,
          icon: <i className="fas fa-file-import" />,
          eventName: uiLoggerName.ui_LRSelected,
        },
        {
          name: "MSME Confirmation",
          to: `/${accountName}/partnerCommunication/msmeConfirmation`,
          icon: <i className="fas fa-check-circle" />,
        },
        {
          name: "Balance Confirmation - Beta",
          to: `/${accountName}/partnerCommunication/balanceConfirmBeta`,
          icon: (
            <i>
              <Task sx={{ height: "28px !important" }} />
            </i>
          ),
        },
        {
          name: "Ledger Request - Beta",
          to: `/${accountName}/partnerCommunication/ledgerReqBeta`,
          icon: <i className="fas fa-file-import" />,
        },
      ],
    },
    {
      hasSubTab: true,
      name: "Automated Recon",
      icon: <i className="fas fa-magic" />,
      subMenu: [
        {
          name: "Ledger",
          to: `/${accountName}/recon360/Summary/Ledger`,
          icon: <i className="fas fa-file-invoice" />,
        },
        {
          name: "Payment Advice",
          to: `/${accountName}/recon360/Summary/PaymentAdvice`,
          icon: <i className="fas fa-receipt" />,
          show: !(
            import.meta.env.VITE_APP_NAKAD_ENV === "production" || import.meta.env.VITE_APP_NAKAD_ENV === "staging"
          ),
        },
      ],
    },
    {
      hasSubTab: true,
      name: "Dispute Resolution",
      icon: (
        <i className="fad">
          <svg className="icon" style={{ height: "30px", width: "30px", fill: "#541c4c" }}>
            <use xlinkHref="#icon-dispute-resolution" />
          </svg>
        </i>
      ),
      subMenu: [
        {
          name: "Closure Tracker",
          to: `/${accountName}/disputeResolution/closureTracker`,
          icon: (
            <i className="fad">
              <svg className="icon" style={{ height: "30px", width: "30px", fill: "#777" }}>
                <use xlinkHref="#icon-closure-tracker" />
              </svg>
            </i>
          ),
        },
      ],
    },
    ...ledgerAnalyticsModule,
    {
      hasSubTab: true,
      name: "Recon MIS",
      icon: (
        <i>
          <Dashboard />
        </i>
      ),
      subMenu: [
        {
          name: "Process Management",
          to: `/${accountName}/ReconMIS/ProcessManagement`,
          icon: <i className="fa-solid fa-chart-simple" />,
        },
        {
          name: "Client Reporting",
          to: `/${accountName}/ReconMIS/ClientReporting`,
          icon: <i className="fa-solid fa-chart-line" />,
          show: actor.userType === UserTypes.CustomerSuccess,
        },
        {
          name: "AP Recon Insights",
          to: `/${accountName}/ReconMIS/APReconInsights`,
          icon: (
            <i className="fad">
              <svg className="icon" style={{ height: "30px", width: "30px", fill: "#777" }}>
                <use xlinkHref="#icon-money-send-svgrepo" />
              </svg>
            </i>
          ),
          show: !(
            import.meta.env.VITE_APP_NAKAD_ENV === "production" || import.meta.env.VITE_APP_NAKAD_ENV === "staging"
          ),
        },
        {
          name: "AR Recon Insights",
          to: `/${accountName}/ReconMIS/ARReconInsights`,
          icon: (
            <i className="fad">
              <svg className="icon" style={{ height: "30px", width: "30px", fill: "#777" }}>
                <use xlinkHref="#icon-money-recive-svgrepo" />
              </svg>
            </i>
          ),
          show: !(
            import.meta.env.VITE_APP_NAKAD_ENV === "production" || import.meta.env.VITE_APP_NAKAD_ENV === "staging"
          ),
        },
      ],
    },
    {
      hasSubTab: false,
      name: "Partner Master",
      to: `/${accountName}/partnerMaster`,
      icon: <i className="fa-solid fa-user-group" />,
    },
    {
      hasSubTab: false,
      name: "Settings",
      to: `/${accountName}/reconSettings`,
      icon: <i className="fas fa-cog" />,
    },
    {
      hasSubTab: false,
      name: "Supply Chain Dashboard",
      to: `/${accountName}/supplierchaindashboard`,
      icon: <i className="fa-solid fa-box" />,
      show: !(import.meta.env.VITE_APP_NAKAD_ENV === "production" || import.meta.env.VITE_APP_NAKAD_ENV === "staging"),
    },
  ];

  const filteredSubMenu = allNavBarButtons?.map((navBarBtns) => {
    if (navBarBtns?.subMenu?.length > 0) {
      navBarBtns.subMenu = navBarBtns.subMenu.filter((subMenu) => (subMenu.show === undefined ? true : subMenu.show));
      return navBarBtns;
    } else return navBarBtns;
  });

  const filteredNavBarButtons = filteredSubMenu?.filter((button) => {
    if (button.show === undefined) {
      return true;
    }
    return button.show;
  });

  return filteredNavBarButtons;
}
