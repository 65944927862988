import DeleteIcon from "@mui/icons-material/Delete";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ConfigPopupAnchorRecon360Context } from "../Transformer/ColumnHeaderConfig";

export type Item = {
  nthIndex: number;
  category: string;
  n: false;
  rule: string;
  ruleId: number;
  string1: true;
  string2: false;
  text: string;
  textToReplaceWith: string;
};

export type DraggableListItemProps = {
  item: Item;
  index: number;
  disabledrag: boolean;
  setDisabledrag: any;
};

export interface Payload {
  n: number;
  ruleId: number;
  string1: string;
  string2: string;
  ruleIndex: number;
  editing: boolean;
}

const DraggableListItem = ({ item, index, disabledrag, setDisabledrag }: DraggableListItemProps) => {
  const providerValue = useContext(ConfigPopupAnchorRecon360Context);

  const [nthIndex, setNthIndex] = useState(null);
  const [text, setText] = useState("");
  const [textToReplaceWith, setTextToReplaceWith] = useState("");

  useEffect(() => {
    setNthIndex(item.nthIndex ? item.nthIndex : null);
    setText(item.text ? item.text : "");
    setTextToReplaceWith(item.textToReplaceWith ? item.textToReplaceWith : "");
  }, [item]);

  const inputCheck = (_, indexinputCheck: number, iteminputCheck: any, items: any) => {
    const payload = {
      ruleId: iteminputCheck.ruleId,
      n: iteminputCheck.n,
      string1: iteminputCheck.string1,
      string2: iteminputCheck.string2,
      nthIndex,
      text,
      textToReplaceWith,
    };
    items[indexinputCheck] = payload;
    providerValue.setItems(items);
  };

  const getSpecificRule = (id: number) => {
    const rulesArray = providerValue.rules;
    const specRule = rulesArray.filter((val: { ruleId: number }) => id === val.ruleId);
    return specRule?.[0].rule;
  };

  return (
    <>
      <Draggable draggableId={index.toString()} index={index} isDragDisabled={disabledrag}>
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            secondaryAction={
              <IconButton edge="end" aria-label="remove">
                <DeleteIcon onClick={() => providerValue.handleRemoveClick(item, index)} />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <MenuIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={getSpecificRule(item.ruleId)} style={{ maxWidth: "300px" }} />
            {item.n && (
              <TextField
                id="number"
                type="number"
                label="number"
                value={nthIndex}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDisabledrag(true);
                  setNthIndex(e.target.value);
                }}
                required={true}
                onBlur={() => {
                  inputCheck(1, index, item, providerValue.items);
                }}
                variant="outlined"
                style={{ margin: "0 5px" }}
              />
            )}
            {item.string1 && (
              <TextField
                id="remove"
                label="remove"
                variant="outlined"
                value={text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDisabledrag(true);
                  setText(e.target.value);
                }}
                required={true}
                onBlur={() => {
                  inputCheck(2, index, item, providerValue.items);
                }}
                style={{ margin: "0 5px" }}
              />
            )}
            {item.string2 && (
              <TextField
                id="replace"
                label="replace"
                variant="outlined"
                value={textToReplaceWith}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDisabledrag(true);
                  setTextToReplaceWith(e.target.value);
                }}
                onBlur={() => {
                  inputCheck(3, index, item, providerValue.items);
                }}
                style={{ margin: "0 5px" }}
              />
            )}
          </ListItem>
        )}
      </Draggable>
    </>
  );
};

export default DraggableListItem;
