import { Add } from "@mui/icons-material";
import { Button } from "@mui/material";
import React from "react";

//this is the col definition for reconSummaryDetail table
export function reconSummaryDetailColDef() {
  return [
    {
      Header: (): any => null,
      id: "expander",
      Cell: ({ row }: any) => {
        if (row.id === "1" || row.id === "2" || row.id === "3" || row.id === "4" || row.id === "5" || row.id === "8") {
          const { isExpanded } = row;
          return (
            <span {...row.getToggleRowExpandedProps()} style={{ display: "flex", alignItems: "center" }}>
              <Add
                sx={{
                  rotate: isExpanded ? "45deg" : "0deg",
                  transition: "all 0.2s ease",
                  cursor: "pointer",
                  color: "#777",
                }}
              />
            </span>
          );
        } else {
          return null;
        }
      },
      // We can override the cell renderer with a SubCell to be used with an expanded row
      SubCell: (): any => null, // No expander on an expanded row
      minWidth: 40,
      maxWidth: 40,
      width: 40,
    },
    {
      Header: "Reconciliation Status",
      accessor: "line_item",
      Sort: true,
      minWidth: 400,
      maxWidth: 400,
      width: 400,
    },

    {
      Header: "Breakup",
      accessor: "sub_total",
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    },
    {
      Header: "Value",
      accessor: "total",
      Sort: true,
      minWidth: 200,
      maxWidth: 200,
      width: 200,
    },
    {
      Header: "See details",
      accessor: "details",
      Sort: false,
      minWidth: 150,
      maxWidth: 150,
      width: 150,
      padding: 0,
      textAlign: "center",
    },
  ];
}

//this is col def used for tds,payment,invoice,dncn mismatch
export function AmountMismatchColDef(adjustEntry, raiseDisputeHandler, matchIdAncessorVal, adjustEntryParameter) {
  return [
    {
      Header: "Reconciliation Status",
      accessor: "item",
      Sort: true,
      columns: [],
      maxWidth: 225,
      minWidth: 225,
      width: 225,
    },
    {
      Header: () => <div className="borderRight">Own</div>,
      id: "own",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      columns: [
        {
          Header: "Match Id",
          accessor: matchIdAncessorVal,
          Sort: true,
          maxWidth: 100,
          minWidth: 100,
          width: 100,
        },
        {
          Header: "Reference #",
          accessor: "doc_own",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value",
          accessor: "value",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
      Sort: true,
    },
    {
      Header: "Business Partner",
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: "doc_businessPartner",
          Sort: true,
          maxWidth: 180,
          minWidth: 180,
          width: 180,
        },
        {
          Header: "Value",
          accessor: "value_businessPartner",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
        {
          Header: "Doc. date",
          accessor: "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 140,
          minWidth: 140,
          width: 140,
        },
      ],
    },
    {
      Header: "Difference",
      accessor: "difference",
      Sort: true,
      columns: [],
      maxWidth: 140,
      minWidth: 140,
      width: 140,
    },
    // -----Dispute UI------

    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => {
        return (
          <span className="dispute_btn">
            {row.original?.entry_adjusted === "Entry Adjusted" ? (
              <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
            ) : row.original?.entry_adjusted === "Dispute Raised" ? (
              <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
            ) : (
              <>
                <Button
                  className="adjust_entry_btn"
                  onClick={() => {
                    adjustEntry(row?.original?.[adjustEntryParameter]);
                  }}
                >
                  Adjust Entry
                </Button>
                <Button
                  className="raise_dispute_btn"
                  onClick={() => {
                    raiseDisputeHandler(row?.original?.[adjustEntryParameter], row?.original?.value);
                  }}
                >
                  Raise Dispute
                </Button>
              </>
            )}
          </span>
        );
      },
    },
  ];
}

//this col def is used for tables when docs are missing either from partner ledger or from own ledger
export function DocNotPresentInLedgerColDef(adjustEntry, raiseDisputeHandler, type, adjustEntryParameter) {
  return [
    {
      Header: type,
      maxWidth: null,
      minWidth: null,
      width: null,
      accessor: "",
      Sort: true,
      columns: [
        {
          Header: "Reference #",
          accessor: type === "Own" ? "doc_own" : "doc_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Value",
          accessor: type === "Own" ? "value" : "value_businessPartner",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
        {
          Header: "Doc. date",
          accessor: type === "Own" ? "doc_date_qtr" : "doc_date_qtr_sup",
          Sort: true,
          maxWidth: 200,
          minWidth: 200,
          width: 200,
        },
      ],
    },

    // -----Dispute UI------
    {
      Header: "Action",
      accessor: "entry_adjusted",
      Sort: true,
      maxWidth: 280,
      minWidth: 280,
      width: 280,
      Cell: ({ row }: any) => (
        <span className="dispute_btn">
          {row.original?.entry_adjusted === "Entry Adjusted" ? (
            <Button style={{ color: "#531C4C" }}>Entry Adjusted</Button>
          ) : row.original?.entry_adjusted === "Dispute Raised" ? (
            <Button style={{ color: "#d6235c" }}>Dispute Raised</Button>
          ) : (
            <>
              <Button className="adjust_entry_btn" onClick={() => adjustEntry(row?.original?.[adjustEntryParameter])}>
                Adjust Entry
              </Button>
              <Button
                className="raise_dispute_btn"
                onClick={() => {
                  raiseDisputeHandler(row?.original?.[adjustEntryParameter], row?.original?.value);
                }}
              >
                Raise Dispute
              </Button>
            </>
          )}
        </span>
      ),
    },
  ];
}
