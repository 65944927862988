import { Close } from "@mui/icons-material";
import {
  Box,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useContext } from "react";
import { FileDetails } from "src/entity/recon-entity/ReconInterfaces";
import { Recon360Context } from "../Recon360";

export default function ReuseOldFilesModal() {
  const {
    openReuseOldFilesModal,
    setOpenReuseOldFilesModal,
    ledgerFiles,
    fileDetails,
    isSheetSelectionFinishRef,
    isFileReuploaded,
    disableUploadBothSide,
    excelFileUploadOnChangeFileName,
    uploadSideRef,
    reUploadLedger,
    ignoreDoctypesList,
  } = useContext(Recon360Context);

  return (
    <MuiDialog open={openReuseOldFilesModal} maxWidth="xs" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Re-use Old Files?</DialogTitle>
        <IconButton
          onClick={() => {
            setOpenReuseOldFilesModal(false);
            ledgerFiles.current = [];
            fileDetails.current = {};
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>Do you want to re-use the already uploaded file(s) as per the updated configuration? </FormLabel>
        <div className="mt_10">
          <p className="mb_5">Following files were uploaded during Last Upload.</p>
          <ol type="1">
            {Object.entries(fileDetails.current as FileDetails)?.map(([fileName, values]) =>
              values.map((value) => (
                <li key={`${fileName}_${value}`} className="ml_30">
                  {fileName} {value}
                </li>
              ))
            )}
          </ol>
        </div>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          className="theme_outline_btn"
          startIcon={<Close />}
          size="small"
          onClick={() => {
            setOpenReuseOldFilesModal(false);
            ledgerFiles.current = [];
            fileDetails.current = {};
          }}
          sx={{ px: 2 }}
        >
          No
        </Button>
        <Button
          className="theme_btn"
          size="small"
          disabled={disableUploadBothSide}
          onClick={() => {
            isSheetSelectionFinishRef.current = true; // dont give again sheet selection modal, use sheet names from server only
            isFileReuploaded.current = true;
            // excelFileUploadOnChange(sortFilesByName(ledgerFiles.current), excelFileUploadOnChangeFileName);
            uploadSideRef.current = excelFileUploadOnChangeFileName;
            ignoreDoctypesList.current = [];
            reUploadLedger("true");
            setTimeout(() => setOpenReuseOldFilesModal(false), 500);
          }}
          sx={{ px: 2 }}
        >
          Yes
        </Button>
      </DialogActions>
    </MuiDialog>
  );
}
