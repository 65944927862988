import InfoIcon from "@mui/icons-material/Info";
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { userContext } from "src/Components/Contexts/userContext";
import {
  BUSINESS_PARTNER_LEDGER,
  Customized,
  DOCUMENT_TYPE_NAMES,
  OWN_LEDGER,
} from "src/Utils/Recon/Recon360/Constants";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";
import { Codes, Element } from "../Transformer/DocumentTypeConfig";

export default function UnassignedDoctypeAdvancedModal() {
  const { actor } = useContext(userContext);
  const {
    openUnassignedDoctypeAdvancedDialog,
    setUnassignedDoctypeAdvancedDialog,
    duplicateTagName,
    subTab,
    setItemForUnassignedDoctypeAdvancedDialog,
    setSelectedDocumentTypeInUnassignedModal,
    isChangedDocTypeOnScreenSuggestionMap,
    documentTypeRow,
    setDocumentTypeRow,
    createdocumentTypeTagsForMappedTags,
    configUserType,
    headerName,
    headerNameBp,
    setHeaderNameOwn,
    setHeaderNameBusiness,
    itemForUnassignedDoctypeAdvancedDialog,
    documentTypesTags,
    extractTextBeforeTilde,
    selectedDocumentTypeInUnassignedModal,
    documentTypes,
    setDuplicateTagName,
    saveRuleApiOwn,
    templateName,
    sanitizeColOwn,
    compareLedgersCheckEndDate,
    saveRuleApi,
    templateNameBp,
    sanitizeColBusiness,
    businessPartnerIntegrationWithERP,
    setOpenDocumentTypeDialog,
    fetchDataFromERP,
    ignoreDoctypesList,
    reUploadLedger,
    openUnassignedDoctypeDialogFromView,
  } = useContext(Recon360Context);
  const [checkedAddParticular, setCheckedAddParticular] = useState<boolean>(true);
  const [addParticularText, setAddParticularText] = useState<string>("");
  const [addParticularTextArr, setAddParticularTextArr] = useState<string[]>([]);
  const [checkedExcludeParticular, setCheckedExcludeParticular] = useState<boolean>(false);
  const [excludeParticularText, setExcludeParticularText] = useState<string>("");
  const [excludeParticularTextArr, setExcludeParticularTextArr] = useState<string[]>([]);
  const [transactionValue, setTransactionValue] = useState<number>(0);
  const [documentTypeRowInUnassignedAdvancedModal, setDocumentTypeRowInUnassignedAdvancedModal] = useState([]);
  const [documentTypesTagsInUnassignedAdvancedModal, setDocumentTypesTagsInUnassignedAdvancedModal] = useState<any>([]);
  const [checkedReference, setCheckedReference] = React.useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);

  const handleChangeReference = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedReference(event.target.checked);
  };

  const handleChangeAddParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedAddParticular(event.target.checked);
  };

  const handleChangeExcludeParticular = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedExcludeParticular(event.target.checked);
  };

  const handleChangeTransactionValue = (event: any) => {
    setTransactionValue(event.target.value);
  };

  const createdocumentTypeTags = (newtemp: any) => {
    const obj = newtemp.map((item: any) => ({
      name: item.name,
      allTransaction: item.codes.filter((val: any) => val.sign === 0),
      debit: item.codes.filter((val: any) => val.sign === 1),
      credit: item.codes.filter((val: any) => val.sign === 2),
    }));
    setDocumentTypesTagsInUnassignedAdvancedModal(obj);
  };
  const delRow = (element: any, ind: number) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRowInUnassignedAdvancedModal;
    const user = newData.find((d) => d.name === element.name);
    Object.assign(user, element);
    const resultWithCodes = [...newData]?.filter((obj) => obj?.codes?.length > 0);
    setDocumentTypeRowInUnassignedAdvancedModal(resultWithCodes);
    createdocumentTypeTags(resultWithCodes);
  };

  const replaceWord = (word: string) => {
    const replacements = {
      Invoice: "invoice",
      Payment: "payment",
      "Discount/Rebate": "payment",
      "DN/CN": "debitAndCreditNotes",
      Reversal: "reversal",
      "Inter Document": "interDocument",
      TDS: "tds",
      "Ignore this time": "Ignore this time",
      "Never ask again": "Never ask again",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };
  const rverseReplaceWord = (word: string) => {
    const replacements = {
      invoice: "Invoice",
      payment: subTab === "Payment Advice" ? "Discount/Rebate" : "Payment",
      debitAndCreditNotes: "DN/CN",
      reversal: "Reversal",
      interDocument: "Inter Document",
      tds: "TDS",
      "Ignore this time": "Ignore this time",
      "Never ask again": "Never ask again",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };

  const addCodesToHeaderNameJSON = (codesArray: any, jsonObject: any) => {
    // Loop through each code object in the codesArray
    codesArray.forEach((codeObj: any) => {
      // Find the keyName in the jsonObject that matches the name of the codeObj
      const keyName = Object.keys(jsonObject).find((key) => key.toLowerCase() === codeObj.keyName.toLowerCase());
      if (keyName) {
        // Push the codeObj into the codes array of the matching keyName object
        jsonObject[keyName] = [...jsonObject[keyName], ...codeObj.codes];
      }
    });
    return jsonObject;
  };

  const resetStatesOfUnassignedDoctypeAdvancedModal = () => {
    setUnassignedDoctypeAdvancedDialog(false);
    setItemForUnassignedDoctypeAdvancedDialog(null);
    setCheckedAddParticular(true);
    setAddParticularText("");
    setAddParticularTextArr([]);
    setCheckedExcludeParticular(false);
    setExcludeParticularTextArr([]);
    setTransactionValue(0);
    setSelectedDocumentTypeInUnassignedModal(null);
    isChangedDocTypeOnScreenSuggestionMap.current = false;
    setDocumentTypeRowInUnassignedAdvancedModal([]);
    setDocumentTypesTagsInUnassignedAdvancedModal([]);
  };

  const delRowAlreadyMapped = (element: any, ind: number, item: any) => {
    element.codes.splice(ind, 1);
    const newData = documentTypeRow;
    const resultWithCodes = [...newData]?.filter((obj) => obj?.codes?.length > 0);
    setDocumentTypeRow(resultWithCodes);
    createdocumentTypeTagsForMappedTags(resultWithCodes);
    if (configUserType === OWN_LEDGER) {
      const deletedTagFromheaderName = removeObjectByProperties(headerName, item);
      setHeaderNameOwn(deletedTagFromheaderName);
    } else if (configUserType === BUSINESS_PARTNER_LEDGER) {
      const deletedTagFromheaderName = removeObjectByProperties(headerNameBp, item);
      setHeaderNameBusiness(deletedTagFromheaderName);
    }
  };

  const removeObjectByProperties = (obj: any, properties: any) => {
    for (const key in obj) {
      if (Array.isArray(obj[key])) {
        obj[key] = obj[key].filter((item: any) => {
          for (const prop in properties) {
            if (item[prop] !== properties[prop]) {
              return true;
            }
          }
          return false;
        });
      }
    }
    return obj;
  };
  const checkIsDuplicateSign = (oldSign: 0 | 1 | 2, newSign: 0 | 1 | 2) => {
    if (oldSign === 0) return true;
    else if (oldSign === newSign) return true;
    else if (oldSign !== newSign && newSign === 0) return true;
    else return false;
  };

  const checkArrayValueEqual = (ComparandArray: string[], ComparatorArray: string[]) => {
    if (ComparandArray.length !== ComparatorArray.length) return false;
    ComparandArray.sort();
    ComparatorArray.sort();
    for (let i = 0; i < ComparandArray.length; i++) {
      if (ComparandArray[i].trim() !== ComparatorArray[i].trim()) return false;
    }
    return true;
  };
  const checkIsDuplicateCode = (newCode: Codes, docTypeCodes: Element[]) => {
    let isDuplicateCode = false;
    let duplicateDocTypeName = "";

    docTypeCodes.forEach((docTypeCode) => {
      docTypeCode.codes.forEach((existingCode) => {
        if (existingCode.code.trim() === newCode.code.trim()) {
          if (
            checkArrayValueEqual(existingCode.addParticulars, newCode.addParticulars) &&
            checkArrayValueEqual(existingCode.excludeParticulars, newCode.excludeParticulars)
          ) {
            const duplicateSign = checkIsDuplicateSign(existingCode.sign as 0 | 1 | 2, newCode.sign as 0 | 1 | 2);

            if (duplicateSign) isDuplicateCode = true;
            if (isDuplicateCode) {
              duplicateDocTypeName = docTypeCode.name[0].toUpperCase() + docTypeCode.name.slice(1);
              return;
            }
          }
        }
      });
    });

    return { isDuplicateCode, duplicateDocTypeName };
  };

  return (
    <Dialog open={openUnassignedDoctypeAdvancedDialog} onClose={() => setUnassignedDoctypeAdvancedDialog(true)}>
      <header className="modal-card-head">
        <p className="fs_16 fw_900">
          Define Unassigned Doc type using Particulars/Narration:{" "}
          <span className="err_text">{itemForUnassignedDoctypeAdvancedDialog?.occurenceCount} Entries missing</span>
        </p>
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            resetStatesOfUnassignedDoctypeAdvancedModal();
          }}
        />
      </header>
      <section className="modal-card-body">
        <div>
          {duplicateTagName ? (
            <div className="mb_15 error_text_config">
              This tag is already mapped in{" "}
              {duplicateTagName === "Payment"
                ? subTab === "Payment Advice"
                  ? "Discount/Rebate"
                  : "Payment"
                : duplicateTagName}{" "}
            </div>
          ) : (
            ""
          )}
          <div>
            {/* show here already added tags and also tags after Add more */}
            {/* already mapped tags from config */}
            {documentTypeRow.map((element, docTypeIndex) => {
              return (
                <Grid key={`documentTypeRowAlreadyMapped_${docTypeIndex}`}>
                  <div className="mb_15 mt_10 unassignedAdvanceDocTypeName">{rverseReplaceWord(element?.name)}</div>
                  <div>
                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTags[docTypeIndex].allTransaction[0] !== "" &&
                    documentTypesTags[docTypeIndex].allTransaction.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">All Transaction : </span>
                        {documentTypesTags[docTypeIndex].allTransaction.map((item: any, itemInd: number) => {
                          return (
                            <React.Fragment key={item + itemInd}>
                              {item?.addParticulars &&
                              item?.addParticulars.length > 0 &&
                              item?.excludeParticulars &&
                              item?.excludeParticulars.length > 0 ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        delRowAlreadyMapped(element, itemInd, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        delRowAlreadyMapped(element, itemInd, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 &&
                                (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        delRowAlreadyMapped(element, itemInd, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        delRowAlreadyMapped(element, itemInd, item);
                                      }}
                                    />
                                  </button>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : null}

                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTags[docTypeIndex].debit[0] !== "" &&
                    documentTypesTags[docTypeIndex].debit.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">Debit Transaction: </span>
                        {documentTypesTags[docTypeIndex].debit.map((item: any, itemInd: number) => {
                          return (
                            <React.Fragment key={item + itemInd}>
                              {item?.addParticulars &&
                              item?.addParticulars.length > 0 &&
                              item?.excludeParticulars &&
                              item?.excludeParticulars.length > 0 ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        const indexForDelete =
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0
                                            ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                            : itemInd;
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        const indexForDelete =
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0
                                            ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                            : itemInd;
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 &&
                                (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        const indexForDelete =
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0
                                            ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                            : itemInd;
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        const indexForDelete =
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0
                                            ? itemInd + documentTypesTags[docTypeIndex].allTransaction.length
                                            : itemInd;
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : null}

                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTags[docTypeIndex].credit[0] !== "" &&
                    documentTypesTags[docTypeIndex].credit.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">Credit Transaction: </span>
                        {documentTypesTags[docTypeIndex].credit.map((item: any, itemInd: number) => {
                          return (
                            <React.Fragment key={item + itemInd}>
                              {item?.addParticulars &&
                              item?.addParticulars.length > 0 &&
                              item?.excludeParticulars &&
                              item?.excludeParticulars.length > 0 ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        let indexForDelete;
                                        if (
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                          documentTypesTags[docTypeIndex].debit.length > 0
                                        ) {
                                          indexForDelete =
                                            itemInd +
                                            documentTypesTags[docTypeIndex].allTransaction.length +
                                            documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                          indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                          indexForDelete =
                                            itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                        } else {
                                          indexForDelete = itemInd;
                                        }
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.addParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "#00c1ff" }} key={particularInd}>
                                            +{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        let indexForDelete;
                                        if (
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                          documentTypesTags[docTypeIndex].debit.length > 0
                                        ) {
                                          indexForDelete =
                                            itemInd +
                                            documentTypesTags[docTypeIndex].allTransaction.length +
                                            documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                          indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                          indexForDelete =
                                            itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                        } else {
                                          indexForDelete = itemInd;
                                        }
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 &&
                                (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                      return (
                                        <React.Fragment key={particular + particularInd}>
                                          <span style={{ color: "red" }} key={particularInd}>
                                            -{particular}
                                          </span>
                                        </React.Fragment>
                                      );
                                    })}
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        let indexForDelete;
                                        if (
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                          documentTypesTags[docTypeIndex].debit.length > 0
                                        ) {
                                          indexForDelete =
                                            itemInd +
                                            documentTypesTags[docTypeIndex].allTransaction.length +
                                            documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                          indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                          indexForDelete =
                                            itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                        } else {
                                          indexForDelete = itemInd;
                                        }
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="config_tags" key={itemInd}>
                                    <span>{item.code}</span>
                                    <button
                                      className="delete config_delete"
                                      aria-label="close"
                                      onClick={() => {
                                        let indexForDelete;
                                        if (
                                          documentTypesTags[docTypeIndex].allTransaction.length > 0 &&
                                          documentTypesTags[docTypeIndex].debit.length > 0
                                        ) {
                                          indexForDelete =
                                            itemInd +
                                            documentTypesTags[docTypeIndex].allTransaction.length +
                                            documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].allTransaction.length === 0) {
                                          indexForDelete = itemInd + documentTypesTags[docTypeIndex].debit.length;
                                        } else if (documentTypesTags[docTypeIndex].debit.length === 0) {
                                          indexForDelete =
                                            itemInd + documentTypesTags[docTypeIndex].allTransaction.length;
                                        } else {
                                          indexForDelete = itemInd;
                                        }
                                        delRowAlreadyMapped(element, indexForDelete, item);
                                      }}
                                    />
                                  </button>
                                </>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </Grid>
              );
            })}
            {documentTypeRowInUnassignedAdvancedModal.map((element, docTypeIndex) => {
              return (
                <div key={`documentTypeRowInUnassignedAdvancedModal_${docTypeIndex}`}>
                  <div className="mb_15 mt_10 unassignedAdvanceDocTypeName">{element?.name}</div>
                  <div>
                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction[0] !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">All Transaction : </span>
                        {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction.map(
                          (item: any, itemInd: number) => {
                            return (
                              <React.Fragment key={item + itemInd}>
                                {item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          delRow(element, itemInd);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          delRow(element, itemInd);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 &&
                                  (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          delRow(element, itemInd);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          delRow(element, itemInd);
                                        }}
                                      />
                                    </button>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    ) : null}

                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit[0] !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">Debit Transaction: </span>
                        {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.map(
                          (item: any, itemInd: number) => {
                            return (
                              <React.Fragment key={item + itemInd}>
                                {item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          const indexForDelete =
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0
                                              ? itemInd +
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length
                                              : itemInd;
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          const indexForDelete =
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0
                                              ? itemInd +
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length
                                              : itemInd;
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 &&
                                  (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          const indexForDelete =
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0
                                              ? itemInd +
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length
                                              : itemInd;
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          const indexForDelete =
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0
                                              ? itemInd +
                                                documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                  .length
                                              : itemInd;
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    ) : null}

                    {element.codes.length > 0 &&
                    element.codes[0].code !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit[0] !== "" &&
                    documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit.length > 0 ? (
                      <div className="config_tags_div_docType ">
                        <span className="fw_600">Credit Transaction: </span>
                        {documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].credit.map(
                          (item: any, itemInd: number) => {
                            return (
                              <React.Fragment key={item + itemInd}>
                                {item?.addParticulars &&
                                item?.addParticulars.length > 0 &&
                                item?.excludeParticulars &&
                                item?.excludeParticulars.length > 0 ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          let indexForDelete;
                                          if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0 &&
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length;
                                          } else {
                                            indexForDelete = itemInd;
                                          }
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.addParticulars &&
                                  item?.addParticulars.length > 0 &&
                                  (!item?.excludeParticulars || item?.excludeParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.addParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "#00c1ff" }} key={particularInd}>
                                              +{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          let indexForDelete;
                                          if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0 &&
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length;
                                          } else {
                                            indexForDelete = itemInd;
                                          }
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : item?.excludeParticulars &&
                                  item?.excludeParticulars.length > 0 &&
                                  (!item?.addParticulars || item?.addParticulars?.length === 0) ? (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      {item?.excludeParticulars.map((particular: string, particularInd: number) => {
                                        return (
                                          <React.Fragment key={particular + particularInd}>
                                            <span style={{ color: "red" }} key={particularInd}>
                                              -{particular}
                                            </span>
                                          </React.Fragment>
                                        );
                                      })}
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          let indexForDelete;
                                          if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0 &&
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length;
                                          } else {
                                            indexForDelete = itemInd;
                                          }
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button className="config_tags" key={itemInd}>
                                      <span>{item.code}</span>
                                      <button
                                        className="delete config_delete"
                                        aria-label="close"
                                        onClick={() => {
                                          let indexForDelete;
                                          if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length > 0 &&
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length > 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                              .length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length;
                                          } else if (
                                            documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].debit.length === 0
                                          ) {
                                            indexForDelete =
                                              itemInd +
                                              documentTypesTagsInUnassignedAdvancedModal[docTypeIndex].allTransaction
                                                .length;
                                          } else {
                                            indexForDelete = itemInd;
                                          }
                                          delRow(element, indexForDelete);
                                        }}
                                      />
                                    </button>
                                  </>
                                )}
                              </React.Fragment>
                            );
                          }
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
          <hr style={{ height: "1px", backgroundColor: "gray" }} />
          <div className="vertical_center_align gap_30 mt_20">
            <span>
              <Chip label={extractTextBeforeTilde(itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText)} />
            </span>
            <span>
              <Select
                size="small"
                name="documentType"
                variant="outlined"
                sx={{ minWidth: "200px" }}
                displayEmpty={true}
                value={selectedDocumentTypeInUnassignedModal}
                renderValue={(value) =>
                  value === "Ignore this time" || value === "Never ask again" ? "Select Document Type" : value
                }
                onChange={(e) => {
                  setSelectedDocumentTypeInUnassignedModal(e.target.value);
                }}
              >
                <MenuItem disabled={true}>Select Document Type</MenuItem>
                {documentTypes
                  ?.filter((val) => val !== "Ignore this time" && val !== "Never ask again")
                  ?.map((val, i) => (
                    <MenuItem
                      key={i}
                      value={val}
                      style={{
                        color: val === "Ignore this time" ? "orange" : val === "Never ask again" ? "red" : "",
                      }}
                    >
                      {val}
                    </MenuItem>
                  ))}
              </Select>
            </span>
          </div>
          <div className="space_between mt_20">
            <div>
              <div className="mt_10">
                <div>
                  <span>
                    <Checkbox
                      checked={checkedAddParticular}
                      onChange={handleChangeAddParticular}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </span>
                  <span className="fw_600">Add Particular/ Narration</span>
                  <span>
                    <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                      <IconButton>
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </span>
                </div>
                {checkedAddParticular && (
                  <div>
                    <TextField
                      id="outlined-name-addParticularText"
                      placeholder="Enter Particular/ Narration"
                      fullWidth={true}
                      value={addParticularText}
                      onChange={(e: any) => {
                        const strAdd = e.target.value.replace(/\s\s+/g, " ");
                        const arrAdd = strAdd.split(",");
                        const arrWithoutEmptyStringAdd = arrAdd.filter((entry: any) => entry.trim() !== "");
                        const arrWithTrimmedStringAdd = arrWithoutEmptyStringAdd.map((stringAdd: any) =>
                          stringAdd.trim()
                        );
                        const uniqArrAdd: any = [...new Set(arrWithTrimmedStringAdd)];
                        setAddParticularTextArr(uniqArrAdd);
                        setAddParticularText(e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="mt_10">
                <div>
                  <span>
                    <Checkbox
                      checked={checkedExcludeParticular}
                      onChange={handleChangeExcludeParticular}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </span>
                  <span className="fw_600">Exclude Particular/ Narration</span>
                  <span>
                    <Tooltip title="Please click on the check box and add the word that you want to match that will appear in particular/narration column. You can add multiple commas separated words if you want to match multiple words. For eg. Add: bank , TDS for ‘Particular’">
                      <IconButton>
                        <InfoIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </span>
                </div>
                {checkedExcludeParticular && (
                  <div>
                    <TextField
                      id="outlined-name-excludeParticularText"
                      placeholder="Enter Particular/ Narration"
                      fullWidth={true}
                      value={excludeParticularText}
                      onChange={(e: any) => {
                        const str = e.target.value.replace(/\s\s+/g, " ");
                        const arr = str.split(",");
                        const arrWithoutEmptyString = arr.filter((entry: any) => entry.trim() !== "");
                        const arrWithTrimmedStringExclude = arrWithoutEmptyString.map((stringExclude: any) =>
                          stringExclude.trim()
                        );
                        const uniqArr: any = [...new Set(arrWithTrimmedStringExclude)];
                        setExcludeParticularTextArr(uniqArr);
                        setExcludeParticularText(e.target.value);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="mt_10 ml_10">
                <FormControlLabel
                  className="fw_600"
                  control={
                    <Checkbox
                      checked={checkedReference}
                      onChange={handleChangeReference}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<Typography style={{ fontWeight: 500 }}>Include Reference Also</Typography>}
                />
              </div>
            </div>
            <div>
              <div>Select transaction</div>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={transactionValue}
                  onChange={(e) => {
                    handleChangeTransactionValue(e);
                  }}
                >
                  <FormControlLabel value={0} control={<Radio />} label="All Transaction" />
                  <FormControlLabel value={1} control={<Radio />} label="Debit Transaction" />
                  <FormControlLabel value={2} control={<Radio />} label="Credit Transaction" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </section>
      <footer className="modal-card-foot space_between">
        <div>
          <Button
            className="theme_outline_btn"
            disabled={
              selectedDocumentTypeInUnassignedModal === "Ignore this time" ||
              selectedDocumentTypeInUnassignedModal === "Never ask again"
            }
            onClick={() => {
              // check duplicate code here
              const currentCode = {
                code: extractTextBeforeTilde(itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText),
                sign: Number(transactionValue),
                addParticulars: addParticularTextArr,
                excludeParticulars: excludeParticularTextArr,
                group: { groupId: "All", groupName: "All" },
                useReference: checkedReference,
              };

              const DocTypes = DOCUMENT_TYPE_NAMES;
              let DocTypeRowsFromHeaderName: Element[];
              const DocTypeRowsFromUnassignedAdvanceModal: Element[] = DocTypes.map((docType) => {
                return {
                  name: docType,
                  codes: documentTypeRowInUnassignedAdvancedModal.find((row) => row?.keyName === docType)?.codes || [],
                };
              });
              // check duplicate code START
              if (configUserType === OWN_LEDGER) {
                DocTypeRowsFromHeaderName = DocTypes.map((docType) => {
                  return { name: docType, codes: headerName[docType] };
                });
              } else if (configUserType === BUSINESS_PARTNER_LEDGER) {
                DocTypeRowsFromHeaderName = DocTypes.map((docType) => {
                  return { name: docType, codes: headerNameBp[docType] };
                });
              }

              const isDuplicateAtHeaderName = checkIsDuplicateCode(currentCode, DocTypeRowsFromHeaderName);

              const isDuplicateAtUnassignedAdvanceModal = checkIsDuplicateCode(
                currentCode,
                DocTypeRowsFromUnassignedAdvanceModal
              );

              if (isDuplicateAtHeaderName.isDuplicateCode) {
                setDuplicateTagName(isDuplicateAtHeaderName.duplicateDocTypeName);
              } else if (isDuplicateAtUnassignedAdvanceModal.isDuplicateCode) {
                setDuplicateTagName(isDuplicateAtUnassignedAdvanceModal.duplicateDocTypeName);
              }

              // check duplicate code END, if not duplicate at either case, allow saving of code

              if (!isDuplicateAtHeaderName.isDuplicateCode && !isDuplicateAtUnassignedAdvanceModal.isDuplicateCode) {
                const data = [
                  ...documentTypeRowInUnassignedAdvancedModal,
                  {
                    name: selectedDocumentTypeInUnassignedModal,
                    keyName: replaceWord(selectedDocumentTypeInUnassignedModal),
                    codes: [
                      {
                        code: extractTextBeforeTilde(itemForUnassignedDoctypeAdvancedDialog?.unidentifiedText),
                        sign: Number(transactionValue),
                        addParticulars: addParticularTextArr,
                        excludeParticulars: excludeParticularTextArr,
                        group: { groupId: "All", groupName: "All" },
                        useReference: checkedReference,
                      },
                    ],
                  },
                ];

                const resultDocumentTypeRowInUnassignedAdvancedModal = data.reduce((acc, obj) => {
                  const existingObj = acc.find((item: any) => item.name === obj.name);
                  if (existingObj) {
                    existingObj.codes.push(...obj.codes);
                  } else {
                    acc.push(obj);
                  }
                  return acc;
                }, []);
                setDocumentTypeRowInUnassignedAdvancedModal(resultDocumentTypeRowInUnassignedAdvancedModal);
                createdocumentTypeTags(resultDocumentTypeRowInUnassignedAdvancedModal);
                setAddParticularText("");
                setExcludeParticularText("");
                setAddParticularTextArr([]);
                setExcludeParticularTextArr([]);
                setDuplicateTagName("");
                setCheckedReference(false);
              }
            }}
          >
            Add More
          </Button>
        </div>
        <div>
          <Button
            className="theme_btn"
            disabled={saveBtnLoading}
            onClick={async () => {
              setSaveBtnLoading(true);
              if (configUserType === OWN_LEDGER) {
                const modifiedJSONHeaderName = await addCodesToHeaderNameJSON(
                  documentTypeRowInUnassignedAdvancedModal,
                  headerName
                );
                await saveRuleApiOwn(
                  modifiedJSONHeaderName,
                  templateName || Customized,
                  sanitizeColOwn,
                  openUnassignedDoctypeDialogFromView ? false : true
                );
                if (actor.integration) {
                  compareLedgersCheckEndDate();
                }
              } else if (configUserType === BUSINESS_PARTNER_LEDGER) {
                const modifiedJSONHeaderName = await addCodesToHeaderNameJSON(
                  documentTypeRowInUnassignedAdvancedModal,
                  headerNameBp
                );
                await saveRuleApi(
                  modifiedJSONHeaderName,
                  templateNameBp || Customized,
                  sanitizeColBusiness,
                  openUnassignedDoctypeDialogFromView ? false : true
                );
                if (businessPartnerIntegrationWithERP) {
                  compareLedgersCheckEndDate();
                }
              }
              setOpenDocumentTypeDialog(false);
              setSaveBtnLoading(false);
              resetStatesOfUnassignedDoctypeAdvancedModal();

              if (!fetchDataFromERP) {
                ignoreDoctypesList.current = [];
                reUploadLedger("false");
              }
            }}
          >
            <LoadingIcon loading={saveBtnLoading} />
            Save and proceed
          </Button>
        </div>
      </footer>
    </Dialog>
  );
}
