import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  IconButton,
} from "@mui/material";
import React, { useContext } from "react";
import { ConfigPopupAnchorRecon360Context } from "./ColumnHeaderConfig";

const ReuseConfigModal = () => {
  const { openReuseConfigModal, setOpenReuseConfigModal, setOpenReuseSanitizerModal } = useContext(
    ConfigPopupAnchorRecon360Context
  );
  const closeReuseConfigModal = () => {
    setOpenReuseConfigModal(false);
  };
  return (
    <div>
      <MuiDialog open={openReuseConfigModal} maxWidth="xs" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle>Create a template?</DialogTitle>
          <IconButton
            onClick={() => {
              closeReuseConfigModal();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
          <FormLabel>Do you want to create a template for this config?</FormLabel>
        </DialogContent>
        <DialogActions className="dialog_footer space_around">
          <Button
            className="theme_btn"
            size="small"
            sx={{
              ".MuiButton-startIcon": {
                display: "inherit",
                marginRight: 0,
                marginLeft: 0,
              },
            }}
            onClick={() => {
              closeReuseConfigModal();
              setOpenReuseSanitizerModal(true);
            }}
          >
            Yes
          </Button>
          <Button
            className="theme_btn theme_btn_warning"
            size="small"
            onClick={() => {
              closeReuseConfigModal();
            }}
          >
            No
          </Button>
        </DialogActions>
      </MuiDialog>
    </div>
  );
};

export default ReuseConfigModal;
