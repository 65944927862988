import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context, StateDispatch } from "../Recon360";

interface UpdateBulkTasksConfirmationModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  title: string;
  content: any;
  updateBulkTasks: any;
  isLoading: boolean;
}
export default function UpdateBulkTasksConfirmationModal({
  open,
  setOpen,
  title,
  content,
  updateBulkTasks,
  isLoading,
}: UpdateBulkTasksConfirmationModalProps) {
  const { companyId, branchCode } = useContext(Recon360Context);
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Alert severity="info">{content}</Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CANCEL_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CANCEL_CLICK.message,
              }
            );
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_UPDATETASKS_UPDATE_CLICK.message,
              }
            );
            updateBulkTasks();
          }}
        >
          <LoadingIcon loading={isLoading} />
          Update
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
