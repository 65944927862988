import { Button, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import MuiDialog from "@mui/material/Dialog";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import CustomToast from "src/Components/CustomToast";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { Recon360Context } from "../Recon360";

export const TICKET_PRIORITIES = ["Medium", "High"];

export const CreateTicketModal = (props) => {
  const { companyId, branchCode, businessPartnerSelected } = useContext(Recon360Context);

  const [makerId, setMakerId] = useState(null);
  const [checkerId, setCheckerId] = useState(null);
  const [approverId, setApproverId] = useState(null);
  const [status, setStatus] = useState(null);
  const [priority, setPriority] = useState(TICKET_PRIORITIES[0]);
  const [dueDate, setDueDate] = useState(null);

  const handleCreateTicket = async () => {
    if (!makerId || !checkerId || !status) {
      toast.error(<CustomToast message="Maker, Checker and Status are required fields." />);
      return;
    }

    await props.createTicket({
      makerId,
      checkerId,
      approverId,
      status,
      priority,
      dueDateISO: dueDate ? new Date(dueDate).toISOString().slice(0, -1) + new Date().toString().slice(28, 33) : null,
    });

    // clear all state
    setMakerId(null);
    setCheckerId(null);
    setApproverId(null);
    setStatus(null);
    setPriority(TICKET_PRIORITIES[0]);
    setDueDate(null);
    props.setCreateNewTicket(false);
  };

  return (
    <MuiDialog open={props.createNewTicket}>
      <DialogTitle className="dialog_header">
        <p className="fs_24">New Recon Task</p>
        <button
          className="delete ml_20"
          aria-label="close"
          onClick={() => {
            // clear all state
            setMakerId(null);
            setCheckerId(null);
            setApproverId(null);
            setStatus(null);
            setPriority(TICKET_PRIORITIES[0]);
            setDueDate(null);
            props.setCreateNewTicket(false);
          }}
        />
      </DialogTitle>
      <DialogContent>
        <div>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Maker* :</div>
            <div className="w_400 category_textFeild_width_390">
              <div className="control has-icons-right">
                <div className="select" style={{ display: "flex" }}>
                  <select
                    className="input input-box"
                    value={makerId || ""}
                    onChange={(e) => setMakerId(parseInt(e.target.value))}
                  >
                    <option value={""}>Select Maker</option>
                    {props.ticketUsers.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Checker* :</div>
            <div className="w_400 category_textFeild_width_390">
              <div className="control has-icons-right">
                <div className="select" style={{ display: "flex" }}>
                  <select
                    className="input input-box"
                    value={checkerId || ""}
                    onChange={(e) => setCheckerId(parseInt(e.target.value))}
                  >
                    <option value={""}>Select Checker</option>
                    {props.ticketUsers.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Approver :</div>
            <div className="w_400 category_textFeild_width_390">
              <div className="control has-icons-right">
                <div className="select" style={{ display: "flex" }}>
                  <select
                    className="input input-box"
                    value={approverId || ""}
                    onChange={(e) => setApproverId(parseInt(e.target.value))}
                  >
                    <option value={""}>Select Approver</option>
                    {props.ticketUsers.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Status* :</div>
            <div className="w_400 category_textFeild_width_390">
              <div className="control has-icons-right">
                <div className="select" style={{ display: "flex" }}>
                  <select className="input input-box" value={status || ""} onChange={(e) => setStatus(e.target.value)}>
                    <option value={""}>Select Status</option>
                    {props.ticketStatuses.map((option, index) => (
                      <option key={index} value={option.status}>
                        {option.status}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Priority :</div>
            <div className="w_400 category_textFeild_width_390">
              <div className="control has-icons-right">
                <div className="select" style={{ display: "flex" }}>
                  <select className="input input-box" value={priority} onChange={(e) => setPriority(e.target.value)}>
                    {TICKET_PRIORITIES.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="d_flex m_15">
            <div className="w_300 vertical_center_align fw_600">Due Date :</div>
            <div className="w_400 d_flex textFeild_width_185">
              <TextField
                className="mr_20"
                label="From"
                InputLabelProps={{ shrink: true }}
                id="outlined-size-small"
                size="small"
                type="date"
                value={dueDate}
                inputProps={{
                  min: new Date().toISOString().split("T")[0], // Set min date to today
                }}
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <div>
          <Button
            className="theme_btn"
            sx={{ padding: "8px 22px", borderRadius: "4px" }}
            onClick={() => {
              uiLogger(
                uiLoggerNamesWorkflow.UI_WF_CREATE_TASK_CLICK.functionName,
                companyId.current,
                branchCode.current,
                {
                  message: uiLoggerNamesWorkflow.UI_WF_CREATE_TASK_CLICK.message,
                  businessPartnerId: businessPartnerSelected,
                  ticketStatus: status,
                }
              );
              handleCreateTicket();
            }}
          >
            CREATE TASK
          </Button>
        </div>
      </DialogActions>
    </MuiDialog>
  );
};
