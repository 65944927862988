import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../MsmePartnerPortal/CommonComponents";

export default function ConfirmCloseAndResetModal({
  open,
  setOpen,
  closeAndResetTasks,
  companyId,
  branchCode,
  taskIdForSingleCloseAndReset,
  setClearRows,
}) {
  const [isClosingAndResettingTask, setIsClosingAndResettingTask] = useState<boolean>(false);
  return (
    <Dialog open={open} sx={{ borderRadius: "4px" }}>
      <DialogTitle>Are you sure you want to Close and Reset this task</DialogTitle>
      <DialogContent>
        <Alert severity="warning" sx={{ "& .MuiAlert-message": { fontSize: "14px" } }}>
          The current communication will reset and cannot be reversed
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            uiLogger(
              uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_CANCEL_CLICK.functionName,
              companyId,
              branchCode,
              {
                message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_CANCEL_CLICK.message,
              }
            );
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isClosingAndResettingTask}
          variant="contained"
          onClick={async () => {
            uiLogger(
              uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_PROCEED_CLICK.functionName,
              companyId,
              branchCode,
              {
                message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_PROCEED_CLICK.message,
              }
            );
            setIsClosingAndResettingTask(true);
            await closeAndResetTasks([taskIdForSingleCloseAndReset]);
            setIsClosingAndResettingTask(false);
            setOpen(false);
            setClearRows(true);
          }}
        >
          {<LoadingIcon loading={isClosingAndResettingTask} />}
          Proceed
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
