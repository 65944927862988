import { Button } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Nakad from "../../../../Graphics/NAKAD_FINAL (1).png";

const ConfirmBalanceFromMail = () => {
  const location = useLocation();
  const [showMsg, setShowMsg] = useState(
    "Please click on confirm, if you are agreeing to the balance that is shared with you."
  );
  const [showLoadingIconBtn, setShowLoadingIconBtn] = useState(false);
  const [balanceNotConfirmSuccess, setBalanceNotConfirmSuccess] = useState(true);

  const onSubmitHandler = async () => {
    const params = new URLSearchParams(location.search);
    const data = params.get("data");
    setShowLoadingIconBtn(true);
    await useFetch(API_ENDPOINTS.CONFIRM_BALANCE_THROUGH_MAIL_BUTTON.url, "POST", {
      failureMessage: API_ENDPOINTS.CONFIRM_BALANCE_THROUGH_MAIL_BUTTON.failureMessage,
      data: {
        data,
      },
      thenCallBack: () => {
        setShowMsg("Balance confirmation is recorded successfully. Thanks");
        setShowLoadingIconBtn(false);
        setBalanceNotConfirmSuccess(false);
      },
      catchCallBack: () => {
        setShowMsg('Facing some technical issue. Please reply all "I confirm the balance" in the mail.');
        setShowLoadingIconBtn(false);
      },
    });
  };

  return (
    <div className="resetpass_wrapper">
      <div>
        <img src={Nakad} alt="" className="resetlogo" />
        <p className="enter-info-text">{showMsg}</p>
        <div className="center_align">
          {balanceNotConfirmSuccess && (
            <Button
              className="theme_btn w_250"
              startIcon={<LoadingIcon loading={showLoadingIconBtn} />}
              onClick={() => {
                onSubmitHandler();
              }}
            >
              I confirm the balance
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmBalanceFromMail;
