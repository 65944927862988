import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useState } from "react";
const PdfOptionConfirmModal = (props: any) => {
  const [selectedOption, setSelectedOption] = useState(props.selectedPdfConverter.current);
  return (
    <div>
      <Dialog open={props.openConfirmOptionModal} aria-labelledby="UnreconcileConfirmWarningModal">
        <DialogTitle>Please Confirm Option?</DialogTitle>
        <DialogContent>
          <div>
            <FormControl>
              <RadioGroup
                row={true}
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedOption}
                onChange={(e) => {
                  props.selectedPdfConverter.current = e.target.value;
                  setSelectedOption(props.selectedPdfConverter.current);
                }}
              >
                {/* {!props.disablePdfTableOptionForMultiplePdf && ( */}
                <>
                  <FormControlLabel value="PDFLedgerParser" control={<Radio />} label="Tally" />
                  <FormControlLabel value="AWSTextract" control={<Radio />} label="Option 2" />
                  <FormControlLabel value="PDFTables" control={<Radio />} label="Option 3" />
                </>
                {/* )} */}
              </RadioGroup>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div>
            <Button
              variant="contained"
              className="theme_btn"
              onClick={() => {
                props.onPdfOptionConfirm();
              }}
            >
              Confirm
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PdfOptionConfirmModal;
