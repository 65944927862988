import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { DocumentTypeConfigContext } from "./DocumentTypeConfig";

const EditGroupName = () => {
  const { editGroupEle, openEditGroupName, setOpenEditGroupName, documentTypeRow, setDocumentTypeRow } =
    useContext(DocumentTypeConfigContext);
  const [name, setName] = useState<any>("");
  const [errName, setErrName] = useState(false);
  const [duplicateGrpErrMsg, setDuplicateGrpErrMsg] = useState(false);

  const handleClose = () => {
    setOpenEditGroupName(false);
  };
  const saveEditedGroupName = () => {
    if (name?.trim() !== "") {
      if (name.trim().length > 15) {
        setErrName(true);
      } else {
        let duplicateGrpErr = false;
        const updatedDocumentTypeRow = documentTypeRow.map((item) => {
          if (item.name === editGroupEle.name && item.group?.groupName === name) {
            duplicateGrpErr = true;
            setDuplicateGrpErrMsg(true);
          }
          if (
            item.name === editGroupEle.name &&
            item.group.groupId === editGroupEle.group.groupId &&
            !duplicateGrpErr
          ) {
            // Create a new object with updated values
            return {
              ...item,
              group: { groupId: item.group.groupId, groupName: name },
              codes: item.codes.map((codeItem) => ({
                ...codeItem,
                group: { groupId: item.group.groupId, groupName: name },
              })),
            };
          }
          return item;
        });

        if (!duplicateGrpErr) {
          setDocumentTypeRow(updatedDocumentTypeRow);
          handleClose();
        }
      }
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Dialog open={openEditGroupName} aria-labelledby="EditGroupName">
        <DialogTitle className="dialog_header flex_end">
          {/* <p className="fs_24">Select E-mail template</p> */}
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div className="fs_20 mt_10">
            {" "}
            <span className="fw_600">Group :</span> <span>{editGroupEle.group?.groupId}</span>
          </div>
          <div className="fs_20 d_flex mt_10 center_align_ver_horiz">
            <span className="fw_600"> Name :</span>
            <span>
              <TextField
                size="small"
                variant="outlined"
                required={true}
                fullWidth={true}
                // label="Name"
                name="name"
                error={errName}
                helperText={errName ? "Name should be 15 characters or less" : ""}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  setDuplicateGrpErrMsg(false);
                  if (e.target.value.trim().length > 15) {
                    setErrName(true);
                  } else {
                    setErrName(false);
                  }
                }}
              />
            </span>
          </div>
          {duplicateGrpErrMsg && <div className="err_text">This Group already exist</div>}
        </DialogContent>
        <DialogActions className="dialog_footer flex_end">
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                saveEditedGroupName();
              }}
            >
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditGroupName;
