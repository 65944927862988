import { configureStore } from "@reduxjs/toolkit";
import userListReducer from "../slices/admin/userListSlice";
import bulkMailsReducer from "../slices/partnerCommunication/bulkMailsSlice";

const store = configureStore({
  reducer: {
    userList: userListReducer,
    bulkMailsStatus: bulkMailsReducer,
    // Add other reducers if you have multiple slices
  },
});

export default store;
