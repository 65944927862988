import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { Recon360Context, StateDispatch } from "../Recon360";
import { TicketMetaStatuses } from "./ViewTickets";

interface SaveBulkTasksConfirmationModalProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  tasksCount: number;
  updateMetaStatusForBulkTasks: any;
  isLoading: boolean;
}
export default function SaveBulkTasksConfirmationModal({
  open,
  setOpen,
  tasksCount,
  updateMetaStatusForBulkTasks,
  isLoading,
}: SaveBulkTasksConfirmationModalProps) {
  const { companyId, branchCode } = useContext(Recon360Context);
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to Save {tasksCount} Tasks?</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          This action will close the reconciliations of {tasksCount} Tasks and save the result for future
          reconciliations
        </Alert>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_SAVETASKS_CANCEL_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_SAVETASKS_CANCEL_CLICK.message,
              }
            );
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={isLoading}
          variant="contained"
          onClick={() => {
            uiLogger(
              uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_SAVETASKS_SAVE_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: uiLoggerNamesWorkflow.UI_WF_DB_BULKTASKS_SAVETASKS_SAVE_CLICK.message,
              }
            );
            updateMetaStatusForBulkTasks(TicketMetaStatuses.Saved);
          }}
        >
          <LoadingIcon loading={isLoading} />
          Save
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
