import { FileDownload } from "@mui/icons-material";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import {
  MaterialReactTable,
  MRT_Column,
  type MRT_ColumnDef,
  MRT_Row,
  MRT_RowData,
  MRT_ShowHideColumnsButton,
  MRT_SortingState,
  MRT_TableOptions,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
  useMaterialReactTable,
} from "material-react-table";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

//Date Picker Imports
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
import CustomToast from "../CustomToast";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type CallableUpdater<T = any> = (old: T) => T;

type UpdatedMRT_TableOptions<T> = Omit<MRT_TableOptions<T>, "data"> & { rows: MRT_TableOptions<T>["data"] };

const Styles = styled.div`
  table {
    border-collapse: unset;

    th:contains(.autoGrow) {
      display: inline-table;
    }

    th {
      padding-inline: 1rem !important;

      .Mui-TableHeadCell-Content-Wrapper {
        white-space: nowrap;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
      }

      input.MuiInputBase-input::placeholder,
      .MuiInputBase-input *,
      .MuiFormControlLabel-root {
        font-family: "Roboto", sans-serif;
        font-size: 12px;
      }

      .MuiChip-root {
        height: unset;
      }
    }
  }
`;

interface PropertySticky {
  /**
   * If property `sticky` is supplied, the property `id` or `header` must be supplied as well.
   *
   */
  sticky: "left" | "right";
}

type stickyColumn<T> = PropertySticky & { id?: MRT_ColumnDef<T>["id"] };

type nonStickyColumn = {
  sticky?: never;
};

export type ColDef<T = any> = MRT_ColumnDef<T> & (stickyColumn<T> | nonStickyColumn);

const csvConfig = mkConfig({
  fieldSeparator: ",",
  decimalSeparator: ".",
  useKeysAsHeaders: true,
});

export const s: MRT_TableOptions<any> = {
  columns: [],
  data: [],
};

const CustomDownloadButton = (table, onClickHandler) => (
  <Tooltip title="Download Data">
    <IconButton onClick={() => onClickHandler(table.getSelectedRowModel().rows)}>
      <FileDownload />
    </IconButton>
  </Tooltip>
);

const InternalButtonsMap = {
  globalFilterToggle: MRT_ToggleGlobalFilterButton,
  filterToggle: MRT_ToggleFiltersButton,
  customDownloadButton: CustomDownloadButton,
  showHideColumns: MRT_ShowHideColumnsButton,
  densePaddingToggle: MRT_ToggleDensePaddingButton,
  fullScreenToggle: MRT_ToggleFullScreenButton,
};

interface TableProps<TData extends MRT_RowData> extends UpdatedMRT_TableOptions<TData> {
  columns: ColDef<TData>[];

  /**
   * Pass your data as an array of objects.
   *
   * Data must be memoized or stable (useState, useMemo, etc.)
   *
   * See the usage guide for more info on creating columns and data:
   * @link https://www.material-react-table.com/docs/getting-started/usage
   *
   */
  rows: MRT_TableOptions<TData>["data"];
  loading?: boolean;
  isSaving?: boolean;
  enableAggregations?: boolean;
  setSelectedRow?: StateDispatch<TData[]> | StateDispatch<Record<string, unknown>[]>;
  savedSortState?: MRT_SortingState;
  setSavedSortingState?: StateDispatch<MRT_SortingState>;
  setAllLeafColumns?: StateDispatch<MRT_Column<TData>[]>;
  internalActionsOrder?: (keyof typeof InternalButtonsMap)[];
  pageSelectMode?: boolean;
  clearRowsSelection?: boolean;
  setClearRowsSelection?: StateDispatch<boolean>;
  renderAdditionalBottomToolbarCustomActions?: MRT_TableOptions<TData>["renderBottomToolbarCustomActions"];
  additionalInitialState?: MRT_TableOptions<TData>["initialState"];
  initialStateColumnHiding?: Record<string, boolean>;
}

/**
 * Material React Table v2.13.
 *
 * Take a look at the advanced example for an understanding of the table options:
 * @link https://www.material-react-table.com/docs/examples/advanced
 */
export const ReactTable = <T extends MRT_RowData>({ columns, rows: data, ...props }: TableProps<T>) => {
  const [rowSelection, setRowSelection] = useState({});
  const [sortingState, setSortingState] = useState<MRT_SortingState>([]);
  const [fullScreen, setFullScreen] = useState(false);

  const leftStickedColumns = (columns as ColDef<T>[])
    .filter((col) => col?.sticky === "left")
    .map((col) => col.id?.toString());
  const rightStickedColumns = (columns as ColDef<T>[])
    .filter((col) => col?.sticky === "right")
    .map((col) => col.id?.toString());

  const leftFixed = ["mrt-row-expand", "mrt-row-select", ...leftStickedColumns];
  const rightFixed = ["mrt-row-actions", ...rightStickedColumns];

  const [pinningState, setPinningState] = useState({
    left: leftFixed,
    right: rightFixed,
  });

  const tableOptions: MRT_TableOptions<T> = {
    columns,
    data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    // layoutMode: "grid",
    enableRowVirtualization: true, // virtualization is not supported, if need to use semantic layout
    enableColumnFilterModes: false,
    enableColumnOrdering: true,
    enableColumnResizing: true,
    enableGrouping: props.enableAggregations,
    enableColumnPinning: true,
    enableFacetedValues: true,
    enableRowActions: props.renderRowActionMenuItems?.length > 0 ? true : false,
    enableRowSelection: true,
    enableStickyHeader: true,
    muiFilterCheckboxProps: {
      title: "Filter",
      size: "small",
      sx: { fontFamily: '"Roboto", sans-serif', fontSize: 12 },
    },
    muiFilterDatePickerProps: {
      format: "DD/MM/YYYY",
      slotProps: { openPickerButton: { size: "small" }, textField: { size: "small" } },
    },
    muiFilterSliderProps: { size: "small" },
    muiFilterTextFieldProps: {
      SelectProps: {
        MenuProps: {
          PaperProps: {
            sx: {
              "& .MuiMenu-list": {
                maxHeight: 450,
              },
            },
          },
        },
      },
    },
    defaultDisplayColumn: {
      enableResizing: false, //turn on some features that are usually off for all display columns
      enableSorting: false,
      enableHiding: false,
      enableGrouping: false,
      enablePinning: false,
    },
    state: {
      isLoading: props.loading,
      rowSelection: rowSelection,
      sorting: props.savedSortState?.length ? props.savedSortState : sortingState,
      isSaving: props.isSaving,
      columnPinning: pinningState,
    },
    autoResetPageIndex: false,
    globalFilterFn: "contains",
    onRowSelectionChange: setRowSelection,
    selectAllMode: props.pageSelectMode ? "page" : "all",
    onSortingChange: (sortingFn: MRT_SortingState | CallableUpdater<MRT_SortingState>) => {
      if (typeof sortingFn === "function") {
        const _currentSortState = (sortingFn as CallableUpdater<MRT_SortingState>)(sortingState);
        setSortingState(_currentSortState);
        if (props.setSavedSortingState) props.setSavedSortingState(_currentSortState);
      } else {
        setSortingState(sortingFn as MRT_SortingState);
        if (props.setSavedSortingState) props.setSavedSortingState(sortingFn);
      }
    },
    onColumnPinningChange: (columnPinningState) => {
      const replacer = (currentState: typeof pinningState) => {
        if (currentState) {
          const leftFiltered = currentState.left.filter((col) => !leftFixed.includes(col));
          const rightFiltered = currentState.right.filter((col) => !rightFixed.includes(col));
          const state = {
            left: [...leftFixed, ...leftFiltered],
            right: [...rightFixed, ...rightFiltered],
          };
          setPinningState(state);
        }
      };
      if (typeof columnPinningState === "function") {
        const _currentPinningState = (columnPinningState as CallableUpdater)(pinningState);
        replacer(_currentPinningState);
      } else {
        replacer({ left: columnPinningState.left || [], right: columnPinningState.right || [] });
      }
    },

    initialState: {
      columnVisibility: props.initialStateColumnHiding ? props.initialStateColumnHiding : {},
      showColumnFilters: false,
      showGlobalFilter: true,
      density: "compact",
      pagination: { pageIndex: 0, pageSize: 100 },
      columnPinning: {
        left: ["mrt-row-expand", "mrt-row-select"], // default identifiers, so it gets auto fixed when clicked from options
        right: ["mrt-row-actions"], // default identifiers, so it gets auto fixed when clicked from options
      },
      ...(props?.additionalInitialState || {}),
    },
    paginationDisplayMode: "pages",
    positionToolbarAlertBanner: "top",
    positionToolbarDropZone: "both",
    muiPaginationProps: {
      color: "standard",
      rowsPerPageOptions: [10, 25, 50, 100, 200].map((value) => ({ value, label: `${value} per Page` })),
      shape: "circular",
      variant: "outlined",
    },
    muiTablePaperProps: ({ table }) => ({
      style: {
        left: table.getState().isFullScreen ? "12px" : undefined,
        width: table.getState().isFullScreen ? "93vw" : undefined,
      },
    }),
    muiBottomToolbarProps: {
      style: { width: fullScreen ? "inherit" : "100%", left: fullScreen ? "12px" : "0" },
      className: fullScreen ? "fullscreen" : "",
    },
    muiTopToolbarProps: { className: fullScreen ? "fullscreen" : "" },
    muiTableContainerProps: {
      classes: { root: fullScreen ? "fullscreen" : "" },
      sx: { height: fullScreen ? "100%" : "60vh" },
    },
    //Adding a custom button to the bottom toolbar
    renderBottomToolbarCustomActions: ({ table }) => {
      const { getState, getFilteredRowModel } = table;

      const {
        pagination: { pageIndex, pageSize },
      } = getState();

      return (
        <Box className="vertical_center_align" gap={2}>
          <Typography variant="body2">
            {`${pageSize * pageIndex + 1}–${
              pageSize * (pageIndex + 1) < getFilteredRowModel().rows.length
                ? pageSize * (pageIndex + 1)
                : getFilteredRowModel().rows.length
            } of ${getFilteredRowModel().rows.length}`}
          </Typography>
          {props.renderAdditionalBottomToolbarCustomActions &&
            props.renderAdditionalBottomToolbarCustomActions({ table })}
        </Box>
      );
    },
    renderToolbarInternalActions: ({ table }) => {
      if (props.internalActionsOrder) {
        return props.internalActionsOrder.map((key) => {
          const Component = InternalButtonsMap[key];
          if (key === "customDownloadButton") return <Component table={table} onClickHandler={handleExportRows} />;
          return <Component table={table} key={key} />;
        });
      } else
        return (
          <>
            {/* built-in buttons (must pass in table prop for them to work!) */}
            <MRT_ToggleGlobalFilterButton table={table} />
            <MRT_ToggleFiltersButton table={table} />
            {/* custom button */}
            <Tooltip title="Download Data">
              <IconButton onClick={() => handleExportRows(table.getSelectedRowModel().rows)}>
                <FileDownload />
              </IconButton>
            </Tooltip>
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </>
        );
    },

    // renderRowActionMenuItems: ({ closeMenu }) => [],
    ...props,
    // no properties should override passed properties from consumer component, except the toolbars
  };

  const tableInstance = useMaterialReactTable(tableOptions);

  const { getSelectedRowModel, getAllLeafColumns, getState } = tableInstance;

  const { isFullScreen } = getState();

  const handleExportRows = (rows?: MRT_Row<T>[]) => {
    const rowData = rows?.length > 0 ? rows.map((row) => row.original) : data;

    try {
      const modifiedData = [...rowData].map((row) => {
        const modifiedRow = { ...row };
        for (const key in modifiedRow) {
          if (typeof modifiedRow[key] === "object") {
            if (Array.isArray(modifiedRow[key])) modifiedRow[key] = modifiedRow[key].join(", ");
            else modifiedRow[key] = JSON.stringify(modifiedRow[key]) as any;
          }
        }
        return modifiedRow;
      });

      const csv = generateCsv(csvConfig)(modifiedData);
      download(csvConfig)(csv);
    } catch (error) {
      toast.error(<CustomToast message={error?.toString()} />);
    }
  };

  useEffect(() => {
    const filterSelectedFlatRows = getSelectedRowModel().flatRows.map((item) => item.original);

    if (props.setSelectedRow) props.setSelectedRow(filterSelectedFlatRows);

    if (props.clearRowsSelection) {
      setRowSelection({});
      props.setClearRowsSelection(false);
    }

    if (props.setAllLeafColumns && getAllLeafColumns) {
      props.setAllLeafColumns(getAllLeafColumns());
    }
    if (isFullScreen) {
      setFullScreen(true);
    } else setFullScreen(false);

    if (pinningState) {
      if (pinningState.left.length === 0 && pinningState.right.length === 0)
        setPinningState({ left: leftFixed, right: rightFixed });
      else if (!pinningState.left && !pinningState.right)
        setPinningState({ left: leftFixed, right: pinningState.right });
    }
  }, [
    rowSelection,
    props.setSelectedRow,
    getSelectedRowModel,
    getAllLeafColumns,
    isFullScreen,
    pinningState,
    props.clearRowsSelection,
  ]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Styles>
          <MaterialReactTable table={tableInstance} />
        </Styles>
      </LocalizationProvider>
    </>
  );
};

export default ReactTable;
