export const exists = (value: boolean | string | number | object) => {
  switch (typeof value) {
    case "undefined":
      return false;

    case "number":
      return Boolean(value);
    case "string":
      if (typeof value === "string" && value.trim() === "null") return false;
      if (typeof value === "string" && value.trim() === "undefined") return false;
      if (typeof value === "string" && value.trim() === "true") return true;
      if (typeof value === "string" && value.trim() === "false") return false;

      return Boolean(value.trim());

    case "object":
      // array
      if (Array.isArray(value)) return value.length > 0;
      // other object
      return Object.keys(value).length > 0;

    case "boolean":
      return Boolean(value);

    case "function":
      return value ? true : false;

    default:
      break;
  }

  return false;
};
