import { FormControlLabel, Tooltip } from "@mui/material";
import React from "react";
import { Android12Switch } from "src/Components/Common/Loader";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface PageSelectModeToggleProps {
  pageSelectMode: boolean;
  setPageSelectMode: (value: boolean) => void;
  useCaseType: UseCaseType;
  companyId?: string;
  branchCode?: string;
}

export const PageSelectModeToggle = ({
  pageSelectMode,
  setPageSelectMode,
  useCaseType,
  companyId,
  branchCode,
}: PageSelectModeToggleProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Tooltip
            title="When this toggle is enabled, use 'select all checkbox' to select/deselect all partners in the current page."
            arrow
          >
            <Android12Switch
              onChange={() => setPageSelectMode(!pageSelectMode)}
              onClick={(ev) => {
                if (useCaseType === UseCaseType.balanceConfirmationBeta) {
                  uiLogger(
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_PAGE_SELECT_MODE_CLICK.functionName,
                    companyId,
                    branchCode,
                    {
                      message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_PAGE_SELECT_MODE_CLICK.message,
                    }
                  );
                }
                (ev.target as HTMLInputElement).checked ? setPageSelectMode(false) : setPageSelectMode(true);
              }}
              checked={pageSelectMode}
            />
          </Tooltip>
        }
        label="Page Select Mode"
      />
    </>
  );
};
