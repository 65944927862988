import { ChevronLeft, Sync } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Tooltip,
} from "@mui/material";
import { Column, ColumnDef } from "@tanstack/react-table";

import moment from "moment";
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MatchedEntriesForManualRecon } from "../../../entity/recon-entity/ReconInterfaces";
import FormatDate, { formatDate } from "../../../Utils/DateUtils";
import {
  gapAccountedAsName,
  manualReconStatusName,
  showEntriesSuggestion,
} from "../../../Utils/Recon/Recon360/Constants";
import { uiLoggerNamesRecon } from "../../../Utils/Recon/UiLogger/Constants";
import IndeterminateCheckbox from "../../Admin/IndeterminateCheckbox";
// import DetailsTable from '../../ReactTable/DetailsTable';
import { Currency } from "dinero.js";
import Loader from "src/Components/Common/Loader";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import SuggestedMatchTable from "src/Components/ReactTable/SuggestedMatchTable";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import uiLogger from "src/Utils/UiLogger";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import { Recon360Context } from "../Recon360";
import { a11yProps, TabPanel } from "../ReconSettings/ReconSettings";
import "./ManualRecon.scss";
import ManualReconReconcileBpFilter from "./ManualReconReconcileBpFilter";
import ManualReconReconcileOwnFilter from "./ManualReconReconcileOwnFilter";
import ReconcileConfirmWarningModal from "./ReconcileConfirmWarningModal";
import UnreconcileConfirmWarningModal from "./UnreconcileConfirmWarningModal";

type ColDef = ColumnDef<any> & {
  sticky?: "left" | "right";
};

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export interface ManualReconContextInterface {
  isOpenUnreconcileConfirmWarningModal: boolean;
  setIsOpenUnreconcileConfirmWarningModal: StateDispatch<boolean>;
  isOpenReconcileConfirmWarningModal: boolean;
  setIsOpenReconcileConfirmWarningModal: StateDispatch<boolean>;
  unReconcileEntriesOnMatchId: () => void;
  reconcileEntries: (val) => void;
  reconcileTotalSum: number;
  selectedRowOwn: any[];
  selectedRowBp: any[];
  amountTolerance: number;
  openManualReconReconcileOwnFilter: boolean;
  setOpenManualReconReconcileOwnFilter: StateDispatch<boolean>;
  storeRowsDataReconciledOwn: any[];
  entryNumOwn: string;
  setEntryNumOwn: StateDispatch<string>;
  setIsFilterAppliedOwnSide: StateDispatch<boolean>;
  setRowsDataReconciledOwn: StateDispatch<any[]>;
  openManualReconReconcileBpFilter: boolean;
  setOpenManualReconReconcileBpFilter: StateDispatch<boolean>;
  storeRowsDataReconciledBp: any[];
  entryNumBp: string;
  setEntryNumBp: StateDispatch<string>;
  setIsFilterAppliedBpSide: StateDispatch<boolean>;
  setRowsDataReconciledBp: StateDispatch<any[]>;
  absoluteReconcileTotalSum: number;
  showLoadingIconUnreconcile: boolean;
  showLoadingIconReconcile: boolean;
  gapAccountedName: string;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  isFilterAppliedOwnSide: boolean;
  isFilterAppliedBpSide: boolean;
  reconcileTotalPercentage: any;
}

export const ManualReconContext = createContext<ManualReconContextInterface>(null);

const ManualRecon = () => {
  const {
    currency,
    businessPartnerSelected,
    businessPartnerSelectedRef,
    businessPartnerSelectedCompanyIdRef,
    companyId,
    branchCode,
    checkboxDataManualRecon,
    amountTolerance,
    totalAmount,
    hasManuallyReconciled,
    setOpenManualRecon,
    getManuallyChangedEntries,
    getSummaryForReconciliationCommonFunction,
  } = useContext(Recon360Context);

  const [rowsDataUnreconciled, setRowsDataUnreconciled] = useState<MatchedEntriesForManualRecon[]>([]);
  const [rowsDataReconciledOwn, setRowsDataReconciledOwn] = useState([]);
  const [storeRowsDataReconciledOwn, setStoreRowsDataReconciledOwn] = useState([]);
  const [rowsDataReconciledBp, setRowsDataReconciledBp] = useState([]);
  const [storeRowsDataReconciledBp, setStoreRowsDataReconciledBp] = useState([]);
  const [matchIdValue, setMatchIdValue] = useState<string>("");
  const [matchIdValueArr, setMatchIdValueArr] = useState<string[]>([]);
  const [isOpenUnreconcileConfirmWarningModal, setIsOpenUnreconcileConfirmWarningModal] = useState<boolean>(false);
  const [isOpenReconcileConfirmWarningModal, setIsOpenReconcileConfirmWarningModal] = useState<boolean>(false);
  const [isFilterAppliedOwnSide, setIsFilterAppliedOwnSide] = useState<boolean>(false);
  const [isFilterAppliedBpSide, setIsFilterAppliedBpSide] = useState<boolean>(false);
  const [entryNumOwn, setEntryNumOwn] = useState<string>("");
  const [entryNumBp, setEntryNumBp] = useState<string>("");
  const [selectedEntryNumbersOwn, setSelectedEntryNumbersOwn] = useState([]);
  const [selectedEntryNumbersBp, setSelectedEntryNumbersBp] = useState([]);
  const [reconcileTotalSum, setReconcileTotalSum] = useState<number>(0);
  const [reconcileTotalPercentage, setReconcileTotalPercentage] = useState<any>(0);
  const [reconcileOwnSum, setReconcileOwnSum] = useState<number>(0);
  const [reconcileBpSum, setReconcileBpSum] = useState<number>(0);
  const [absoluteReconcileTotalSum, setAbsoluteReconcileTotalSum] = useState<number>(0);
  const [showApplyOptions, setShowApplyOptions] = useState<boolean>(false);
  const [selectedRowOwn, setSelectedRowOwn] = useState<any[]>([]);
  const [selectedRowBp, setSelectedRowBp] = useState<any[]>([]);
  const textFieldRefUnReconcile = useRef(null);
  const textFieldRefReconcileOwn = useRef(null);
  const textFieldRefReconcileBp = useRef(null);
  const [openManualReconReconcileOwnFilter, setOpenManualReconReconcileOwnFilter] = useState<boolean>(false);
  const [openManualReconReconcileBpFilter, setOpenManualReconReconcileBpFilter] = useState<boolean>(false);
  const [showLoadingIconUnreconcile, setShowLoadingIconUnreconcile] = useState<boolean>(false);
  const [showLoadingIconReconcile, setShowLoadingIconReconcile] = useState<boolean>(false);
  const [gapAccountedName, setGapAccountedName] = useState<string>("");
  const [showChangesDoneCard, setShowChangesDoneCard] = useState(false);
  const [reFilter, setReFilter] = useState(false);
  const ColsRecon = { otherUniqueId: false, postingDate: false, particulars: false, dncnId: false, paymentId: false };
  const [hiddenColsReconOwn, setHiddenColsReconOwn] = useState(ColsRecon);
  const [hiddenColsReconBp, setHiddenColsReconBp] = useState(ColsRecon);
  const [allLeafColumnsOwn, setAllLeafColumnsOwn] = useState<Column<any>[]>([]);
  const [allLeafColumnsBp, setAllLeafColumnsBp] = useState<Column<any>[]>([]);
  const [savedTableSortOwn, setSavedTableSortOwn] = useState([]);
  const [savedTableSortBp, setSavedTableSortBp] = useState([]);
  const [value, setValue] = useState<number>(0);
  const [suggestionsPresent, setSuggestionsPresent] = useState(false);
  const [loader, setLoader] = useState(true);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  const [reconcileSuggestionsLoading, setReconcileSuggestionsLoading] = useState(false);
  const [selectedSuggestedEntry, setSelectedSuggestedEntry] = useState([]);

  const [rowsDataSuggestion, setRowsDataSuggestion] = useState([]);
  const [showEntries, setShowEntries] = useState(null);
  const [suggestedEntryTrackIndex, setSuggestedEntryTrackIndex] = useState(null);
  const optionsFilterEntries = ["Entry Number", "Match ID", "Reconciliation Status"];
  const [selectedFilterEntry, setSelectedFilterEntry] = useState(null);
  const [entryNumChangeStatus, setEntryNumChangeStatus] = useState("");
  const [matchIDChangeStatus, setMatchIDChangeStatus] = useState("");
  const [selectLedger, setSelectLedger] = useState(null);
  const [optionsReconcileStatus, setOptionsReconcileStatus] = useState([]);
  const [selectedOptionReconcileStatus, setSelectedOptionReconcileStatus] = useState(null);
  const [selectedOptionChangeReconcileStatus, setSelectedOptionChangeReconcileStatus] = useState(null);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  const [rowsDataChangeReconcileStatus, setRowsDataChangeReconcileStatus] = useState([]);
  const [getReconciledEntriesLoading, setGetReconciledEntriesLoading] = useState(false);
  const [selectedRowChangeReconcileStatus, setSelectedRowChangeReconcileStatus] = useState([]);
  const [updateReconStatusManuallyLoading, setUpdateReconStatusManuallyLoading] = useState(false);
  const [autoOutOfPeriodLoading, setAutoOutOfPeriodLoading] = useState(false);
  const [loadingReconcileTableOwn, setLoadingReconcileTableOwn] = useState(false);
  const [loadingReconcileTableBp, setLoadingReconcileTableBp] = useState(false);

  const columnDefinitionSuggestionViewMore: ColDef[] = [];

  // Conditionally include "Own" section
  if (showEntries !== showEntriesSuggestion.BP) {
    columnDefinitionSuggestionViewMore.push({
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Entry no.",
          accessorKey: "entryNumber",
          size: 130,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.entryNumber}>
                  {item.entryNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Date",
          accessorKey: "documentDate",
          size: 120,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div key={index}>{formatDate(item.documentDate)}</div>
              ))}
            </div>
          ),
        },
        {
          header: "Reference",
          accessorKey: "referenceNumber",
          size: 100,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.referenceNumber}>
                  {item.referenceNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "entryType",
          size: 100,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.entryType}>
                  {item.entryType}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Amount",
          accessorKey: "amount",
          size: 130,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div
                  key={index}
                  className="textOverflow_hidden"
                  title={formatMoney(ToDineroObj(item.amount, currency.current as Currency))}
                >
                  {item.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Doc Type",
          accessorKey: "documentType",
          size: 120,
          cell: ({ row }: any) => (
            <div>
              {row.original?.ownEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.documentType}>
                  {item.documentType}
                </div>
              ))}
            </div>
          ),
        },
      ],
    });
  }

  // Conditionally include "BP" section
  if (showEntries !== showEntriesSuggestion.OWN) {
    columnDefinitionSuggestionViewMore.push({
      header: () => <div className="borderLeft">BP</div>,
      id: "bpUnreconcile",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Entry no.",
          accessorKey: "entryNumber",
          size: 130,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.entryNumber}>
                  {item.entryNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Date",
          accessorKey: "documentDate",
          size: 120,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div key={index}>{formatDate(item.documentDate)}</div>
              ))}
            </div>
          ),
        },
        {
          header: "Reference",
          accessorKey: "referenceNumber",
          size: 100,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.referenceNumber}>
                  {item.referenceNumber}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "entryType",
          size: 100,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.entryType}>
                  {item.entryType}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Amount",
          accessorKey: "amount",
          size: 130,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div
                  key={index}
                  className="textOverflow_hidden"
                  title={formatMoney(ToDineroObj(item.amount, currency.current as Currency))}
                >
                  {item.amount !== null ? formatMoney(ToDineroObj(item.amount, currency.current as Currency)) : "-"}
                </div>
              ))}
            </div>
          ),
        },
        {
          header: "Doc Type",
          accessorKey: "documentType",
          size: 120,
          cell: ({ row }: any) => (
            <div>
              {row.original?.partnerEntries.map((item, index) => (
                <div key={index} className="textOverflow_hidden" title={item.documentType}>
                  {item.documentType}
                </div>
              ))}
            </div>
          ),
        },
      ],
    });
  }

  // Always include the "Selection" section
  columnDefinitionSuggestionViewMore.push(
    {
      header: () => <div className=""></div>,
      id: "amtdiff",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date difference",
          accessorKey: "dateDifference",
          size: 130,
        },
        {
          header: "Amount difference",
          accessorKey: "amountDifference",
          size: 130,
          cell: ({ row }: any) => (
            <div>
              <div
                className="textOverflow_hidden"
                title={formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))}
              >
                {row.original?.amountDifference !== null
                  ? formatMoney(ToDineroObj(row.original?.amountDifference, currency.current as Currency))
                  : "-"}
              </div>
            </div>
          ),
        },
        {
          header: "Percentage difference",
          accessorKey: "percentageDifference",
          size: 130,
        },
      ],
    },

    {
      id: "selection",
      sticky: "right",
      header: ({ table }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </div>
      ),
      cell: ({ row }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        </div>
      ),
      size: 35,
    }
  );

  const columnDefinitionUnreconciled: ColDef[] = [
    {
      header: "MATCH ID",
      accessorKey: "matchId",
      sticky: "left",
      size: 140,
    },
    {
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "ownDate",
          size: 140,
          cell: ({ row }) => (row.original.ownDate ? FormatDate(row.original.ownDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "ownDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "ownReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "ownAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.ownAmount !== null
                ? formatMoney(ToDineroObj(row.original.ownAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "ownType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "ownCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "ownDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "ownPostingDate",
          size: 150,
          cell: ({ row }) => (row.original.ownPostingDate ? FormatDate(row.original.ownPostingDate) : ""),
        },
      ],
    },
    {
      header: () => <div className="borderLeft">BP</div>,
      id: "bpUnreconcile",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "businessPartnerDate",
          size: 120,
          cell: ({ row }) => (row.original.businessPartnerDate ? FormatDate(row.original.businessPartnerDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "businessPartnerDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "businessPartnerReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "businessPartnerAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.businessPartnerAmount !== null
                ? formatMoney(ToDineroObj(row.original.businessPartnerAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "businessPartnerType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "businessPartnerCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "businessPartnerDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "businessPartnerPostingDate",
          size: 150,
          cell: ({ row }) =>
            row.original.businessPartnerPostingDate ? FormatDate(row.original.businessPartnerPostingDate) : "",
        },
      ],
    },
  ];
  const columnDefinitionChangeReconcileStatus: ColDef[] = [
    {
      id: "selection",
      sticky: "left",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      header: ({ table }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox
            checked={table.getIsAllRowsSelected()}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      cell: ({ row }) => (
        <div className="center_align_ver_horiz">
          <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        </div>
      ),
      size: 35,
    },
    {
      header: "MATCH ID",
      accessorKey: "matchId",
      sticky: "left",
      size: 140,
    },
  ];

  if (selectLedger !== showEntriesSuggestion.BP) {
    columnDefinitionChangeReconcileStatus.push({
      header: () => <div className="borderRight">Own</div>,
      id: "own",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "ownDate",
          size: 140,
          cell: ({ row }) => (row.original.ownDate ? FormatDate(row.original.ownDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "ownDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "ownReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "ownAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.ownAmount !== null
                ? formatMoney(ToDineroObj(row.original.ownAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "ownType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "ownCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "ownDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.ownDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "ownPostingDate",
          size: 150,
          cell: ({ row }) => (row.original.ownPostingDate ? FormatDate(row.original.ownPostingDate) : ""),
        },
      ],
    });
  }

  if (selectLedger !== showEntriesSuggestion.OWN) {
    columnDefinitionChangeReconcileStatus.push({
      header: () => <div className="borderLeft">BP</div>,
      id: "bpUnreconcile",
      size: null,
      accessorKey: "",
      columns: [
        {
          header: "Date",
          accessorKey: "businessPartnerDate",
          size: 120,
          cell: ({ row }) => (row.original.businessPartnerDate ? FormatDate(row.original.businessPartnerDate) : ""),
        },
        {
          header: "Doc Type",
          accessorKey: "businessPartnerDocumentType",
          size: 100,
        },
        {
          header: "Reference",
          accessorKey: "businessPartnerReferenceNumber",
          size: 180,
        },
        {
          header: "Amount",
          accessorKey: "businessPartnerAmount",
          size: 180,
          cell: ({ row }: any) => (
            <div>
              {row.original.businessPartnerAmount !== null
                ? formatMoney(ToDineroObj(row.original.businessPartnerAmount, currency.current as Currency))
                : "-"}
            </div>
          ),
        },
        {
          header: "Type",
          accessorKey: "businessPartnerType",
          size: 150,
        },
        // {
        //   header: "Credit Amount",
        //   accessorKey: "businessPartnerCreditAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerCreditAmount)}</div>,
        // },
        // {
        //   header: "Debit Amount",
        //   accessorKey: "businessPartnerDebitAmount",
        //   maxSize: 150,
        //   minSize: 150,
        //   size: 150,
        //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.businessPartnerDebitAmount)}</div>,
        // },
        {
          header: "Posting Date",
          accessorKey: "businessPartnerPostingDate",
          size: 150,
          cell: ({ row }) =>
            row.original.businessPartnerPostingDate ? FormatDate(row.original.businessPartnerPostingDate) : "",
        },
      ],
    });
  }

  columnDefinitionChangeReconcileStatus.push(
    {
      header: "Amount Difference",
      accessorKey: "valueDifference.amountDiff",
      size: 180,
      cell: ({ row }: any) => (
        <div>
          {row.original.valueDifference?.amountDiff !== null
            ? formatMoney(ToDineroObj(row.original.valueDifference?.amountDiff, currency.current as Currency))
            : "-"}
        </div>
      ),
    },
    {
      header: "% Amount Difference",
      accessorKey: "valueDifference.percentDiff",
      size: 190,
    },
    {
      header: "Reconciliation Status",
      accessorKey: "reconStatus",
      sticky: "right",
      size: 200,
    }
  );

  const columnDefinitionReconciledOwn = useMemo(
    (): ColDef[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        size: 35,
      },
      {
        header: "Entry no.",
        accessorKey: "entryNo",
        sticky: "left",
        size: 100,
      },
      {
        header: () => <div className="borderRight">Own</div>,
        id: "reconciledOwn",
        size: null,
        accessorKey: "",
        columns: [
          {
            header: "Date",
            accessorKey: "date",
            accessorFn: (row) => moment(row.date).format("YYYYMMDD"),
            size: 120,
            cell: ({ row }) => (row.original.date ? FormatDate(row.original.date) : ""),
          },
          {
            header: "Reference",
            accessorKey: "referenceNumber",
            size: 180,
          },
          {
            header: "Type",
            accessorKey: "type",
            size: 100,
          },
          {
            header: "Amount",
            accessorKey: "amount",
            size: 140,
            cell: ({ row }: any) => (
              <div>
                {row.original.amount !== null
                  ? formatMoney(ToDineroObj(row.original.amount, currency.current as Currency))
                  : "-"}
              </div>
            ),
          },
          {
            header: "Doc Type",
            accessorKey: "documentType",
            size: 100,
          },
          {
            header: "Other Unique ID",
            accessorKey: "otherUniqueId",
            size: 100,
          },
          {
            header: "Posting Date",
            accessorKey: "postingDate",
            size: 130,
            cell: ({ row }) => (row.original.postingDate ? FormatDate(row.original.postingDate) : ""),
          },
          {
            header: "Particulars",
            accessorKey: "particulars",
            size: 100,
          },
          // {
          //   header: "Credit Amount",
          //   accessorKey: "creditAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.creditAmount)}</div>,
          // },
          // {
          //   header: "Debit Amount",
          //   accessorKey: "debitAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.debitAmount)}</div>,
          // },
          {
            header: "DNCN ID",
            accessorKey: "dncnId",
            size: 100,
          },
          {
            header: "Payment ID",
            accessorKey: "paymentId",
            size: 100,
          },
        ],
      },
    ],
    []
  );
  const columnDefinitionReconciledBp = useMemo(
    (): ColDef[] => [
      {
        id: "selection",
        sticky: "left",
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        header: ({ table }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        cell: ({ row }) => (
          <div className="center_align_ver_horiz">
            <IndeterminateCheckbox checked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
          </div>
        ),
        size: 35,
      },
      {
        header: "Entry no.",
        accessorKey: "entryNo",
        sticky: "left",
        size: 100,
      },
      {
        header: () => <div className="borderRight">BP</div>,
        id: "reconciledBp",
        size: null,
        accessorKey: "",
        columns: [
          {
            header: "Date",
            accessorKey: "date",
            accessorFn: (row) => moment(row.date).format("YYYYMMDD"),
            size: 120,
            cell: ({ row }) => (row.original.date ? FormatDate(row.original.date) : ""),
          },
          {
            header: "Reference",
            accessorKey: "referenceNumber",
            size: 180,
          },
          {
            header: "Type",
            accessorKey: "type",
            size: 100,
          },
          {
            header: "Amount",
            accessorKey: "amount",
            size: 140,
            cell: ({ row }: any) => (
              <div>
                {row.original.amount !== null
                  ? formatMoney(ToDineroObj(row.original.amount, currency.current as Currency))
                  : "-"}
              </div>
            ),
          },
          {
            header: "Doc Type",
            accessorKey: "documentType",
            size: 100,
          },
          {
            header: "Other Unique ID",
            accessorKey: "otherUniqueId",
            size: 100,
          },
          {
            header: "Posting Date",
            accessorKey: "postingDate",
            size: 130,
            cell: ({ row }) => (row.original.postingDate ? FormatDate(row.original.postingDate) : ""),
          },
          {
            header: "Particulars",
            accessorKey: "particulars",
            size: 100,
          },
          // {
          //   header: "Credit Amount",
          //   accessorKey: "creditAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.creditAmount)}</div>,
          // },
          // {
          //   header: "Debit Amount",
          //   accessorKey: "debitAmount",
          //   maxSize: 100,
          //   minSize: 100,
          //   size: 100,
          //   cell: ({ row }: any) => <div>{formatNumberINR(row.original.debitAmount)}</div>,
          // },
          {
            header: "DNCN ID",
            accessorKey: "dncnId",
            size: 100,
          },
          {
            header: "Payment ID",
            accessorKey: "paymentId",
            size: 100,
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    createAndFetchManualReconSuggestions("");
    getPossibleReconStatuses();
  }, []);

  useEffect(() => {
    Object.keys(hiddenColsReconOwn).forEach((columnId) => {
      const foundColumn = allLeafColumnsOwn.find((column) => column.id === columnId);
      if (hiddenColsReconOwn[columnId] && foundColumn) foundColumn.toggleVisibility(false);
    });

    Object.keys(hiddenColsReconBp).forEach((columnId) => {
      const foundColumn = allLeafColumnsBp.find((column) => column.id === columnId);
      if (hiddenColsReconBp[columnId] && foundColumn) foundColumn.toggleVisibility(false);
    });
  }, [hiddenColsReconOwn, hiddenColsReconBp, allLeafColumnsOwn, allLeafColumnsBp]);

  useEffect(() => {
    const checkValExistsInArr = (arr: any[]) => {
      const keysWithValues = {
        ...ColsRecon,
      };
      Object.keys(keysWithValues).forEach((key) => {
        keysWithValues[key] = !arr.some((row) => row[key]);
      });

      return keysWithValues;
    };

    setHiddenColsReconOwn(checkValExistsInArr(rowsDataReconciledOwn));
    setHiddenColsReconBp(checkValExistsInArr(rowsDataReconciledBp));
    // eslint-disable-next-line
  }, [rowsDataReconciledOwn, rowsDataReconciledBp]);

  const [selectedValues, setSelectedValues] = useState(
    checkboxDataManualRecon.map((item) => ({
      ...item,
      isSelected: false,
    }))
  );

  useEffect(() => {
    // Calculate the sum of "amount" from selectedRowOwn
    const sumSelectedRowOwn =
      selectedRowOwn.length > 0 ? selectedRowOwn.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the sum of "amount" from selectedRowBP
    const sumSelectedRowBP =
      selectedRowBp.length > 0 ? selectedRowBp.reduce((accumulator, item) => accumulator + item.amount, 0) : 0;

    // Calculate the overall sum
    const overallSum = sumSelectedRowOwn + sumSelectedRowBP;
    setReconcileTotalSum(overallSum);
    const overallPercentage =
      (Math.abs(overallSum) * 100) / Math.max(Math.abs(sumSelectedRowOwn), Math.abs(sumSelectedRowBP));
    setReconcileTotalPercentage(overallPercentage.toFixed(2));
    setReconcileOwnSum(sumSelectedRowOwn);
    setReconcileBpSum(sumSelectedRowBP);
    setAbsoluteReconcileTotalSum(Math.abs(overallSum));

    // Extract entry numbers from selectedRowOwn
    const entryNumbersArrayOwn = selectedRowOwn?.map((item) => item.entryNo);
    setSelectedEntryNumbersOwn(entryNumbersArrayOwn);
    const entryNumbersArrayBp = selectedRowBp?.map((item) => item.entryNo);
    setSelectedEntryNumbersBp(entryNumbersArrayBp);
  }, [selectedRowOwn, selectedRowBp]);

  const isAllStrikethroughChecked = selectedValues.every((checkbox) => !checkbox.isValid || checkbox?.isSelected);

  const handleChangeParent = (event) => {
    const isChecked = event.target.checked;
    setSelectedValues(
      selectedValues.map((checkbox) => (!checkbox.isValid ? checkbox : { ...checkbox, isSelected: isChecked }))
    );
  };

  const handleChangeChild = (index) => (event) => {
    if (checkboxDataManualRecon[index].isValid) {
      const newSelectedValues = [...selectedValues];
      newSelectedValues[index].isSelected = event.target.checked;
      setSelectedValues(newSelectedValues);
    }
  };

  // -----

  const preAppliedChanges = async (changes) => {
    await useFetch("/api/PreAppliedChanges", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        changes,
      },
      thenCallBack: () => {
        getManuallyChangedEntries();
      },
    });
  };

  const applyAllChanges = async () => {
    // from selectedValues filter out selected true
    // then remove isSelected key and pass that array in to function
    // Filter out objects where isSelected is true
    const filteredIsSelectedData = selectedValues?.filter((item) => item?.isSelected);
    // Remove isSelected key from filteredIsSelectedData
    const filteredDataWithoutSelectedKey = filteredIsSelectedData?.map(({ isSelected, ...rest }) => rest);
    await preAppliedChanges(filteredDataWithoutSelectedKey);
    handleCloseApplyAllChanges();
  };

  const handleCloseApplyAllChanges = () => {
    setShowApplyOptions(false);
  };

  const getManuallyUnreconciledEntries = async (ownEntries, businessPartnerEntries, showTable) => {
    await useFetch("/api/GetManuallyUnreconciledEntries", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        ownEntries,
        businessPartnerEntries,
      },
      thenCallBack: (response) => {
        if (showTable === "showReconcileTable") {
          setRowsDataReconciledOwn(response.data.data.own);
          setStoreRowsDataReconciledOwn(response.data.data.own);
          setRowsDataReconciledBp(response.data.data.businessPartner);
          setStoreRowsDataReconciledBp(response.data.data.businessPartner);
        }
      },
    });
  };

  const getManuallyReconciledEntries = async (ownEntries, businessPartnerEntries, showTable) => {
    await useFetch("/api/GetManuallyReconciledEntries", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        ownEntries,
        businessPartnerEntries,
      },
      thenCallBack: (response) => {
        if (showTable === "showUnReconcileTable") {
          setRowsDataUnreconciled(response.data.data);
          const responseData: MatchedEntriesForManualRecon[] = response.data.data as MatchedEntriesForManualRecon[];
          // Extract unique matchId values
          const uniqueMatchIds = [...new Set(responseData.map((item) => item.matchId))];
          setMatchIdValueArr(uniqueMatchIds);
        }
      },
    });
  };

  const showDetailsOfEntriesOnMatchId = async (matchId) => {
    await useFetch("/api/ShowDetailsOfEntriesOnMatchId", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        matchId,
      },
      thenCallBack: (response) => {
        setRowsDataUnreconciled(response.data.data);
      },
      catchCallBack: () => {
        setRowsDataUnreconciled([]);
      },
    });
  };
  const unReconcileEntriesOnMatchId = async () => {
    setShowLoadingIconUnreconcile(true);
    await useFetch("/api/UnreconcileEntriesOnMatchId", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        matchId: matchIdValueArr,
      },
      thenCallBack: () => {
        setRowsDataUnreconciled([]);
        getManuallyChangedEntries();
        setMatchIdValue("");
        setIsOpenUnreconcileConfirmWarningModal(false);
        setShowLoadingIconUnreconcile(false);
      },
      catchCallBack: () => {
        setShowLoadingIconUnreconcile(false);
      },
    });
  };
  const reconcileEntries = async (gapAccountedAs) => {
    setShowLoadingIconReconcile(true);
    setGapAccountedName(gapAccountedAs);
    await useFetch("/api/ReconcileEntries", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        entryNumbersOwn: selectedEntryNumbersOwn,
        entryNumbersBusinessPartner: selectedEntryNumbersBp,
        gapAccountedAs,
      },
      thenCallBack: () => {
        setRowsDataReconciledOwn([]);
        setStoreRowsDataReconciledOwn([]);
        setRowsDataReconciledBp([]);
        setStoreRowsDataReconciledBp([]);
        setSelectedEntryNumbersOwn([]);
        setSelectedEntryNumbersBp([]);
        setReconcileTotalSum(0);
        setReconcileTotalPercentage(0);
        setReconcileOwnSum(0);
        setReconcileBpSum(0);
        setAbsoluteReconcileTotalSum(0);
        setSelectedRowOwn([]);
        setSelectedRowBp([]);
        getManuallyChangedEntries();
        setIsOpenReconcileConfirmWarningModal(false);
        setShowLoadingIconReconcile(false);
        setGapAccountedName(null);
        getUnreconciledEntriesOnReconcileClick();
      },
      catchCallBack: () => {
        setShowLoadingIconReconcile(false);
        setGapAccountedName(null);
      },
    });
  };
  const getUnreconciledEntriesOnReconcileClick = async () => {
    setLoadingReconcileTableOwn(true);
    setLoadingReconcileTableBp(true);
    await useFetch("/api/GetUnreconciledEntries", "GET", {
      config: {
        params: {
          businessPartnerId: businessPartnerSelected,
          businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
          ownCompanyId: companyId.current,
        },
      },
      thenCallBack: (response) => {
        setRowsDataReconciledOwn(response.data.data.own);
        setStoreRowsDataReconciledOwn(response.data.data.own);
        setRowsDataReconciledBp(response.data.data.businessPartner);
        setStoreRowsDataReconciledBp(response.data.data.businessPartner);
        setReFilter(true);
        setLoadingReconcileTableOwn(false);
        setLoadingReconcileTableBp(false);
      },
    });
  };

  const unReconcileShowDetailsClick = () => {
    const cleanedValue = matchIdValue.replace(/\s*,\s*/g, " "); // Replace commas surrounded by spaces with a single space
    const arrayFromString = cleanedValue.split(/\s+/).filter(Boolean);
    setMatchIdValueArr(arrayFromString);
    showDetailsOfEntriesOnMatchId(arrayFromString);
  };

  const handleEnterKeyPress = (e) => {
    if (e.keyCode === 13) {
      textFieldRefUnReconcile.current.blur(); // Remove focus from the TextField
      unReconcileShowDetailsClick();
    }
  };
  const handleEnterKeyPressReconcileOwn = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileOwn.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledOwnFromEntryNum();
    }
  };
  const handleEnterKeyPressReconcileBp = (e) => {
    if (e.keyCode === 13) {
      textFieldRefReconcileBp.current.blur(); // Remove focus from the TextField
      filterRowsDataReconciledBpFromEntryNum();
    }
  };

  const filterRowsDataReconciledOwnFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledOwn;
    const entryNumArray = entryNumOwn.split(/[ ,]+/);
    if (entryNumOwn?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => {
        // Convert item.entryNo to lowercase string for case-insensitive comparison
        const entryNoAsString = String(item.entryNo).toLowerCase();

        // Check if entryNoAsString is included in entryNumArray (converted to lowercase)
        return entryNumArray.map((val) => val.toLowerCase()).includes(entryNoAsString);
      });
      setRowsDataReconciledOwn(filteredRowsData);
      setIsFilterAppliedOwnSide(true);
    } else {
      // entryNumOwn is empty or undefined, so do not apply filtering
      // Use the original data
      setIsFilterAppliedOwnSide(false);
      setRowsDataReconciledOwn(storeRowsDataReconciledOwn);
    }
  };
  const filterRowsDataReconciledBpFromEntryNum = () => {
    const tempRowData = storeRowsDataReconciledBp;
    const entryNumArray = entryNumBp.split(/[ ,]+/);
    if (entryNumBp?.trim()) {
      const filteredRowsData = tempRowData?.filter((item) => {
        // Convert item.entryNo to lowercase string for case-insensitive comparison
        const entryNoAsString = String(item.entryNo).toLowerCase();

        // Check if entryNoAsString is included in entryNumArray (converted to lowercase)
        return entryNumArray.map((val) => val.toLowerCase()).includes(entryNoAsString);
      });
      setRowsDataReconciledBp(filteredRowsData);
      setIsFilterAppliedBpSide(true);
    } else {
      setIsFilterAppliedBpSide(false);
      setRowsDataReconciledBp(storeRowsDataReconciledBp);
    }
  };

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    clearChangeReconcileStatusStates();
    setValue(newValue);
    if (newValue === 2) {
      // reconcile tab
      getUnreconciledEntriesOnReconcileClick();
    }
  };

  const createAndFetchManualReconSuggestions = async (create) => {
    setStatusDataFetching(true);
    await useFetch("/api/CreateAndFetchManualReconSuggestions", "POST", {
      showSuccessToast: create !== "" ? true : false,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        userAction: create,
      },
      thenCallBack: (response) => {
        setLoader(false);
        setStatusDataFetching(false);
        setSuggestionsPresent(response.data?.data?.SuggestionsPresent);
        setRowsDataSuggestion(response.data?.data?.SuggestionSummary);
      },
      catchCallBack: () => {
        setLoader(false);
        setStatusDataFetching(false);
      },
    });
  };

  const reconcileSuggestedMatch = async () => {
    setReconcileSuggestionsLoading(true);
    const matchIds = selectedSuggestedEntry?.map((entry) => entry.matchId);
    await useFetch("/api/ReconcileSuggestedMatch", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        userAction: "selectAllSuggestion",
        suggestedIds: matchIds || [],
      },
      thenCallBack: (response) => {
        setShowEntries(null);
        setSelectedSuggestedEntry([]);
        setReconcileSuggestionsLoading(false);
        setSuggestionsPresent(response.data?.data?.SuggestionsPresent);
        setRowsDataSuggestion(response.data?.data?.SuggestionSummary);
      },
      catchCallBack: () => {
        setReconcileSuggestionsLoading(false);
      },
    });
  };

  const selectOptionFilterEntries = (e: any, value: string) => {
    clearChangeReconcileStatusStates();
    if (value !== null) {
      e.persist();
      setSelectedFilterEntry(value);
    } else {
      setSelectedFilterEntry(null);
    }
  };
  const selectOptionReconcileStatus = (e: any, value: string) => {
    if (value !== null) {
      e.persist();
      setSelectedOptionReconcileStatus(value);
    } else {
      setSelectedOptionReconcileStatus(null);
    }
  };
  const selectOptionChangeReconcileStatus = (e: any, value: string) => {
    if (value !== null) {
      e.persist();
      setSelectedOptionChangeReconcileStatus(value);
    } else {
      setSelectedOptionChangeReconcileStatus(null);
    }
  };

  const getPossibleReconStatuses = async () => {
    await useFetch("/api/GetPossibleReconStatuses", "GET", {
      thenCallBack: (response) => {
        setOptionsReconcileStatus(response.data.data.allReconStatuses);
      },
    });
  };

  const getReconciledEntries = async () => {
    setGetReconciledEntriesLoading(true);
    await useFetch("/api/ShowDetailsOfEntriesOnMatchId", "POST", {
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        matchId: convertArrayFromString(matchIDChangeStatus),
        reconciliationStatus: selectedOptionReconcileStatus,
        ownEntryNumbers:
          selectLedger === showEntriesSuggestion.OWN ? convertArrayFromString(entryNumChangeStatus.toUpperCase()) : [],
        partnerEntryNumbers:
          selectLedger === showEntriesSuggestion.BP ? convertArrayFromString(entryNumChangeStatus.toUpperCase()) : [],
      },
      thenCallBack: (response) => {
        setGetReconciledEntriesLoading(false);
        setShowChangeStatus(true);
        setRowsDataChangeReconcileStatus(response.data.data);
      },
      catchCallBack: () => {
        setGetReconciledEntriesLoading(false);
      },
    });
  };
  const updateReconStatusManually = async () => {
    setUpdateReconStatusManuallyLoading(true);
    const matchId = selectedRowChangeReconcileStatus?.map((entry) => entry.matchId);
    const ownEntryNumbers = selectedRowChangeReconcileStatus?.map((entry) => entry.ownEntryNumber);
    const businessPartnerEntryNumbers = selectedRowChangeReconcileStatus?.map(
      (entry) => entry.businessPartnerEntryNumber
    );
    await useFetch("/api/UpdateReconStatusManually", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
        branchCode: branchCode.current,
        matchId,
        ownEntryNumbers,
        businessPartnerEntryNumbers,
        reconciliationStatus: selectedOptionChangeReconcileStatus,
      },
      thenCallBack: () => {
        setSelectedFilterEntry(null);
        clearChangeReconcileStatusStates();
        setUpdateReconStatusManuallyLoading(false);
      },
      catchCallBack: () => {
        setUpdateReconStatusManuallyLoading(false);
      },
    });
  };

  function convertArrayFromString(matchIdValue) {
    const cleanedValue = matchIdValue.replace(/\s*,\s*/g, " "); // Replace commas surrounded by spaces with a single space
    const arrayFromString = cleanedValue.split(/\s+/).filter(Boolean);
    return arrayFromString;
  }

  const reconcileOutOfPeriodEntries = async () => {
    setAutoOutOfPeriodLoading(true);
    // ParamsStateUpdate({ AOOP: "true" });
    await useFetch("/api/ReconcileOutOfPeriodEntries", "POST", {
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        businessPartnerCompanyId: businessPartnerSelectedCompanyIdRef.current,
        ownCompanyId: companyId.current,
      },
      thenCallBack: () => {
        setAutoOutOfPeriodLoading(false);
      },
      catchCallBack: () => {
        setAutoOutOfPeriodLoading(false);
      },
    });
  };

  const clearChangeReconcileStatusStates = () => {
    setEntryNumChangeStatus("");
    setMatchIDChangeStatus("");
    setSelectLedger(null);
    setSelectedOptionReconcileStatus(null);
    setSelectedOptionChangeReconcileStatus(null);
    setShowChangeStatus(false);
    setRowsDataChangeReconcileStatus([]);
    setSelectedRowChangeReconcileStatus([]);
    setSelectedFilterEntry(null);
  };
  const manualReconContextValue: ManualReconContextInterface = {
    isOpenUnreconcileConfirmWarningModal,
    setIsOpenUnreconcileConfirmWarningModal,
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    unReconcileEntriesOnMatchId,
    reconcileEntries,
    reconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    amountTolerance,
    openManualReconReconcileOwnFilter,
    setOpenManualReconReconcileOwnFilter,
    storeRowsDataReconciledOwn,
    entryNumOwn,
    setEntryNumOwn,
    setIsFilterAppliedOwnSide,
    setRowsDataReconciledOwn,
    openManualReconReconcileBpFilter,
    setOpenManualReconReconcileBpFilter,
    storeRowsDataReconciledBp,
    entryNumBp,
    setEntryNumBp,
    setIsFilterAppliedBpSide,
    setRowsDataReconciledBp,
    absoluteReconcileTotalSum,
    showLoadingIconUnreconcile,
    showLoadingIconReconcile,
    gapAccountedName,
    reFilter,
    setReFilter,
    isFilterAppliedOwnSide,
    isFilterAppliedBpSide,
    reconcileTotalPercentage,
  };

  return (
    <>
      <ManualReconContext.Provider value={manualReconContextValue}>
        <Grid>
          <div>
            <Grid container={true} className=" manual_box_container" sx={{ position: "relative" }}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider", textAlign: "center" }} className="space_between">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    <Tab label="Suggested Matches" {...a11yProps(0)} sx={{ textTransform: "none" }} />
                    <Tab label="Change Reconciliation Status" {...a11yProps(1)} sx={{ textTransform: "none" }} />
                    <Tab label="Reconcile" {...a11yProps(2)} sx={{ textTransform: "none" }} />
                    <Tab label="Unreconcile" {...a11yProps(3)} sx={{ textTransform: "none" }} />
                    <Tab label="Out of Period" {...a11yProps(4)} sx={{ textTransform: "none" }} />
                  </Tabs>
                  <Tooltip
                    title={autoOutOfPeriodLoading ? "Please wait until Auto out of period action is completed." : ""}
                    classes={{ tooltip: "fs_12" }}
                  >
                    <Button
                      className="theme_outline_btn"
                      onClick={() => {
                        if (autoOutOfPeriodLoading) return;
                        setOpenManualRecon(false);
                        getSummaryForReconciliationCommonFunction();
                        uiLogger(
                          uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_BACK_CLICK.functionName,
                          companyId.current,
                          branchCode.current,
                          {
                            message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_BACK_CLICK.message,
                            businessPartnerId: businessPartnerSelectedRef.current,
                          }
                        );
                      }}
                      sx={{ right: "310px" }}
                    >
                      Back
                    </Button>
                  </Tooltip>
                </Box>
                <TabPanel value={value} index={0}>
                  {loader ? (
                    <Loader />
                  ) : (
                    <Grid className="fade_in">
                      {suggestionsPresent ? (
                        <>
                          <Grid>
                            <Button
                              onClick={() => {
                                setShowEntries(null);
                                createAndFetchManualReconSuggestions("create");
                                uiLogger(
                                  uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_REFRESH_SUGGESTION_CLICK
                                    .functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message:
                                      uiLoggerNamesRecon
                                        .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_REFRESH_SUGGESTION_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                              className="theme_btn"
                              style={{ width: "211px" }}
                              startIcon={
                                statusDataFetching ? (
                                  <LoadingIcon loading={statusDataFetching} />
                                ) : (
                                  <Sync className={statusDataFetching ? "loading-active" : ""} />
                                )
                              }
                              disabled={statusDataFetching}
                            >
                              Refresh Suggestion
                            </Button>
                          </Grid>
                          <Grid
                            item={true}
                            style={{ width: "fit-content", borderRadius: "12px", border: "none", overflow: "hidden" }}
                            className="mt_20 suggestion_table"
                          >
                            <table style={{ width: "fit-content", border: "none", overflow: "hidden" }}>
                              <tr>
                                <th className="suggestion_header">Suggestion</th>
                                <th className="header">Recon Type</th>
                                <th className="header">Match Count</th>
                                <th className="header">Total Value</th>
                                <th className="header"></th>
                              </tr>
                              {rowsDataSuggestion.map((row, index) => (
                                <tr key={index}>
                                  <td className="suggestionMessage">{row.suggestionMessage}</td>
                                  <td className="text_center">{row.reconType}</td>
                                  <td className="text_center">{row.matchCount}</td>
                                  <td className="text_center">
                                    {row.amount !== null
                                      ? formatMoney(ToDineroObj(row.amount, currency.current as Currency))
                                      : "-"}
                                  </td>
                                  <td className="text_center">
                                    <button
                                      className="view_more"
                                      onClick={() => {
                                        setShowEntries(row.showEntries);
                                        setSuggestedEntryTrackIndex(index);
                                        uiLogger(
                                          uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_CLICK
                                            .functionName,
                                          companyId.current,
                                          branchCode.current,
                                          {
                                            message:
                                              uiLoggerNamesRecon
                                                .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_CLICK.message,
                                            businessPartnerId: businessPartnerSelectedRef.current,
                                          }
                                        );
                                      }}
                                    >
                                      View More
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </table>
                          </Grid>
                          {
                            // showViewMore
                            showEntries && (
                              <>
                                <Grid
                                  item={true}
                                  md={showEntries === showEntriesSuggestion.BOTH ? 12 : 6}
                                  className="mt_30"
                                  style={{ width: "fit-content" }}
                                >
                                  <SuggestedMatchTable
                                    columns={columnDefinitionSuggestionViewMore}
                                    rows={rowsDataSuggestion?.[suggestedEntryTrackIndex].suggestedEntryTrack}
                                    sortEnable={false}
                                    fromTab="ManualRecon"
                                    actorType="BPDashboard"
                                    setSelectedRow={setSelectedSuggestedEntry}
                                  />

                                  <Grid className="mt_30" display="flex" justifyContent="center" position="relative">
                                    <Button
                                      disabled={reconcileSuggestionsLoading || selectedSuggestedEntry?.length === 0}
                                      startIcon={<LoadingIcon loading={reconcileSuggestionsLoading} />}
                                      onClick={() => {
                                        reconcileSuggestedMatch();
                                        uiLogger(
                                          uiLoggerNamesRecon
                                            .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_RECONCILE_CLICK
                                            .functionName,
                                          companyId.current,
                                          branchCode.current,
                                          {
                                            message:
                                              uiLoggerNamesRecon
                                                .UI_RL_RECONCILE_MANUALLY_SUGGESTED_MATCHES_VIEW_MORE_RECONCILE_CLICK
                                                .message,
                                            businessPartnerId: businessPartnerSelectedRef.current,
                                          }
                                        );
                                      }}
                                      className="theme_btn theme_btn_purple"
                                      style={{ width: "202px" }}
                                    >
                                      Reconcile
                                    </Button>
                                  </Grid>
                                </Grid>
                              </>
                            )
                          }
                        </>
                      ) : (
                        <>
                          <Grid>
                            <Grid md={8}>
                              <Card className="p_20">
                                <div className="fs_26 fw_700">Reduce your efforts in Manual Reconciliation</div>
                                <div className="fs_18 fw_400">
                                  Make your Manual Reconciliation simple by our AI-powered Suggested Matches
                                </div>
                                <div className="mt_60">
                                  <Button
                                    onClick={() => {
                                      createAndFetchManualReconSuggestions("create");
                                    }}
                                    className={
                                      statusDataFetching
                                        ? "theme_outline_btn w_250"
                                        : "theme_btn theme_btn_purple w_250"
                                    }
                                    disabled={statusDataFetching}
                                    startIcon={<LoadingIcon loading={statusDataFetching} />}
                                  >
                                    {statusDataFetching ? "Generating Matches" : "Get Suggested Matches"}
                                  </Button>
                                </div>
                              </Card>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <>
                    <Grid className="fade_in">
                      <Grid md={10}>
                        <Card className="p_20">
                          <div className="space_between">
                            <div className="d_flex">
                              <div>
                                <Autocomplete
                                  onChange={(e, value) => selectOptionFilterEntries(e, value)}
                                  value={selectedFilterEntry}
                                  id="optionsFilterEntries"
                                  className="w_210"
                                  options={optionsFilterEntries}
                                  size="small"
                                  getOptionLabel={(option) => option}
                                  disableClearable={true}
                                  renderInput={(params) => <TextField {...params} placeholder="Filter Entries By" />}
                                />
                              </div>

                              {selectedFilterEntry === "Entry Number" && (
                                <>
                                  <div className="ml_10">
                                    <TextField
                                      className="pl_10 w_300"
                                      size="small"
                                      variant="outlined"
                                      fullWidth={true}
                                      name="name"
                                      placeholder="Enter Entry Number"
                                      value={entryNumChangeStatus}
                                      onChange={(e) => {
                                        setEntryNumChangeStatus(e.target.value);
                                        setShowChangeStatus(false);
                                      }}
                                      sx={{ background: "white" }}
                                    />
                                  </div>

                                  <div className="vertical_center_align ml_10">
                                    <span className="mr_10">Select Ledger</span>
                                    <FormControl>
                                      <RadioGroup
                                        row={true}
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={selectLedger}
                                        onChange={(e) => {
                                          setSelectLedger(e.target.value);
                                          setShowChangeStatus(false);
                                        }}
                                      >
                                        <FormControlLabel
                                          value={showEntriesSuggestion.OWN}
                                          control={<Radio />}
                                          label="Own"
                                        />
                                        <FormControlLabel
                                          value={showEntriesSuggestion.BP}
                                          control={<Radio />}
                                          label="BP"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </div>
                                </>
                              )}

                              {selectedFilterEntry === "Match ID" && (
                                <div className="ml_10">
                                  <TextField
                                    className="pl_10 w_300"
                                    size="small"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="name"
                                    placeholder="Enter Match ID"
                                    value={matchIDChangeStatus}
                                    onChange={(e) => {
                                      setMatchIDChangeStatus(e.target.value);
                                      setShowChangeStatus(false);
                                    }}
                                    sx={{ background: "white" }}
                                  />
                                </div>
                              )}
                              {selectedFilterEntry === "Reconciliation Status" && (
                                <div className="ml_10">
                                  <Autocomplete
                                    onChange={(e, value) => {
                                      selectOptionReconcileStatus(e, value), setShowChangeStatus(false);
                                    }}
                                    id="optionsReconcileStatus"
                                    className="w_210"
                                    options={optionsReconcileStatus}
                                    size="small"
                                    getOptionLabel={(option) => option}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                      <TextField {...params} placeholder="Select Recon Status" />
                                    )}
                                  />
                                </div>
                              )}
                            </div>
                            <div>
                              <Button
                                disabled={
                                  getReconciledEntriesLoading ||
                                  selectedFilterEntry === null ||
                                  (selectedFilterEntry === "Entry Number" &&
                                    (entryNumChangeStatus?.trim() === "" || selectLedger === null)) ||
                                  (selectedFilterEntry === "Match ID" && matchIDChangeStatus?.trim() === "") ||
                                  (selectedFilterEntry === "Reconciliation Status" &&
                                    selectedOptionReconcileStatus === null)
                                }
                                startIcon={<LoadingIcon loading={getReconciledEntriesLoading} />}
                                onClick={() => {
                                  getReconciledEntries();
                                }}
                                className="theme_btn"
                              >
                                View Entries
                              </Button>
                            </div>
                          </div>
                        </Card>
                      </Grid>
                      {showChangeStatus && (
                        <Grid className="mt_20">
                          <Grid>
                            <div className="d_flex">
                              <div className="ml_10">
                                <Autocomplete
                                  onChange={(e, value) => selectOptionChangeReconcileStatus(e, value)}
                                  id="optionsReconcileStatus"
                                  className="w_210"
                                  options={optionsReconcileStatus}
                                  size="small"
                                  getOptionLabel={(option) => option}
                                  disableClearable={true}
                                  renderInput={(params) => <TextField {...params} placeholder="Change Status" />}
                                />
                              </div>
                              <div className="ml_10">
                                <Button
                                  disabled={
                                    updateReconStatusManuallyLoading ||
                                    selectedOptionChangeReconcileStatus === null ||
                                    selectedRowChangeReconcileStatus.length === 0
                                  }
                                  startIcon={<LoadingIcon loading={updateReconStatusManuallyLoading} />}
                                  onClick={() => {
                                    updateReconStatusManually();
                                    uiLogger(
                                      uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGE_RECON_STATUS_APPLY_CLICK
                                        .functionName,
                                      companyId.current,
                                      branchCode.current,
                                      {
                                        message:
                                          uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGE_RECON_STATUS_APPLY_CLICK
                                            .message,
                                        businessPartnerId: businessPartnerSelectedRef.current,
                                      }
                                    );
                                  }}
                                  className="theme_btn"
                                >
                                  Apply
                                </Button>
                              </div>
                            </div>
                          </Grid>
                          <Grid container={true} className="mt_20">
                            <Grid item={true} md={12}>
                              <BpDashboardTable
                                columns={columnDefinitionChangeReconcileStatus}
                                setSelectedRow={setSelectedRowChangeReconcileStatus}
                                rows={rowsDataChangeReconcileStatus}
                                sortEnable={true}
                                fromTab="ManualRecon"
                                actorType="BPDashboard"
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <>
                    <Grid
                      container={true}
                      className="min_height_500 manual_box_container fade_up"
                      sx={{ position: "relative" }}
                    >
                      <Grid item={true} md={12} className="br_10 main_tables_box">
                        <>
                          <Grid className="mb_15" justifyContent="center" display="flex">
                            <div className="center_align matchId_heading">
                              Own Sum: {formatMoney(ToDineroObj(reconcileOwnSum, currency.current as Currency))}
                            </div>
                            <div className="center_align matchId_heading ml_10 mr_10">
                              Gap: {formatMoney(ToDineroObj(reconcileTotalSum, currency.current as Currency))}{" "}
                              {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0
                                ? `(${reconcileTotalPercentage} %)`
                                : ""}
                            </div>
                            <div className="center_align matchId_heading">
                              BP Sum: {formatMoney(ToDineroObj(reconcileBpSum, currency.current as Currency))}
                            </div>
                          </Grid>
                          <Grid container={true} className="">
                            <Grid item={true} md={5.9}>
                              <Grid container={true} className="mb_15">
                                <Grid item={true} md={7}>
                                  <TextField
                                    inputRef={textFieldRefReconcileOwn}
                                    className="pl_10"
                                    size="small"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="name"
                                    placeholder="Insert Entry no.s like 23, 24"
                                    value={entryNumOwn}
                                    onChange={(e) => {
                                      setEntryNumOwn(e.target.value);
                                      setIsFilterAppliedOwnSide(true);
                                    }}
                                    onKeyDown={handleEnterKeyPressReconcileOwn}
                                    sx={{ background: "white" }}
                                  />
                                </Grid>
                                <Grid item={true} md={1} className="vertical_center_align">
                                  <span
                                    className="pl_10 cursor_pointer filter_icon"
                                    onClick={() => {
                                      setOpenManualReconReconcileOwnFilter(true);
                                    }}
                                  >
                                    {isFilterAppliedOwnSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                                  </span>
                                </Grid>
                                <Grid item={true} md={4} className="flex_end">
                                  <Button
                                    className="theme_btn"
                                    onClick={() => {
                                      setLoadingReconcileTableOwn(true);
                                      setTimeout(() => {
                                        setLoadingReconcileTableOwn(false);
                                      }, 500);

                                      filterRowsDataReconciledOwnFromEntryNum();
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </Grid>
                              </Grid>
                              {loadingReconcileTableOwn ? null : (
                                <>
                                  <BpDashboardTable
                                    columns={columnDefinitionReconciledOwn}
                                    rows={rowsDataReconciledOwn}
                                    setSelectedRow={setSelectedRowOwn}
                                    sortEnable={true}
                                    fromTab="ManualRecon"
                                    actorType="BPDashboard"
                                    setAllLeafColumns={setAllLeafColumnsOwn}
                                    savedSortState={savedTableSortOwn}
                                    setSavedSortingState={setSavedTableSortOwn}
                                  />
                                  <span style={{ fontSize: 11, color: "#777", textTransform: "capitalize" }}>
                                    {Object.entries(hiddenColsReconOwn).filter((entry) => entry[1]).length &&
                                      `These Columns Were Automatically Hidden: `}
                                    {Object.entries(hiddenColsReconOwn)
                                      .filter((entry) => entry[1])
                                      .map((filtered) => filtered[0])
                                      .join(", ")}
                                  </span>
                                </>
                              )}
                            </Grid>
                            <Grid item={true} md={0.2} /> {/* space between tables */}
                            <Grid item={true} md={5.9}>
                              <Grid container={true} className="mb_15">
                                <Grid item={true} md={7}>
                                  <TextField
                                    inputRef={textFieldRefReconcileBp}
                                    className="pl_10"
                                    size="small"
                                    variant="outlined"
                                    fullWidth={true}
                                    name="name"
                                    placeholder="Insert Entry no.s like 23, 24"
                                    value={entryNumBp}
                                    onChange={(e) => {
                                      setEntryNumBp(e.target.value);
                                      setIsFilterAppliedBpSide(true);
                                    }}
                                    onKeyDown={handleEnterKeyPressReconcileBp}
                                    sx={{ background: "white" }}
                                  />
                                </Grid>
                                <Grid item={true} md={1} className="vertical_center_align">
                                  <span
                                    className="pl_10 cursor_pointer filter_icon"
                                    onClick={() => {
                                      setOpenManualReconReconcileBpFilter(true);
                                    }}
                                  >
                                    {isFilterAppliedBpSide ? <FilterAltIcon /> : <FilterAltOffIcon />}
                                  </span>
                                </Grid>
                                <Grid item={true} md={4} className="flex_end">
                                  <Button
                                    className="theme_btn"
                                    onClick={() => {
                                      setLoadingReconcileTableBp(true);
                                      setTimeout(() => {
                                        setLoadingReconcileTableBp(false);
                                      }, 500);
                                      filterRowsDataReconciledBpFromEntryNum();
                                    }}
                                  >
                                    Show Details
                                  </Button>
                                </Grid>
                              </Grid>
                              {loadingReconcileTableBp ? null : (
                                <>
                                  <BpDashboardTable
                                    columns={columnDefinitionReconciledBp}
                                    rows={rowsDataReconciledBp}
                                    setSelectedRow={setSelectedRowBp}
                                    sortEnable={true}
                                    fromTab="ManualRecon"
                                    actorType="BPDashboard"
                                    setAllLeafColumns={setAllLeafColumnsBp}
                                    savedSortState={savedTableSortBp}
                                    setSavedSortingState={setSavedTableSortBp}
                                  />
                                  <span style={{ fontSize: 11, color: "#777", textTransform: "capitalize" }}>
                                    {Object.entries(hiddenColsReconBp).filter((entry) => entry[1]).length &&
                                      `These Columns Were Automatically Hidden: `}
                                    {Object.entries(hiddenColsReconBp)
                                      .filter((entry) => entry[1])
                                      .map((filtered) => filtered[0])
                                      .join(", ")}
                                  </span>
                                </>
                              )}
                            </Grid>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                    <Grid className="mt_30 fade_up" display="flex" justifyContent="center" position="relative">
                      <Button
                        disabled={(selectedRowOwn?.length < 1 && selectedRowBp?.length < 1) || showLoadingIconReconcile}
                        onClick={() => {
                          absoluteReconcileTotalSum < 1
                            ? reconcileEntries(gapAccountedAsName.roundingError)
                            : setIsOpenReconcileConfirmWarningModal(true);

                          uiLogger(
                            uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_RECONCILE_CLICK.functionName,
                            companyId.current,
                            branchCode.current,
                            {
                              message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_RECONCILE_CLICK.message,
                              businessPartnerId: businessPartnerSelectedRef.current,
                            }
                          );
                        }}
                        className="theme_btn w_300"
                        startIcon={<LoadingIcon loading={showLoadingIconReconcile} />}
                      >
                        Reconcile
                      </Button>
                    </Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <>
                    <Grid className="mb_15 fade_up" justifyContent="center" display="flex">
                      <div className="center_align matchId_heading">Type in the Match IDs You want to unreconcile</div>
                    </Grid>

                    <Grid
                      container={true}
                      className="min_height_500 manual_box_container fade_up"
                      sx={{ position: "relative" }}
                    >
                      <Grid item={true} md={12} className="br_10 main_tables_box">
                        <>
                          <Grid container={true} className="mb_15">
                            <Grid item={true} md={9}>
                              <TextField
                                inputRef={textFieldRefUnReconcile}
                                size="small"
                                variant="outlined"
                                fullWidth={false}
                                name="name"
                                placeholder="Insert Id's like I1, P7"
                                value={matchIdValue}
                                onChange={(e) => {
                                  setMatchIdValue(e.target.value);
                                }}
                                onKeyDown={handleEnterKeyPress}
                                sx={{ background: "#fff", width: "400px" }}
                              />
                            </Grid>
                            <Grid item={true} md={3} className="flex_end">
                              <Button
                                className="theme_btn"
                                onClick={() => {
                                  unReconcileShowDetailsClick();
                                }}
                              >
                                Show Details
                              </Button>
                            </Grid>
                          </Grid>
                          <Grid container={true}>
                            <Grid item={true} md={12}>
                              <BpDashboardTable
                                columns={columnDefinitionUnreconciled}
                                rows={rowsDataUnreconciled}
                                sortEnable={true}
                                fromTab="ManualRecon"
                              />
                            </Grid>
                          </Grid>
                          <Grid className="mt_30" display="flex" justifyContent="center" position="relative">
                            {/* {manualReconType === manualReconTypeName.UNRECONCILE && showUnreconcileButton && ( */}
                            <Button
                              onClick={() => {
                                setIsOpenUnreconcileConfirmWarningModal(true);
                                uiLogger(
                                  uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_UNRECONCILE_CLICK.functionName,
                                  companyId.current,
                                  branchCode.current,
                                  {
                                    message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_UNRECONCILE_CLICK.message,
                                    businessPartnerId: businessPartnerSelectedRef.current,
                                  }
                                );
                              }}
                              disabled={rowsDataUnreconciled.length === 0}
                              className="theme_btn theme_btn_warning w_300"
                            >
                              Unreconcile
                            </Button>
                          </Grid>
                        </>
                      </Grid>
                    </Grid>
                  </>
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <>
                    <Grid className="mb_15 fade_in" justifyContent="center" display="flex">
                      <Button
                        disabled={autoOutOfPeriodLoading}
                        startIcon={<LoadingIcon loading={autoOutOfPeriodLoading} />}
                        onClick={() => {
                          reconcileOutOfPeriodEntries();
                        }}
                        className="theme_btn"
                      >
                        Auto Checking for out of Period
                      </Button>
                    </Grid>
                  </>
                </TabPanel>
              </Box>
              <Card className={showChangesDoneCard ? "changes_done active" : "changes_done"} component={"div"}>
                <IconButton
                  className="open_changes_icon"
                  onClick={() => {
                    setShowChangesDoneCard(!showChangesDoneCard);
                    uiLogger(
                      uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGES_DONE_ARROW_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        message: uiLoggerNamesRecon.UI_RL_RECONCILE_MANUALLY_CHANGES_DONE_ARROW_CLICK.message,
                        businessPartnerId: businessPartnerSelectedRef.current,
                      }
                    );
                  }}
                >
                  <ChevronLeft sx={{ rotate: showChangesDoneCard ? "180deg" : "0", transition: "inherit" }} />
                </IconButton>
                <Grid>
                  {!hasManuallyReconciled ? (
                    <div className="changes_done_heading">Last Time Changes</div>
                  ) : (
                    <div className="changes_done_heading">Changes Done</div>
                  )}

                  <div className="changes_box">
                    <Box sx={{ display: "flex", flexDirection: "column", px: 2, py: 1 }} className="total_changes_box">
                      <FormControlLabel
                        label={`Total: ${
                          totalAmount ?? formatMoney(ToDineroObj(totalAmount, currency.current as Currency))
                        }`}
                        className="changes_done_total"
                        control={
                          <Checkbox
                            checked={isAllStrikethroughChecked}
                            indeterminate={
                              !isAllStrikethroughChecked && selectedValues.some((checkbox) => checkbox?.isSelected)
                            }
                            onChange={handleChangeParent}
                            style={{
                              opacity: !hasManuallyReconciled ? "1" : "0",
                              cursor: "unset",
                            }}
                          />
                        }
                        sx={{
                          mx: 0,
                          my: 0,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: !hasManuallyReconciled ? "300px" : "370px",
                        overflowY: "auto",
                        px: 2,
                        py: 1,
                        gap: 0.5,
                      }}
                      className="changes_done_box"
                    >
                      {checkboxDataManualRecon.map((data, index) => (
                        <FormControlLabel
                          className={
                            data.status === manualReconStatusName.manuallyUnreconciled
                              ? "changes_done_entries_unReconcile"
                              : "changes_done_entries_reconcile"
                          }
                          key={index}
                          label={
                            data.amount !== null
                              ? formatMoney(ToDineroObj(data.amount, currency.current as Currency))
                              : "-"
                          }
                          onClick={() => {
                            if (data.isValid) {
                              if (hasManuallyReconciled) {
                                if (data.status === manualReconStatusName.manuallyUnreconciled) {
                                  getManuallyUnreconciledEntries(
                                    data.ownEntries,
                                    data.businessPartnerEntries,
                                    "showReconcileTable"
                                  );
                                } else {
                                  getManuallyReconciledEntries(
                                    data.ownEntries,
                                    data.businessPartnerEntries,
                                    "showUnReconcileTable"
                                  );
                                }
                              } else {
                                if (data.status === manualReconStatusName.manuallyUnreconciled) {
                                  getManuallyReconciledEntries(
                                    data.ownEntries,
                                    data.businessPartnerEntries,
                                    "showUnReconcileTable"
                                  );
                                } else {
                                  getManuallyUnreconciledEntries(
                                    data.ownEntries,
                                    data.businessPartnerEntries,
                                    "showReconcileTable"
                                  );
                                }
                              }
                            }
                          }}
                          control={
                            <Checkbox
                              checked={selectedValues[index]?.isSelected}
                              onChange={handleChangeChild(index)}
                              value={data.amount}
                              style={{
                                opacity: data.isValid && !hasManuallyReconciled ? "1" : "0",
                                // cursor: data.isValid && hasManuallyReconciled ? "pointer" : "unset",
                              }}
                            />
                          }
                          sx={{
                            mx: 0,
                            my: 0,
                            textDecoration: data.isValid ? "none" : "line-through",
                          }}
                        />
                      ))}
                    </Box>
                  </div>
                  {!hasManuallyReconciled && (
                    <div>
                      <div className="apply_changes">
                        <Button
                          className="apply_changes_text"
                          onClick={() => {
                            setShowApplyOptions(true);
                          }}
                          // disabled={selectedValues.length < 1 || selectedValues?.every((item) => item?.isSelected === false)}
                        >
                          Apply All Changes?
                        </Button>
                      </div>
                      {showApplyOptions && (
                        <div className="center_align">
                          <CheckIcon
                            className="cursor_pointer"
                            style={{ color: "#27b27c" }}
                            onClick={() => {
                              applyAllChanges();
                            }}
                          />
                          <ClearIcon
                            className="cursor_pointer"
                            style={{ marginLeft: "20px", color: "red" }}
                            onClick={() => {
                              handleCloseApplyAllChanges();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </Grid>
              </Card>
            </Grid>
          </div>

          <UnreconcileConfirmWarningModal />
          <ReconcileConfirmWarningModal />
          <ManualReconReconcileOwnFilter />
          <ManualReconReconcileBpFilter />
        </Grid>
      </ManualReconContext.Provider>
    </>
  );
};

export default ManualRecon;
