import { Button, TextField } from "@mui/material";
import Card from "@mui/material/Card";
import React, { useEffect, useMemo } from "react";
import { useBlockLayout, useExpanded, useFilters, usePagination, useRowSelect, useSortBy, useTable } from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import "./PdfTable.scss";
import "./Table.scss";

const defaultPropGetter = () => ({});

function PdfTable({
  columns,
  rows,
  getCellProps = defaultPropGetter,
  id,
  sortEnable,
  renderRowSubComponent,
  setSelectedRow,
  actorType,
  pdfTableHeading,
  errorMessage,
  highlightedColumns,
}: any) {
  const columnsData: any = useMemo(() => columns, [columns]);

  const rowsData: any = useMemo(() => rows, [rows]);

  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    state,
    prepareRow,
    visibleColumns,
    selectedFlatRows,
    // state: { selectedRowIds },
  } = useTable(
    {
      columns: columnsData,
      data: rowsData,
      defaultColumn,
      initialState: { pageSize: 200 },
    },

    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useBlockLayout,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    if (actorType === "admin") {
      const filterselectedFlatRows = selectedFlatRows.map((item) => item.original);
      setSelectedRow(filterselectedFlatRows);
    }
  }, [selectedFlatRows, setSelectedRow, actorType]);

  return (
    <Card style={{ overflowX: "scroll", padding: "20px" }}>
      {/* <CssBaseline /> */}
      <table {...getTableProps()} id={id} style={{ borderRadius: "1%" }} className="pdf-table-row">
        {pdfTableHeading ? (
          <thead>
            <tr>
              <th className="pdfTableHeading" />
            </tr>
            {errorMessage ? (
              <tr>
                <th className="errorMessage">{errorMessage}</th>
              </tr>
            ) : null}
          </thead>
        ) : (
          <thead>
            {headerGroups.map((headerGroup, iHead) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={"thead_tr" + iHead}>
                {headerGroup.headers.map((column, iRow) => (
                  <th {...column.getHeaderProps()} key={"th" + iRow}>
                    {sortEnable ? (
                      <div className="column" {...column.getSortByToggleProps()}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div>
                              {column.render("Header")} <i className="fa fa-sort-desc" aria-hidden="true" />
                            </div>
                          ) : (
                            <div>
                              {column.render("Header")} <i className="fa fa-sort-asc" aria-hidden="true" />
                            </div>
                          )
                        ) : (
                          <div>
                            {column.render("Header")}{" "}
                            <i className="fa fa-sort" style={{ display: "none" }} aria-hidden="true" />
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="column">{column.render("Header")} </div>
                    )}

                    {/* <div>
                      {column.canFilter ? column.render('Filter') : null}
                    </div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <tbody {...getTableBodyProps()}>
          {page.map((row, iRow) => {
            prepareRow(row);
            const rowProps = row.getRowProps();
            return (
              <React.Fragment key={rowProps.key}>
                <tr {...row.getRowProps()} key={"tbody_tr" + iRow}>
                  {row.cells.map((cell, iCell) => {
                    return (
                      <td
                        key={"td" + iCell}
                        {...cell.getCellProps([
                          {
                            className: cell.column,
                            style: cell.column,
                          },
                          getCellProps(cell),
                        ])}
                        className={pdfTableHeading ? "pdfTableHeading_border" : null}
                        style={{
                          backgroundColor: pdfTableHeading && highlightedColumns.includes(cell?.value) ? "#48c774" : "",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
                {row.isExpanded && renderRowSubComponent({ row, rowProps, visibleColumns })}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div style={{ paddingLeft: "10px", marginTop: "15px", textAlign: "center" }}>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        &nbsp;
        <span> | Go to page: &nbsp;</span>
        <TextField
          variant="outlined"
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(pageNumber);
          }}
          style={{ width: "50px" }}
          className="table_select_ui"
        />
        &nbsp;
        <select className="table_select_ui" value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
          {[10, 25, 50, 200].map((lineItems) => (
            <option key={lineItems} value={lineItems}>
              Show {lineItems}
            </option>
          ))}
        </select>
        &nbsp;
        <Button className="next_prevbtn" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>
        <Button className="next_prevbtn" onClick={() => previousPage()} disabled={!canPreviousPage}>
          Previous
        </Button>
        <Button className="next_prevbtn" onClick={() => nextPage()} disabled={!canNextPage}>
          Next
        </Button>
        <Button className="next_prevbtn" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>
      </div>
    </Card>
  );
}

export default PdfTable;
