import { RestartAlt } from "@mui/icons-material";
import { Box, Button, Tab, Tabs } from "@mui/material";
import React, { useContext, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { TabPanel } from "../../ReconSettings/ReconSettings";
import SendReminderTab from "../Common/SendReminderSettings";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import StatusResetWarningModal from "../CommonLegacy/StatusResetWarningModal";
import { LrBetaContext } from "./LedgerRequestBeta";

const LedgerRequestBetaSettings = ({
  companyId,
  branchCode,
  ...props
}: {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  listUserEmailTemplate: (name: string) => void;
}) => {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isOpenResetModal, setIsOpenResetModal] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { listAllBusinessPartnersWSR } = useContext(LrBetaContext);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleCloseResetModal = () => {
    setIsOpenResetModal(false);
  };

  const resetStatesPartnerCommunication = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.RESET_STATUS_FOR_LEDGER_REQUEST.url, "POST", {
      failureMessage: API_ENDPOINTS.RESET_STATUS_FOR_LEDGER_REQUEST.failureMessage,
      showSuccessToast: true,
      thenCallBack: (_response) => {
        listAllBusinessPartnersWSR();
        setShowLoadingIcon(false);
        handleCloseResetModal();
      },
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
      },
    });
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label="Reset Status" sx={{ textTransform: "none" }} />
          <Tab label="Send Reminder" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0}>
        {
          <div className="center_align">
            <Button
              variant="contained"
              className=""
              onClick={() => {
                setIsOpenResetModal(true);
              }}
              startIcon={<RestartAlt />}
              color="warning"
            >
              Reset status
            </Button>
          </div>
        }
      </TabPanel>
      <TabPanel value={tabIndex} index={1}>
        <SendReminderTab
          companyId={companyId}
          branchCode={branchCode}
          listUserEmailTemplate={props.listUserEmailTemplate}
          useCaseType={UseCaseType.ledgerRequestBeta}
        />
      </TabPanel>
      <StatusResetWarningModal
        isOpenResetModal={isOpenResetModal}
        handleCloseResetModal={handleCloseResetModal}
        handleReset={resetStatesPartnerCommunication}
        showLoadingIcon={showLoadingIcon}
      />
    </>
  );
};

export default LedgerRequestBetaSettings;
