import { Button, Tooltip } from "@mui/material";
import React, { useContext } from "react";
import { Dialog } from "src/Components/Dialog/Dialog";
import { transFormColumnHeader } from "../../../Utils/Recon/Recon360/Constants";
import Table from "../../ReactTable/Table";
import { Recon360Context } from "../Recon360";
import "./UploadedSummary.scss";

const UploadedSummary = (props: any) => {
  const { setWhichActionRequired, cancelPollingRequest } = useContext(Recon360Context);
  const columnDefinitionColumnMapping = React.useMemo(() => {
    let headers: string[] = [];
    if (props.showConfigMappedData?.columnHeader && props.showConfigMappedData?.columnHeader.length > 0) {
      headers = Object.keys(props.showConfigMappedData?.columnHeader[0]);
    }
    return headers.map((header) => ({
      Header: header,
      accessor: (row) => row[header], // Function accessor to handle special characters
      minWidth: 280,
      Cell: ({ value }: any) => <div>{transFormColumnHeader(value)}</div>,
    }));
    // eslint-disable-next-line
  }, [props.showConfigMappedData]);

  const columnDefinitionDocType = React.useMemo(() => {
    let headers: string[] = [];
    if (props.showConfigMappedData?.docType && props.showConfigMappedData?.docType.length > 0) {
      headers = Object.keys(props.showConfigMappedData?.docType[0]);
    }
    return headers.map((header) => ({
      Header: header,
      accessor: (row) => row[header], // Function accessor to handle special characters
      minWidth: 280,
    }));
  }, [props.showConfigMappedData]);

  return (
    <Dialog open={props.showUploadedSummary} onClose={() => props.setShowUploadedSummary(true)}>
      <header className="modal-card-head">
        {props.showConfigMapped ? (
          <p className="modal-card-title">Configuration Used</p>
        ) : (
          <p className="modal-card-title">Upload summary</p>
        )}
        <button
          className="delete"
          aria-label="close"
          onClick={() => {
            props.setShowUploadedSummary(false);
            props.setShowConfigMapped(false);
            setWhichActionRequired(null);
            cancelPollingRequest();
          }}
        />
      </header>
      <section className="modal-card-body">
        {props.showConfigMapped ? (
          <div>
            <div>
              <h2 className="configMapHeader">Column mapping used</h2>
              <div>
                <Table
                  columns={columnDefinitionColumnMapping}
                  rows={props.showConfigMappedData?.columnHeader}
                  sortEnable={false}
                  showPagination={false}
                />
              </div>
            </div>
            <div className="mt_30">
              <h2 className="configMapHeader">Doc type mapping used</h2>
              <div>
                <Table
                  columns={columnDefinitionDocType}
                  rows={props.showConfigMappedData?.docType}
                  sortEnable={false}
                  showPagination={false}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="UploadedSummary">
            <table>
              <thead>
                <tr>
                  <th>Doc type</th>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return (
                      <Tooltip title={k} key={keyInd}>
                        <th>{k?.substring(0, 20) + "..."}</th>
                      </Tooltip>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Overall</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.overall}</td>;
                  })}
                </tr>
                <tr>
                  <td>Invoice</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.invoice}</td>;
                  })}
                </tr>
                <tr>
                  <td>{props.subTab === "Payment Advice" ? "Discount/Rebate" : "Payment"}</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.payment}</td>;
                  })}
                </tr>
                <tr>
                  <td>DN/CN</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.dncn}</td>;
                  })}
                </tr>
                <tr>
                  <td>TDS</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.tds}</td>;
                  })}
                </tr>
                <tr>
                  <td>Reversal</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.reversal}</td>;
                  })}
                </tr>
                <tr>
                  <td>Internal document</td>
                  {Object.keys(props.showUploadedSummaryObject)?.map((k: any, keyInd: number) => {
                    return <td key={keyInd}>{props.showUploadedSummaryObject[k]?.internalDocument}</td>;
                  })}
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </section>
      <footer className="modal-card-foot flex_end">
        <div className="space_between w_100_per">
          {props.showConfigMapped ? (
            <Button className="theme_outline_btn" onClick={() => props.setShowConfigMapped(false)}>
              View Upload Summary
            </Button>
          ) : (
            <Button className="theme_outline_btn" onClick={() => props.setShowConfigMapped(true)}>
              View configuration used
            </Button>
          )}

          <Button
            className="button is-success theme_btn"
            onClick={() => {
              props.setShowUploadedSummary(false);
              props.setShowConfigMapped(false);
              setWhichActionRequired(null);
              cancelPollingRequest();
            }}
          >
            Continue
          </Button>
        </div>
      </footer>
    </Dialog>
  );
};

export default UploadedSummary;
