import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import uiLogger from "src/Utils/UiLogger";
import { EmailSendCount, EmailTemplate } from "../../../../entity/recon-entity/ReconInterfaces";
import { uiLoggerName } from "../../../../Utils/Recon/UiLogger/Constants";
import { TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import EmailTemplateDeleteWarningModal from "../CommonLegacy/EmailTemplateDeleteWarningModal";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";

interface SelectEmailTemplateDialogProps {
  deleteUserEmailTemplate: (templateID: number) => void;
  sendSoleOrBulkMail: EmailSendCount;
  lastSelectedTemplateID: number;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}
const SelectEmailTemplate = (props: SelectEmailTemplateDialogProps) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [errSelectedTemplate, setErrSelectedTemplate] = useState<boolean>(false);

  // Email States BEGIN
  const {
    emailTemplates,
    openSelectEmailTemplate,
    setEmailTemplateContent,
    setOpenCreateEmailTemplate,
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    setOpenSendEmailOnCancel,
  } = useContext(PartnerCommsContext);
  // Email States END

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);
  const { setIsOpenDeleteModal } = useContext(PartnerCommsContext);

  const handleClose = () => {
    setOpenSelectEmailTemplate(false);
  };

  const handleChange = (templateName: string) => {
    setSelectedTemplate(templateName);
    setErrSelectedTemplate(false);
  };

  useEffect(() => {
    if (props.lastSelectedTemplateID) {
      const templateName = emailTemplates.find(
        (template) => template.templateId === props.lastSelectedTemplateID
      )?.templateName;

      if (templateName) handleChange(templateName);
    } else setSelectedTemplate("");
  }, [emailTemplates, openSelectEmailTemplate, props.lastSelectedTemplateID, props.sendSoleOrBulkMail]);

  const nextSelectEmailTemplate = () => {
    if (selectedTemplate.trim() === "") {
      setErrSelectedTemplate(true);
    }
    if (selectedTemplate.trim() !== "") {
      if (emailTemplates.length > 0) {
        const filteredSelectedTemplate = emailTemplates.filter((item: EmailTemplate) => {
          return item.templateName === selectedTemplate;
        })[0];
        setEmailTemplateContent(filteredSelectedTemplate);
      }

      setOpenSelectEmailTemplate(false);
      setOpenSendEmailTemplate(true);
      setSelectedTemplate("");
    }
  };
  return (
    <>
      <Dialog
        open={openSelectEmailTemplate}
        // onClose={handleClose}
        aria-labelledby="SelectEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Select E-mail template</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div className="pt_10">
            <RadioGroup
              aria-labelledby="select email template"
              name="radio-buttons-group"
              onChange={(_e, val) => handleChange(val)}
            >
              {errSelectedTemplate && <p className="err_text">Please select E-mail template</p>}
              {emailTemplates.length > 0 &&
                emailTemplates.map((val, valInd: number) => (
                  <div className="vertical_center_align space_between" key={`${valInd}_emailTemplates`}>
                    <FormControlLabel
                      value={val.templateName}
                      control={<Radio checked={val.templateName === selectedTemplate} />}
                      label={val.templateName}
                      key={valInd}
                    />
                    <div className="vertical_center_align">
                      {!val?.default && (
                        <DeleteIcon
                          onClick={() => {
                            setSelectedTemplateId(val?.templateId);
                            setIsOpenDeleteModal(true);
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}

              {/* <FormControlLabel value="Ledger request" control={<Radio />} label="Ledger request" />
        <FormControlLabel value="Confirm CB" control={<Radio />} label="Confirm CB" />
        <FormControlLabel value="Warning" control={<Radio />} label="Warning" />
        <FormControlLabel value="Stop payment" control={<Radio />} label="Stop payment" />
        <FormControlLabel value="NOC" control={<Radio />} label="NOC" />
        <FormControlLabel value="Other templates" control={<Radio />} label="Other templates" /> */}
            </RadioGroup>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
                setOpenCreateEmailTemplate(true);
                setOpenSendEmailOnCancel(false);
                uiLogger(uiLoggerName.ui_TemplateCreatedOnMain, props.companyId, props.branchCode);
              }}
            >
              Create template
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                nextSelectEmailTemplate();
              }}
            >
              Next
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      {openSelectEmailTemplate && (
        <EmailTemplateDeleteWarningModal
          handleDelete={async () => {
            if (openSelectEmailTemplate) props.deleteUserEmailTemplate(selectedTemplateId);
          }}
          handleDeletePdf={async () => {}}
        />
      )}
    </>
  );
};

export default SelectEmailTemplate;
