import { Close } from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, FormLabel, IconButton } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CheckboxGroup } from "../ReconMIS/utils";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface PartialDialogProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
  fields: string[];
  afterFieldSelect: (fields: string[]) => Promise<void>;
}

type SelectedFieldsObj = {
  [k: string]: boolean;
};

const PartialFieldUpdateDialog = (props: PartialDialogProps) => {
  const initialFieldsObj: SelectedFieldsObj = {};
  props.fields.forEach((field) => (initialFieldsObj[field] = false));

  const [selectedObj, setSelectedObj] = useState<SelectedFieldsObj>(initialFieldsObj);
  useEffect(() => {
    if (props.open) {
      setSelectedObj(initialFieldsObj);
    }
  }, [props.open, props.fields]);
  return (
    <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Select Fields</DialogTitle>
        <IconButton
          onClick={() => {
            props.setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5 }}>
        <FormLabel>Select fields which you want to update from the uploaded file.</FormLabel>
        <Box py={2} px={1}>
          <CheckboxGroup
            row={true}
            onChange={(_e, v) =>
              setSelectedObj({
                ...selectedObj,
                ...v,
              })
            }
            sx={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              width: "100%",
            }}
          >
            {Object.keys(selectedObj).map((field, _i) => (
              <FormControlLabel
                key={field + _i}
                control={<Checkbox checked={selectedObj[field]} name={field} />}
                label={field}
              />
            ))}
          </CheckboxGroup>
        </Box>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          className="theme_outline_btn"
          autoFocus={true}
          onClick={() => {
            props.setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="theme_btn"
          autoFocus={true}
          onClick={async () => {
            props.setOpen(false);
            props.afterFieldSelect(Object.keys(selectedObj).filter((_k) => selectedObj[_k]));
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PartialFieldUpdateDialog;
