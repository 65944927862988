import React from "react";
import "./ReconLoader.scss";
// import NakadLogo from "../../../Graphics/icon.png";

const ReconLoader = () => {
  return (
    <div className="recon_outer">
      <div className="recon_loader">{/* <img src={NakadLogo} /> */}</div>
    </div>
  );
};

export default ReconLoader;
