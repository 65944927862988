import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import React from "react";
import { Dialog } from "../../Dialog/Dialog";

export default function UploadPdfErrorModal({
  openPdfDialogErrorMessage,
  setOpenPdfDialogErrorMessage,
  pdfDialogErrorMessage,
}) {
  return (
    <Dialog open={openPdfDialogErrorMessage} onClose={() => setOpenPdfDialogErrorMessage(false)}>
      <header className="modal-card-head">
        <p className="modal-card-title">Uploaded PDF File</p>
      </header>
      <section className="modal-card-body">
        <Grid>
          <p className="errorPastDate_modal_text">{pdfDialogErrorMessage}</p>
        </Grid>
      </section>
      <footer className="modal-card-foot" style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button
            className="button is-success"
            onClick={() => {
              setOpenPdfDialogErrorMessage(false);
            }}
          >
            Close
          </Button>
        </div>
      </footer>
    </Dialog>
  );
}
