import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { NdAsyncButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface CommonDialogProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
}

interface DRNewCommunicationDialogProps extends CommonDialogProps {
  yesAction: () => Promise<void>;
}

export const DRNewCommunicationDialog = ({ open, setOpen, yesAction }: DRNewCommunicationDialogProps) => {
  const [waiting, setWaiting] = useState(false);
  return (
    <>
      <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
        <DialogTitle>Do you want to start new communication for this task?</DialogTitle>
        <DialogContent className={$.flex_column} sx={{ py: 2, px: 3 }}>
          <Alert severity="info" sx={{ font: "inherit" }}>
            No existing communication found for this task
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button
            className={$.BR_fix}
            variant="text"
            color="inherit"
            onClick={async () => {
              setOpen(false);
            }}
            disabled={waiting}
          >
            No
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            onClick={async () => {
              setWaiting(true);
              await yesAction();
              setOpen(false);
              setWaiting(false);
            }}
            color="primary"
          >
            Yes
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
