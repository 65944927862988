import { Autocomplete, Button, Card, CardContent, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { ClientType } from "src/entity/models/FrontendActor";

import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";

const AccountSetting = () => {
  const [selectedClientType, setSelectedClientType] = useState(null);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const handleChange = (val) => {
    setSelectedClientType(val);
  };

  useEffect(() => {
    getClientType();
  }, []);
  const getClientType = () => {
    useFetch(API_ENDPOINTS.GET_CLIENT_TYPE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_CLIENT_TYPE.failureMessage,
      thenCallBack: (response) => {
        setSelectedClientType(response.data.clientType);
      },
    });
  };
  const updateClientType = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.UPDATE_CLIENT_TYPE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLIENT_TYPE.failureMessage,
      showSuccessToast: true,
      data: {
        clientType: selectedClientType,
      },
      thenCallBack: () => {
        setShowLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };
  return (
    <div>
      <Card className="w_fit_content">
        <CardContent>
          <div className="vertical_center_align">
            <div>
              <h6 className="client_type_text">Set Client Type:</h6>
            </div>
            <div className="ml_40 mr_16">
              <Autocomplete
                onChange={(_evt, value) => handleChange(value)}
                style={{ width: "240px" }}
                className="update-custom-border-radius"
                value={selectedClientType}
                disableClearable
                options={Object.values(ClientType)}
                getOptionLabel={(option) => option}
                sx={{ width: 240 }}
                renderInput={(params) => <TextField {...params} label="Select Client Type" />}
              />
            </div>
            <div>
              <Button
                startIcon={<LoadingIcon loading={showLoadingIcon} />}
                className={selectedClientType === null ? "grey_btn" : "green_btn"}
                disabled={selectedClientType === null || showLoadingIcon}
                onClick={() => {
                  updateClientType();
                }}
              >
                SAVE
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default AccountSetting;
