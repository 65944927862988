import { Autocomplete, Box, Button, Chip, Grid, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { Alert, DialogActions, DialogContent, DialogTitle, FormLabel } from "@mui/material";
import SendMailDialog from "@mui/material/Dialog";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  EmailSendCount,
  EmailTemplate,
  PartnerCommunicationSelected,
  PDFTemplate,
  TemplateType,
} from "src/entity/recon-entity/ReconInterfaces";
import { userContext } from "../../../Contexts/userContext";
import Editor from "../Editor/Editor";
import "../Styles/SendEmailTemplate.scss";

import { ChevronRight, Delete } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import {
  updateIsSendingBulkMailsBC,
  updateIsSendingBulkMailsLR,
  updateIsSendingBulkMailsMSME,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerName } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import useFetch from "../../../Common/useFetch";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, TallyOrSapCompanyId } from "../CommonLegacy/CommonComponents";
import EmailTemplateDeleteWarningModal from "../CommonLegacy/EmailTemplateDeleteWarningModal";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";

interface SendEmailTemplateProps<T extends object> {
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  base64Image: string;
  signature: string;
  sendSoleOrBulkMail: EmailSendCount;
  selectedRow: object[] | T[] | any[];
  listAllBusinessPartnerMapping: () => void;
  listAllBusinessPartnersWSR: () => Promise<void>;
  listUserEmailTemplate: (param: string) => void;
  partnerCommunicationSelected: PartnerCommunicationSelected;
  templateType: TemplateType;
  storeAllBusinessPartnerCheck: React.MutableRefObject<{ [k: number]: boolean }>;
  mailTo?: string;
  files?: File[];
  msmeConfirmation?: boolean;
  setIntervalBulkMails: any;
}

const SendEmailTemplate = <T extends object>(props: SendEmailTemplateProps<T>) => {
  const { actor } = useContext(userContext);
  const [dynamicContent, setDynamicContent] = useState<string>("");
  // const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [files, setFiles] = useState<File[]>(props.files || []);
  const [pdfFiles, setPdfFiles] = useState<PDFTemplate[]>([]);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  const [copyAlert, setCopyAlert] = useState<boolean>(false);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [errEmailSubject, setErrEmailSubject] = useState<boolean>(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState<boolean>(false);
  const [showUserEmailTemplateToBeUpdatedModal, setUserEmailTemplateToBeUpdatedModal] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>(null);
  const [anchorMenuEl, setAnchorMenuEl] = useState<HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const openSubMenu = Boolean(anchorMenuEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => setAnchorMenuEl(event.currentTarget);

  const handleCloseMenu = () => setAnchorEl(null);
  const handleCloseSubMenu = () => setAnchorMenuEl(null);

  // Email States BEGIN
  const {
    emailTemplateContent,
    setEmailTemplateContent,
    openSendEmailTemplate,
    setOpenSendEmailTemplate,
    setOpenCreateEmailTemplate,
    setOpenSendEmailOnCancel,
    emailTemplates,
    emailBusinessPartnerId,
    emailDisplayName,
    fromEmailAddress,
    pdfTemplates,
    setCreatePdfTemplate,
    dynamicContentOptions,
    allowSubjectChange,
  } = useContext(PartnerCommsContext);

  // const contextData = useContext(PartnerCommsContext);

  const [selectedTemplateId, setSelectedTemplateId] = useState<number>(null);
  const { setIsOpenDeleteModal, handleCloseDeleteModal, setShowDeleteLoadingIcon } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();

  // Email States END

  useEffect(() => {
    if (emailTemplateContent) {
      setDynamicContent(emailTemplateContent.emailBody);
      setEmailSubject(emailTemplateContent.emailSubject);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateContent, dynamicContentOptions]);

  const handleClose = () => {
    setOpenSendEmailTemplate(false);
    setSelectedTemplateName("");
    setFiles([]);
    setPdfFiles([]);
  };

  const checkIfUserEmailTemplateHasToUpdate = () => {
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("templateName", emailTemplateContent.templateName);

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      useFetch(API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.url, "POST", {
        failureMessage: API_ENDPOINTS.CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED.failureMessage,
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: (response) => {
          // check response here
          setShowLoadingIcon(false);
          if (response.data.isTemplateUpdated) {
            setUserEmailTemplateToBeUpdatedModal(true);
          } else {
            if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
              if (props.msmeConfirmation) sendMailMsme();
              else sendEmail();
            } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
              if (props.msmeConfirmation) sendMailInBulkMsme();
              else sendMailInBulk();
            }
          }
        },
        catchCallBack: () => {
          setShowLoadingIcon(false);
        },
      });
    }
  };

  const updateUserEmailTemplate = () => {
    setUserEmailTemplateToBeUpdatedModal(false);
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId);
    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      useFetch(API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_USER_EMAIL_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: () => {
          setShowLoadingIcon(false);
          if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
            if (props.msmeConfirmation) sendMailMsme();
            else sendEmail();
          } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
            if (props.msmeConfirmation) sendMailInBulkMsme();
            else sendMailInBulk();
          }
          handleClose();

          // reload templates after Adding/Updating User Email Template
          props.listUserEmailTemplate("");

          // props.setLoader(true);
        },
        catchCallBack: () => {
          setShowLoadingIcon(false);
        },
      });
    }
  };

  const sendEmail = () => {
    setUserEmailTemplateToBeUpdatedModal(false);
    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }

    let nonEmptyBody = "";

    if (!dynamicContent || dynamicContent === "<p><br /></p>") nonEmptyBody = null;
    else nonEmptyBody = dynamicContent;

    const bodyFormData = new FormData();
    bodyFormData.append("receiverId", emailBusinessPartnerId?.toString());
    bodyFormData.append("emailBody", nonEmptyBody);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", props.branchCode || DEFAULT_BRANCH_CODE);

    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (pdfFiles !== null && pdfFiles?.length > 0) {
      bodyFormData.append("pdfTemplates", pdfFiles.map((file) => file.templateId).join(", "));
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);
      useFetch(API_ENDPOINTS.SEND_MAIL.url, "POST", {
        failureMessage: API_ENDPOINTS.SEND_MAIL.failureMessage,
        showSuccessToast: true,
        data: bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
        thenCallBack: () => {
          setShowLoadingIcon(false);
          handleClose();
          // props.setLoader(true);
          props.listAllBusinessPartnersWSR();
          // reload templates after mail sent
          props.listUserEmailTemplate("");
        },
        catchCallBack: () => {
          setShowLoadingIcon(false);
        },
      });
    }
  };

  const sendMailMsme = () => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.SEND_EMAIL_FOR_MSME_CONFIRMATION.url, "POST", {
      failureMessage: API_ENDPOINTS.SEND_EMAIL_FOR_MSME_CONFIRMATION.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: emailBusinessPartnerId,
        emailSubject: emailSubject,
        emailBody: dynamicContent,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setShowLoadingIcon(false);
        handleClose();
        // props.setLoader(true);
        props.listAllBusinessPartnersWSR();
        // reload templates after mail sent
        props.listUserEmailTemplate("");
      },
      catchCallBack: (_err) => {
        setShowLoadingIcon(false);
      },
    });
  };

  const sendMailInBulk = () => {
    setUserEmailTemplateToBeUpdatedModal(false);

    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
    }
    let selectedBPIds: any[];
    if (props.partnerCommunicationSelected === PartnerCommunicationSelected.ledgerRequest) {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.businessPartnerId;
      });
    } else if (props.partnerCommunicationSelected === PartnerCommunicationSelected.balanceConfirmation) {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.businessPartnerId;
      });
    } else if (props.partnerCommunicationSelected === PartnerCommunicationSelected.msme) {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item.businessPartnerId;
      });
    } else {
      selectedBPIds = props.selectedRow.map((item: any) => {
        return item?.ownBusinessPartnerMapping?.businessPartnerId;
      });
    }

    const bodyFormData = new FormData();
    bodyFormData.append("receiversIds", selectedBPIds as any);
    bodyFormData.append("emailBody", dynamicContent);
    bodyFormData.append("emailSubject", emailSubject);
    bodyFormData.append("templateName", emailTemplateContent.templateName);
    bodyFormData.append("templateType", props.templateType);
    bodyFormData.append("senderCompanyId", props.companyId || DEFAULT_COMPANY_ID);
    bodyFormData.append("branchCode", props.branchCode || DEFAULT_BRANCH_CODE);
    bodyFormData.append("templateId", emailTemplateContent.templateId.toString());

    if (files !== null && files.length > 0) {
      // for (let i = 0; i < attachment.length; i++) {
      //   bodyFormData.append("file", attachment[i]);
      // }
      for (const item of files) {
        bodyFormData.append("file", item);
      }
    }

    if (pdfFiles !== null && pdfFiles?.length > 0) {
      bodyFormData.append("pdfTemplates", pdfFiles.map((file) => file.templateId).join(", "));
    }

    if (emailSubject.trim() !== "") {
      setShowLoadingIcon(true);

      if (props.templateType === "CB") dispatch(updateIsSendingBulkMailsBC(true));
      if (props.templateType === "LR") dispatch(updateIsSendingBulkMailsLR(true));

      useFetch(API_ENDPOINTS.SEND_MAIL_IN_BULK.url, "POST", {
        failureMessage: API_ENDPOINTS.SEND_MAIL_IN_BULK.failureMessage,
        data: bodyFormData,
        config: { headers: { "Content-Type": "multipart/form-data" } },
        thenCallBack: () => {
          handleClose();
          setShowLoadingIcon(false);

          // Repeatedly call getEmailsSentInBulkStatus every 30 seconds until isComplete is true
          props.setIntervalBulkMails();
        },
        catchCallBack: () => {
          setShowLoadingIcon(false);
          handleClose();
          if (props.templateType === "CB") dispatch(updateIsSendingBulkMailsBC(false));
          if (props.templateType === "LR") dispatch(updateIsSendingBulkMailsLR(false));
        },
      });
    }
  };

  const sendMailInBulkMsme = () => {
    setUserEmailTemplateToBeUpdatedModal(false);

    if (emailSubject.trim() === "") {
      setErrEmailSubject(true);
      return;
    }

    const selectedBPIds = props.selectedRow.map((item) => item?.businessPartnerId);

    setShowLoadingIcon(true);
    dispatch(updateIsSendingBulkMailsMSME(true));

    useFetch(API_ENDPOINTS.SEND_BULK_EMAIL_FOR_MSME.url, "POST", {
      failureMessage: API_ENDPOINTS.SEND_BULK_EMAIL_FOR_MSME.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerIds: selectedBPIds,
        emailSubject: emailSubject,
        emailBody: dynamicContent,
        companyId: props.companyId || DEFAULT_COMPANY_ID,
        branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setShowLoadingIcon(false);
        handleClose();
        // Repeatedly call getMsmeBulkMailStatus every 30 seconds until isComplete is true
        props.setIntervalBulkMails();
      },
      catchCallBack: () => {
        dispatch(updateIsSendingBulkMailsMSME(false));
        setShowLoadingIcon(false);
        handleClose();
      },
    });
  };

  const getPDFTemplateBody = (_templateId: number) => {
    return new Promise<{ templateBody: string }>((resolve, reject) => {
      useFetch<{ templateBody: string }>(API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.url, "POST", {
        failureMessage: API_ENDPOINTS.GET_PDF_TEMPLATE_BODY.failureMessage,
        showSuccessToast: true,
        data: {
          templateId: _templateId,
          templateType: props.templateType,
        },
        thenCallBack: (_res) => {
          if (_res.data.templateBody) {
            resolve({ templateBody: _res.data.templateBody });
          }
        },
        catchCallBack: reject,
        errorCallback: reject,
      });
    });
  };

  const deletePDFTemplate = (_templateId: number) => {
    useFetch(API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PC_PDF_TEMPLATE.failureMessage,
      showSuccessToast: true,
      data: {
        templateId: _templateId,
        templateType: props.templateType,
      },
      thenCallBack: (_res) => {
        props.listUserEmailTemplate("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  const getShortName = (name: string) => {
    if (!name.includes(".")) {
      if (name.length > 12) return name.substring(0, 6) + "..." + name.substring(name.length - 6);
      else return name;
    }

    const splittedName = name.split(".");
    const extension = splittedName[splittedName.length - 1];
    splittedName.splice(splittedName.length - 1);
    let fileName = splittedName.join("");
    if (fileName.length > 12) {
      fileName = fileName.substring(0, 6) + "..." + fileName.substring(fileName.length - 6);
    }
    return fileName + "." + extension;
  };

  return (
    <>
      <SendMailDialog
        fullWidth={true}
        maxWidth="lg"
        open={openSendEmailTemplate}
        classes={{ paper: "maxWidth_1000" }}
        // onClose={handleClose}
        aria-labelledby="SendEmailTemplate"
        // className="editBpContactMaster_popup"
      >
        <DialogContent sx={{ py: 1.25 }}>
          <div className="email_text">
            <Box>
              <p className="Email_Field">
                <FormLabel className="w_100">From : </FormLabel>
                {fromEmailAddress}
              </p>
              <p className="Email_Field">
                <FormLabel className="w_100">Display Name : </FormLabel>
                {emailDisplayName}
              </p>
              <div style={{ display: "grid", gridTemplateColumns: "30px 96%", padding: "3px", alignItems: "center" }}>
                <FormLabel className="w_100">To : </FormLabel>{" "}
                <Box display={"flex"} sx={{ whiteSpace: "nowrap", width: "inherit", gap: 0.5 }}>
                  <span className="Email_Field">
                    {props.sendSoleOrBulkMail === EmailSendCount.Sole && props.mailTo ? props.mailTo : ""}
                    {props.sendSoleOrBulkMail === EmailSendCount.Bulk
                      ? props.selectedRow.map((item) => item.businessPartnerName).join(", ")
                      : ""}
                  </span>
                  {props.sendSoleOrBulkMail === EmailSendCount.Bulk && (
                    <FormLabel>({props.selectedRow.length} Recipients)</FormLabel>
                  )}
                </Box>
              </div>
              <div className="mt_5" style={{ marginTop: 5 }}>
                <FormLabel>+ CC</FormLabel>
                <FormLabel className="ml_10">+ BCC</FormLabel>
              </div>
            </Box>
            <Box display={"flex"} gap={2} alignItems="center" mt={2} mb={1}>
              <FormLabel className="w_100">Subject : </FormLabel>
              <TextField
                size="small"
                id="mail_subject"
                variant="outlined"
                placeholder="Enter Subject"
                value={emailSubject}
                error={errEmailSubject}
                helperText={errEmailSubject ? "please enter subject" : ""}
                disabled={false}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                  if (e.target.value.trim() !== "") {
                    setErrEmailSubject(false);
                  } else {
                    setErrEmailSubject(true);
                  }
                }}
                sx={{
                  width: "90%",
                }}
              />
            </Box>
            {!allowSubjectChange && (
              <Alert severity="info" sx={{ py: 0, mb: 1 }}>
                <Typography fontSize={14}>
                  Subject will not change for existing mail communication initiated with Business Partners
                </Typography>
              </Alert>
            )}
            <div>
              <Editor
                dynamicContent={dynamicContent}
                setDynamicContent={setDynamicContent}
                selectedTemplateName={selectedTemplateName}
                selectedEmailTemplateContent={emailTemplateContent}
              />
              {/* <NewEditor /> */}
            </div>
            <div className="mt_10 pl_10" style={{ whiteSpace: "pre-wrap" }}>
              {props.signature !== null && props.signature !== undefined && props.signature !== "" ? (
                <span>{props.signature}</span>
              ) : (
                <>
                  <p>
                    <FormLabel className="w_100">Thanks,</FormLabel>
                  </p>
                  <p>{actor?.name}</p>
                  {/* <p>{props.emailDisplayName}</p> */}
                </>
              )}
            </div>
            <div className="Signature_Box">
              {props.base64Image && <img src={props.base64Image} style={{ width: "180px", height: "80px" }} />}
            </div>
            <div className="Footer_Content">
              <div style={{ height: 10 }} className="mb_10 flex_end">
                {copyAlert && (
                  <Alert severity="success" sx={{ width: 200, height: 48, position: "relative", top: -30 }}>
                    Copied!
                  </Alert>
                )}
              </div>
              <Box display={"flex"} gap={1} sx={{ overflowX: "auto" }}>
                {files !== null &&
                  files.length > 0 &&
                  files.map((_file, fileInd) => (
                    <Chip
                      title={files[fileInd].name}
                      key={files[fileInd].name}
                      label={getShortName(files[fileInd].name)}
                      onDelete={() => {
                        getShortName(files[fileInd].name);
                        files.splice(fileInd, 1);
                        setFiles([...files]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                        background: "rgba(84, 28, 76, 0.1) !important",
                        "&:hover": {
                          background: "rgba(84, 28, 76, 0.2) !important",
                        },
                      }}
                    />
                  ))}
                {pdfFiles?.length > 0 &&
                  pdfFiles.map((_file, fileInd) => (
                    <Chip
                      title={_file.templateName}
                      key={_file.templateName + fileInd}
                      label={getShortName(_file.templateName)}
                      onDelete={() => {
                        getShortName(_file.templateName);
                        pdfFiles.splice(fileInd, 1);
                        setPdfFiles([...pdfFiles]);
                      }}
                      deleteIcon={<Delete sx={{ fontSize: 20, ":hover": { color: "#f118 !important" } }} />}
                      sx={{
                        maxWidth: 200,
                        "&, span": {
                          textOverflow: "unset !important",
                        },
                        background: "rgba(84, 28, 76, 0.1) !important",
                        "&:hover": {
                          background: "rgba(84, 28, 76, 0.2) !important",
                        },
                      }}
                    />
                  ))}
              </Box>
              <Grid item={true} xs={12} sm={12} className="d_flex">
                {props.sendSoleOrBulkMail && !props.msmeConfirmation && (
                  <div className="email_width">
                    <Grid item={true} xs={4} sm={4}>
                      <div>
                        <Button
                          variant="contained"
                          component="label"
                          className="theme_btn"
                          style={{ width: "100%" }}
                          size="small"
                          onClick={handleClick}
                        >
                          <span className="file-icon">
                            <i className="fas fa-upload" />
                          </span>
                          Attachment
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={openMenu}
                          onClose={handleCloseMenu}
                          sx={{ "&>*": { color: "#555}" } }}
                          keepMounted={true}
                        >
                          <MenuItem
                            onClick={() => {
                              if (inputRef.current) inputRef.current?.click();
                            }}
                          >
                            <span>Upload Local File</span>
                            <i
                              className="fa-solid fa-upload"
                              style={{ position: "absolute", right: 16, color: "#aaa" }}
                            />
                            <input
                              ref={inputRef}
                              hidden={true}
                              multiple={true}
                              type="file"
                              onChange={(e) => {
                                setFiles([...files, ...Array.from(e.target.files)]);
                                handleCloseMenu();
                              }}
                            />
                          </MenuItem>
                          <MenuItem onClick={handleMenuClick}>
                            <span>Select PDF Template</span>
                            <ChevronRight sx={{ position: "relative", left: 4, color: "#aaa" }} />
                          </MenuItem>
                        </Menu>
                        <Menu
                          anchorEl={anchorMenuEl}
                          open={openSubMenu}
                          onClose={handleCloseSubMenu}
                          sx={{ "&>*": { color: "#555}" } }}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          {pdfTemplates.map((template, _i) => (
                            <span className="space_between vertical_center_align" key={template.templateName + _i}>
                              <MenuItem
                                key={template.templateName + _i}
                                onClick={async () => {
                                  handleCloseMenu();
                                  handleCloseSubMenu();
                                  await getPDFTemplateBody(template.templateId);
                                  if (!pdfFiles.includes(template)) setPdfFiles([...pdfFiles, template]);
                                }}
                                sx={{ width: "100%" }}
                              >
                                <span>{template.templateName}</span>
                                {/* <ChevronRight sx={{ position: "relative", left: 4, color: "#aaa" }} /> */}
                              </MenuItem>
                              <Delete
                                onClick={() => {
                                  setSelectedTemplateId(template?.templateId);
                                  setIsOpenDeleteModal(true);
                                }}
                              />
                            </span>
                          ))}
                          <MenuItem
                            key="Others"
                            value="Others+#Create template"
                            style={{ borderTop: "1px solid #ddd" }}
                            onClick={() => {
                              handleCloseSubMenu();
                              handleCloseMenu();
                              setSelectedTemplateName("");
                              handleClose();
                              setCreatePdfTemplate(true);
                              setOpenCreateEmailTemplate(true);
                              setOpenSendEmailOnCancel(true);
                            }}
                          >
                            + Create PDF template
                          </MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </div>
                )}
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <Autocomplete
                      size="small"
                      onChange={(_, value: any) => {
                        if (value !== null) {
                          navigator.clipboard.writeText(value);
                          // insertDynamiccontent(value);
                          setCopyAlert(true);
                          setTimeout(() => {
                            setCopyAlert(false);
                          }, 3000);
                        }
                      }}
                      options={dynamicContentOptions}
                      //   value={dynamicContent}
                      renderInput={(params) => (
                        <TextField {...params} label="Dynamic Content" placeholder="Dynamic Content" />
                      )}
                    />
                  </Grid>
                </div>
                <div className="email_width">
                  <Grid item={true} xs={4} sm={4}>
                    <TextField
                      select={true}
                      size="small"
                      label="Choose Template"
                      name="Choosetemplate"
                      variant="outlined"
                      fullWidth={true}
                      onChange={(e) => {
                        if (e.target.value === "Others+#Create template") {
                          setSelectedTemplateName("");
                          handleClose();
                          setOpenCreateEmailTemplate(true);
                          setOpenSendEmailOnCancel(true);
                        } else {
                          setSelectedTemplateName(e.target.value);
                          const filteredSelectedTemplate = emailTemplates.filter((item: EmailTemplate) => {
                            return item.templateName === e.target.value;
                          })[0];
                          setEmailTemplateContent(filteredSelectedTemplate);
                        }
                      }}
                      value={selectedTemplateName}
                    >
                      {emailTemplates?.length > 0 &&
                        emailTemplates.map((item: EmailTemplate, itemInd: number) => (
                          <MenuItem key={itemInd} value={item.templateName}>
                            {item.templateName}
                          </MenuItem>
                        ))}
                      <MenuItem
                        key="Others"
                        value="Others+#Create template"
                        style={{ borderTop: "1px solid #ddd" }}
                        onClick={() =>
                          uiLogger(uiLoggerName.ui_TemplateCreatedOnEmailModal, props.companyId, props.branchCode)
                        }
                      >
                        + Create template
                      </MenuItem>
                    </TextField>
                  </Grid>
                </div>
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="email_dialog_footer_btn">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme_btn"
              onClick={() => {
                Object.keys(props.storeAllBusinessPartnerCheck?.current || {}).forEach((key) => {
                  props.storeAllBusinessPartnerCheck.current[key] = false;
                });
                checkIfUserEmailTemplateHasToUpdate();
                uiLogger(
                  props.sendSoleOrBulkMail === EmailSendCount.Sole
                    ? uiLoggerName.ui_SingleEmailSent
                    : uiLoggerName.ui_BulkEmailSent
                );
              }}
              disabled={showLoadingIcon}
            >
              <LoadingIcon loading={showLoadingIcon} />
              Send
            </Button>
          </div>
        </DialogActions>
      </SendMailDialog>

      <SendMailDialog open={showUserEmailTemplateToBeUpdatedModal}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Update E-mail template</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => setUserEmailTemplateToBeUpdatedModal(false)}
          />
        </DialogTitle>
        <DialogContent>
          <div>
            <p className="mt_30 mb_25 ">Do you want to update the email template to use it in future?</p>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                if (props.sendSoleOrBulkMail === EmailSendCount.Sole) {
                  if (props.msmeConfirmation) sendMailMsme();
                  else sendEmail();
                } else if (props.sendSoleOrBulkMail === EmailSendCount.Bulk) {
                  if (props.msmeConfirmation) sendMailInBulkMsme();
                  else sendMailInBulk();
                }
              }}
            >
              No
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                updateUserEmailTemplate();
                uiLogger(uiLoggerName.ui_UpdatedEmailTemplate, props.companyId, props.branchCode);
              }}
            >
              Yes
            </Button>
          </div>
        </DialogActions>
      </SendMailDialog>

      {openSendEmailTemplate && (
        <EmailTemplateDeleteWarningModal
          handleDelete={async () => {}}
          handleDeletePdf={async () => {
            if (openSendEmailTemplate) deletePDFTemplate(selectedTemplateId);
          }}
        />
      )}
    </>
  );
};

export default SendEmailTemplate;
