import { Autocomplete, Button, TextField } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useState } from "react";

import { TicketDetails } from "src/entity/recon-entity/ReconInterfaces";
import { uiLoggerNamesWorkflow } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { Recon360Context } from "../Recon360";
import { TagsInput } from "../ReconMIS/ClientReporting/AutoCompletes";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

interface ViewTicketsFilterProps {
  storeRowsDataTicket: TicketDetails[];
  rowsDataTicket: TicketDetails[];
  setRowsDataTicket: StateDispatch<TicketDetails[]>;
  openFilter: boolean;
  setOpenFilter: StateDispatch<boolean>;
  allPartners: string[];
  allStatuses: string[];
  allUsers: string[];
}

const ViewTicketsFilter = (props: ViewTicketsFilterProps) => {
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedMaker, setSelectedMaker] = useState<string[]>([]);
  const [selectedChecker, setSelectedChecker] = useState<string[]>([]);
  const [selectedApprover, setSelectedApprover] = useState<string[]>([]);
  const [selectedTaskID, setSelectedTaskID] = useState<string[]>([]);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);

  const { companyId, branchCode } = useContext(Recon360Context);

  type keys = "partnerNames" | "status";

  const [filterObj, setFilterObj] = useState<{ [k in keys]: any }>(null);

  const handleClose = () => {
    props.setOpenFilter(false);
  };

  const filterTickets = () => {
    let tempRowData = props.storeRowsDataTicket;

    if (selectedTaskID.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedTaskID?.some((val) => item.id?.toString()?.toLowerCase() === val?.toLowerCase());
      });
    }

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          if (partnerRow.businessPartner?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedStatus.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedStatus?.some((val) => item.status?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (selectedMaker.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedMaker?.some((val) => item.maker?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (selectedChecker.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedChecker?.some((val) => item.checker?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (selectedApprover.length > 0) {
      tempRowData = tempRowData?.filter((item) => {
        return selectedApprover?.some((val) => item.approver?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    setFilterObj({ partnerNames: partnerNames, status: selectedStatus });

    props.setRowsDataTicket(tempRowData);

    handleClose();
  };

  const clearAllAppliedFilters = () => {
    setSelectedStatus([]);
    setPartnerNames([]);
    setSelectedMaker([]);
    setSelectedChecker([]);
    setSelectedApprover([]);
    setSelectedTaskID([]);

    setFilterObj(null);

    handleClose();
    setTimeout(() => {
      props.setOpenFilter(true);
      props.setRowsDataTicket(props.storeRowsDataTicket);
    }, 700);
  };

  return (
    <div>
      <Dialog open={props.openFilter} className="filter" maxWidth={"md"}>
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button
            className="delete ml_20"
            aria-label="close"
            onClick={() => {
              setPartnerNames(filterObj?.partnerNames || []);
              setSelectedStatus(filterObj?.status || []);
              handleClose();
            }}
          />
        </DialogTitle>
        <DialogContent>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Task ID :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedTaskID}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedTaskID(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.storeRowsDataTicket?.map((option) => option?.id?.toString())}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select Task ID" />}
              />
            </div>
          </div>

          <div className="d_flex m_15">
            <div className="w_300 vertical_center_align fw_600">Partner Names :</div>
            <div className="w_400 d_flex category_textField_width_390 ">
              <TagsInput
                tags={partnerNames}
                setTags={setPartnerNames}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Names"
                splitter="newline"
                limitTags={3}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={props.storeRowsDataTicket?.map((option) => option?.businessPartner)}
              />
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Ticket Status :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedStatus}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedStatus(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allStatuses || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select status" />}
              />
            </div>
          </div>

          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Maker :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedMaker}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedMaker(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allUsers || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select Maker" />}
              />
            </div>
          </div>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Checker :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedChecker}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedChecker(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allUsers || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select Checker" />}
              />
            </div>
          </div>
          <div className="d_flex m_15 ">
            <div className="w_300 vertical_center_align fw_600">Approver :</div>
            <div className="w_400 category_textField_width_390">
              <Autocomplete
                // limitTags={3}
                value={selectedApprover}
                fullWidth={true}
                onChange={(_e, value) => {
                  setSelectedApprover(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allUsers || []}
                getOptionLabel={(option) => option}
                filterSelectedOptions={true}
                renderInput={(params) => <TextField {...params} label="Select Approver" />}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              onClick={() => {
                uiLogger(
                  uiLoggerNamesWorkflow.UI_WF_DB_FILTER_APPLY_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  { message: uiLoggerNamesWorkflow.UI_WF_DB_FILTER_APPLY_CLICK.message }
                );
                filterTickets();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewTicketsFilter;
