import { Add, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { BcBetaContext } from "../BalanceConfirmationBeta";
import NewCommunicationType from "./NewAndEditCommunicationType";

export interface CommsType {
  id: number;
  name: string;
}

interface ManageCommsTypesProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ManageCommunicationType: React.FunctionComponent<ManageCommsTypesProps> = (props): JSX.Element => {
  const [communicationTypes, setCommunicationTypes] = useState<CommsType[]>([]);
  const { companyId, branchCode } = useContext(BcBetaContext);

  const [commsTypeId, setCommsTypeId] = useState<number | undefined>(undefined);

  const [openNewComms, setOpenNewComms] = useState(false);

  const handleClose = () => {
    props.setOpen(false);
  };

  const ListCommunicationTypes = async () => {
    return useFetch<{ data: CommsType[] }>(API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_COMMUNICATION_TYPES.failureMessage,
      thenCallBack: (_res) => {
        if (_res.data?.data) setCommunicationTypes(_res.data?.data);
      },
    });
  };

  const DeleteCommunicationType = async (CommsTypeId: number) => {
    return useFetch<{ response: string; message: string }>(API_ENDPOINTS.DELETE_COMMUNICATION_TYPE.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_COMMUNICATION_TYPE.failureMessage,
      data: {
        communicationTypeId: CommsTypeId,
      },
      thenCallBack: (_res) => {
        ListCommunicationTypes();
      },
    });
  };

  useEffect(() => {
    if (props.open) ListCommunicationTypes();
  }, [props.open]);

  return (
    <>
      <Dialog open={props.open} maxWidth="sm" fullWidth={true}>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"}>
            <Typography variant="h6">Manage Communication Types</Typography>
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", p: 2, gap: 0.5 }}>
          <List>
            <ListItem
              sx={{
                borderRadius: "4px",
                bgcolor: "#F5F5F5",
                mb: 1,
              }}
            >
              <ListItemText primary="Create new Communication Type" />
              <IconButton
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesPartnerCommunication.UI_PC_BCB_MANAGE_COMMUNICATION_NEW_CLICK.functionName,
                    companyId,
                    branchCode,
                    { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_MANAGE_COMMUNICATION_NEW_CLICK.message }
                  );
                  setCommsTypeId(null);
                  setOpenNewComms(true);
                }}
              >
                <Add />
              </IconButton>
            </ListItem>
            {communicationTypes.map((commType) => {
              return (
                <ListItem key={commType.id} sx={{ mb: 1, "&:hover": { bgcolor: "#F5F5F5" } }}>
                  <ListItemText primary={commType.name} />
                  <Box display="flex" gap={1}>
                    <Button
                      color="primary"
                      onClick={() => {
                        setOpenNewComms(true);
                        setCommsTypeId(commType.id);
                      }}
                    >
                      EDIT
                    </Button>
                    <Button
                      color="error"
                      onClick={() => {
                        DeleteCommunicationType(commType.id);
                      }}
                    >
                      DELETE
                    </Button>
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
      <NewCommunicationType
        open={openNewComms}
        setOpen={setOpenNewComms}
        communicationTypeId={commsTypeId}
        onClose={async () => {
          await ListCommunicationTypes();
          // close if editing existing comms type with commsTypeId
          if (commsTypeId) props.setOpen(false);
        }}
      />
    </>
  );
};
