import { Close } from "@mui/icons-material";
import { DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel } from "@mui/material";
import { Box, Button, Card, FormGroup, Grid, IconButton, Radio, Stack, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Android12Switch } from "src/Components/Common/Loader";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ENV_NAME } from "src/Utils/Recon/ENV/Constants";
import { ZapScaleEventsConstants } from "src/Utils/ZapscaleEvents/Contsants";
import { LoggedInSkeleton } from "../../../Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "../../../Common/TopNavBar";
import useFetch from "../../../Common/useFetch";
import { userContext } from "../../../Contexts/userContext";
import { EmailInput, GetAutoComplete } from "./AutoCompletes";

interface Template {
  id: number;
  name: string;
  emailTo: string[];
  emailCc: string[];
  subject: string;
  emailBody: string;
  frequency: "day" | "week" | "bi-week" | "month";
  reports: Reports[];
  isActive?: boolean;
  includedEmails: string[];
}

interface Reports {
  id: number;
  name: string;
}

const ClientReporting = () => {
  const { actor } = useContext(userContext);

  const [open, setOpen] = useState<boolean>(false);

  // Template Form Fields
  const [name, setName] = useState<string>("");
  const [emailTo, setEmailTo] = useState<string[]>([]);
  const [emailCc, setEmailCc] = useState<string[]>([]);
  const [emailBody, setEmailBody] = useState("");
  const [subject, setSubject] = useState<string>("");
  const [frequency, setFrequency] = useState<"day" | "week" | "bi-week" | "month" | "">("");
  const [includedEmails, setIncludedEmails] = useState<string[]>([]);
  const [allIncludeEmailList, setAllIncludeEmailList] = useState<string[]>([]);
  // End of Template Form Fields
  const [notFilled, setNotFilled] = useState<boolean>(false);

  const [templates, setTemplates] = useState<Template[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>({} as Template); // selected Template

  const [reports, setReports] = useState<Reports[]>([]);
  const [selectedReports, setSelectedReports] = useState<Reports[]>([]); // selected AutoComplete

  const [, setGeneratedTemplateId] = useState<number>(null);
  const [nextPage, setNextPage] = useState(false);

  const checkFormInvalid = (filledData) => {
    for (const key in filledData) {
      if (typeof filledData[key] === "object" && filledData[key].length === 0) {
        return true;
      } else if (filledData[key] === "") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const filledData = { name, emailTo, emailCc, subject, frequency };
    if (checkFormInvalid(filledData)) setNotFilled(true);
    else setNotFilled(false);
  }, [name, emailTo, emailCc, subject, frequency, includedEmails]);

  const AddAutoReportTemplateMIS = () => {
    useFetch<{ templateId: number }>(API_ENDPOINTS.ADD_AUTO_REPORT_TEMPLATE_MIS.url, "POST", {
      failureMessage: API_ENDPOINTS.ADD_AUTO_REPORT_TEMPLATE_MIS.failureMessage,
      showSuccessToast: true,
      data: {
        name,
        emailTo,
        emailCc,
        subject,
        emailBody,
        frequency,
        reports: selectedReports,
      },
      thenCallBack: (res) => {
        if (res.data.templateId) setGeneratedTemplateId(res.data.templateId);
        ResetState();
        setOpen(false);
        ListAutoReportTemplateMIS();
      },
    });
  };

  const UpdateAutoReportTemplateMIS = () => {
    const payloadKeys = {
      id: selectedTemplate.id,
      name,
      emailTo,
      emailCc,
      subject,
      emailBody,
      frequency,
      reports: selectedReports,
      includedEmails,
    };

    const diff = Object.keys(selectedTemplate).reduce((diff, key) => {
      if (key === "createdAt") return diff;
      if (selectedTemplate[key] === payloadKeys[key]) return diff;
      return {
        ...diff,
        [key]: payloadKeys[key],
      };
    }, {});

    useFetch<{ templateId: number }>(API_ENDPOINTS.UPDATE_AUTO_REPORT_TEMPLATE_MIS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_AUTO_REPORT_TEMPLATE_MIS.failureMessage,
      showSuccessToast: true,
      data: {
        templateId: selectedTemplate.id,
        ...diff,
      },
      thenCallBack: (_res) => {
        ResetState();
        setOpen(false);
        ListAutoReportTemplateMIS();
      },
    });
  };

  const ToggleAutoReportTemplateMIS = () => {
    useFetch(API_ENDPOINTS.TOGGLE_AUTO_REPORT_TEMPLATE_MIS.url, "POST", {
      failureMessage: API_ENDPOINTS.TOGGLE_AUTO_REPORT_TEMPLATE_MIS.failureMessage,
      showSuccessToast: true,
      data: {
        templateId: selectedTemplate.id,
      },
      thenCallBack: (_res) => {
        ListAutoReportTemplateMIS();
        setSelectedTemplate({ ...selectedTemplate, isActive: !selectedTemplate.isActive });
      },
    });
  };

  const ListAutoReportTemplateMIS = () => {
    useFetch<{ templates: Template[] }>(API_ENDPOINTS.LIST_AUTO_REPORT_TEMPLATE_MIS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_AUTO_REPORT_TEMPLATE_MIS.failureMessage,
      thenCallBack: (res) => {
        if (res.data.templates?.length) setTemplates(res.data.templates);
      },
    });
  };

  const ListAutoReportMIS = () => {
    useFetch<{ reports: Reports[] }>(API_ENDPOINTS.LIST_AUTO_REPORT_MIS.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_AUTO_REPORT_MIS.failureMessage,
      thenCallBack: (res) => {
        if (res.data.reports?.length) setReports(res.data.reports.map((rep) => ({ id: rep.id, name: rep.name })));
      },
    });
  };

  const GetUserEmailList = () => {
    useFetch<{ userEmails: string[] }>(API_ENDPOINTS.GET_USER_EMAILS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_USER_EMAILS.failureMessage,
      thenCallBack: (res) => {
        setAllIncludeEmailList(res?.data?.userEmails || []);
      },
    });
  };

  const TriggerAutoReportTemplateMIS = () => {
    useFetch<{ reports: Reports[] }>(API_ENDPOINTS.TRIGGER_AUTO_REPORT_TEMPLATE_MIS.url, "POST", {
      failureMessage: API_ENDPOINTS.TRIGGER_AUTO_REPORT_TEMPLATE_MIS.failureMessage,
      showSuccessToast: true,
      data: {
        id: selectedTemplate.id,
      },
    });
  };

  const ResetState = () => {
    setName("");
    setEmailCc([]);
    setEmailTo([]);
    setSubject("");
    setEmailBody("");
    setFrequency("");
    setGeneratedTemplateId(null);
    setSelectedReports([]);
    setSelectedTemplate({} as Template);
    setNextPage(false);
    setIncludedEmails([]);
  };

  const HandleClose = () => {
    setOpen(false);
    ResetState();
  };

  useEffect(() => {
    if (actor?.zsMonitorAccount && import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.production && window.zapscale) {
      window.zapscale.trackEvent(ZapScaleEventsConstants.RECON_MIS_OPEN);
    }
    ListAutoReportTemplateMIS();
    ListAutoReportMIS();
    GetUserEmailList();
  }, []);

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons("Client Reports", actor.name, {}, actor)}>
      <Grid lg={12} container={true} flexDirection={"column"} alignItems={"start"} gap={1}>
        <Box className="vertical_center_align" sx={{ width: "100%", mt: 2 }}>
          <Card className="" sx={{ p: 0.5, mr: "auto", width: 300 }}>
            <Typography fontSize={18} color={"#555"} textAlign={"center"}>
              Client Reporting Module
            </Typography>
          </Card>

          <Card className="" sx={{ p: 0.75, mr: 2 }}>
            <GetAutoComplete<Template, false>
              options={templates}
              selected={selectedTemplate}
              setSelected={setSelectedTemplate}
              onChange={(_e, val) => {
                if (val) {
                  setName(val.name);

                  setEmailCc(val.emailCc);
                  setEmailTo(val.emailTo);
                  setSubject(val.subject);
                  setEmailBody(val.emailBody);
                  setFrequency(val.frequency);
                  setIncludedEmails(val.includedEmails || []);

                  setGeneratedTemplateId(val.id);

                  const foundReports = val.reports
                    .map((report) => reports.find((rep) => rep.name === report.name))
                    .filter((rep) => rep);
                  setSelectedReports(foundReports);

                  setOpen(true);
                } else {
                  ResetState();
                }
              }}
              label="Select Template"
              getOptionLabel={(_opt) => (_opt as Template).name || ""}
              sx={{ width: 200 }}
              multiple={false}
              disabled={nextPage}
            />
          </Card>

          <Button
            size="small"
            className="theme_btn"
            onClick={() => {
              ResetState();
              setOpen(true);
              setNextPage(false);
            }}
          >
            Create Template
          </Button>
        </Box>

        <Card hidden={!open} sx={{ width: "100%" }}>
          <Box className="dialog_header space_between" alignItems="center" pr={1}>
            <DialogTitle>{!selectedTemplate.id ? "Create" : "View"} Reporting Template</DialogTitle>
            <div className="right_box">
              <FormControlLabel
                control={
                  <Android12Switch
                    onClick={() => {
                      ToggleAutoReportTemplateMIS();
                    }}
                    checked={selectedTemplate.isActive === true}
                  />
                }
                label="Active"
              />
              <IconButton onClick={HandleClose}>
                <Close />
              </IconButton>
            </div>
          </Box>

          {/* main content */}
          <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 0.5, minHeight: "50vh" }}>
            <Stack className="firstPage fade_in" direction={"column"} component={"div"} hidden={nextPage}>
              <Grid display={"grid"} gridTemplateColumns={"1fr 1fr"} justifyItems={"center"} alignItems={"center"}>
                {/* left form */}
                <Box
                  component={"form"}
                  className="d_flex"
                  sx={{ flexFlow: "column", gap: 1, "& > div": { minHeight: 45 } }}
                >
                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <FormLabel className="width_150" required={true}>
                        Name :
                      </FormLabel>
                      <TextField
                        size="small"
                        label="Name"
                        sx={{ width: 500 }}
                        value={name}
                        onChange={(_e) => setName(_e.target.value)}
                      />
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <FormLabel className="width_150" required={true}>
                        To :
                      </FormLabel>
                      <EmailInput emails={emailTo} setEmails={setEmailTo} label="Email Address" />
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <FormLabel className="width_150" required={true}>
                        CC :
                      </FormLabel>
                      <EmailInput emails={emailCc} setEmails={setEmailCc} label="Email CC" />
                    </Box>
                  </FormControl>
                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <FormLabel className="width_150" required={true}>
                        Subject :
                      </FormLabel>
                      <TextField
                        size="small"
                        label="Subject"
                        sx={{ width: 500 }}
                        value={subject}
                        onChange={(_e) => setSubject(_e.target.value)}
                      />
                    </Box>
                  </FormControl>
                </Box>

                {/* right options */}

                <Box
                  component={"form"}
                  className="d_flex"
                  sx={{ flexFlow: "column", gap: 1, "& > div": { minHeight: 45 } }}
                >
                  <GetAutoComplete<Reports>
                    options={reports}
                    selected={selectedReports}
                    setSelected={setSelectedReports}
                    disableClearable={true}
                    readOnly={false}
                    label="Selected Reports"
                    limitTags={4}
                    getOptionLabel={(_opt) => (_opt as Reports).name}
                    sx={{
                      width: 400,
                      maxHeight: 160,
                      zIndex: 100,
                      "& .MuiFormControl-root": {
                        bgcolor: "#fff",
                      },
                    }}
                    disabled={!selectedTemplate?.id}
                    hidden={!selectedTemplate?.id}
                  />

                  <FormControl>
                    <Box display={"inline-flex"} gap={4} alignItems="center">
                      <FormLabel className="width_100" required={true}>
                        Frequency :
                      </FormLabel>
                      <FormGroup row={true}>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={frequency === "day"}
                              onClick={(_e) => (frequency === "day" ? setFrequency("") : setFrequency("day"))}
                            />
                          }
                          label="Daily"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={frequency === "week"}
                              onClick={(_e) => (frequency === "week" ? setFrequency("") : setFrequency("week"))}
                            />
                          }
                          label="Weekly"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={frequency === "bi-week"}
                              onClick={(_e) => (frequency === "bi-week" ? setFrequency("") : setFrequency("bi-week"))}
                            />
                          }
                          label="Bi-Weekly"
                        />
                        <FormControlLabel
                          control={
                            <Radio
                              checked={frequency === "month"}
                              onClick={(_e) => (frequency === "month" ? setFrequency("") : setFrequency("month"))}
                            />
                          }
                          label="Monthly"
                        />
                      </FormGroup>
                    </Box>
                  </FormControl>
                  <GetAutoComplete<string>
                    options={allIncludeEmailList}
                    selected={includedEmails}
                    setSelected={setIncludedEmails}
                    disableClearable={true}
                    readOnly={false}
                    getOptionLabel={(_opt) => _opt}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} label={"Include Emails"} required={false} />}
                  />
                </Box>
              </Grid>{" "}
              {/* Grid End of 2 Columns Layout */}
              <FormControl className="emailBodyBox mt_10" fullWidth={true}>
                <TextField
                  multiline={true}
                  required={true}
                  rows={5}
                  minRows={3}
                  placeholder="EmailBody"
                  value={emailBody}
                  onChange={(_e) => setEmailBody(_e.target.value)}
                  sx={{ "& *": { border: "none !important" }, boxShadow: "0 0 8px rgba(0,0,0, 0.2)" }}
                />
              </FormControl>
            </Stack>

            <Stack className="nextPage vertical_center_align" component="div" hidden={!nextPage} my="auto">
              <NextPage selectedReports={selectedReports} setSelectedReports={setSelectedReports} reports={reports} />
            </Stack>
          </DialogContent>

          <DialogActions className="dialog_footer">
            <Typography fontSize={14} mr={"auto"} ml={2} hidden={!!selectedTemplate.id}>
              Step {nextPage ? "2" : "1"} of 2
            </Typography>
            <Button
              size="small"
              className="theme_btn"
              onClick={TriggerAutoReportTemplateMIS}
              hidden={!selectedTemplate.id}
              sx={{ mr: "auto" }}
            >
              Send Report
            </Button>
            <Button
              size="small"
              className="theme_outline_btn"
              onClick={() => setNextPage(false)}
              hidden={!nextPage || !!selectedTemplate.id}
            >
              Back
            </Button>
            <Button size="small" className="theme_outline_btn" onClick={HandleClose} hidden={nextPage}>
              Cancel
            </Button>
            <Button
              size="small"
              className="theme_btn"
              onClick={() => setNextPage(true)}
              hidden={nextPage || !!selectedTemplate.id}
              disabled={notFilled}
            >
              Next
            </Button>
            <Button
              size="small"
              className="theme_btn"
              onClick={() => UpdateAutoReportTemplateMIS()}
              hidden={nextPage || !selectedTemplate.id}
              disabled={!selectedTemplate.id || notFilled}
            >
              Update
            </Button>
            <Button
              size="small"
              className="theme_btn"
              onClick={() => AddAutoReportTemplateMIS()}
              hidden={!nextPage}
              disabled={notFilled}
            >
              Save
            </Button>
          </DialogActions>
        </Card>
      </Grid>
    </LoggedInSkeleton>
  );
};

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface NextPageProps {
  reports: Reports[];
  selectedReports: Reports[];
  setSelectedReports: StateDispatch<Reports[]>;
}

const NextPage = ({ reports, selectedReports, setSelectedReports }: NextPageProps) => {
  const [allReports, setAllReports] = useState<Reports[]>([]);

  useEffect(() => {
    if (reports.length > 0) setAllReports(reports);
  }, [reports]);

  // const [openAddReport, setOpenAddReport] = useState(false);

  return (
    <Box className="fade_in">
      <Typography fontSize={18} color="#999" mb={2} textAlign="center">
        Select Reports:
      </Typography>
      <Box className="d_flex" sx={{ gap: 1 }}>
        <GetAutoComplete<Reports>
          options={allReports}
          selected={selectedReports}
          setSelected={setSelectedReports}
          label="Selected Reports"
          getOptionLabel={(_opt) => (_opt as Reports).name}
          sx={{ width: 400 }}
        />

        {/* <Button size="small" className="theme_btn" onClick={() => setOpenAddReport(true)}>
          Create Report
        </Button> */}

        {/* <AddReport open={openAddReport} setOpen={setOpenAddReport} /> */}
      </Box>
    </Box>
  );
};

export default ClientReporting;
