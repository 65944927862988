import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertTitle, Box, Button, IconButton } from "@mui/material";
import React from "react";
import { ToastContentProps } from "react-toastify";

type ModifiedToastContentProps = Omit<ToastContentProps["toastProps"], "type"> & {
  type: "info" | "success" | "warning" | "error";
};

interface CustomToastProps extends Partial<ToastContentProps> {
  message: string;
  title?: string;
  enableAction?: boolean;
  toastProps?: ModifiedToastContentProps;
  data?: ToastContentProps["data"];
  closeToast?: ToastContentProps["closeToast"];
}

const CustomToast = ({ closeToast, toastProps, message, title, enableAction = false }: CustomToastProps) => {
  return (
    <Alert severity={toastProps.type} sx={{ "& .MuiAlert-message": { width: "100%" } }}>
      <Box
        sx={{ display: "flex", direction: "row", justifyContent: "space-between", alignItems: "start", gap: "12px" }}
      >
        {title ? <AlertTitle>{title}</AlertTitle> : message}
        <Box sx={{ display: "flex", direction: "row", gap: 2 }}>
          {enableAction && (
            <Button color={toastProps.type} sx={{ padding: 0 }}>
              View
            </Button>
          )}
          <IconButton sx={{ padding: 0 }} onClick={closeToast}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {title ? message : null}
    </Alert>
  );
};

export default CustomToast;
