import { AttachFile, Delete, Visibility } from "@mui/icons-material";
import { Box, Button, Chip, CircularProgress, Skeleton, Stack } from "@mui/material";
import { Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import nakadImg from "src/Graphics/NAKAD_FINAL (1).png";
import { IFilledDetails } from "./CommonComponents";
import $ from "./PartnerPortal.module.scss";

export const NavBar = ({ companyName, companyLogo }: { companyName: string; companyLogo: string }) => {
  return (
    <>
      <Stack className={$.nav} direction={"row"} px={3} py={2} position={"sticky"} top={0} bgcolor={"#fff"} zIndex={10}>
        <div className="left">
          <span className="blank"></span>
        </div>
        <div className={$.middle + " vertical_center_align"}>
          {companyLogo !== "" && <img className={$.header_img} src={companyLogo} alt="Partner Logo" />}
          <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
            {companyName ? companyName : ` `}
          </Typography>
        </div>
        <div className={$.end + " vertical_center_align"}>
          <Typography className={$.navText} variant="overline">
            Powered By
          </Typography>
          <Link to={"/"}>
            <img className={$.header_img} src={nakadImg} alt="Nakad Logo" />
          </Link>
        </div>
      </Stack>
    </>
  );
};

export const Header = ({ headerText }: { headerText: string }) => {
  return (
    <>
      <Stack className="center_align" direction={"row"} px={3} py={3}>
        <Typography variant="h5" fontWeight={700} width={900}>
          {headerText}
        </Typography>
      </Stack>
    </>
  );
};

export const LoadingPage = ({ noHeader }: { noHeader?: boolean }) => {
  return (
    <>
      {!noHeader && <NavBar companyName=" " companyLogo="" />}
      <Stack
        className="center_align"
        gap={"60px"}
        maxWidth={800}
        mx={"auto"}
        my={10}
        sx={{ "& .MuiSkeleton-root": { borderRadius: "4px" } }}
      >
        <Skeleton variant="rectangular" width={400} height={32} />
        <Box className="d_flex" gap={25}>
          <Box className="d_flex" gap={2}>
            <Skeleton variant="rectangular" width={32} height={32} />
            <Skeleton variant="rectangular" width={180} height={32} />
          </Box>
          <Box className="d_flex" gap={2}>
            <Skeleton variant="rectangular" width={32} height={32} />
            <Skeleton variant="rectangular" width={180} height={32} />
          </Box>
        </Box>
        <Stack gap={5}>
          <Skeleton variant="rectangular" height={32} />
          <Box className="d_flex" gap={2}>
            <Skeleton variant="rectangular" width={220} height={120} />
            <Skeleton variant="rectangular" width={220} height={120} />
            <Skeleton variant="rectangular" width={330} height={120} />
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export const FilledDetailsGrid = ({ data, isNotMsme }: { data: IFilledDetails; isNotMsme?: boolean }) => {
  return (
    <Box display={"grid"} gridTemplateColumns={isNotMsme ? "auto auto" : "auto auto auto"} columnGap={5} rowGap={2}>
      <Box className="info_box">
        <Typography variant="overline">Name of the Company/Firm/Enterprise</Typography>
        <Typography variant="subtitle2">{data.companyName}</Typography>
      </Box>
      {!isNotMsme && (
        <Box className="info_box">
          <Typography variant="overline">PAN Number</Typography>
          <Typography variant="subtitle2">{data.panNumber}</Typography>
        </Box>
      )}
      <Box className="info_box">
        <Typography variant="overline">Authorized Person Name</Typography>
        <Typography variant="subtitle2">{data.personName}</Typography>
      </Box>
      {isNotMsme && (
        <Box className="info_box">
          <Typography variant="overline">PAN Number</Typography>
          <Typography variant="subtitle2">{data.panNumber}</Typography>
        </Box>
      )}
      {!isNotMsme && (
        <>
          <Box className="info_box">
            <Typography variant="overline">MSME/Udyam Registration Number</Typography>
            <Typography variant="subtitle2">{data.msmeUdyamNumber}</Typography>
          </Box>
          <Box className="info_box">
            <Typography variant="overline">Enterprise Segment</Typography>
            <Typography variant="subtitle2">
              {data.segment ? data.segment?.slice(0, 1)?.toUpperCase() + data.segment?.slice(1) + " Enterprise" : ""}
            </Typography>
          </Box>
        </>
      )}
      <Box className="info_box">
        <Typography variant="overline">Authorized Person Designation</Typography>
        <Typography variant="subtitle2">{data.designation}</Typography>
      </Box>
    </Box>
  );
};

export type UploadFileType = "signedPdf" | "udyamCertificate";

export const CommonUploaderBox = ({
  fileName,
  fileRef,
  fileType,
  viewFn,
  deleteFn: delFn,
  setOpenDialog,
  viewMode,
  ...props
}: {
  fileName: string;
  fileRef?: React.MutableRefObject<File>;
  fileType: UploadFileType;
  viewFn: (type: UploadFileType) => void;
  deleteFn?: (type: UploadFileType) => void;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
  viewMode?: boolean;
  disabled?: boolean;
}) => {
  const fileUploader = useRef<HTMLInputElement>(null);

  const titleBody = {
    signedPdf: {
      title: "Signed and Stamped MSME Confirmation Document",
      body: "Upload the scanned copy of the signed and sealed MSME Confirmation Document",
    },
    udyamCertificate: {
      title: "MSME / Udyam Certificate",
      body: "Upload the valid MSME / Udyam Certificate",
    },
  };

  return (
    <>
      <Box className={$.uploader_box}>
        <Box className="vertical_center_align" p={2}>
          <Box className="left" mr={"auto"}>
            <Typography variant="subtitle1" fontWeight={700} color={"#000"}>
              {titleBody[fileType].title}
            </Typography>
            <Typography variant="body2">{titleBody[fileType].body}</Typography>
          </Box>
          {!viewMode && (
            <Button
              startIcon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
              onClick={() => fileUploader.current && fileUploader.current.click()}
              disabled={props.disabled}
            >
              Upload
            </Button>
          )}
        </Box>
        {fileName && (
          <CommonFileViewDeleteBox
            fileName={fileName}
            fileType={fileType}
            viewFn={viewFn}
            delFn={delFn}
            viewMode={viewMode}
          />
        )}
      </Box>
      <input
        ref={fileUploader}
        name="ConfirmDocUploader"
        type="file"
        multiple={false}
        onClick={(_e) => ((_e.target as HTMLInputElement).value = null)}
        onChange={(_e) => {
          fileRef.current = _e.target.files[0];
          setOpenDialog(true);
        }}
        hidden
        style={{ visibility: "hidden" }}
        accept=".pdf"
      />
    </>
  );
};

export const CommonFileViewDeleteBox = ({
  fileName,
  fileType,
  viewFn,
  delFn: deleteFn,
  viewMode,
}: {
  fileName: string;
  fileType: UploadFileType;
  viewFn: (type: UploadFileType) => void;
  delFn?: (type: UploadFileType) => void;
  viewMode?: boolean;
}) => {
  const [openDelDialog, setOpenDelDialog] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);

  useEffect(() => {
    if (openDelDialog) setDisabled(false);
  }, [openDelDialog]);

  return (
    <>
      <Box className="d_flex" sx={{ borderTop: "1px solid #D7D7D7", background: "#F3F3F3" }} p={2} gap={1}>
        <Chip
          sx={{ mr: "auto", borderRadius: "8px" }}
          variant="outlined"
          label={
            <div className="vertical_center_align">
              <AttachFile fontSize="small" />
              <Typography>{fileName}</Typography>
            </div>
          }
        />
        <Button className={$.BR_fix} variant="outlined" startIcon={<Visibility />} onClick={() => viewFn(fileType)}>
          View
        </Button>
        {!viewMode && (
          <Button
            className={$.BR_fix}
            variant="outlined"
            startIcon={<Delete />}
            color="error"
            onClick={() => setOpenDelDialog(true)}
          >
            Delete
          </Button>
        )}
      </Box>
      <Dialog
        open={openDelDialog}
        onClose={() => setOpenDelDialog(false)}
        classes={{ paper: $.BR_fix }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Are you sure you want to Delete this file?</DialogTitle>
        <DialogActions>
          <Button
            className={$.BR_fix}
            onClick={() => {
              setOpenDelDialog(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            className={$.BR_fix}
            color="error"
            disabled={disabled}
            onClick={() => {
              deleteFn(fileType);
              setDisabled(true);
            }}
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const BackdropLoader = ({
  openBackDrop,
  loadingText,
}: {
  openBackDrop: boolean;
  loadingText: React.ReactNode;
}) => {
  return (
    <Dialog sx={{ color: "#541C4C", zIndex: (theme) => theme.zIndex.modal + 1500 }} open={openBackDrop}>
      <Box
        className="vertical_center_align"
        component={"div"}
        px={2}
        py={3}
        gap={"12px"}
        borderRadius={"12px"}
        bgcolor={loadingText ? "#fff" : "transparent"}
        width={loadingText ? 380 : "unset"}
      >
        <CircularProgress color="inherit" />
        <Typography>{loadingText}</Typography>
      </Box>
    </Dialog>
  );
};
