import React from "react";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { PageStatus } from "./LedgerRequestPortal";

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export const LrPortalContext = createContext<ILrPortalContext>(null);

export interface ILrPortalContext {
  pageStatus: PageStatus;
  setPageStatus: StateDispatch<PageStatus>;
  editing: boolean;
  setEditing: StateDispatch<boolean>;
}

export default function LedgerRequestPortalContext({ children }: { children: React.ReactNode }) {
  const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");
  const [editing, setEditing] = useState<boolean>(false);

  const commonContextValues: ILrPortalContext = {
    pageStatus,
    setPageStatus,
    editing,
    setEditing,
  };

  return <LrPortalContext.Provider value={commonContextValues}>{children}</LrPortalContext.Provider>;
}
