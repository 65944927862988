import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { DOCUMENT_TYPES_OPTIONS_CUSTOM_GAP } from "src/Utils/Recon/Recon360/Constants";
import { CUSTOM_GAP_DES_COLUMNS } from "src/Utils/Recon/ReconSetting/Constants";
import { uiLoggerNamesCustomGapDescription } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import MonetaryInput from "../../Common/MonetaryInput";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import Table, { ColumnDefs } from "../../ReactTable/Table";
import { formatNumberINR } from "../ReconMIS/utils";

const CustomGapDescription = () => {
  const [openAddCustomGapModal, setOpenAddCustomGapModal] = useState<boolean>(false);
  const [loader, setLoader] = useState(false);
  const [loaderRen, setLoaderRen] = useState(false);
  const [editCategory, setEditCategory] = useState(null);
  const [editElementIndex, setEditElementIndex] = useState(null);
  const [fromEditModal, setFromEditModal] = useState(false);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  const [data, setData] = useState<any>({});

  const columnDefinitionCustomGap: ColumnDefs[] = [
    {
      Header: "Logic",
      id: "logic",
      accessor: "actionOwner",
      width: 450,
      Sort: false,
      Cell: ({ row }) => (
        <div className="noHide d_flex" style={{ flexDirection: "column", flexWrap: "wrap" }}>
          {row.original.logic.map((item, index) => (
            <Chip
              key={index}
              label={`${item.column} ${item.operator} ${formatNumberINR(item.condition)}`}
              // Add margin for separation
              sx={{
                m: "5px",
                overflow: "visible",
                wordWrap: "break-word",
                "& > span": { overflow: "auto", textOverflow: "initial" },
              }}
            />
          ))}
        </div>
      ),
    },
    {
      Header: "Gap description",
      accessor: "gapDescription",
      width: 300,
      Sort: false,
    },
    {
      Header: "Action Owner",
      accessor: "actionOwner",
      width: 150,
      Sort: false,
    },
    {
      Header: "Edit",
      accessor: "edit",
      width: 100,
      Sort: false,
    },
    {
      Header: "Delete",
      accessor: "delete",
      width: 100,
      Sort: false,
    },
  ];

  const columnsOptions = [
    "Document Type (Own)",
    "Document Type (Partner)",
    "Amount Difference",
    "Amount (Own)",
    "Amount (Partner)",
    "Entry Type (Own)",
    "Entry Type (Partner)",
    "Group Name (Own)",
    "Group Name (Partner)",
  ];
  const operatorsMap = {
    "Document Type (Own)": ["Exact Text"],
    "Document Type (Partner)": ["Exact Text"],
    "Amount Difference": ["Smaller Than (<)", "More Than (>)"],
    "Amount (Own)": ["Smaller Than (<)", "More Than (>)"],
    "Amount (Partner)": ["Smaller Than (<)", "More Than (>)"],
    "Entry Type (Own)": ["Exact Text"],
    "Entry Type (Partner)": ["Exact Text"],
    "Group Name (Own)": ["Exact Text"],
    "Group Name (Partner)": ["Exact Text"],
  };

  const actionOwnerOptions = ["Own", "Partner"];
  const gapDescriptionVal = useRef("");
  const [rowsDataAddCustomGap, setRowsDataAddCustomGap] = useState({
    actionOwner: "Own",

    gapDescription: "",

    logic: [
      {
        column: "",
        operator: "",
        condition: "",
      },
    ],
  });
  const [selectedCategoryForAddModal, setSelectedCategoryForAddModal] = useState<string>(null);

  const columnDefinitionAddCustomGap = useMemo(
    () => [
      {
        header: "Columns",
        accessorKey: "column",
        size: 200,
        enableSorting: false,
        cell: ({ row }: any) => (
          <div>
            <Autocomplete
              onChange={(e, value) => {
                handleColumnsChange(e, value, row.index);
              }}
              options={columnsOptions}
              size="small"
              getOptionLabel={(option) => option}
              defaultValue={row.original.column}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        ),
      },
      {
        header: "Operator",
        accessorKey: "operator",
        size: 200,
        enableSorting: false,
        cell: ({ row }: any) => (
          <div>
            <Autocomplete
              onChange={(event, value) => handleOperatorChange(event, value, row.index)}
              options={operatorsMap[row.original.column] || []} // Use the selected column to fetch operator options
              size={loader ? "small" : "small"}
              getOptionLabel={(option) => option}
              defaultValue={row.original.operator}
              disableClearable={true}
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        ),
      },
      {
        header: "Condition",
        accessorKey: "condition",
        size: 200,
        enableSorting: false,
        cell: ({ row }: any) => (
          <div>
            {(row.original.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_PARTNER ||
              row.original.column === CUSTOM_GAP_DES_COLUMNS.DOC_TYPE_OWN ||
              row.original.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_OWN ||
              row.original.column === CUSTOM_GAP_DES_COLUMNS.GROUP_NAME_PARTNER) &&
            row.original.operator === "Exact Text" ? (
              <TextField
                size="small"
                type="text"
                value={row.original.condition}
                onChange={(event) => handleConditionChange(event.target.value, row.index)}
              />
            ) : row.original.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_PARTNER ||
              row.original.column === CUSTOM_GAP_DES_COLUMNS.ENTRY_TYPE_OWN ? (
              <div>
                <Autocomplete
                  freeSolo={false}
                  options={DOCUMENT_TYPES_OPTIONS_CUSTOM_GAP.map((name) => name[0].toUpperCase() + name.slice(1))}
                  renderInput={(params) => <TextField {...params} />}
                  fullWidth={true}
                  size="small"
                  value={row.original.condition}
                  onChange={(_e, val) => handleConditionChange(val, row.index)}
                />
              </div>
            ) : (
              <MonetaryInput
                size="small"
                key={row.original.condition}
                value={
                  !isNaN(parseFloat(row.original.condition as string))
                    ? parseFloat(row.original.condition as string).toLocaleString("en-IN")
                    : row.original.condition || ""
                }
                placeholder={row.original.condition as string}
                setValue={(value) => {
                  if ((value.toString() !== row.original?.condition?.toString() && value) || value === 0)
                    handleConditionChange(value, row.index);
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                }}
                sx={{
                  "input::placeholder": {
                    fontSize: "14px",
                  },
                }}
              />
            )}
          </div>
        ),
      },

      {
        header: "Delete",
        accessorKey: "delete",
        size: 150,
        enableSorting: false,
        cell: ({ row }: any) => (
          <div onClick={() => deleteLogicItem(row.index)}>
            <DeleteIcon />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [loader]
  );

  const handleActionOwnerChange = (_, value) => {
    setRowsDataAddCustomGap((prevData) => ({
      ...prevData,
      actionOwner: value, // Update the actionOwner value in your data
    }));
  };

  const handleGapDescriptionChange = (event) => {
    const { value } = event.target;
    if (value.length <= 100) {
      // Check the character limit
      gapDescriptionVal.current = value;
      setRowsDataAddCustomGap((prevData) => ({
        ...prevData,
        gapDescription: value,
      }));
    }
  };

  const handleOperatorChange = (_, value, rowIndex) => {
    setRowsDataAddCustomGap((prevState) => {
      const updatedLogic = [...prevState.logic];
      updatedLogic[rowIndex].operator = value;
      // Reset the condition to an empty string
      updatedLogic[rowIndex].condition = "";

      return {
        ...prevState,
        logic: updatedLogic,
      };
    });
  };

  const handleConditionChange = (value, rowIndex) => {
    setRowsDataAddCustomGap((prevState) => {
      const updatedLogic = [...prevState.logic];
      updatedLogic[rowIndex].condition = value;
      return {
        ...prevState,
        logic: updatedLogic,
      };
    });
  };

  const deleteLogicItem = (indexToDelete) => {
    setRowsDataAddCustomGap((prevState) => ({
      ...prevState,
      logic: prevState.logic.filter((_, index) => index !== indexToDelete),
    }));
    setLoader(!loader);
  };

  // Modify the handleColumnsChange function to accept the rowIndex
  const handleColumnsChange = (_, value, rowIndex) => {
    // Ensure that the row object is defined
    //   if (rowsDataAddCustomGap.logic[rowIndex]) {
    // Create a copy of the current state
    const updatedRowsData = { ...rowsDataAddCustomGap };

    // Update the selected column
    updatedRowsData.logic[rowIndex].column = value;
    updatedRowsData.gapDescription = gapDescriptionVal.current;

    // Get the available operator options for the selected column
    const operatorOptions = operatorsMap[value] || [];
    // Update the operator to the first option (if available)
    if (operatorOptions.length > 0) {
      updatedRowsData.logic[rowIndex].operator = operatorOptions[0];
      // Reset the condition to an empty string
      updatedRowsData.logic[rowIndex].condition = "";
    } else {
      // If no operator options are available, reset the operator
      updatedRowsData.logic[rowIndex].operator = "";
      // Reset the condition to an empty string
      updatedRowsData.logic[rowIndex].condition = "";
    }

    // Update the state with the modified data

    setRowsDataAddCustomGap(updatedRowsData);
    setLoader(!loader);
    // }
  };
  const handleAddLogicItem = () => {
    // Create a new logic item with empty strings
    const newLogicItem = {
      column: "",
      operator: "",
      condition: "",
    };

    // Update the state to include the new logic item
    setRowsDataAddCustomGap((prevState) => {
      return {
        ...prevState,
        logic: [...prevState.logic, newLogicItem],
      };
    });
    setLoader(!loader);
  };

  const handleCloseAddModal = () => {
    // setOpenAddCustomGapModal(false);
    // Optionally, you can reset the rowsDataAddCustomGap to clear the form
    gapDescriptionVal.current = "";
    setRowsDataAddCustomGap({
      actionOwner: "Own",
      gapDescription: "",
      logic: [{ column: "", operator: "", condition: "" }],
    });
    setOpenAddCustomGapModal(false);
    setLoader(!loader);
    setLoaderRen(false);
  };

  // Define a function to handle editing an element
  const handleEdit = (category, elementIndex) => {
    // Perform the edit action for the specific element within the category
    setRowsDataAddCustomGap(data?.[category]?.[elementIndex]);
    gapDescriptionVal.current = data?.[category]?.[elementIndex]?.gapDescription;
    setEditCategory(category);
    setEditElementIndex(elementIndex);
    setFromEditModal(true);
    setLoaderRen(true);
    setOpenAddCustomGapModal(true);
    setLoader(!loader);
  };

  const handleDelete = (category, elementIndex) => {
    setData((prevData) => ({
      ...prevData,
      [category]: prevData[category].filter((_, index) => index !== elementIndex),
    }));
  };

  const handleSave = () => {
    if (fromEditModal) {
      // Create a copy of the current data object
      const updatedData = { ...data };

      // Find the category to edit
      const categoryToEdit = updatedData[editCategory];

      if (categoryToEdit) {
        // Check if the specified index is valid
        if (editElementIndex >= 0 && editElementIndex < categoryToEdit.length) {
          // Check if the `logic` array in rowsDataAddCustomGap is empty
          if (rowsDataAddCustomGap.logic.length === 0) {
            // If `logic` is empty, delete the element at the specified index
            categoryToEdit.splice(editElementIndex, 1);
          } else {
            // Replace the element at the specified index with rowsDataAddCustomGap

            categoryToEdit[editElementIndex] = rowsDataAddCustomGap;
          }

          // Update the data object
          updatedData[editCategory] = categoryToEdit;

          // Now, `updatedData` contains the modified data
          // You can set it as your new state or perform any other actions needed
          setData(updatedData);
          // insertCustomSummaryDescription(updatedData);
        }
      }
    } else {
      // Create a copy of the data object
      const updatedData = { ...data };

      // Add the rowsDataAddCustomGap to the selected category
      updatedData[selectedCategoryForAddModal].push(rowsDataAddCustomGap);

      // Update the data state
      setData(updatedData);
      // insertCustomSummaryDescription(updatedData);
    }
    setLoaderRen(true);
    handleCloseAddModal();
    // setLoader(!loader)
  };

  const replaceWordStatusName = (word: string) => {
    const replacements = {
      invoiceMismatchOther: "Invoice Mismatch Other",
      invoiceNotPresentInBusinessPartner: "Invoice Not Present In BusinessPartner",
      invoiceNotPresentInOwn: "Invoice Not Present In Own",
      paymentMismatchOther: "Payment Mismatch Other",
      paymentNotPresentBusinessPartner: "Payment Not Present BusinessPartner",
      paymentNotPresentInOwn: "Payment Not Present In Own",
      dncnMismatchOther: "DNCN Mismatch Other",
      dncnNotPresentBusinessPartner: "DNCN Not Present BusinessPartner",
      dncnNotPresentInOwn: "DNCN Not Present In Own",
      suggestedTDSMatches: "Suggested TDS Matches",
      tdsNotPresentBusinessPartner: "TDS Not Present BusinessPartner",
      tdsNotPresentInOwn: "TDS Not Present In Own",
      miscellneousOwn: "Miscellaneous Own",
      msicelleneousBusinessPartner: "Miscellaneous BusinessPartner",
      manualMisMatch: "Manual MisMatch",
    };
    const modifiedWord = replacements[word as keyof typeof replacements];
    return modifiedWord;
  };

  const isLogicEmpty = (logic) => {
    for (const item of logic) {
      if (
        !item.column ||
        !item.operator ||
        (typeof item.condition === "string" && item.condition?.trim() === "") ||
        gapDescriptionVal.current?.trim() === ""
      ) {
        return true; // Logic is not complete
      }
    }
    return false; // Logic is complete
  };

  useEffect(() => {
    getCustomSummaryDescription();
    // eslint-disable-next-line
  }, []);

  const getCustomSummaryDescription = () => {
    useFetch(API_ENDPOINTS.GET_CUSTOM_SUMMARY_DESCRIPTION.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_CUSTOM_SUMMARY_DESCRIPTION.failureMessage,
      thenCallBack: (response) => {
        setData(response.data?.data || {});
      },
    });
  };

  const insertCustomSummaryDescription = (dataPayload) => {
    setShowLoadingIcon(true);
    useFetch(API_ENDPOINTS.INSERT_CUSTOM_SUMMARY_DESCRIPTION.url, "POST", {
      failureMessage: API_ENDPOINTS.INSERT_CUSTOM_SUMMARY_DESCRIPTION.failureMessage,
      showSuccessToast: true,
      data: { customGapDescription: dataPayload },
      thenCallBack: () => {
        setShowLoadingIcon(false);
        // handleCloseAddModal();
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <>
        <Card>
          <CardContent>
            <div className="space_between">
              <div className="fs_20 fw_600">Custom Gap Description</div>
              <div className="vertical_center_align">
                <Button
                  variant="contained"
                  onClick={() => {
                    insertCustomSummaryDescription(data);
                    uiLogger(
                      uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Save_Click
                        .functionName,
                      null,
                      null,
                      {
                        message:
                          uiLoggerNamesCustomGapDescription
                            .UI_RL_ReconSummaryCalculation_CustomGapDescription_Save_Click.message,
                      }
                    );
                  }}
                  className="theme_btn"
                  disabled={showLoadingIcon}
                >
                  <LoadingIcon loading={showLoadingIcon} />
                  Save
                </Button>
              </div>
            </div>
            <div>
              <div className="fs_20 mt_20">Recon status:</div>
            </div>

            <div>
              {Object?.entries(data)?.map(([category, categoryData]) => (
                <CategoryComponent
                  key={category}
                  category={category}
                  data={categoryData}
                  handleEdit={handleEdit}
                  handleDelete={handleDelete}
                  setOpenAddCustomGapModal={setOpenAddCustomGapModal}
                  setSelectedCategoryForAddModal={setSelectedCategoryForAddModal}
                  columnDefinitionCustomGap={columnDefinitionCustomGap}
                  setLoaderRen={setLoaderRen}
                  loaderRen={loaderRen}
                  replaceWordStatusName={replaceWordStatusName}
                  setFromEditModal={setFromEditModal}
                />
              ))}
            </div>
          </CardContent>
        </Card>
        <Dialog
          open={openAddCustomGapModal}
          // onClose={handleCloseAddModal}
          aria-labelledby="CategoryForAddModal"
          maxWidth="md"
          // className="editBpContactMaster_popup"
        >
          <DialogTitle>
            <Grid container={true} direction="row" className="space_between">
              <span className="fs_24 fw_600">
                Gap description logic: {replaceWordStatusName(selectedCategoryForAddModal)}
              </span>
              <span className="vertical_center_align">
                {!fromEditModal && (
                  <button
                    className="delete ml_20  "
                    aria-label="close"
                    type="button"
                    onClick={() => handleCloseAddModal()}
                  />
                )}
              </span>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <div className="vertical_center_align  fw_600 mt_15">
              <div className="w_160 fs_18">Gap description:</div>
              <div>
                <TextareaAutosize
                  minRows={2} // Limit to two rows
                  maxRows={2}
                  // placeholder="Enter a description"
                  // value={rowsDataAddCustomGap.gapDescription}
                  value={gapDescriptionVal.current}
                  onChange={handleGapDescriptionChange}
                  className="mw_250"
                  style={{ width: "100%", padding: "5px" }}
                />
              </div>
            </div>
            <div className="vertical_center_align  fw_600 mt_15 ">
              <div className="w_160 fs_18">Action Owner:</div>
              <div className="w_200">
                <Autocomplete
                  size="small"
                  disableClearable={true}
                  options={actionOwnerOptions}
                  value={rowsDataAddCustomGap.actionOwner}
                  onChange={handleActionOwnerChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>
            </div>
            <div className="mt_15">
              <div className="fs_18 fw_600 w_160">Logic:</div>
            </div>

            <div className="pt_10">
              <BpDashboardTable
                columns={columnDefinitionAddCustomGap}
                rows={rowsDataAddCustomGap.logic}
                sortEnable={false}
                fromTab={"CustomGapDescription"}
              />
            </div>
          </DialogContent>
          <DialogActions className="dialog_footer space_between">
            <div>
              <Button
                className="theme_btn"
                onClick={() => {
                  handleAddLogicItem();
                }}
              >
                +Add
              </Button>
            </div>
            <div>
              <Button
                className="theme_btn"
                onClick={() => {
                  if (isLogicEmpty(rowsDataAddCustomGap.logic)) {
                    // alert("Please fill in all logic fields before saving.");
                    toast.error(
                      <CustomToast message="Please fill in all logic fields & Gap description before saving." />
                    );
                  } else {
                    handleSave();
                    if (editElementIndex) {
                      uiLogger(
                        uiLoggerNamesCustomGapDescription
                          .UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Update_Click.functionName,
                        null,
                        null,
                        {
                          message:
                            uiLoggerNamesCustomGapDescription
                              .UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Update_Click.message,
                        }
                      );
                    } else {
                      uiLogger(
                        uiLoggerNamesCustomGapDescription
                          .UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Update_Click.functionName,
                        null,
                        null,
                        {
                          message:
                            uiLoggerNamesCustomGapDescription
                              .UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Update_Click.message,
                        }
                      );
                    }
                  }
                }}
              >
                Update
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </>
    </>
  );
};
function CategoryComponent({
  category,
  data,
  handleEdit,
  handleDelete,
  setOpenAddCustomGapModal,
  setSelectedCategoryForAddModal,
  columnDefinitionCustomGap,
  setLoaderRen,
  loaderRen,
  replaceWordStatusName,
  setFromEditModal,
}) {
  // Define the table columns using the provided `columnDefinitionCustomGap`
  const columns = columnDefinitionCustomGap;

  // Assign display names to the arrow functions
  columns[3].Cell = function EditCell({ row }) {
    return (
      <div
        onClick={() => {
          handleEdit(category, row.index);
          uiLogger(
            uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Click
              .functionName,
            null,
            null,
            {
              message:
                uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_EditPencil_Click
                  .message,
            }
          );
        }}
      >
        <EditIcon />
      </div>
    );
  };
  // delete has 4 index in columnDefinitionCustomGap

  columns[4].Cell = function DeleteCell({ row }) {
    return (
      <div
        onClick={() => {
          handleDelete(category, row.index);
          uiLogger(
            uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Delete_Click
              .functionName,
            null,
            null,
            {
              message:
                uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Delete_Click
                  .message,
            }
          );
        }}
      >
        <DeleteIcon />
      </div>
    );
  };

  const getBlankData = (count) => {
    const dataArray = [];
    for (let i = 0; i < count; i++) {
      dataArray.push({
        actionOwner: "",
        gapDescription: "",
        logic: [
          {
            column: "",
            operator: "",
            condition: "",
          },
        ],
      });
    }
    return dataArray;
  };

  return (
    <div className="mt_40">
      <>
        <Button variant="contained" className="text_trans_none bg_gray text_col_white mw_300" disabled={true}>
          {replaceWordStatusName(category)} {/* Use the category as the button label */}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setFromEditModal(false);
            setOpenAddCustomGapModal(true);
            setSelectedCategoryForAddModal(category);
            setLoaderRen(true);
            uiLogger(
              uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Click
                .functionName,
              null,
              null,
              {
                message:
                  uiLoggerNamesCustomGapDescription.UI_RL_ReconSummaryCalculation_CustomGapDescription_Add_Click
                    .message,
              }
            );
          }}
          className="theme_btn ml_30"
        >
          +Add
        </Button>
        {loaderRen ? (
          <Table
            columns={columns}
            rows={getBlankData(data?.length + 1)}
            sortEnable={false}
            loading={true}
            fromTab={"CustomGapDescription"}
          />
        ) : (
          <Table columns={columns} rows={data} sortEnable={false} fromTab={"CustomGapDescription"} />
        )}
      </>
    </div>
  );
}

export default CustomGapDescription;
