import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdButton, NdTextField, validateEmail } from "../MsmePartnerPortal/CommonComponents";

export default function PortalAccessDialog({ openPortalAccessDialog, setOpenPortalAccessDialog }) {
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(true);
  const [submittingRequest, setSubmittingRequest] = useState(false);
  const [accessRejected, setAccessRejected] = useState(false);

  const submitRequest = async () => {
    setSubmittingRequest(true);
    useFetch(API_ENDPOINTS.VERIFY_EMAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.VERIFY_EMAIL.failureMessage,
      showSuccessToast: true,
      data: {
        data: encryptedData,
        email: email,
      },
      thenCallBack: (res) => {
        setSubmittingRequest(false);
        if (res.data.isAllowed) {
          setOpenPortalAccessDialog(false);
        } else {
          setAccessRejected(true);
        }
      },
      catchCallBack: () => {
        setSubmittingRequest(false);
      },
    });
  };
  return (
    <>
      <Dialog
        sx={{ zIndex: 1500 }}
        open={openPortalAccessDialog}
        PaperProps={{
          style: {
            borderRadius: "4px",
            width: "600px",
          },
        }}
      >
        <DialogTitle sx={{ padding: "16px 24px 8px" }}>One last step before you access the portal</DialogTitle>
        <DialogContent
          sx={{
            padding: "16px 24px",
            paddingTop: "16px !important",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
          }}
        >
          {accessRejected ? (
            <>
              <Alert sx={{ borderRadius: "4px !important" }} severity="warning">
                <span className="fw_500 fs_16">You are not allowed to access the portal</span>
              </Alert>
              <p className="fw_400 fs_14">
                If you have any issues please contact to{" "}
                <a className="fw_700 fs_14" href="mailto:support@nakad.co">
                  support@nakad.co
                </a>
              </p>
            </>
          ) : (
            <>
              <Typography fontSize={14} fontWeight={500}>
                Enter your Official Email address
              </Typography>
              <NdTextField
                label="Enter Email"
                value={email}
                onChange={(e) => {
                  setEmailError(!validateEmail(e.target.value));
                  setEmail(e.target.value);
                }}
                error={email && emailError}
                helperText={email && emailError && "Invalid Email ID"}
              />
              <Typography fontSize={12} fontWeight={400} sx={{ color: "#1E8A60", textTransform: "uppercase" }}>
                You will be redirected to the portal after this step
              </Typography>
            </>
          )}
        </DialogContent>
        {
          <DialogActions sx={{ padding: "8px", minHeight: "36px" }}>
            {!accessRejected && (
              <NdButton disabled={emailError || submittingRequest} variant="contained" onClick={submitRequest}>
                {<LoadingIcon loading={submittingRequest} />} Submit
              </NdButton>
            )}
          </DialogActions>
        }
      </Dialog>
    </>
  );
}
