export const labels: string[] = [
  "Document Identifier",
  "Document Date",
  "Posting Date",
  "Clearing Date",
  "Debit Amount",
  "Credit Amount",
  "Invoice Number",
  "Payment ID",
  "DN/CN ID",
  "Inv ID for DN/CN",
  "Document Number",
  "Clearing Document",
  "Other Unique ID",
  "Miscellaneous",
  "Particulars/ Narration",
  "Withholding Tax Amount",
];
export const groupLabels: string[] = [
  "",
  "Date",
  "",
  "",
  "Amount",
  "",
  "IDs",
  "",
  "",
  "",
  "",
  "",
  "",
  "Miscellaneous",
  "",
  "",
];

export const columnHeaderDescription = {
  documentType: "Name of the column which contains the document or voucher type.",
  documentDate: "Name of the column which contains date of Invoice/ payment/ debit note etc.",
  postingDate:
    "Name of the column which contains date on which the entry has been posted in the ERP.  If not present, kindly leave it blank.",
  clearingDate: "The Column which contains the date, on which the entry has been closed.",
  debitAmount: "Name of the column containing entries which are debited",
  creditAmount: "Name of the column containing entries which are credited",
  referenceNumber: "Name of the column containing invoice numbers",
  paymentId: "Name of the column containing Payment IDs. If not present, kindly leave it blank.",
  dncnId:
    "Unique Identifier to match DN/CN entries, if you maintain it in “invoice no.” column then add the same field here",
  invoiceIdForDncn:
    "Applicable only for DNCN entries Column Name which contains reference to the Invoice on which the DNCN has been raised.",
  documentNumber:
    "Internal reference number, which shall be used for clubbing Invoices, Payments, DNCN and then match with entries on BP side.",
  clearingDocument: "The column which contains the document no. which has been used to clear the entry.",
  otherUniqueId:
    "Name of the column having ID generated by the ERP software or any other ID like Assignment Number. If not present, kindly leave it blank.",
  miscellaneous: "Name of the columns you’ll like to see in results in Own Ledger in Output.",
  particulars: "Name of the column containing particular/narration information of the entry.",
  taxAmount: "Name of the column containing withholding tax amount",
};

export enum saveAsNewTemplate {
  SAVE_AS_NEW = "saveAsNew",
  UPDATE_EXISTING = "updateExisting",
}
