import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import MicrosoftLogo from "../../../Graphics/Microsoft logo.svg";

const SelectEmailDomain = (props: any) => {
  const handleClose = () => {
    props.setOpenSelectEmailDomain(false);
  };

  return (
    <>
      <Dialog
        open={props.openSelectEmailDomain}
        // onClose={handleClose}
        aria-labelledby="SelectEmailDomain"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Select email domain</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="mt_20 center_align">
              <Button
                variant="outlined"
                // startIcon={<DeleteIcon />}
                style={{
                  width: "215px",
                  borderColor: "#5E5E5E",
                  color: "#5E5E5E",
                  borderRadius: 0,
                  textTransform: "none",
                  padding: "8px",
                }}
                onClick={() => {
                  handleClose();
                  props.getAuthorisationCodeUrl();
                }}
              >
                <span className="vertical_center_align mr_10">
                  <img src={MicrosoftLogo} alt="" />
                </span>{" "}
                Sign in with Microsoft
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn w_160"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmail(true);
                }}
              >
                Gmail
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn w_160"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmailForOutlook(true);
                }}
              >
                Microsoft Outlook
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn w_160"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmailForZimbra(true);
                }}
              >
                Zimbra
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn w_160"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmailForGoDaddy(true);
                }}
              >
                GoDaddy
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmailForMicrosoftExchange(true);
                }}
              >
                Microsoft Exchange
              </Button>
            </div>
            <div className="mt_20 center_align">
              <Button
                className="theme_btn w_160"
                onClick={() => {
                  handleClose();
                  props.setOpenSendEmailFromOfficialEmailForOther(true);
                }}
              >
                Other
              </Button>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer left_align">
          <div className="w_100_per">
            <Button
              className="theme_outline_btn"
              onClick={() => {
                handleClose();
              }}
            >
              Close
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectEmailDomain;
