import { DineroObject } from "dinero.js";

/**
 * Utility interface
 */
export const DineroAmount = { amount: 0, currency: "INR", precision: 2 } as DineroObject;
export type TypeCurrency = keyof typeof DineroAmount.currency;
export interface SapLedger {
  assignment: string;
  documentNumber: number;
  documentType: string;
  documentDate: Date;
  lineId: string;
  paymentTerms: string;
  paymentDate: Date;
  netDueDate: Date;
  amountLCvalue: string;
  Lcurrency: string;
  remarks: string;
  itemState: string;
  clearingDate: Date;
  clearingDocumentNumber: number;
  reference: string;
  postingDate: Date;
  specialGlInd: string;
  companyCode: string;
  buisnessArea: string;
  buisnessPlace: string;
  yearMonth: string;
  glAccount: string;
  paymentMethod: string;
  purchaseDocument: string;
  baselinePaymentDate: Date;
  amountInDocCurrency: string;
  currency: string;
  withholdtaxbaseamount: string;
  withholdtaxamount: string;
  taxcurrency: string;
}
export interface SapUploadRequest {
  supplierCode: number;
  ledger: SapLedger[];
}

export enum AccountTypeForRecon {
  ReconUser = "ReconUser",
  Partner = "Partner",
  Both = "Both",
}
