import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  index: number;
  value: number;
  sx?: SxProps;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, sx, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, height: "inherit", ...sx }}>{children}</Box>}
    </div>
  );
};

export default TabPanel;
