import React from "react";
import { createContext, Dispatch, SetStateAction, useState } from "react";
import { PageStatus } from "./BalanceConfirmationPortal";

type StateDispatch<T = any> = Dispatch<SetStateAction<T>>;

export const BcPortalContext = createContext<IBcPortalContext>(null);

export interface IBcPortalContext {
  confirmStatus: boolean;
  setConfirmStatus: StateDispatch<boolean>;
  pageStatus: PageStatus;
  setPageStatus: StateDispatch<PageStatus>;
  editing: boolean;
  setEditing: StateDispatch<boolean>;
}

export default function BalanceConfirmationPortalContext({ children }: { children: React.ReactNode }) {
  const [confirmStatus, setConfirmStatus] = useState<boolean>(null);
  const [pageStatus, setPageStatus] = useState<PageStatus>("NotFilled");
  const [editing, setEditing] = useState<boolean>(false);

  const commonContextValues: IBcPortalContext = {
    confirmStatus,
    setConfirmStatus,
    pageStatus,
    setPageStatus,
    editing,
    setEditing,
  };

  return <BcPortalContext.Provider value={commonContextValues}>{children}</BcPortalContext.Provider>;
}
